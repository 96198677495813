import React, { PureComponent } from 'react';
import { Tabs } from 'antd';
import cn from 'classnames';

import PropTypes from 'prop-types';
import Immutable from 'immutable';

import MenuItem from './MenuItem';

import styles from './menu.less';

const { TabPane } = Tabs;

class BaseMenuList extends PureComponent {
  static propTypes = {
    items: PropTypes.object,
  };

  state = {
    order: this.props.items.toList(),
    dropdownMenuItems: Immutable.List(),
    itemId: null,
    afterItemId: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.items, this.props.items)) {
      this.setState({
        order: nextProps.items.toList(),
      });
    }
  }

  onDrop = (dragItem, dropItem) => {
    // save
    const newOrderList = this.state.order.map((o) => o.key);
    this.props.onDrop && this.props.onDrop(dragItem, dropItem, newOrderList);
  };

  onMoveItem = (dragItem, dropItem) => {
    if (dragItem.dragType == dropItem.dragType) {
      const itemId = dragItem.key;
      const afterItemId = dropItem.key;

      if (itemId !== this.state.itemId || afterItemId !== this.state.afterItemId) {
        // get dragging item
        const findIndex = (id) => this.state.order.findIndex((o) => o.key === id);
        const dragItemIndex = findIndex(itemId);
        const realDragItem = dragItemIndex !== -1 ? this.state.order.get(dragItemIndex) : dragItem.item;

        // reorder
        let arr = this.state.order;
        // remove from old position if from this list
        if (dragItemIndex !== -1) {
          arr = arr.delete(dragItemIndex);
        }
        // add item to new position
        arr = arr.insert(findIndex(afterItemId), realDragItem);

        // save to local
        this.setState({
          order: arr,
          itemId,
          afterItemId,
        });
      }
    }
  };

  onRemoveItem = (dragItem) => {
    const itemId = dragItem.key;
    const findIndex = (id) => this.state.order.findIndex((o) => o.key === id);
    const dragItemIndex = findIndex(itemId);

    // save in local state
    const arr = this.state.order.delete(dragItemIndex);
    this.setState({
      order: arr,
    });

    // save order
    const newOrderList = arr.map((o) => o.key);
    this.props.onRemoved && this.props.onRemoved(dragItem, null, newOrderList);
  };

  render() {
    const {
      listId,
      activeId,
      dragType,
      canDrag,
      canDrop,
      tabPosition,
      direction,
      className,
      popupClassName,
      itemClassName,
    } = this.props;

    const { order } = this.state;

    return (
      <Tabs
        listId={listId}
        className={cn(
          styles.menu,
          {
            [styles.menuHorizontal]: direction === 'row',
            [styles.menuVertical]: direction === 'column',
          },
          className,
        )}
        popupClassName={popupClassName}
        tabPosition={tabPosition}
        activeKey={activeId}
      >
        {order.map((item, i) => (
          <TabPane
            listId={listId}
            tab={
              <MenuItem
                listId={listId}
                key={String(i)}
                item={item}
                dragType={dragType}
                canDrag={canDrag}
                canDrop={canDrop}
                onMoveItem={this.onMoveItem}
                onRemoveItem={this.onRemoveItem}
                onDrop={this.onDrop}
                className={cn(styles.item, itemClassName)}
              />
            }
            key={item.key}
          />
        ))}
      </Tabs>
    );
  }
}

export default BaseMenuList;
