import React from 'react';
import ImmutableProptypes from 'react-immutable-proptypes';
import cn from 'classnames';

import Immutable from 'immutable';
import PropTypes from 'prop-types';

import RecordDropdown from './RecordDropdown';
import styles from './controls.less';

class ObjectControl extends React.PureComponent {
  loadAvailableItems = (...args) => {
    if (this.props.config.get('enableSelect')) {
      const loadData = this.props.controlConfig.get('loadAvailableItems');
      loadData(...args);
    }
  };

  requestParams = (controlConfig = this.props.controlConfig) =>
    (controlConfig.get('requestParams') && controlConfig.get('requestParams').toJS
      ? controlConfig.get('requestParams').toJS()
      : controlConfig.get('requestParams')) || {};

  additionalClickItems = (controlConfig = this.props.controlConfig) => {
    let additionalClickItems = controlConfig.get('additionalClickItems');
    additionalClickItems =
      additionalClickItems && additionalClickItems.toJS ? additionalClickItems.toJS() : additionalClickItems;
    additionalClickItems = additionalClickItems || [];
    return additionalClickItems;
  };

  render() {
    const config = this.props.config || Immutable.Map();
    const params = { ...this.props.params, fieldId: this.props.id };
    return (
      <RecordDropdown
        {...this.props}
        // recordsView={this.props.recordsView}
        // params={params}
        enableSelect={this.props.config.get('enableSelect')}
        enableCreate={this.props.config.get('enableCreate')}
        remoteGroup={this.props.controlConfig.get('elementsRemoteGroup', 'linkedObjects')}
        wrapperClassName={cn(styles.object, this.props.className)}
        requestParams={this.requestParams()}
        config={config}
        additionalClickItems={this.additionalClickItems() || []}
        loadAvailableItems={this.loadAvailableItems}
        clearAvailableItems={this.props.controlConfig.get('clearAvailableItems')}
      />
    );
  }
}

ObjectControl.propTypes = {
  value: ImmutableProptypes.list,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  controlConfig: PropTypes.object,
};

export default ObjectControl;
