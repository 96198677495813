import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import Category from '../../common/UI/Category';

const log = require('debug')('CRM:Component:Filter:SwitchField');

class SwitchField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  onSave = (value) => {
    const { onSave, fieldId } = this.props;
    this.setState({ value });
    log('filter value', value);
    onSave(fieldId, value);
  };

  onClickItem = (item) => {
    if (this.props.readOnly) {
      return;
    }

    const value = this.state.value != item ? item : null;
    this.onSave(value);
  };

  render() {
    const { readOnly, t } = this.props;

    const items = Immutable.fromJS([
      {
        id: 'true',
        name: t('filter.keys.$TRUE'),
        color: 'bed9d6',
        // color: "D0FBBF"
      },
      {
        id: 'false',
        name: t('filter.keys.$FALSE'),
        color: 'e0e0e0',
        // color: "F9C2C2"
      },
    ]);

    return (
      <div>
        {items.map((item) => {
          const id = item.get('id');
          const checked = this.state.value == id;

          return (
            <Category
              key={id}
              checked={checked}
              readOnly={readOnly}
              title={item.get('name')}
              backgroundColor={`#${item.get('color')}`}
              onClick={_.bind(this.onClickItem, this, id)}
            />
          );
        })}
      </div>
    );
  }
}

SwitchField.propTypes = {
  fieldId: PropTypes.string,
  value: PropTypes.string,
  config: ImmutablePropTypes.contains({
    type: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default withTranslation()(SwitchField);
