import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Radio, Input } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TEXT, MASKTEXT, TEXT_EDITOR, MULTILINE } from '../../../../../configs/textFieldSubTypes';

import SelectWithFilter from '../../SelectWithFilter';

import styles from './controls.less';

const RadioGroup = Radio.Group;
class Radiobutton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  onChangeItem = (itemId) => {
    if (!this.props.editable) {
      return false;
    }

    this.setState({ value: itemId });
    this.props.onChange && this.props.onChange(itemId);
    this.props.onEndEditing && this.props.onEndEditing(itemId);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const newValue = nextProps.value;
      this.setState({
        value: newValue,
      });
    }
  }

  handlerTextType = (type) => {
    if (this.props.isMasktext) {
      this.setState({ value: 'masktext' });
    } else {
      if (type === TEXT_EDITOR) {
        this.setState({ value: 'multiline' });
      } else {
        this.setState({ value: type });
      }
    }
  };

  render() {
    let items = this.props.config.get('items');
    const mode = this.props.config.get('mode');
    const type = this.props.config.get('type');
    const textFieldSubTypes = [TEXT, MASKTEXT, TEXT_EDITOR, MULTILINE];

    if (textFieldSubTypes.includes(this.props.value)) this.handlerTextType(this.props.value);

    const { editable } = this.props;
    let checkedItem;
    let content;
    if (!editable) {
      items = items.filter((item) => item.get('id') === this.props.value);
    }
    if (
      ((items && items.size) >= 7 && type != 'radio' && type != 'radiobuttonGroup') ||
      mode == 'dropdown' ||
      type == 'select' ||
      type == 'radiobuttonSelect'
    ) {
      if (!editable) {
        checkedItem = items.find((item) => item.get('id') === this.props.value);
        content = (
          <Input className={styles.radioSelectReadOnly} readOnly value={checkedItem && checkedItem.get('name')} />
        );
      } else {
        content = (
          <SelectWithFilter
            mode="single"
            value={{ key: this.state.value }}
            onChange={this.onChangeItem}
            placeholder={this.props.placeholder}
            notFoundContent={this.props.t('dropdown.noitems')}
            items={items.map((item) => ({ key: item.get('id'), text: item.get('name') })).toJS()}
            className={styles.radioSelect}
          />
        );
        // showSearch
      }
    } else {
      content = (
        <RadioGroup
          value={this.state.value}
          onChange={(e) => this.onChangeItem(e.target.value)}
          className={styles.radiogroupList}
        >
          {items.map((item, key) => {
            const ref = key ? 'input' : 'inputFirst';
            const id = String(item.get('id'));
            return (
              <Radio
                key={id}
                className={cn(styles.radioItem, {
                  readonly: !editable,
                })}
                disabled={!editable}
                ref={
                  ref // need specify disabled to readonly
                }
                checked={this.state.value === id}
                value={id}
              >
                {item.get('name')}
              </Radio>
            );
          })}
        </RadioGroup>
      );
    }
    return content;
  }
}

Radiobutton.propTypes = {
  value: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
};

export default withTranslation()(Radiobutton);
