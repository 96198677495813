import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import _ from 'lodash';

import RecordDropdown from '../../common/UI/ControlList/controls/RecordDropdown';
import availableLinkedRecordActions from '../../../actions/availableLinkedRecordActions';
import { userFilters } from '../../../configs/filterKeys';

export default class UserField extends React.PureComponent {
  inMapper = (v) => {
    if (v.toJS) {
      v = v.toJS();
    }

    // special filters;
    let { title } = v;
    if (!title) {
      const filter = _.find(userFilters, (f) => f.key == v.id);
      title = filter && filter.value;
    }
    if (!title) {
      title = '???';
    }

    return {
      key: v.id,
      text: title,
      icon: 'users-1',
    };
  };

  outMapper = (item) => ({
    id: item.key,
    title: item.text,
  });

  onChange = (items) => {
    const { onSave, fieldId } = this.props;

    items = items.map(this.outMapper);

    onSave(fieldId, items);
  };

  render() {
    // @override this.props.multiselect
    let config = this.props.config || Immutable.Map({});
    config = config.set('multiselect', true);
    config = config.set('enableSelect', true);
    config = config.set('enableCreate', false);

    const additionalItems = [];
    if (userFilters) {
      userFilters.forEach((f) => {
        additionalItems.push({
          key: f.key,
          text: f.value,
          sort: -1,
        });
      });
    }

    return (
      <RecordDropdown
        config={config}
        additionalItems={additionalItems}
        remoteGroup="users"
        value={this.props.value && this.props.value.map(this.inMapper)}
        readOnly={this.props.readOnly}
        apiOnly={false}
        onChange={this.onChange}
        loadAvailableItems={availableLinkedRecordActions.loadAvailableItems}
        clearAvailableItems={availableLinkedRecordActions.clearAvailableItems}
      />
    );
  }
}

UserField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func,
  readOnly: PropTypes.bool,
};
