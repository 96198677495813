import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router';
import { connect } from '../../../StateProvider';
import apiActions from '../../../../actions/apiActions';
import { prompt } from '../../../common/Modal';
import routes from '../../../../routes';

import Companies from './Companies';
import Menu from './Menu';

import styles from './fullMenu.less';

function Overlay(props) {
  const IconForSectionDefault = 'content-3';
  const { visible, history, hideMenu } = props;
  const { t } = useTranslation();

  const handleClickCreacteCompany = () => {
    window.open(routes.register.path);
  };

  const onCreateSection = (name) => {
    apiActions
      .createSection(
        {},
        {
          name,
          icon: IconForSectionDefault,
        },
      )
      .then((res) => {
        history.push(`/section/${res.id}`);
      });
  };
  // const openCreateModal = () => {
  //   hidePopover();

  //   prompt({
  //     headerText: t('modals.createSection.headerText'),
  //     placeHolder: t('modals.createSection.inputPlaceHolder'),
  //     okText: t('buttons.create'),
  //     cancelText: t('buttons.cancel'),
  //     onOk: onCreateSection,
  //   });
  // };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Companies isAdmin hideMenu={hideMenu} />
      </div>
      <div className={styles.body}>
        <Menu isAdmin visible={visible} hideMenu={hideMenu} />
      </div>
    </div>
  );
}

export default withRouter(Overlay);
