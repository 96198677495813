import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Modal } from 'antd';
import _ from 'lodash';
import { matchPath, withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import ImportHeader from './ImportHeader';
import ImportTable from './ImportDataTable';
import ImportFooter from './ImportFooter';
import Loading from '../../common/Loading';

import importActions from '../../../actions/importActions';
import apiActions from '../../../actions/apiActions';

import getLink from '../../common/router/getLink';
import Prompt from '../../common/router/Prompt';

import { connect } from '../../StateProvider';
import routes from '../../../routes';

import { RECORD_STATUS_FIELD_ID } from '../../../configs/import';

import styles from './ImportStyles.less';

class ImportDataComponent extends React.Component {
  state = {
    colorField: Immutable.fromJS({
      type: 'field',
      value: RECORD_STATUS_FIELD_ID,
    }),
    openModal: false,
    isFinished: false,
  };

  // LIFECYCLE
  componentDidMount() {
    const {
      file,
      catalogId,
      i18n: { language },
    } = this.props;
    /* проблема в том что комопнента не может пройти жизненный цикл до маунта потому что дочерние рендерятся в ДОМ и выпадают в ошибку,
    так как у нас не ещё данные не сформированны экшеном parseExcel, экшен синхронный так что после выполнения экшена,
    можно показывать модалку и без ошибок откроется модалка */
    importActions.parseExcel(file, catalogId, language);
    this.setState({ openModal: true });
  }

  // setRecordValue
  onChangeRecordValue = (recordId, fieldId, value) => {
    importActions.changeRecordValue(recordId, fieldId, value);
  };

  onChangeColumnField = (columnId, field) => {
    const {
      catalogId,
      i18n: { language },
    } = this.props;

    importActions.changeColumnField(columnId, field, catalogId, language);
  };

  // DATA
  onDeleteRecord = async (record) => {
    importActions.removeRecord(record);
  };

  onRestoreRecord = async (record) => {
    importActions.restoreRecord(record);
  };

  // SAVE
  startSaving = async () => {
    const { catalogId } = this.props;

    await importActions.importData(catalogId);
  };

  stopSaving = () => {
    const { catalogId } = this.props;
    importActions.stopImport(catalogId);
  };

  closeModal = () => {
    const { sectionId, catalogId } = this.props;
    this.props.history.push(getLink(this.props.location, routes.catalog, { sectionId, catalogId }));
    if (this.state.isFinished) this.onCloseModal();
  };

  onCloseModal = () => {
    const { catalogId } = this.props;
    importActions.clearImportData(catalogId);

    /* в процессе импорта мы отключаем лайвы с помощью изменения конфигов полей и для того чтобы не восстанавливать конфиги просто получим их с сервера */
    apiActions.getCatalog({ catalogId });
  };

  onChangeFinishState = () => {
    this.setState({ isFinished: true });
  };

  render() {
    const { catalogId, sectionId, sceneId } = this.props;
    const { columns, t } = this.props;

    const { colorField, openModal, isFinished } = this.state;

    return (
      <Modal
        open={openModal}
        maskClosable={false}
        closable={false}
        footer={[]}
        width="auto"
        wrapClassName="large-modal-bpium"
        className={styles.modalClassName}
      >
        <Prompt
          when={!isFinished}
          message={(location) => {
            const match = matchPath(location.pathname, {
              path: routes.catalog.path,
              exact: false,
              strict: false,
            });

            if (match) {
              return 'Are you sure?';
            }
            return true;
          }}
          callback={this.onCloseModal}
        />
        <div className={styles.importComponentWrapper}>
          <ImportHeader sectionId={sectionId} catalogId={catalogId} closeModal={this.closeModal} />

          {!(columns && columns.isEmpty()) ? (
            <ImportTable
              catalogId={catalogId}
              sectionId={sectionId}
              sceneId={sceneId}
              colorField={colorField}
              onRestoreRecord={this.onRestoreRecord}
              onDeleteRecord={this.onDeleteRecord}
              onChangeRecordValue={this.onChangeRecordValue}
              onChangeColumnField={this.onChangeColumnField}
            />
          ) : (
            <Loading fullHeight text={t('import.key.loading')} className={styles.loading} />
          )}
          <ImportFooter
            onChangeFinishState={this.onChangeFinishState}
            stopSaving={this.stopSaving}
            startSaving={this.startSaving}
            closeModal={this.closeModal}
          />
        </div>
      </Modal>
    );
  }
}

/* поправить */
ImportDataComponent.propTypes = {
  catalogId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  sceneId: PropTypes.string,
  file: PropTypes.object.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  columns: ImmutablePropTypes.list,
  records: ImmutablePropTypes.map,
};

export default withRouter(
  withTranslation()(
    connect(ImportDataComponent, {
      columns: ['import', 'columns'],
      records: ['import', 'records'],
    }),
  ),
);
