import Immutable from 'immutable';
import _ from 'lodash';
import guid from 'guid';

import index from 'deepdash/index';
import apiActions from '../actions/apiActions';
import FIELD_TYPES from '../configs/fieldTypes';

const systemCatalogs = {
  USERS: '$users',
};

export default {
  maxLimit: 50,
  currentLoading: null,

  prepareAvailableMentions(params) {
    const { catalogId, recordId } = params;
    const mentions = [];
    const excludeUsers = {}; // to remove duplicates

    // record data
    const recordValues = this.getIn(['records', catalogId, recordId, 'values']);

    if (!recordValues) {
      return;
    }

    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    const usersCatalog = this.get('catalogs').find((catalog) => catalog.get('id') == systemCatalogs.USERS);
    const usersCatalogId = (usersCatalog && usersCatalog.get('id')) || '3'; // TODO remove after implement API keys

    // users from fields
    const usersFields = fields.filter((field) => field.get('type') === FIELD_TYPES.USER);
    const usersFieldsIds = usersFields.map((f) => f.get('id'));
    recordValues
      .filter((value, fieldId) => usersFieldsIds.includes(fieldId))
      .forEach((value, fieldId) => {
        const field = fields.find((f) => f.get('id') === fieldId);
        // can be several users
        value = value.map((i) => {
          excludeUsers[i.get('id')] = true;
          mentions.push({
            id: `$users:${i.get('id')}`,
            display: i.get('title'),
            subText: field && field.get('name'),
          });
        });
      });

    // current record
    const currentRecord = this.getIn(['records', catalogId, recordId]) || Immutable.Map();
    const currentCatalogIcon = this.getIn(['catalogs', catalogId, 'icon']);
    mentions.push({
      id: `${currentRecord.get('catalogId')}:${currentRecord.get('id')}`,
      display: currentRecord.get('title'),
      icon: currentCatalogIcon,
    });

    // links from fields
    const linksFields = fields.filter((field) => field.get('type') === FIELD_TYPES.OBJECT);
    const linksFieldsIds = linksFields.map((f) => f.get('id'));

    recordValues
      .filter((value, fieldId) => linksFieldsIds.includes(fieldId))
      .forEach((value, fieldId) => {
        const field = fields.find((f) => f.get('id') === fieldId);
        // can be several links
        value = value.map((i) => {
          const catalogId = i.get('catalogId');
          if (catalogId == '$users') {
            excludeUsers[i.get('recordId')] = true;
          }
          mentions.push({
            id: `${catalogId}:${i.get('recordId')}`,
            display: i.get('recordTitle'),
            icon: i.get('catalogIcon'),
            subText: field && field.get('name'),
          });
        });
      });

    // all users as mentions
    const users = this.getIn(['users']);
    if (!_.isEmpty(users)) {
      users.forEach((user) => {
        if (!excludeUsers[user.get('id')]) {
          mentions.push({
            id: `$users:${user.get('id')}`,
            display: user.get('title'),
          });
        }
      });
    }

    let chatInput = this.getIn(['records', catalogId, recordId, 'chat', 'input']) || Immutable.Map();
    chatInput = chatInput.set('availableMentions', Immutable.fromJS(mentions));
    this.setIn(['records', catalogId, recordId, 'chat', 'input'], chatInput);
    this.changed();
  },

  // загрузка сообщений
  loadMessages(params, getFromStart = true, limit = this.maxLimit) {
    const { catalogId, recordId } = params;

    if (!recordId) {
      return;
    }

    // дефолтное состояние
    const chatExists = this.getIn(['records', catalogId, recordId, 'chat']);
    if (!chatExists) {
      this.setChatProp(params, 'allLoadedMessages', false);
      this.setChatProp(params, 'scrollStartPosition', 1); // какую позицию (какое сообщение) открывать на экране по умолчанию
    }

    // а не идет ли уже запрос?
    if (this.getChatProp(params, 'loadingMessages')) {
      return false;
    }

    // а нужен ли запрос?
    const allLoadedMessages = this.getChatProp(params, 'allLoadedMessages');
    if (allLoadedMessages && !getFromStart) {
      return false;
    }

    // лимит
    const query = { limit };

    // дозагрузка прошлых
    if (!getFromStart) {
      const messages = this.getChatProp(params, 'messages') || Immutable.List();
      const lastloadedMessage = messages.first();
      const lastLoadId = lastloadedMessage && lastloadedMessage.get('id');
      if (lastLoadId) {
        query.from = lastLoadId;
      }
    }

    // загрузить сообщения
    apiActions.getMessages(params, query);
    return true;
  },
  getMessages(params, query) {
    this.setChatProp(params, 'loadingMessages', true);
    this.changed();
  },
  getMessagesCompleted(body, params, data, query, response, actionParams) {
    const getFromStart = !query.from;
    const messages = body;

    // total
    const currentTotal = Number(response.headers['x-total-count']) || 0;
    const prevTotal = this.getChatProp(params, 'total');
    if (getFromStart) {
      this.setChatProp(params, 'total', currentTotal);
    }
    const total = this.getChatProp(params, 'total');

    // устанавливаем, чтобы на экране по умолчанию открылось последнее новое сообщение
    let scrollStartPosition;
    messages.forEach((message, index) => {
      if (message.newMessage) {
        scrollStartPosition = index;
      }
    });
    if (scrollStartPosition && !prevTotal) {
      scrollStartPosition += 2; // отсуп сверху в сообщениях
      this.setChatProp(params, 'scrollStartPosition', scrollStartPosition);
    }

    // ??
    this._updateNewMessagesCount(params);

    // обновляем состояния
    this.setChatProp(params, 'loadingMessages', false);

    // применяем загруженные сообщения
    if (getFromStart) {
      const hasLoadedMessages = !!prevTotal;
      if (hasLoadedMessages) {
        const countNewMessages = currentTotal - prevTotal;
        if (countNewMessages >= this.maxLimit && query.limit < this.maxLimit) {
          // если новых сообщений ооочень много
          // стираем все прошлое и записываем только новые
          this.mergeMessages(params, messages, false);
        } else {
          this.mergeMessages(params, messages); // оставляем загруженные
        }

        // запрашивали немного (обновляли), а там понаписали больше, надо дозагрузить
        if (countNewMessages > this.limit && countNewMessages <= this.maxLimit && query.limit < this.maxLimit) {
          this.loadMessages(params, true, this.maxLimit);
        }
      } else {
        this.mergeMessages(params, messages, true);
      }
    } else {
      // если загрузка при скроле в прошлое
      this.mergeMessages(params, messages, true);
    }
  },
  mergeMessages(params, loadedMessages, keepLoaded = true) {
    // выбираем какие из прошлых сообщений оставить
    let messages = this.getChatProp(params, 'messages') || new Immutable.List();
    if (keepLoaded) {
      // удаляем повторные сообщения
      // но оставляем отправленные с ошибкой
      const newIds = {};
      loadedMessages.forEach((m) => (newIds[m.id] = true));
      messages = messages.filter((m) => m.get('sending') || !newIds[m.get('id')]);
    } else {
      // оставляем только не отправленные сообщения
      messages = messages.filter((m) => m.get('sending'));
    }

    // обьединяем и сортируем
    messages = Immutable.fromJS(loadedMessages).concat(messages);
    messages = messages.sort((a, b) => a.get('id') - b.get('id'));
    this.setChatProp(params, 'messages', messages);

    // обновляем состояния
    const total = this.getChatProp(params, 'total');
    this.setChatProp(params, 'allLoadedMessages', messages.length < total);

    // ?? чтото для Виртуозы, но не понял что
    // https://virtuoso.dev/virtuoso-api/interfaces/VirtuosoProps/#scrollFirstItemIndex
    // считаем количство загруженных сообщений, убираем сообщения, которых нет на сервере
    const messagesCount = messages.filter((m) => !m.get('sending')).size;
    const nextFirstItemIndex = total - messagesCount; // находим число незагруженных с сервера сообщений
    this.setChatProp(params, 'scrollFirstItemIndex', nextFirstItemIndex);

    this.changed();
  },
  getMessagesFailed(body, params, data, query, res, actionParams) {
    this.setChatProp(params, 'loadingMessages', false);
    this.changed();
  },
  _updateNewMessagesCount(params) {
    const { catalogId, recordId } = params;

    // МЕНЯЕМ В СПИСКЕ ЧАТОВ ЧТО ПРОЧИТАЛИ СООБЩЕНИЕ И ОНО БОЛЬШЕ НЕ НОВОЕ
    const chatsItems = this.getIn(['chats', 'items'])?.map((item) => {
      if (item.get('catalogId') == catalogId && item.get('recordId') == recordId) {
        item = item.setIn(['message', 'newMessage'], false);
      }
      return item;
    });
    this.setIn(['chats', 'items'], chatsItems);

    // МЕНЯЕМ В ЗАПИСИ ЧТО ПРОЧИТАЛИ СООБЩЕНИЕ И ОНО БОЛЬШЕ НЕ НОВОЕ
    let chatOptions = this.getIn(['records', catalogId, recordId, 'chatOptions']);

    // если по этой записи есть новые сообщения, то мы изменяем стейт
    if (chatOptions && chatOptions.get('newMessages')) {
      chatOptions = chatOptions.set('newMessages', false);

      this.setIn(['records', catalogId, recordId, 'chatOptions'], chatOptions);
      // TODO ВЫНЕСТИ В ПОДПИСКИ ДЛЯ КАЖДОГО СЦЕНА, КАТАЛОГ, СЕКЦИЯ

      // МЕНЯЕМ В СЦЕНЕ СОСТОЯНИЕ ЗАПИСИ О ТОМ ЧТО ПОЛЬЗОВАТЕЛЬ ПРОЧИТАЛ СООБЩЕНИЯ
      const scenes = this.get('scenes');
      let sceneRecords = scenes.find(
        (value, key) => !value.getIn(['params', 'recordId']) && value.getIn('params', 'catalogId'),
      );
      const sceneId = sceneRecords.get('sceneId');
      if (sceneRecords && sceneRecords.get('records')) {
        const recordIndex = sceneRecords.get('records').findKey((r) => r.get('id') == recordId);
        if (recordIndex != -1) {
          sceneRecords = sceneRecords.setIn([recordIndex, 'chatOptions', 'newMessages'], false);
        }

        const prevRecordsCountNewMessages = sceneRecords.get('recordsCountNewMessages');
        sceneRecords = sceneRecords.set('recordsCountNewMessages', Math.max(0, prevRecordsCountNewMessages - 1));
        this.setIn(['scenes', sceneId], sceneRecords);
      }

      // МЕНЯЕМ В КАТАЛОГЕ КОЛИЧЕСТВО ЗАПИСЕЙ В КОТОРЫХ ЕСТЬ НОВЫЕ СООБЩЕНИЯ УБИРАЯ ЗАПИСЬ КОТОРУЮ ПРОЧИТАЛИ
      let catalog = this.getIn(['catalogs', catalogId]);
      const currentCountCatalog = catalog.getIn(['chat', 'newChats']);
      const newCountCatalog = Math.max(0, currentCountCatalog - 1);
      catalog = catalog.setIn(['chat', 'newChats'], newCountCatalog);
      this.setIn(['catalogs', catalog.get('id')], catalog);

      // МЕНЯЕМ В СЕКЦИИ КОЛИЧЕСТВО КАТАЛОГОВ В КОТОРЫХ ЕСТЬ НОВЫЕ СООБЩЕНИЯ ПО ЗАПИСЕ УБИРАЯ КАТАЛОГ ЕСЛИ ОН ПУСТ
      let section = this.getIn(['sections', catalog.get('sectionId')]);
      const currentCountSection = section.get('newChats');
      if (currentCountSection) {
        const newCountSection = currentCountSection - 1;
        if (newCountSection <= 0) {
          section = section.delete('newChats');
        } else {
          section = section.set('newChats', newCountSection);
        }
      }
      this.setIn(['sections', section.get('id')], section);
    }
  },

  // написание сообщения
  sendMessage(params, data, id) {
    const { catalogId, recordId } = params;
    let messages = this.getIn(['records', catalogId, recordId, 'chat', 'messages']);
    let message;

    // remove message if exists
    if (id) {
      message = messages && messages.find((m) => m.get('id') == id);
      if (message) {
        messages = messages.filter((m, i) => m.get('id') !== id);
      }
    }

    // create new message
    id = id || guid.raw();
    message = this._createMessage(params, id, data);

    // add message to list
    messages = messages.push(message);
    this.setIn(['records', catalogId, recordId, 'chat', 'messages'], messages);

    // save message
    apiActions.createMessage(params, data, { id });
    this.changed();
  },
  createMessage(params, data, actionParams = {}) {
    const { id } = actionParams;
    this.setMessageProps(params, id, {
      sending: true,
      sendingAction: 'create',
      sendingError: false,
    });
  },
  _createMessage(params, id, data) {
    const { catalogId, recordId } = params;
    const author = this.get('user');

    // основные параметры
    let message = _.cloneDeep(data);
    message = {
      id: id || guid.raw(),
      catalogId,
      recordId,
      isNew: true,
      deleted: false,
      author: {
        catalogId: '$users',
        recordId: author.get('id'),
        recordTitle: author.get('title'),
      },
      createdDate: new Date().toISOString(),
      sending: true,
      ...message,
    };

    // full reply message data (to display in chat list)
    if (message.replyMessageId) {
      const reply = this.getIn(['records', catalogId, recordId, 'chat', 'messages']).find(
        (m) => m.get('id') == message.replyMessageId,
      );
      if (reply) {
        message.reply = reply.toJS();
      }
    }

    message = Immutable.fromJS(message);
    return message;
  },
  createMessageCompleted(body, params, data, query, res, actionParams) {
    // change state & update ID
    // isNew не меняется, чтобы сообщение обновилось данными с сервера при следующей загрузке
    const oldId = actionParams.id;
    const newId = body && body.id;
    this.setMessageProps(params, oldId, {
      id: newId,

      sending: false,
      sendingAction: '',
      sendingError: false,
    });

    this.changed();
    this.loadMessages(params, true, 10);
  },
  createMessageFailed(body, params, data, query, actionParams) {
    const oldId = actionParams.id;
    this.setMessageProps(params, oldId, {
      sending: false,
      sendingError: true,
    });
    this.changed();
  },

  // удаление сообщения
  deleteMessage(params, query) {
    const { messageId } = params;
    this.setMessageProps(params, messageId, {
      sending: true,
      sendingAction: 'delete',
      sendingError: false,
    });
  },
  deleteMessageCompleted(body, params, data, query, res, actionParams) {
    // mark as deleted
    const { messageId } = params;
    this.setMessageProps(params, messageId, {
      deleted: true,
      deletedDate: new Date().toISOString(),

      sending: false,
      sendingAction: '',
      sendingError: false,
    });
    this.changed();
  },
  deleteMessageFailed(body, params, data, query, actionParams) {
    const { messageId } = params;
    this.setMessageProps(params, messageId, {
      deleted: false,

      sending: false,
      sendingError: true,
    });
    this.changed();
  },

  // изменение сообщения
  updateMessage(params, data) {
    const { messageId } = params;

    // save to list
    this.setMessageProps(params, messageId, {
      text: data.text,
      mentions: data.mentions,
      attachments: data.attachments,
      replyMessageId: data.replyMessageId, // not working now, maybe api dpes not allow to change reply message id

      sending: true,
      sendingAction: 'edit',
      sendingError: false,
    });
  },
  updateMessageCompleted(body, params, data, query, res, actionParams) {
    const { messageId } = params;

    // save to list
    this.setMessageProps(params, messageId, {
      text: data.text,
      mentions: data.mentions,
      attachments: data.attachments,
      replyMessageId: data.replyMessageId, // not working now, maybe api dpes not allow to change reply message id

      sending: false,
      sendingAction: '',
      sendingError: false,
    });

    // clear input
    this.clearMessagesInput(params);
    this.changed();
  },
  updateMessageFailed(body, params, data, query, res, actionParams) {
    const { messageId } = params;
    this.setMessageProps(params, messageId, {
      sending: false,
      sendingError: true,
    });
  },

  // UI MESSAGE actions
  setReplyMessage(params, messageId) {
    const { catalogId, recordId } = params;
    const messages = this.getIn(['records', params.catalogId, params.recordId, 'chat', 'messages']);
    const message = messages.find((m, i) => m.get('id') == messageId);
    this.setIn(['records', catalogId, recordId, 'chat', 'input', 'replyMessage'], message);
    this.changed();
  },
  setEditMessage(params, messageId) {
    const { catalogId, recordId } = params;
    const message = this.getIn(['records', params.catalogId, params.recordId, 'chat', 'messages']).find(
      (m, i) => m.get('id') == messageId,
    );

    this.setIn(['records', catalogId, recordId, 'chat', 'input', 'editMessage'], message);

    // get attachments from edit message
    this.setIn(
      ['records', catalogId, recordId, 'chat', 'input', 'attachments'],
      this.getIn(['records', catalogId, recordId, 'chat', 'input', 'editMessage', 'attachments']),
    );

    // input value
    this.setMessageText(params, message.get('text'));

    this.changed();
  },
  cancelResendMessage(params, messageId) {
    const { catalogId, recordId } = params;
    const messages = this.getIn(['records', catalogId, recordId, 'chat', 'messages']).filter(
      (m, i) => m.get('id') !== messageId,
    );
    this.setIn(['records', catalogId, recordId, 'chat', 'messages'], messages);
    this.changed();
  },
  cancelUpdateMessage(params, messageId) {
    this.setMessageProps(params, messageId, {
      sending: false,
      sendingError: false,
    });
    this.changed();
  },
  cancelDeleteMessage(params, messageId) {
    this.setMessageProps(params, messageId, {
      sending: false,
      sendingError: false,
    });
    this.changed();
  },

  /* UI INPUT actions */
  clearMessagesInput(params) {
    this.setMessageText(params, '');
    this.setMessageAttachments(params, Immutable.List());
    this.cancelReplyMessage(params);
    this.cancelEditMessage(params);
    this.changed();
  },
  setMessageText(params, text) {
    const { catalogId, recordId } = params;
    this.setIn(['records', catalogId, recordId, 'chat', 'input', 'text'], text);
    this.changed();
  },
  setMessageAttachments(params, text) {
    const { catalogId, recordId } = params;
    this.setIn(['records', catalogId, recordId, 'chat', 'input', 'attachments'], text);
    this.changed();
  },
  setLoadingAttachment(params, flag) {
    const { catalogId, recordId } = params;
    this.setIn(['records', catalogId, recordId, 'chat', 'loadingAttachment'], flag);
    this.changed();
  },

  /* UI MESSAGE actions */
  cancelReplyMessage(params) {
    const { catalogId, recordId } = params;
    this.deleteIn(['records', catalogId, recordId, 'chat', 'input', 'replyMessage']);
    this.changed();
  },
  cancelEditMessage(params) {
    const { catalogId, recordId } = params;
    this.deleteIn(['records', catalogId, recordId, 'chat', 'input', 'editMessage']);
    this.changed();
  },
  async copyMessageTextToClipboard(params, text) {
    const { catalogId, recordId, messageId } = params;
    const message = this.getIn(['records', catalogId, recordId, 'chat', 'messages']).find(
      (message) => message.get('id') === messageId,
    );

    if (text !== '') {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error(err);
      }
    } else if (message.get('text')) {
      try {
        await navigator.clipboard.writeText(message.get('text'));
      } catch (err) {
        console.error(err);
      }
    }
  },

  /* helpers */
  getChatProp(params, propName) {
    const { catalogId, recordId } = params;
    return this.getIn(['records', catalogId, recordId, 'chat', propName]);
  },
  setChatProp(params, propName, propValue) {
    const { catalogId, recordId } = params;
    const chat = this.getIn(['records', catalogId, recordId, 'chat']);
    if (!chat) {
      this.setIn(['records', catalogId, recordId, 'chat'], Immutable.Map());
    }
    this.setIn(['records', catalogId, recordId, 'chat', propName], propValue);
  },
  setMessageProp(params, messageId, propName, propValue) {
    this.setMessageProps(params, messageId, { [propName]: propValue });
  },
  setMessageProps(params, messageId, data) {
    const { catalogId, recordId } = params;
    let messages = this.getIn(['records', catalogId, recordId, 'chat', 'messages']);
    messages = messages.map((m) => {
      if (m.get('id') == messageId) {
        data = Immutable.fromJS(data);
        m = m.merge(data);
      }
      return m;
    });
    this.setIn(['records', catalogId, recordId, 'chat', 'messages'], messages);
  },
};
