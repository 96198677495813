export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'bpiumFindRecords',
  title: t('script.controlls.bpiumFindRecords.title'),
  helplink: '',
  class: 'bpium',
  color: '#7cab9e',
  border: 2,
  icon: 'anticon-icon edition-25',
  offset_x: 5,
  offset_y: -12,
  toolbar_offset_x: 5,
  toolbar_offset_y: -12,
  priority: 1,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.bpiumFindRecords.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'paramsSection',
      name: t('script.controlls.bpiumFindRecords.title'),
      type: 'group',
    },
    {
      id: 'catalogFormat',
      name: t('script.common.enterCatalog'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'catalog',
            name: t('script.common.enterCatalog'),
            color: 'E7E9ED',
          },
          {
            id: 'var',
            name: t('script.common.fromVariables'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['catalog'],
        map: 'input',
      },
    },
    {
      id: 'catalogId',
      name: t('script.common.catalog'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'catalogs',
        map: 'input',
      },
      visible: {
        catalogFormat: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'catalogIdFromVar',
      name: t('script.common.catalogId'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.numberExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        catalogFormat: {
          $include: 'var',
        },
      },
    },
    {
      id: 'viewId',
      name: t('script.controlls.bpiumFindRecords.view'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'views',
        parent: 'catalogId',
        map: 'input',
      },
      visible: {
        catalogId: {
          $ne: false,
        },
        catalogFormat: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'filtersFormat',
      name: t('script.controlls.bpiumFindRecords.filter'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'keyvalue',
            name: t('script.common.fieldCondition'),
            color: 'E7E9ED',
          },
          {
            id: 'json',
            name: 'JSON',
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['keyvalue'],
        map: 'input',
      },
    },
    {
      id: 'filters',
      name: '',
      type: 'pair',
      readOnly: false,
      config: {
        placeholderValue: t('script.common.fieldId'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        data: 'fields',
        parent: 'catalogId',
        map: 'input',
      },
      visible: {
        filtersFormat: {
          $include: 'keyvalue',
        },
      },
    },
    {
      id: 'filtersJson',
      name: '',
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.bpiumFindRecords.filterJsonHint'),
      config: {
        type: 'multiline',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.expression'),
      },
      visible: {
        filtersFormat: {
          $include: 'json',
        },
      },
    },
    {
      id: 'sortField',
      name: t('script.controlls.bpiumFindRecords.sort'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'fields',
        parent: 'catalogId',
        map: 'input',
      },
      visible: {
        catalogId: {
          $ne: false,
        },
        catalogFormat: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'sortType',
      name: '',
      type: 'dropdown',
      readOnly: false,
      config: {
        defaultEmptyValue: ['1'],
        items: [
          {
            id: '1',
            name: t('script.controlls.bpiumFindRecords.sortAsc'),
            color: 'E7E9ED',
          },
          {
            id: '-1',
            name: t('script.controlls.bpiumFindRecords.sortDesc'),
            color: 'E7E9ED',
          },
        ],
        map: 'input',
      },
      visible: {
        sortField: {
          $ne: false,
        },
      },
    },
    {
      id: 'section0',
      name: t('script.controlls.bpiumFindRecords.receive'),
      type: 'group',
    },
    {
      id: 'fieldsFormat',
      name: t('script.controlls.bpiumFindRecords.fields'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'all',
            name: t('script.controlls.bpiumFindRecords.allFields'),
            color: 'E7E9ED',
          },
          {
            id: 'specified',
            name: t('script.controlls.bpiumFindRecords.specifiedFields'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['all'],
        map: 'input',
      },
    },
    {
      id: 'fieldsSpecified',
      name: t('script.common.fieldsId'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      hint: t('script.controlls.bpiumFindRecords.fieldsSpecifiedHint'),
      visible: {
        fieldsFormat: {
          $include: 'specified',
        },
      },
    },
    {
      id: 'limit',
      name: t('script.controlls.bpiumFindRecords.recordsCount'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
      hint: t('script.controlls.bpiumFindRecords.limitHint'),
    },
    {
      id: 'offset',
      name: t('script.controlls.bpiumFindRecords.offset'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
      hint: 'API-параметр: offset',
    },
    {
      id: 'section1',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'output',
      name: t('script.common.saveTo'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.bpiumFindRecords.outputHint'),
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
    },
    {
      id: 'outputCount',
      name: t('script.controlls.bpiumFindRecords.outputCount'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.bpiumFindRecords.outputCountHint'),
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
    },
  ],
  exits: [],
});
