import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from '../StateProvider';

class RightsExceptions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      exceptions: this.props.exceptions || {},
      basePrivilege: this.props.basePrivilege || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      exceptions: nextProps.exceptions || {},
      basePrivilege: nextProps.basePrivilege || '',
    });
  }

  render() {
    const exceptions = {};
    let exceptionsList = [];
    const { basePrivilege } = this.props;

    const { catalogId } = this.props;
    let hasExceptions = false;

    const fieldNames = this.props.catalogs.getIn([catalogId, 'fields']);
    _.forEach(this.state.exceptions.toJS(), (privilege, fieldId) => {
      let fieldName = '';
      fieldNames.forEach((field) => {
        if (field.get('id') == fieldId) {
          fieldName = field.get('name');
        }
      });
      if (privilege != basePrivilege && fieldName) {
        if (!exceptions[privilege]) {
          exceptions[privilege] = [];
        }
        exceptions[privilege].push(fieldName);
        hasExceptions = true;
      }
    });

    if (hasExceptions) {
      _.forEach(exceptions, (list, privilege) => {
        const { length } = list;
        const countField = length > 1 ? ` ${length}` : '';
        const hideHidden = privilege == 'hide' && this.props.readOnly;

        exceptionsList.push(
          this.props.t(`modals.access.exception.${privilege}`) + countField + (!hideHidden ? ': ' : ''),
        );
        if (!hideHidden) {
          exceptionsList.push(list.join(', '));
        }
        exceptionsList.push('\n');
      });

      exceptionsList = exceptionsList.join('');

      return (
        <div>
          {exceptionsList.split('\n').map((i, key) => (
            <div key={key}>
              <small>{i}</small>
            </div>
          ))}
        </div>
      );
    }

    return null;
  }
}

RightsExceptions.propTypes = {
  exceptions: PropTypes.object.isRequired,
  basePrivilege: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
};

export default withTranslation()(connect(RightsExceptions, ['catalogs']));
