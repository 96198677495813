import _ from 'lodash';
import types from './types';
import { createElementsHelper } from '../createElementsHelper';

export function parse(parameter) {
  if (!_.isObject(parameter)) {
    return parameter;
  }

  const { definition, value } = parameter;

  if (definition) {
    const { entries, items } = definition;

    if (entries) {
      return types.map.parse(parse, entries);
    }

    if (items) {
      return types.list.parse(parse, items);
    }

    return parse(definition);
  }

  return types.value.parse(value);
}

export function format(factory, businessObject, type, { name, configName, value }) {
  const definition = _.isArray(value)
    ? _.get(value[0], 'key') !== undefined // hashable array (with keys)
      ? types.map.format(value)
      : types.list.format(value)
    : types.value.format(value);

  return createElementsHelper(
    factory,
    type,
    {
      name,
      configName,
      definition,
    },
    businessObject,
  );
}
