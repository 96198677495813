import { Badge, Space } from 'antd';
import React from 'react';

import Icon from '../../../../common/UI/Icon';
import AvatarUser from '../../../../common/UI/AvatarUser';

import styles from './UserAvatarMenu.less';

function UserAvatar({ styleUserMenuBadge, userName, recordsEnds, licenseEnds }) {
  return (
    <Badge style={styleUserMenuBadge} dot={recordsEnds || licenseEnds}>
      <Space size={5} className={styles.containerAvatar}>
        <AvatarUser userName={userName} />
        <Icon type="icon arrows-triangle-big-1-01" className={styles.arrow} />
      </Space>
    </Badge>
  );
}

export default UserAvatar;
