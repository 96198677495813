export const RADIOBUTTON_LIST = {
  RADIOBUTTONGROUP: 'radiobuttonGroup',
  RADIOBUTTONSELECT: 'radiobuttonSelect',
};

export const TEXT_LIST = {
  TEXTEDITOR: 'textEditor',
};

export const CONTACT_LIST = {
  PHONE: 'phone',
  EMAIL: 'email',
  SITE: 'site',
};
