export default (t) => ({
  element: 'bpmn:ParallelGateway',
  title: t('script.controlls.parallelGateway.title'),
  helplink: '',
  class: 'gateway',
  color: 'orange',
  border: 2,
  icon: 'anticon-icon interface-72',
  offset_x: -34,
  offset_y: 6,
  toolbar_offset_x: -6,
  toolbar_offset_y: -44,
  priority: 1,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.parallelGateway.defaultEmptyValue'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
  ],
  exits: [],
});
