import React from 'react';
import _ from 'lodash';
import { Progress, Row } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { connect } from '../../../StateProvider';
import Icon from '../../../common/UI/Icon';
import LoadingSpin from '../../../common/LoadingSpin';

import styles from '../../styles/styles.less';

function Description(props) {
  const { t } = useTranslation();
  const descriptionText = props.descriptionText || t('batchUpdateRecords.result.description.modified');
  return (
    <div className={props.wrapperClassname}>
      <Row type="flex" align="middle" className={styles.resultListTitle}>
        <div className={styles.sectionToggle}>
          <Icon className={styles.headerIcon} type="icon programing-17" />
          <span className={styles.headerText}>{t('batchUpdateRecords.result.description.header')}</span>
        </div>
      </Row>
      <div className={styles.descriptionWrapper}>
        <div className={styles.textWrapper}>
          <span>{`${descriptionText}${props.successRecordsCount || 0} / ${props.recordsCount || 0} `}</span>
          <span>
            {props.waitingTime ? `${t('batchUpdateRecords.result.description.waitingTime')}${props.waitingTime}` : ''}
          </span>
        </div>
        <Progress percent={props.processedPercents} successPercent={props.successPercent} />
      </div>
    </div>
  );
}

// Description.propTypes = {
//   sceneId: PropTypes.string,
//   sectionId: PropTypes.string,
//   catalogId: PropTypes.string,
//   recordsCount: PropTypes.string || PropTypes.number,
//   errorRecordsCount: PropTypes.string || PropTypes.number,
//   successRecordsCount: PropTypes.string || PropTypes.number,
//   processedPercents: PropTypes.string || PropTypes.number,
//   successPercent: PropTypes.string || PropTypes.number,
//   waitingTime: PropTypes.string,
// }

export default connect(
  Description,
  {
    scenes: ['scenes'],
  },
  (props, { scenes }) => {
    const { sceneId } = props;

    const batchUpdateScene = scenes.get(sceneId);
    const recordsCount = parseInt(batchUpdateScene.get('recordsCount'), 10);

    const successRecords = batchUpdateScene.getIn(['data', 'successRecords']);
    const successRecordsCount = (successRecords && parseInt(successRecords.size, 10)) || 0;

    const errorRecords = batchUpdateScene.getIn(['data', 'errorRecords']);
    const errorRecordsCount = (errorRecords && parseInt(errorRecords.size, 10)) || 0;

    let successPercent = successRecordsCount && recordsCount ? +successRecordsCount / +recordsCount : 0;
    let processedPercents =
      (successRecordsCount || errorRecordsCount) && recordsCount
        ? (+successRecordsCount + +errorRecordsCount) / +recordsCount
        : 0;

    successPercent = successPercent && Math.round(successPercent * 100);
    processedPercents = processedPercents && Math.round(processedPercents * 100);

    let waitingTime = batchUpdateScene.get('waitingTime');
    waitingTime = waitingTime && moment.utc(waitingTime).format('HH:mm:ss');

    return {
      ...props,
      recordsCount,
      successRecordsCount,
      errorRecordsCount,
      successPercent,
      processedPercents,
      waitingTime,
    };
  },
);
