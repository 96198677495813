export default (t) => ({
  element: 'bpmn:StartEvent',
  service: '',
  title: t('script.controlls.startEvent.title'),
  helplink: '',
  class: 'event',
  color: '#6ea5c6',
  border: 2,
  icon: 'anticon-icon arrows-triangle-big-4-01',
  offset_x: 8,
  offset_y: -15,
  toolbar_offset_y: -13,
  priority: 0,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.startEvent.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
  ],
  exits: [],
});
