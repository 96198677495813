import { fromJS } from 'immutable';
import _ from 'lodash';

import i18n from '../../../configs/i18n';
import FieldApi from '../../../models/FieldApi';

export default {
  recordHasErrors(catalogId, recordId, values, fields, checkLinkedRecords = true, checkChangeYourself = false) {
    const recordErrors = this.validateRecord(catalogId, recordId, fields, values, checkChangeYourself);

    if (!_.isEmpty(recordErrors)) {
      return Object.values(recordErrors)[0];
    }
    if (checkLinkedRecords) {
      /**
       * if this record hasn't errors continue
       * checking all the linkedRecords
       */

      const linkedRecords = this.getLinkedRecords(catalogId, fromJS(values), true);
      let linkedRecordError;

      linkedRecords.some(({ linkedRecord, fieldId }) => {
        // check all the linked records

        const linkedCatalogId = linkedRecord.get('catalogId');
        const linkedRecordId = linkedRecord.get('recordId');

        const field = fields.find((field) => field.get('id') === fieldId);
        let extendedFields = field.getIn(['config', 'fields', linkedCatalogId]);
        extendedFields = extendedFields.filter((extField) => !!extField.get('editable'));
        const record = this.getIn(['records', linkedCatalogId, linkedRecordId]);

        if (!record) {
          return false;
        }

        const filterParams = { catalogId, fieldId, linkedCatalogId };

        let linkedRecordValues = record.get('values');

        /* фильтрация значений становится избыточной, при фильтрации полей, вед без указания полей не будет смылса и в значениях */
        // linkedRecordValues = this.filterExtendedValues(
        //   filterParams,
        //   linkedRecordValues
        // );

        linkedRecordValues = linkedRecordValues.toJS();

        const error = this.recordHasErrors(linkedCatalogId, linkedRecordId, linkedRecordValues, extendedFields, false);

        return (linkedRecordError = error);
      });

      return linkedRecordError;
    }
  },

  validateRecord(catalogId, recordId, fields, values, checkChangeYourself = false) {
    const errors = {};

    fields &&
      fields.forEach((field) => {
        const fieldId = field.get('id');

        const value = values[fieldId];

        const error = this.validateRecordField(catalogId, recordId, field.get('id'), value, checkChangeYourself);

        if (error) {
          errors[fieldId] = error;
        }
      });
    /* deprecated ? */
    // recordActions.updateErrorFields(catalogId, recordId, errors);
    return errors;
  },

  validateRecordField(catalogId, recordId, fieldId, value, checkChangeYourself = false) {
    // do not check hidden field
    const hiddenFields = this.getIn(['records', catalogId, recordId, 'hiddenFields']);

    if (hiddenFields && hiddenFields.get(fieldId)) {
      return;
    }

    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    const field = fields && fields.find((field) => field.get('id') === fieldId);

    if (!field) {
      return;
    }

    let error;
    // если открыта запись Сотрудники и пытаются изменить себя
    if (checkChangeYourself) {
      let originalValue = this.getIn(['records', catalogId, recordId, 'originValues', fieldId]);
      originalValue = originalValue && originalValue.toJS ? originalValue.toJS() : originalValue;
      if (!_.isEqual(originalValue, value)) {
        error = FieldApi.checkChangeYourself(field, value);
        if (error) {
          return error;
        }
      }
    }

    // validate required
    const isEmpty = FieldApi.validateRequired(field, value);

    if (field.get('required') && isEmpty) {
      error = i18n.t('message.requiredFieldEmpty');
    }

    // validate value
    const errorValue = FieldApi.validateValue(field, value, true);

    if (errorValue && typeof errorValue !== 'boolean') {
      error = errorValue;
    }
    // update state
    if (error) {
      /* deprecated ? */
      // recordActions.updateErrorField(catalogId, recordId, fieldId, error);

      // update errors to display
      this.setIn(['records', catalogId, recordId, 'errors', fieldId], error);
    } else if (this.getIn(['records', catalogId, recordId, 'errors'])) {
      this.deleteIn(['records', catalogId, recordId, 'errors', fieldId]);
    }
    // this.changed();

    return error;
  },
};
