import _ from 'lodash';
import { parse as parseParam } from './parameter';

export function parseInput(parameters) {
  return _(parameters).keyBy('name').mapValues(parseParam).value();
}

export function parseOutput(parameters) {
  return _(parameters).reduce((o, { name, $attrs: { configName }, ...parameter }) => {
    const value = {
      key: name,
      value: parseParam(parameter),
    };

    if (o[configName]) {
      if (!_.isArray(o[configName])) {
        o[configName] = [o[configName]];
      }
      o[configName].push(value);
    } else {
      o[configName] = value;
    }

    return o;
  }, {});
}
