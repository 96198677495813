import _ from 'lodash';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { withTranslation } from 'react-i18next';

import LinkedItem from '../../LinkedItem';
import ButtonClose from '../../ButtonClose';
import PairWithSubValue from './PairWithSubValue';

import { SCRIPT } from '../../../../../configs/contactFieldSubTypes';
import styles from './controls.less';

const log = require('debug')('CRM:Component:Record:ContactField');

const emptyContact = Immutable.fromJS({
  value: '',
  subValue: '',
});
const emptyList = Immutable.fromJS([emptyContact]);

/* manageFocusMixin: {
  onFocus: function() {
    this.setState({ inFocus: true });
  }
  onBlur: function() {
    this.setState({ inFocus: false });
  }
} */

class Pair extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoFocus: this.props.autoFocus,
      removed: Immutable.List(),
      newItem: null,
      lastChanged: {
        index: null,
        property: null,
      },
    };
  }

  triggerParentSave(value = this.props.value) {
    const newValue = value.toJS();
    this.changed = true;
    this.lastChangedValue = newValue;
    this.props.onChange && this.props.onChange(newValue);
  }

  onEndEditing = () => {
    this.props.onEndEditing && this.changed && this.props.onEndEditing(this.lastChangedValue);
    this.changed = false;
  };

  onChangeItem = (itemIndex, itemProperty, value) => {
    let newValue = this.props.value;
    const newState = {};
    // const controlValue = value;

    if (!newValue || !newValue.get(itemIndex)) {
      if (!newValue) {
        newValue = Immutable.List();
      }
      newValue = newValue.push(emptyContact.set(itemProperty, value));

      _.assign(newState, {
        newItem: null,
        autoFocus: false,
      });
    } else {
      newValue = newValue.setIn([itemIndex, itemProperty], value);
    }

    this.setState(
      _.assign(newState, {
        lastChanged: {
          index: itemIndex,
          property: itemProperty,
        },
      }),
    );

    this.triggerParentSave(newValue);
  };

  onValueKeyPress = (e) => {
    // create new record in script mode on Enter click
    if (e.key === 'Enter') {
      const { config } = this.props;
      const type = config.get('type');

      if (type === SCRIPT) {
        e.preventDefault();
        this.onItemAdd();
      }
    }
  };

  onRemoveItem = (itemIndex) => {
    log('remove item', itemIndex);

    const { value } = this.props;
    const size = (value && value.size) || 0;
    if (itemIndex >= size) {
      this.setState({
        newItem: null,
        autoFocus: false,
      });
      return;
    }

    const removeItem = value && value.get(itemIndex);
    const valueOne = removeItem.getIn(['value']);
    const subValue = removeItem.getIn(['subValue']);
    const newValue = value.remove(itemIndex);
    let { removed } = this.state;

    if (valueOne || subValue) {
      removed = removed.unshift(removeItem);
    }

    this.setState({
      removed,
      lastChanged: {
        index: 0,
        property: 'value',
      },
    });

    this.triggerParentSave(newValue);
    this.onEndEditing(newValue);
  };

  onRestoreItem = (itemIndex) => {
    this.setState(
      {
        autoFocus: true,
      },
      () => {
        let item = this.state.removed.get(itemIndex);
        item = item.delete('id');
        const newValue = this.props.value.push(item);
        this.setState({
          value: newValue,
          removed: this.state.removed.remove(itemIndex),
          lastChanged: {
            index: newValue && newValue.size - 1,
            property: 'value',
          },
        });
        this.triggerParentSave(newValue);
        this.onEndEditing(newValue);
      },
    );
  };

  onItemAdd = () => {
    log('add item');

    // create immediate
    let newValue = this.props.value;
    if (!newValue) {
      newValue = Immutable.List();
    }
    newValue = newValue.push(emptyContact);

    this.triggerParentSave(newValue);
    this.setState({
      autoFocus: true,
    });

    /*
    this.setState({
      newItem: emptyContact,
      autoFocus: true
    });
    */
  };

  getAllOptions = () => {
    if (this.props.config && this.props.config.get('options')) {
      const sections = this.props.config.get('options');
      const allOptions = [];
      sections.map((section) => {
        const options = section.get('options');
        options &&
          options.map((option) => {
            allOptions.push(option);
          });
      });
      return Immutable.List(allOptions);
    }
  };

  render() {
    const { updateProcess, editable, config, eventable, t } = this.props;
    const type = config.get('type');
    const options = this.getAllOptions();
    const size = this.props.value && this.props.value.size;
    let value = size ? this.props.value : emptyList;

    const { newItem, lastChanged } = this.state;

    if (newItem) {
      value = value.push(newItem);
    }

    let changedIndex = lastChanged.index;
    let changedProperty = lastChanged.property;

    if (changedIndex >= size) {
      changedIndex = 0;
      changedProperty = 'value';
    }

    const exists = value.map((item, index) => (
      <li className={styles.pairItem} key={index}>
        <Row type="flex">
          <PairWithSubValue
            id={this.props.htmlId}
            autoFocus={this.state.autoFocus}
            type={type}
            theme={config.get('theme')}
            placeholderValue={config.get('placeholderValue') || ''}
            placeholderSubValue={config.get('placeholderSubValue') || ''}
            value={item.get('value') || ''}
            subValue={item.get('subValue') || ''}
            options={options}
            valueChangeFn={(value) => this.onChangeItem(index, 'value', value)}
            subValueChangeFn={(value) => this.onChangeItem(index, 'subValue', value)}
            onEndEditing={this.onEndEditing}
            onValueKeyPress={this.onValueKeyPress}
            editable={editable}
            eventable={eventable}
            valueUpdateProcess={changedIndex === index && changedProperty === 'value' && updateProcess}
            subValueUpdateProcess={changedIndex === index && changedProperty === 'subValue' && updateProcess}
            error={this.props.error || null}
          />
          {editable && !!size && (
            <ButtonClose
              title={t('record.fields.contact.removeBtnTitle')}
              onClick={() => this.onRemoveItem(index)}
              small
              shiftRight
            />
          )}
        </Row>
      </li>
    ));

    const removed = this.state.removed.map((item, index) => (
      <LinkedItem
        key={index}
        title={item.get('subValue') || item.get('value')}
        item={{
          icon: 'transfers-70',
          text: item.get('value') || item.get('subValue'),
        }}
        classNameItem={styles.restoreItemText}
        classNameColor={styles.restoreItemColor}
        classNameItemColor={styles.restoreItemColor}
        onClick={() => this.onRestoreItem(index)}
      />
    ));

    return (
      <div className={this.props.wrapperClassName}>
        <ul>
          {exists}
          {removed}
        </ul>

        {editable && !newItem && (
          <span className={styles.pairAddButton} onClick={this.onItemAdd}>
            {t('record.addBtn')}
            ...
          </span>
        )}
      </div>
    );
  }
}

Pair.propTypes = {
  hint: PropTypes.string,
  value: ImmutablePropTypes.list,
  // config: ImmutablePropTypes.contains({
  //   type: PropTypes.string
  // }).isRequired,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
  error: PropTypes.string,
};

export default withTranslation()(Pair);
