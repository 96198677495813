import debug from 'debug';
import _ from 'lodash';
import Immutable from 'immutable';
import apiActions from '../actions/apiActions';

const log = debug('CRM:sectionsMixin');

export default {
  /* ===============================
   * Get Sections
   */
  getSection({ sectionId }) {
    log('getSection', sectionId);
    this.setIn(['sectionsOptions', 'sectionsLoading', sectionId], true);
    this.changed();
  },

  getSectionCompleted(section, { sectionId }) {
    log('getSection complete', section);
    this.setIn(['sectionsOptions', 'sectionsLoading', sectionId], false);
    this.setIn(['sectionsOptions', 'sectionsLoaded', sectionId], true);
    this.setIn(['sections', sectionId], Immutable.fromJS(section));
    this.changed();
  },

  getSectionFailed(params) {
    log('getSection failed', params);
    this.setIn(['sectionsOptions', 'sectionsLoading', params.sectionId], false);
    this.setIn(['sectionsOptions', 'sectionsLoadError', params.sectionId], true);
    this.changed();
  },

  getSections(params) {
    log('getSections', params);
    if (!this.get('initialDataLoaded') && !this.get('initialDataLoading')) {
      this.set('initialDataLoading', true);
      this.changed();
    }
  },

  getSectionsCompleted(res) {
    const sections = this.get('sections');
    const byId = {};
    res.forEach((s, i) => {
      s.id = s.id.toString();
      s.index = i;
      byId[s.id] = s;
    });
    this.set('sections', sections.mergeDeep(byId));

    this.set('initialDataLoading', false);
    this.set('initialDataLoaded', true);
    this.changed();
  },

  getSectionsFailed() {
    if (!this.get('initialDataLoaded') && this.get('initialDataLoading')) {
      this.set('initialDataLoading', false);
      this.changed();
    }
  },

  /* ========================
   * Create Section
   */
  createSection(params) {
    this.setIn(['sectionsOptions', 'sectionCreating'], true);
    this.setIn(['sectionsOptions', 'sectionCreateError'], null);
    this.changed();
  },

  createSectionCompleted(res, params, data) {
    this.setIn(
      ['sections', res.id],
      Immutable.fromJS({
        id: res.id,
        name: data.name,
        icon: '',
      }),
    );
    this.setIn(['sectionsOptions', 'sectionCreating'], false);
    this.setIn(['sectionsOptions', 'sectionCreateError'], null);

    this.changed();

    apiActions.getSections();
  },

  createSectionFailed(err, params) {
    const errText = _.isObject(err) && err.text ? err.text : '';
    this.setIn(['sectionsOptions', 'sectionCreating'], false);
    this.setIn(['sectionsOptions', 'sectionCreateError'], errText || true);
    this.changed();
  },

  /* ========================
   * Update Section
   */
  updateSection(params) {
    this.setIn(['sections', params.sectionId, 'updating'], true);
    this.setIn(['sections', params.sectionId, 'updateError'], null);
    this.changed();
  },

  updateSectionCompleted(res, params, data) {
    let section = this.getIn(['sections', params.sectionId]);
    section = section.merge(data);
    this.setIn(['sections', params.sectionId], section);

    // update catalog order
    if (data.catalogsPriorities) {
      this.changeSortIndex(data.catalogsPriorities);
    }

    this.setIn(['sections', params.sectionId, 'updating'], false);
    this.setIn(['sections', params.sectionId, 'updateError'], null);

    this.changed();
  },

  updateSectionFailed(err, params) {
    const errText = _.isObject(err) && err.text ? err.text : '';
    this.setIn(['sections', params.sectionId, 'updating'], false);
    this.setIn(['sections', params.sectionId, 'updateError'], errText || true);
    this.changed();
  },

  /* ========================
   * Delete Section
   */
  deleteSection(params) {
    this.setIn(['sections', params.sectionId, 'deleting'], params.sectionId);
    this.setIn(['sections', params.sectionId, 'deleteError'], null);
    this.changed();
  },

  deleteSectionCompleted(res, params) {
    const sections = this.get('sections').delete(params.sectionId);
    this.set('sections', sections);

    this.changed();

    apiActions.getSections();
  },

  deleteSectionFailed(err, params) {
    const errText = _.isObject(err) && err.text ? err.text : '';
    this.setIn(['sections', params.sectionId, 'deleting'], false);
    this.setIn(['sections', params.sectionId, 'deleteError'], errText || true);
    this.changed();
  },
};
