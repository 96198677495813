import React from 'react';
import Immutable from 'immutable';
import guid from 'guid';
import _ from 'lodash';
import urlParser from 'url';
import Mime from 'mime-types';
import isUrl from 'is-url';

import FIELD_TYPES from '../../configs/fieldTypes';
import { fileTypes } from '../../components/common/UI/ControlList/controls/fileViewer/getViewerType';
import { VALUE_STATUSES } from '../../configs/import';
import { Field } from './BaseClass.js';

const delimiters = ['\t', '\n'];
const splitValue = (values, delimiter) => {
  values = _.chain(values).split(delimiter).map(_.trim).value();

  return Immutable.List(values);
};

const getExtensionByTitle = (title) => {
  title = String(title).split('.');
  const extensionByTitle = title.length > 1 ? title.at(-1) : '';

  return fileTypes[extensionByTitle] ? extensionByTitle : '';
};

const getTitleFromUrl = (url) => {
  const parsedURL = urlParser.parse(url);
  const pathName = parsedURL.pathname && parsedURL.pathname.split('/');

  if (pathName.at(-1) !== '') {
    // filename by pathname last item
    return pathName.at(-1);
  }
  if (pathName.at(-2) !== '' && pathName.length !== 1) {
    // filename by pathname prelast item
    return pathName.at(-2);
  }
  return false;
};

const getUrl = (value) => {
  if (isUrl(value)) {
    const title = getTitleFromUrl(value);
    if (title) {
      const extension = getExtensionByTitle(title);
      const mimeType = Mime.lookup(`.${extension}`);

      return Immutable.Map({
        temporaryId: guid.raw(),
        mime: mimeType,
        title,
        url: value,
      });
    }
  }
};

export default class File extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.FILE;

  static components = {
    inline: require('../../components/common/dataTypes/FileField').default,
    control: require('../../components/common/UI/ControlList/controls/File').default,
  };

  static getComponent = (type) => File.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static parseValue = (field, value) => {
    value = _.trim(value);
    const multiselect = field && field.get(['config', 'multiselect']);

    /* парс пустого значения */
    if (_.isEmpty(value)) {
      value = File.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (field) {
      if (multiselect) {
        /* как заданное через разделители */
        let valuesByDelimetr = {};

        _.forEach(delimiters, (delimiter) => {
          /* разделение значения по разделителям */
          const splitedValuesByDelimetr = splitValue(value, delimiter);

          if (splitedValuesByDelimetr && !_.isEmpty(splitedValuesByDelimetr)) {
            /* для каждого значения проводим валидацию, это нужно для "мягкого" режима отображения */
            const validatedValues = splitedValuesByDelimetr
              .map((value) => {
                const url = getUrl(value);
                if (url && File.validateValue(field, Immutable.List([url]))) {
                  return url;
                }
                return null;
              })
              .filter((i) => !_.isNull(i));

            /* заполняем объект удачно распаршенными значениями */
            if (validatedValues && !validatedValues.isEmpty()) {
              valuesByDelimetr[delimiter] = validatedValues;
            }
          }
        });

        valuesByDelimetr = _.sortBy(valuesByDelimetr, (values) => values.size);
        const mostMatchesValues = _.last(valuesByDelimetr);

        if (mostMatchesValues && !mostMatchesValues.isEmpty()) {
          return { value: mostMatchesValues, status: VALUE_STATUSES.VALID };
        }
      } else {
        /* попытка распарсить не мультиселект */
        const url = getUrl(value);
        if (url && File.validateValue(field, Immutable.List([url]))) {
          return { value: Immutable.List([url]), status: VALUE_STATUSES.VALID };
        }
      }
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, value) => {
    if (Immutable.List.isList(value)) {
      return true;
    }

    if (!field) {
      return false;
    }
  };

  static isEmpty = (value) => !(value && value.size);

  static getEmptyValue = () => Immutable.List();

  static receivePossibleItems = (records) => {
    const items = [];
    records.forEach((record) => {
      const addItems = record.getIn(['values', this.id]);
      addItems.forEach((addItem) => {
        if (!items.some((item) => _.isEqual(item, addItem))) {
          items.push(addItem);
        }
      });
    });
    return items;
  };

  static createComponent = (field, value, type) => {
    const Component = File.components[type];
    return function ({ containerClassName }) {
      if (File.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static convertFilterToRecordValue = () => undefined;

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = !value || (_.isArray(value) && value.length == 0);
    return empty;
  };

  static boardWitchColor = () => false;

  static visibleRules = (value) => {
    const v = value;

    return { $in: v };
  };

  static sortRecordsInCards = (field, records) => records;

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
