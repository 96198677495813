import React from 'react';
import Immutable from 'immutable';
import _ from 'lodash';
import raf from 'raf';
import { Row, Upload } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import dndContext from '../../../../../services/dndContext';
import AttachFile from './AttachFileModal';
import fileActions from '../../../../../actions/fileActions';

import FileDrop from './FileDrop';
import FileViewer from './fileViewer';
import { getViewerByMimeType } from './fileViewer/getViewerType';

import styles from './controls.less';

const log = require('debug')('CRM:Component:Record:FileField');

class FileField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.xhrs = {};
    this.state = {
      imagesRendered: false,
      updateProgress: Immutable.Map(),
      uploadListeners: {
        progress: [],
        load: [],
        error: [],
      },
    };
  }

  pushUploadListeners = (event, value) => {
    const uploadListeners = { ...this.state.uploadListeners };
    let uploadListenersArr = uploadListeners[event];

    if (value) {
      uploadListenersArr.push(value);
    } else {
      uploadListenersArr = [];
    }
    uploadListeners[event] = uploadListenersArr;

    this.setState({ uploadListeners });
  };

  handleFile = (e) => {
    const { file } = e;
    if (file) {
      this.uploadFile(file);
    }
  };

  uploadFile = (file, value = this.props.value) => {
    const uploadId = Math.random();

    this.onChange(
      (value || Immutable.List()).push(
        Immutable.fromJS({
          id: uploadId,
          title: file.name,
          loading: true,
          size: file.size,
          error: false,
          mimeType: file.type,
        }),
      ),
    );

    fileActions.uploadFile({
      file,
      xhrs: this.xhrs,
      uploadId,
      updateProgress: this.updateProgress,
      pushUploadListeners: this.pushUploadListeners,
      completeUpload: this.completeUpload,
      removeUploadingFile: this.removeUploadingFile,
      setErrorOnFile: this.setErrorOnFile,
      controlConfig: this.props.controlConfig.get('requestParams'),
    });
  };

  uploadContent = (content, { type, title }, value = this.props.value) => {
    const file = new Blob([content], { type });
    file.name = title;
    this.uploadFile(file, value);
  };

  replaceFile = (file, newContent) => {
    const newValue = this.onClickRemoveFile(file);

    // raf will call after props will came again
    // it is needed because onClickRemoveFile call onEndEdit that is also called in raf
    raf(() => {
      this.uploadContent(newContent, file, newValue);
    });
  };

  onEndEditing = (newValue) => {
    this.props.onEndEditing && this.props.onEndEditing(newValue);
  };

  onChange = (newValue) => {
    this.props.onChange && this.props.onChange(newValue);
  };

  completeUpload = (uploadId, newFile) => {
    log('upload file complete', uploadId);
    const index = this.props.value.findIndex((f) => f.get('id') === uploadId);

    if (index !== -1) {
      this.onChange(this.props.value.set(index, newFile));
      this.onEndEditing(this.props.value.set(index, newFile));
    }
  };

  updateProgress = (uploadId, progress) => {
    this.setState({
      updateProgress: this.state.updateProgress.set(uploadId, progress),
    });
  };

  setErrorOnFile = (uploadId) => {
    this.onChange(
      this.props.value.map((f) => {
        if (f.get('id') === uploadId) {
          f = f.set('error', true).set('loading', false);
        }
        return f;
      }),
    );
  };

  removeUploadingFile = (uploadId) => {
    const index = this.props.value.findIndex((f) => f.get('id') === uploadId);
    if (index !== -1) {
      this.onChange(this.props.value.delete(index));
    }
  };

  onClickRemoveFile = (file) => {
    const { value } = this.props;
    const index = value.findIndex(
      (f) =>
        (f.get('id') && f.get('id') === file.id) || (f.get('temporaryId') && f.get('temporaryId') === file.temporaryId),
    );
    const xhr = this.xhrs[file.id];

    if (xhr) {
      xhr.abort();
      delete this.xhrs[file.id];
    }

    if (index !== -1) {
      const newValue = value.delete(index);
      this.onChange(newValue);
      this.onEndEditing(newValue);
      //! file.loading && apiActions.removeFileRecord({ fileId: file.id });
      return newValue;
    }

    return value;
  };

  // Вспомогательная функция для проверки размера файла
  isAllowedFileSize = (file) => {
    const maxAllowedFileSize = 3 * 1000000; // 3 мегабайта
    const isAllowedFileSize = file && file.size < maxAllowedFileSize;
    return isAllowedFileSize;
  };

  // Обработчик события onPaste для получения файлов из буфера обмена
  handlePaste = (event) => {
    if (!this.props.editable) return;
    const targetElement = event.target;
    // Проверяем, вызвано ли событие внутри элемента, где мы хотим обрабатывать вставку
    if (targetElement.className === 'ant-upload') {
      event.preventDefault();
      const clipBoardFiles = _.get(event, ['clipboardData', 'files'], []);
      if (_.size(clipBoardFiles)) {
        // Проверяем, есть ли в буфере изображение
        for (let i = 0; i < clipBoardFiles.length; i++) {
          const file = clipBoardFiles[i];
          // Проверяем, что файл - изображение, и его размер не превышает максимально допустимый
          if (file.type.indexOf('image') !== -1 && this.isAllowedFileSize(file)) {
            this.handleFile({ file });
            break;
          }
        }
      }
    }
  };

  componentWillUnmount() {
    _.forOwn(this.xhrs, (xhr) => {
      xhr.abort();
      // Очистка всех листенеров
      _.forOwn(this.state.uploadListeners, (listeneres, event) => {
        _.forEach(listeneres, (listener) => {
          xhr.removeEventListener(event, listener);
        });
        this.pushUploadListeners(event);
      });
    });

    const { value } = this.props;

    if (value && value.find((val) => val.get('loading'))) {
      let newValue = Immutable.List();
      value.forEach((val) => {
        if (!val.get('loading')) {
          newValue = newValue.push(val);
        }
      });
      this.onChange(newValue);
    }
  }

  render() {
    const { params, value, config, editable } = this.props;
    const values = (value && value.toJS()) || [];
    const mimeType = config.get('mimeType') || '';
    const showUploadButton = editable && (config.get('multiselect') || values.length === 0);
    const mimeTypes = mimeType.split(',').map((t) => t.trim());

    return (
      <>
        {showUploadButton && <FileDrop onDrop={this.handleFile} multiple={this.props.config.get('multiselect')} />}

        <div className={this.props.wrapperClassName} style={{ position: 'relative' }} onPaste={this.handlePaste}>
          <FileViewer
            files={values}
            readOnly={!editable}
            removeFn={this.onClickRemoveFile}
            saveFn={this.replaceFile}
            updateProgress={this.state.updateProgress}
            params={params}
          />
          {this.props.editable ? (
            <Row type="flex" style={!showUploadButton ? { display: 'none' } : null}>
              {editable &&
                mimeTypes.map((mimeType, idx) => {
                  const Viewer = getViewerByMimeType(mimeType);
                  return (
                    Viewer && (
                      <div className={styles.viewerWrapper} key={idx}>
                        <Viewer
                          params={params}
                          key={mimeType}
                          saveFn={
                            editable &&
                            (({ title }, content) =>
                              this.uploadContent(content, {
                                title,
                                type: mimeType,
                              }))
                          }
                        />
                      </div>
                    )
                  );
                })}
              <div title={this.props.t('record.fields.file.drop')} className={styles.uploadWrapper}>
                <Upload
                  className={styles.upload}
                  customRequest={this.handleFile}
                  showUploadList={false}
                  multiple={this.props.config.get('multiselect')}
                  accept={mimeType}
                >
                  {this.props.t('record.fields.file.upload')}
                </Upload>
                <AttachFile onChange={this.onChange} onEndEditing={this.onEndEditing} value={this.props.value} />
              </div>
            </Row>
          ) : null}
        </div>
      </>
    );
  }
}

FileField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
};

export default withTranslation()(dndContext(FileField));
