import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import { Switch } from 'antd';

import styles from './controls.less';

class SwitchControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    this.setState({ value });
  }

  onChange = (value) => {
    const { onChange, onEndEditing } = this.props;

    this.setState({ value });
    onChange && onChange(value);
    onEndEditing && onEndEditing(value);
  };

  onBlur = (e) => {
    const { value } = this.state;

    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.props.onEndEditing && this.props.onEndEditing(value);
    }
  };

  render() {
    const { autoFocus, editable, loading } = this.props;
    const { value } = this.state;

    return (
      <div className={cn(styles.switchWrapper, this.props.wrapperClassName)} tabIndex="-1" onBlur={this.onBlur}>
        <Switch
          defaultChecked={value}
          autoFocus={autoFocus}
          checked={value}
          loading={loading}
          disabled={!editable}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

SwitchControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
  eventable: PropTypes.bool,
  error: PropTypes.string,
};

export default SwitchControl;
