export function secToTime(seconds, template = '') {
  if (!seconds) {
    return '';
  }

  let sec = seconds % 60;
  let min = (seconds - sec) / 60;
  let hours = '';

  if (min > 60 || template.length > 5) {
    hours = `${Math.floor(min / 60)}:`;
    min %= 60;
  }

  if ((hours && min < 10) || template.length > 2) {
    min = `0${min}`;
  }

  min = min ? `${min}:` : '';

  if (min && sec < 10) {
    sec = `0${sec}`;
  }

  return hours + min + sec;
}
