import Mime from 'mime-types';

import i18n from '../../../../../../configs/i18n';
import DefaultViewer from './viewers/Default';
import ImageViewer from './viewers/Image';
import AudioViewer from './viewers/Audio';
import VideoViewer from './viewers/Video';
import OfficeViewer from './viewers/Office';
import PDFViewer from './viewers/PDF';
import CADViewer from './viewers/CAD';
import BpmnViewer from './viewers/Bpmn';

// extension: {getViewer: viewer, icon}
export const fileTypesCategories = {
  document: {
    name: i18n.t('record.attachFile.categories.documents'),
    priority: 1,
  },
  image: {
    name: i18n.t('record.attachFile.categories.images'),
    priority: 2,
  },
  audio: {
    name: i18n.t('record.attachFile.categories.audioFiles'),
    priority: 3,
  },
  video: {
    name: i18n.t('record.attachFile.categories.videoFiles'),
    priority: 4,
  },
  drawing: {
    name: i18n.t('record.attachFile.categories.drawings'),
    priority: 5,
  },
  businessProcesses: {
    name: i18n.t('record.attachFile.categories.bpmn'),
    hidden: true,
  },
  default: {
    name: i18n.t('record.attachFile.categories.default'),
    priority: 999,
  },
};

export const fileTypes = {
  // documents are divided into subtypes (AutoCAD, PDF, MS Office..), therefore indicates getViewer field
  doc: {
    viewer: OfficeViewer,
    icon: 'files-13',
    defenition: 'DOC - Microsoft Word',
    category: fileTypesCategories.document,
  },
  docx: {
    viewer: OfficeViewer,
    icon: 'files-13',
    defenition: 'DOCX - Microsoft Word 2007',
    category: fileTypesCategories.document,
  },
  pdf: {
    viewer: PDFViewer,
    icon: 'files-13',
    defenition: 'PDF - Adobe Portable Document Format',
    category: fileTypesCategories.document,
  },
  ppt: {
    viewer: OfficeViewer,
    icon: 'files-17',
    defenition: 'PPT - Microsoft PowerPoint',
    category: fileTypesCategories.document,
  },
  pptx: {
    viewer: OfficeViewer,
    icon: 'files-17',
    defenition: 'PPTX - Microsoft Office Presentation',
    category: fileTypesCategories.document,
  },
  xls: {
    viewer: OfficeViewer,
    icon: 'files-15',
    defenition: 'XLS - Microsoft Excel',
    category: fileTypesCategories.document,
  },
  xlsx: {
    viewer: OfficeViewer,
    icon: 'files-15',
    defenition: 'XLSX - Microsoft Office Spreadsheet',
    category: fileTypesCategories.document,
  },
  dwf: {
    viewer: CADViewer,
    icon: 'files-66',
    defenition: 'DWF - Autodesk Design Web Format',
    category: fileTypesCategories.drawing,
  },
  hpgl: {
    viewer: CADViewer,
    icon: 'files-66',
    defenition: 'HPGL - Hewlett-Packard Graphics Language',
    category: fileTypesCategories.drawing,
  },
  plt: {
    viewer: CADViewer,
    icon: 'files-66',
    defenition: 'PLT - AutoCAD Potter Document',
    category: fileTypesCategories.drawing,
  },
  dwg: {
    viewer: CADViewer,
    icon: 'files-66',
    defenition: 'DWG - DWG Drawing',
    category: fileTypesCategories.drawing,
  },

  // audio
  au: {
    viewer: AudioViewer,
    defenition: 'AU - Au file format',
    category: fileTypesCategories.audio,
  },
  aac: {
    viewer: AudioViewer,
    defenition: 'AAC - Advanced Audio Coding',
    category: fileTypesCategories.audio,
  },
  mp3: {
    viewer: AudioViewer,
    defenition: 'MP3 - MPEG Audio',
    category: fileTypesCategories.audio,
  },
  ogg: {
    viewer: AudioViewer,
    defenition: 'OGG - Ogg Audio',
    category: fileTypesCategories.audio,
  },
  oga: {
    viewer: AudioViewer,
    defenition: 'OGA - Ogg Vorbis Codec Compressed WAV File',
    category: fileTypesCategories.audio,
  },
  wma: {
    viewer: AudioViewer,
    defenition: 'WMA - Microsoft Windows Media Audio',
    category: fileTypesCategories.audio,
  },
  wax: {
    viewer: AudioViewer,
    defenition: 'WAX - Microsoft Windows Media Audio Redirector',
    category: fileTypesCategories.audio,
  },
  ra: {
    viewer: AudioViewer,
    defenition: 'RA - RealAudio',
    category: fileTypesCategories.audio,
  },
  wav: {
    viewer: AudioViewer,
    defenition: 'WAV - WAVE Audio File Format',
    category: fileTypesCategories.audio,
  },
  webm: {
    viewer: AudioViewer,
    defenition: 'WEBM - Open Web Media Project',
    category: fileTypesCategories.audio,
  },

  // video
  mp4: {
    viewer: VideoViewer,
    defenition: 'MP4, MPEG-4',
    category: fileTypesCategories.video,
  },
  // eslint-disable-next-line no-dupe-keys
  webm: {
    viewer: VideoViewer,
    defenition: 'WEBM - Open Web Media Project',
    category: fileTypesCategories.video,
  },
  ogv: {
    viewer: VideoViewer,
    defenition: 'OGV is a free lossy video compression format',
    category: fileTypesCategories.video,
  },

  // BpmnEditor
  bpmn: {
    viewer: BpmnViewer,
    defenition: 'BPMN - Business Process Model and Notation',
    category: fileTypesCategories.businessProcesses,
    icon: 'files-66',
  },

  // images
  png: {
    viewer: ImageViewer,
    defenition: 'PNG - Portable Network Graphics',
    category: fileTypesCategories.image,
  },
  bmp: {
    viewer: ImageViewer,
    defenition: 'BMP - Bitmap Image File',
    category: fileTypesCategories.image,
  },
  gif: {
    viewer: ImageViewer,
    defenition: 'GIF - Graphics Interchange Format',
    category: fileTypesCategories.image,
  },
  icon: {
    viewer: ImageViewer,
    defenition: 'ICON - Icon Image',
    category: fileTypesCategories.image,
  },
  jpg: {
    viewer: ImageViewer,
    defenition: 'JPG - JPG Image',
    category: fileTypesCategories.image,
  },
  jpeg: {
    viewer: ImageViewer,
    defenition: 'JPEG - JPEG Image',
    category: fileTypesCategories.image,
  },
  tiff: {
    viewer: ImageViewer,
    defenition: 'TIFF - Tagged Image File Format',
    category: fileTypesCategories.image,
  },
  ico: {
    viewer: ImageViewer,
    defenition: 'ICO - Icon file format (ICO)',
    category: fileTypesCategories.image,
  },
  webp: {
    viewer: ImageViewer,
    defenition: 'WEBP - WebP Image (WEBP)',
    category: fileTypesCategories.image,
  },
  default: {
    viewer: DefaultViewer,
    icon: 'files-13',
    category: fileTypesCategories.default,
  },
};

export function getFileTypeByMimeType(mimeType) {
  if (mimeType === 'application/bpmn+xml') {
    return fileTypes.bpmn;
  }
  const extension = Mime.extension(mimeType);
  const type = extension && fileTypes[extension];

  return type;
}

export function getFileType(file) {
  // check by mimeType
  let type = getFileTypeByMimeType(file.mimeType);
  if (type) {
    return type;
  }

  // check by extension of filename
  const fileParts = file && file.title.split('.'); // protection for parsing
  const extension = fileParts.length > 1 ? fileParts[fileParts.length - 1] : ''; // get extension
  type = extension && fileTypes[extension.toLowerCase()];
  if (type) {
    return type;
  }
  // return default viewer
  return fileTypes.default;
}

// to recognize which type of document was requested
export function getViewerByMimeType(mimeType) {
  const type = getFileTypeByMimeType(mimeType);
  return type && type.viewer;
}

export function getViewer(file) {
  const type = getFileType(file);
  return type && type.viewer;
}

// export function isMyFile(mimeType, file ) {
//   return !!getViewer(mimeType, file)
// }
