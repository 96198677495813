import React from 'react';
import Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Category from '../../Category';

import styles from './controls.less';

function getValuesMap(values) {
  const map = {};
  (values || []).forEach((v) => {
    map[v] = true;
  });
  return Immutable.fromJS(map);
}

class CategoryList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: getValuesMap(this.props.value),
    };
  }

  onClickItem = (itemId) => {
    if (!this.props.editable) {
      return;
    }

    let newValues = this.props.config.get('multiselect') ? this.state.values : Immutable.fromJS({});

    newValues = newValues.set(itemId, !this.state.values.get(itemId));

    this.setState({ values: newValues });

    const values = [];
    newValues.forEach((v, id) => {
      if (v) {
        values.push(id);
      }
    });

    this.props.onChange && this.props.onChange(values);
    this.props.onEndEditing && this.props.onEndEditing(values);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.value, this.props.value)) {
      this.setState({
        values: getValuesMap(nextProps.value),
      });
    }
  }

  render() {
    let items = this.props.config.get('items');
    const { editable } = this.props;

    if (!editable) {
      items = items.filter((item) => this.state.values.get(item.get('id')));
    }
    return (
      <div className={styles.categoryList}>
        {items.map((item) => {
          const id = item.get('id');
          const checked = this.state.values.get(id);
          return (
            <Category
              key={id}
              onClick={_.bind(this.onClickItem, this, id)}
              readOnly={!editable}
              checked={checked}
              className={styles.category}
              title={item.get('name')}
              backgroundColor={`#${item.get('color')}`}
            />
          );
        })}
      </div>
    );
  }
}

CategoryList.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
};

export default CategoryList;
