import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router';
import moment from 'moment';

import Record from './Record';
import { connect } from '../StateProvider';
import apiActions from '../../actions/apiActions';
import sceneActions from '../../actions/sceneActions';
import historyActions from '../../actions/historyActions';
import getLink from '../common/router/getLink';
import routes from '../../routes';
import { SECONDS_BEFORE_RECORD_LOADING } from '../../configs/timeConsts';
import messageActions from '../../actions/messageActions';

class RecordController extends React.PureComponent {
  static propTypes = {
    catalogId: PropTypes.string,
    sceneId: PropTypes.string,
    recordId: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    isWebForm: PropTypes.bool,
  };

  state = {};

  onSave = () => {
    const { catalogId, recordId, sceneId, onSave } = this.props;
    this.getRecord({ recordId, catalogId, sceneId });
    onSave && onSave();
  };

  _isRecordLoadedRecent() {
    let loadedRecent = false;
    const loadTime = this.props.record && this.props.record.get('_loadTime');
    const currentTime = moment().toDate().getTime();

    // Time in seconds
    const diff = (currentTime - loadTime) / SECONDS_BEFORE_RECORD_LOADING;

    // Check if record was loaded less than 5 minutes ago
    if (!_.isNaN(diff)) {
      loadedRecent = diff < SECONDS_BEFORE_RECORD_LOADING;
    }
    return loadedRecent;
  }

  _getRecord({ recordId, catalogId, sceneId }) {
    const loadedRecent = this._isRecordLoadedRecent();

    // Do not loadi records if it was loaded recently
    if (loadedRecent) {
      return;
    }

    this.getRecord({ recordId, catalogId, sceneId });
  }

  getRecord({ recordId, catalogId, sceneId }) {
    apiActions
      .getRecord(
        {
          recordId,
          catalogId,
        },
        { withFieldsAdditional: true },
        { sceneId },
      )
      .catch((e) => {
        this.setState({
          recordErrorLoading: true,
        });
        console.error(e);
        sceneActions.deleteScene(sceneId);
        this.props.history.push(getLink(this.props.location, routes.records));
      });
  }

  onRefresh = () => {
    const { recordId, catalogId, sceneId } = this.props;

    const tab = this.getRecordTab();

    if (tab === routes.recordMain) {
      this.getRecord({ recordId, catalogId, sceneId });
    } else if (tab === routes.recordLinks) {
      apiActions.getRelations({
        catalogId,
        recordId,
      });
    } else if (tab === routes.recordHistory) {
      historyActions.loadHistory(sceneId, catalogId, recordId, recordId, {}, true);
    } else if (tab === routes.recordChat) {
      messageActions.loadMessages({ catalogId, recordId }, true, 10);
    }
  };

  isLoading = () => {
    const { record } = this.props;

    if (!record) {
      return;
    }

    const tab = this.getRecordTab();
    if (tab === routes.recordMain) {
      return record.get('loading');
    }
    if (tab === routes.recordLinks) {
      return record.get('linkedDataLoading');
    }
    if (tab === routes.recordHistory) {
      return record.getIn(['history', 'loading']);
    }
  };

  getRecordTab = () => {
    if (
      matchPath(this.props.location.pathname, {
        path: routes.recordMain.path,
        exact: true,
        strict: false,
      })
    ) {
      return routes.recordMain;
    }
    if (
      matchPath(this.props.location.pathname, {
        path: routes.recordLinks.path,
        exact: true,
        strict: false,
      })
    ) {
      return routes.recordLinks;
    }
    if (
      matchPath(this.props.location.pathname, {
        path: routes.recordHistory.path,
        exact: true,
        strict: false,
      })
    ) {
      return routes.recordHistory;
    }
    if (
      matchPath(this.props.location.pathname, {
        path: routes.recordChat.path,
        exact: true,
        strict: false,
      })
    ) {
      return routes.recordChat;
    }
  };

  componentDidMount() {
    const { catalogId, recordId, record, sceneId } = this.props;

    const isNew = record && record.get('isNew');
    if (!isNew) {
      this.getRecord({
        recordId,
        catalogId,
        sceneId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { recordId: newRecordId, record, catalogId, sceneId, sceneExist } = this.props;

    const { recordId: prevRecordId, sceneExist: sceneExistInPast, catalogId: prevCatalogId } = prevProps;

    const isNew = record && record.get('isNew');
    if (prevRecordId !== newRecordId || prevCatalogId !== catalogId) {
      if (!isNew) {
        this.getRecord({
          recordId: newRecordId,
          catalogId,
          sceneId,
        });
      }
    }

    /* в случаях удаления записи должен быть произведен редирект, тк сам компонент записи недоступен */
    if (!sceneExist && sceneExistInPast) {
      /* удаление сцены происходит в трех случаях:
      1. когда закрываем запись. match у нас null и нам нужно открыть все записи.
      2. когда удаляем запись. match у нас не точный нам нужно открыть все записи.
      3. когда изменяем что-то в записи и переходим на другую запись не сохраняя. match у нас точный нам нужно открыть конкретную запись */
      const route = this.props.match?.isExact ? routes.record : routes.records;
      this.props.history.push(getLink(this.props.location, route));
    }
  }

  render() {
    const { sceneId, catalogId, recordId, isWebForm, onDelete, onClose, record } = this.props;

    /* этот флаг нужен для опредления ошибки загрузки записи, чтобы дальнейший компонент отрендерился, но не вызвал дальнейшую загрузку каталога */
    const { recordErrorLoading } = this.state;
    const isNew = record && record.get('isNew');

    /* в случаях удаления записи должен быть произведен редирект, тк сам компонент записи недоступен */
    /*     if (!sceneExist) {
      return <NavRedirect route={routes.records} />
    } */

    return record ? (
      <Record
        sceneId={sceneId}
        catalogId={catalogId}
        recordId={recordId}
        record={record}
        isWebForm={isWebForm}
        isNew={isNew}
        onSave={this.onSave}
        onDelete={onDelete}
        onRefresh={this.onRefresh}
        onClose={onClose}
        isLoading={this.isLoading}
        onClear={this.props.onClear}
        recordErrorLoading={recordErrorLoading}
      />
    ) : null;
  }
}

export default connect(withRouter(RecordController), ['records', 'scenes'], (props, { records, scenes }) => {
  const { catalogId, recordId, sceneId } = props;
  const sceneExist = !!scenes.get(sceneId);
  const record = records && records.getIn([catalogId, recordId]);
  return {
    ...props,
    catalogId,
    recordId,
    record,
    sceneExist,
    records,
  };
});
