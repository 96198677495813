export default (t) => [
  {
    id: 'sectionContext',
    name: t('script.sandbox.fields.sectionContext.name'),
    type: 'group',
  },
  {
    id: 'context',
    name: t('script.sandbox.fields.context.name'),
    type: 'text',
    readOnly: false,
    hint: t('script.sandbox.fields.context.hint'),
    config: {
      type: 'script',
      rows: 10,
      theme: 'codeEditor',
    },
  },
  {
    id: 'processId',
    name: t('script.sandbox.fields.processId.name'),
    type: 'radiobutton',
    readOnly: false,
    hint: t('script.sandbox.fields.processId.hint'),
    config: {
      items: [],
      type: 'select',
    },
  },
  {
    id: 'sectionCode',
    name: t('script.sandbox.fields.sectionCode.name'),
    type: 'group',
  },
  {
    id: 'source',
    name: t('script.sandbox.fields.source.name'),
    type: 'text',
    readOnly: false,
    hint: t('script.sandbox.fields.source.hint'),
    config: {
      type: 'script',
      rows: 15,
      theme: 'codeEditor',
    },
  },
  {
    id: 'result',
    name: t('script.sandbox.fields.result.name'),
    type: 'text',
    readOnly: true,
    hint: t('script.sandbox.fields.result.hint'),
    config: {
      type: 'script',
      rows: 5,
      theme: 'codeEditor',
    },
  },
];
