import React from 'react';
import _ from 'lodash';
import Immutable from 'immutable';

import FIELD_TYPES from '../../configs/fieldTypes';
import { parseDadata } from '../../components/common/UI/ControlList/controls/Address/utils';
import { VALUE_STATUSES } from '../../configs/import';
import { Field } from './BaseClass.js';

const delimiters = [',', ';'];

const splitValue = (values, delimiter) => {
  if (_.isString(values)) {
    values = _.chain(values).split(delimiter).map(_.trim).value();
  }

  return values;
};

const getItems = (value, field) =>
  new Promise(async (resolve, reject) => {
    const request = new XMLHttpRequest();

    const payload = JSON.stringify({
      query: value,
      count: 1,
    });

    request.open('POST', `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`);

    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('Authorization', `Token ${field.getIn(['config', 'token'])}`);

    request.setRequestHeader('Content-Type', 'application/json');
    request.send(payload);

    request.onload = async () => {
      let suggestions;
      if (request.status === 200) {
        suggestions = _.get(JSON.parse(request.response), 'suggestions');
        suggestions = _.map(suggestions || [], (d) => d && parseDadata(d));

        if (suggestions && !_.isEmpty(suggestions)) {
          resolve(suggestions);
        } else {
          reject(false);
        }
      } else if (request.status === 429) {
        try {
          const retryAfter = request.getResponseHeader('retryAfter');
          retryAfter && (await new Promise((resolve) => setTimeout(resolve, retryAfter * 1000 + retryAfter * 100)));
          retryAfter &&
            (await getItems(value, field)
              .then((res) => resolve(res))
              .catch((err) => reject(err)));
        } catch (error) {}
      } else {
        reject(false);
      }
    };

    request.onerror = (err) => {
      console.error(err);
      reject(false);
    };
  });

const findItem = (value, field) =>
  getItems(value, field)
    .then((suggestions) => {
      if (!suggestions) {
        return false;
      }

      let suggestData = _.find(suggestions, (f) => f.value == value);
      suggestData = suggestData || (suggestions ? suggestions[0] : {});
      suggestData = suggestData || {};

      const sValue = {
        value: suggestData ? suggestData.value : value,
        data: suggestData ? suggestData.data : {},
      };

      if (sValue.value) {
        return Immutable.fromJS(sValue);
      }
      return false;
    })
    .catch((err) => console.warn('failed to find item on dadata'));

export default class Address extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.ADDRESS;

  static components = {
    inline: require('../../components/common/dataTypes/AddressField').default,
    control: require('../../components/common/UI/ControlList/controls/Address').default,
  };

  static getComponent = (type) => Address.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static parseValue = async (field, value) => {
    value = _.trim(value);

    /* парс пустого значения */
    if (_.isEmpty(value)) {
      value = Address.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (_.isString(value)) {
      let item = await findItem(value, field);

      if (item && Address.validateValue(field, item)) {
        return { value: item, status: VALUE_STATUSES.VALID };
      }

      /* попытка разделить данные по разделителю */
      _.forEach(delimiters, (delimetr) => {
        const splitedValuesByDelimetr = splitValue(value, delimetr);

        if (splitedValuesByDelimetr && !_.isEmpty(splitedValuesByDelimetr)) {
          splitedValuesByDelimetr[delimetr] = splitedValuesByDelimetr;
        }
      });

      // сортируем по длинне, если указано несколько адресов и выбираем наименьшую длинну, тк наименьшая длинна - признак цельного адрес, а не частей адреса
      const sortedBySizeValue = _.sortBy((valuesByDelimetr) => _.size(valuesByDelimetr));
      const minLengthValue = sortedBySizeValue && sortedBySizeValue[0];

      if (!minLengthValue) {
        return { value, status: VALUE_STATUSES.INVALID };
      }

      item = await findItem(value, field);

      if (Address.validateValue(field, item)) {
        return { value: item, status: VALUE_STATUSES.VALID };
      }
    }
    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, validateValue) => {
    if (!field || !Immutable.Map.isMap(validateValue)) {
      return false;
    }

    /* провести валидацию по запросу на дадата ? и если пришли данные как их валидировать ?
     нужно же обновить данные чтобы все подтянулось и выбрать из нескольких вариантов если они есть */
    return true;
  };

  static createComponent = (field, value, type) => {
    const Component = Address.components[type];

    return function ({ containerClassName }) {
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static getEmptyValue = () => Immutable.List();

  static isEmpty = (value) => !(value && value.size);

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static convertFilterToRecordValue = () => undefined;

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = !value || _.isNull(value) || _.isEmpty(value) || value.value == '';
    return empty;
  };

  static boardWitchColor = () => false;

  static visibleRules = (value) => {};

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get('id');
    // sort by address value
    return records.sortBy((r) => r.getIn(['values', fieldId, 'value']));
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
