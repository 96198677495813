import _ from 'lodash';
import apiActions from '../actions/apiActions';

const TEN_MINUTES = 10 * 60 * 1000;
const interval = 10 * 60 * 1000;

const chatStatusesTimer = {
  timeoutId: null,
  lastCheckNewMessages: null,

  startTimer() {
    this.timeoutId && clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.runTimer.bind(this), interval);
  },

  runTimer() {
    if (!document.hidden) {
      this.lastCheckNewMessages = new Date();
      this.chatsStatuses();
    }
    this.startTimer();
  },

  startTimerCheckNewMessages(nextUpdate = this.nextUpdate) {
    if (!nextUpdate) {
      return;
    }

    const duration = Math.max(nextUpdate - Date.now(), 0);
    this.timeoutId && clearTimeout(this.timeoutId);

    if (duration > 0) {
      this.timeoutId = setTimeout(this.startTimerCheckNewMessages.bind(this), Math.min(duration, 1000));
    } else {
      !document.hidden && this.chatsStatuses();
    }
  },

  getCatalogsCompleted(res, params, data, query) {
    this.startTimerCheckNewMessages();
  },

  chatsStatuses() {
    return apiActions.getChatsStatuses(
      {},
      this.lastCheckNewMessages ? { lastCheckDate: this.lastCheckNewMessages.toJSON() } : {},
    );
  },

  getChatsStatuses(params, query) {},

  getChatsStatusesCompleted(newMessages, params, data, query, res, actionParams) {
    this.lastCheckNewMessages = new Date();

    newMessages.forEach((newMessage) => {
      const { catalogId, newChats } = newMessage;
      let catalog = this.getIn(['catalogs', catalogId]);
      const prevCount = catalog.getIn(['chat', 'newChats']);
      // добавляем count в каталоге
      catalog = catalog.setIn(['chat', 'newChats'], Number(prevCount) + Number(newChats));
      this.setIn(['catalogs', catalogId], catalog);

      // добавляем count в секцию, если у нас в каталоге было до этого нуль, то мы добавляем в секцию
      const sectionId = catalog.get('sectionId');
      const prevCountSections = this.getIn(['sections', sectionId, 'newChats']);
      this.setIn(['sections', sectionId, 'newChats'], Number(prevCountSections) + Number(newChats));
    });
  },
};

// Handle visibility change to trigger actions when tab becomes active
window.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    chatStatusesTimer.startTimerCheckNewMessages();
  }
});

export default chatStatusesTimer;
