import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './dataTypes.less';

function NumberField(props) {
  const { i18n } = useTranslation();
  const className = cn(props.containerClassName, styles.simpleField, styles.alignRight);

  const value = new Intl.NumberFormat(i18n.language).format(props.value);
  // unit
  let units = null;
  if (props.withUnit && props.config.get('unit')) {
    units = (
      <span className={styles.extraData}>
        &nbsp;
        {props.config.get('unit')}
      </span>
    );
  }

  if (props.value == null || props.value === '') {
    return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
  }
  return (
    <span className={className}>
      <span>
        {value}
        {units}
      </span>
    </span>
  );
}

NumberField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  config: PropTypes.object,
  withUnit: PropTypes.bool,
};

export default NumberField;
