// field date axis sub types
export const HOUR = 'hour';
export const HOUR_OF_DAY = 'hourOfDay';
export const DAY = 'day';
export const DAY_OF_WEEK = 'dayOfWeek';
export const WEEK_OF_YEAR = 'weekOfYear';
export const WEEK = 'week';
export const MONTH_OF_YEAR = 'monthOfYear';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const QUARTER_OF_YEAR = 'quarterOfYear';
export const YEAR = 'year';
