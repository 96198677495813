import _ from 'lodash';
import React from 'react';
import Immutable, { List } from 'immutable';
import { withTranslation } from 'react-i18next';
import RecordDropdown from '../../common/UI/ControlList/controls/RecordDropdown';
import availableLinkedRecordActions from '../../../actions/availableLinkedRecordActions';
import { objectFilters, userFilters } from '../../../configs/filterKeys';
import { connect } from '../../StateProvider';
import AppState from '../../../appState';
import Filters from '..';
import styles from '../filter.less';
import AdditionalFilters from '../AdditionalFilters';

class ObjectField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      extendedFiltersFields: Immutable.Map({}),
    };
  }

  componentDidMount() {
    this.updateLinkedObjectFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config || prevProps.value !== this.props.value) {
      this.updateLinkedObjectFields();
    }
  }

  inMapper = (item) => {
    // special filters;
    let title = item.get('recordTitle');
    if (!title) {
      const filter = _.find(objectFilters, (f) => f.key == item.get('catalogId'));
      title = filter && filter.value;
    }
    if (!title) {
      const filter = _.find(userFilters, (f) => f.key == item.get('catalogId'));
      title = filter && filter.value;
    }
    if (!title) {
      const filter = _.find(userFilters, (f) => f.key == item.get('recordId'));
      title = filter && filter.value;
    }
    if (!title) {
      title = '???';
    }

    // icon
    let icon = item.get('catalogIcon');
    const { config } = this.props;
    if (config && !icon) {
      const catalogId = item.get('catalogId');
      const catalogs = config.getIn(['catalogs']);
      const catalog = catalogs.find((c) => c.get('id') == catalogId);
      if (catalog) {
        icon = catalog.get('icon');
      } else {
        const catalog = this.props.catalogs.get(catalogId);
        if (catalog) {
          icon = catalog.get('icon');
        }
      }
    }

    return {
      key: item.get('catalogId') + (item.get('recordId') ? `:${item.get('recordId')}` : ''),
      text: title,
      icon,
      item,
    };
  };

  outMapper = ({ key, text, item }) => {
    const d = key.split(':');
    const result = {
      catalogId: d[0],
      recordTitle: text,
    };
    // record can be undefined (will be converted to "undefined")
    if (d[1]) {
      // eslint-disable-next-line prefer-destructuring
      result.recordId = d[1];
    }
    if (item && item.catalogIcon) {
      result.catalogIcon = item.catalogIcon;
    }
    return result;
  };

  filterMapper = ({ key }) => {
    const d = key.split(':');
    const result = { catalogId: d[0] };
    if (d[1]) {
      // eslint-disable-next-line prefer-destructuring
      result.recordId = d[1];
    }
    return result;
  };

  getSelectedItems = () => {
    const { value } = this.props;
    return value ? value.filter((i) => !i.get('filters')) : Immutable.List();
  };

  getSelectedItemsPlain = () => {
    const items = this.getSelectedItems();
    return items ? items.toJS() : [];
  };

  getExtendedFilterItems = () => {
    const { value } = this.props;
    return value ? value.filter((i) => i.get('filters')) : Immutable.List();
  };

  getExtendedFilterItemsPlain = () => {
    const items = this.getExtendedFilterItems();
    return items ? items.toJS() : [];
  };

  onChange = (items) => {
    items = items.map(this.outMapper);
    this.onSave(items, null);
  };

  onChangeExtended = (catalogId, fieldId, value) => {
    // get current filters by extended fields
    let items = this.getExtendedFilterItemsPlain();
    items = _.keyBy(items, 'catalogId');

    const hasValue = value !== null && !_.isUndefined(value);

    // create item for this extended catalog
    if (!items[catalogId]) {
      items[catalogId] = {
        catalogId,
        filters: {},
      };
    }

    // create filter for this extended catalog field
    if (hasValue) {
      items[catalogId].filters[fieldId] = [{ fieldId, value }]; // filter is array
    }

    // remove field filter, if it is extra filter

    if (!hasValue) {
      delete items[catalogId].filters[fieldId];

      // remove additional extended field filter
      this.removeExtendedFilter(catalogId, fieldId);
    }

    // remove filters if all extended filters be catalog are removed
    items = _.pickBy(items, (i) => !_.isEmpty(i.filters));

    // set & save
    items = _.values(items);
    this.onSave(null, items);
  };

  onSave = (selectedItems = null, extendedFilterItems = null) => {
    const { onSave, fieldId } = this.props;
    // combine selected items & extendede filters
    selectedItems = selectedItems !== null ? selectedItems : this.getSelectedItemsPlain();
    extendedFilterItems = extendedFilterItems !== null ? extendedFilterItems : this.getExtendedFilterItemsPlain();

    const items = _.concat(selectedItems, extendedFilterItems);
    onSave && onSave(fieldId, items);
  };

  sortFn = (items) => {
    if (_.isArray(items)) {
      const orderedItems = _.sortBy(items, 'recordTitle');
      const systemItems = [];
      const dynamicItems = [];
      const otherItems = [];

      items.forEach((item) => {
        if (item.catalogId === '$EMPTY') {
          systemItems.push(item);
        } else if (item.catalogId === 'USER_FIELD') {
          dynamicItems.push(item);
        } else {
          otherItems.push(item);
        }
      });

      return systemItems.concat(dynamicItems.concat(otherItems));
    }
    return items;
  };

  // extra searchable special items
  getExtraItems() {
    const { t, catalogs } = this.props;
    const items = [];

    const config = this.props.config || Immutable.Map({});

    let linkedCatalogs = config.get('catalogs');
    linkedCatalogs.get(0) === '*'
      ? (linkedCatalogs = catalogs.valueSeq().map((i) => i.get('id')))
      : (linkedCatalogs = linkedCatalogs.map((i) => i.get('id')));

    linkedCatalogs = linkedCatalogs.concat(config.get('views').map((i) => i.get('catalogId')));
    linkedCatalogs = linkedCatalogs ? linkedCatalogs.toJS() : [];
    linkedCatalogs = _.uniq(linkedCatalogs);
    if (linkedCatalogs.length === 1) {
      items.push({
        key: linkedCatalogs[0],
        text: t('filter.keys.$HAS'),
        sort: -1,
      });
    } else {
      linkedCatalogs.forEach((i) => {
        const catalog = AppState.getIn(['catalogs']).find((c) => c.get('id') == i);
        if (catalog) {
          items.push({
            key: catalog.get('id'),
            text: `[${t('filter.keys.has_from')} «${catalog.get('name')}»]`,
            sort: -1,
          });
        }
      });
    }
    // if USER catalog is set as link, add USER special filters
    let hasUserCatalog = !!config
      .get('catalogs')
      .filter((c) => c !== '*')
      .find((c) => String(c.get('id')) === '3' || String(c.get('id')) === '$users');
    hasUserCatalog =
      hasUserCatalog ||
      !!config.get('views').find((c) => String(c.get('catalogId')) === '3' || String(c.get('catalogId')) === '$users');
    if (hasUserCatalog) {
      userFilters.forEach((f) => {
        items.push({
          key: `$users:${f.key}`,
          text: f.value,
          sort: -1,
        });
      });
    }
    return items;
  }

  // parse to get all extra filters fields
  updateLinkedObjectFields = () => {
    // extended fields from config
    let fields = this.props.config.get('fields') || Immutable.Map();
    // value may contain extra extendedd fields
    const value = this.props.value || Immutable.Map();

    value.forEach((val) => {
      const catalogId = val.get('catalogId');
      const catalogAllFields = this.props.catalogs.getIn([catalogId, 'fields']);

      let beforeFields = this.state.extendedFiltersFields || Immutable.Map();
      beforeFields = beforeFields.get(catalogId) || List([]);

      // get current filter values
      const filters = val.get('filters');
      if (filters) {
        filters.mapKeys((fieldId) => {
          const field = catalogAllFields.find((f) => f.get('id') === fieldId);
          if (field) {
            const catalogFields = fields.get(catalogId) || Immutable.List();
            // if filter is not from config extended fileds
            if (!catalogFields.some((field) => field.get('id') === fieldId)) {
              fields = fields.setIn([catalogId], catalogFields.push(field));
            }
          }
        });

        // sort like were before (how they were added)
        let sortedFields = fields.get(catalogId);
        sortedFields = sortedFields.map(
          (item) =>
            (item = item.set(
              'index',
              beforeFields.findIndex((before) => before.get('id') == item.get('id')),
            )),
        );

        sortedFields = sortedFields.sortBy((item) => item.get('index'));
        fields = fields.set(catalogId, sortedFields);
      }
    });

    this.setState({ extendedFiltersFields: fields });
  };

  // treeSelect functions
  addExtendedFilter = (catalogId, item) => {
    let { extendedFiltersFields } = this.state;
    let filters = extendedFiltersFields.get(catalogId, List());

    // add new filter field
    filters = filters.push(item);
    extendedFiltersFields = extendedFiltersFields.set(catalogId, filters);

    this.setState({ extendedFiltersFields });
  };

  removeExtendedFilter = (catalogId, fieldId) => {
    const preInstallFields = this.props.config.getIn(['fields', catalogId]) || List([]);

    const isAddedField = !preInstallFields.some((f) => f.get('id') === fieldId);

    if (isAddedField) {
      let { extendedFiltersFields } = this.state;
      let filters = extendedFiltersFields.get(catalogId) || Immutable.List();
      filters = filters.filter((filter) => filter.get('id') !== fieldId);

      extendedFiltersFields =
        filters.size > 0 ? extendedFiltersFields.set(catalogId, filters) : extendedFiltersFields.delete(catalogId);

      this.setState({ extendedFiltersFields });
    }
  };

  render() {
    const { ownerId, fieldId, readOnly, catalogs, withExtendedFilters, t } = this.props;
    const { extendedFiltersFields } = this.state;

    // mutate config for correct work of filter componment
    let config = this.props.config || Immutable.Map({});
    config = config.set('multiselect', true);
    config = config.set('enableSelect', true);
    config = config.set('enableCreate', false);

    // additional filter items
    let additionalItems = [];
    if (objectFilters) {
      objectFilters.forEach((f) => {
        additionalItems.push({
          key: f.key,
          text: f.value,
          sort: -1,
        });
      });
    }
    // extra items for USER catalog
    additionalItems = additionalItems.concat(this.getExtraItems());
    additionalItems = _.uniqBy(additionalItems, 'text');
    // parse value into selected items & extendede filters
    const selectedItems = this.getSelectedItems();
    let extendedFilters = this.getExtendedFilterItems();
    // transform: [{catalogId, filters },...] => {catalogId: filters, }
    extendedFilters = Immutable.Map(extendedFilters.map((v) => [v.get('catalogId'), v.get('filters')]));
    return (
      <div>
        <RecordDropdown
          /* посмотреть используются ли параметры */
          requestParams={{
            fieldId,
            catalogId: this.props.catalogId,
            filters: this.props.filters,
          }}
          remoteGroup="linkedObjectsWithFilters"
          value={selectedItems && selectedItems.map(this.inMapper)}
          additionalItems={additionalItems}
          config={config}
          readOnly={readOnly}
          apiOnly={false}
          sortFn={this.sortFn}
          onChange={this.onChange}
          loadAvailableItems={availableLinkedRecordActions.loadAvailableItems}
          clearAvailableItems={availableLinkedRecordActions.clearAvailableItems}
        />
        {withExtendedFilters && (
          <div className={styles.extendedFiltersWrapper}>
            {!_.isEmpty(extendedFiltersFields) &&
              extendedFiltersFields.entrySeq().map(([catalogId, fields]) => {
                // create catalog header
                let catalogHeader = null;
                if (fields.length > 1) {
                  const catalogName = catalogs.getIn([catalogId, 'name']);
                  catalogHeader = catalogName ? <div className={styles.filterItemGroup}>{catalogName}</div> : null;
                }

                // create filters for extendede fields
                if (fields) {
                  const filters = extendedFilters && extendedFilters.get(catalogId);
                  return (
                    <div className={styles.filterExtendedFields} key={catalogId}>
                      {catalogHeader}
                      <Filters
                        catalogId={catalogId}
                        ownerId={ownerId}
                        fields={fields}
                        filters={filters} // format Immutable {filterId: [{value}], }
                        readOnly={readOnly}
                        withExtendedFilters={false}
                        onSave={(filterId, fieldId, value) => {
                          this.onChangeExtended(catalogId, fieldId, value);
                        }}
                      />
                    </div>
                  );
                }
              })}
            <AdditionalFilters
              config={config}
              extendedFiltersFields={extendedFiltersFields}
              catalogs={this.props.catalogs}
              onSelect={this.addExtendedFilter}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(
  connect(ObjectField, {
    catalogs: ['catalogs'],
  }),
);
