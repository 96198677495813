import _ from 'lodash';

import pure from './pure';
import generating from './generating';
import validation from './validation';
import deleting from './deleting';
import changes from './changes';
import updatedFields from './updatedFields';
import parents from './parents';

export default _.assign({}, changes, pure, generating, validation, deleting, updatedFields, parents);
