import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';

import Category from '../../common/UI/Category';
import PRIVILEGE_CODES from '../../../configs/privilegeCodes';

class FieldControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  onChangeItem = (itemId) => {
    // unset if checked again
    if (this.state.value == itemId) {
      itemId = '';
    }

    // set
    this.setState({
      value: itemId,
    });
    this.props.onSave(itemId);
  };

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  render() {
    const isCatalog = !!(this.props.object && this.props.object.catalogId && !this.props.object.recordId);

    const items = [];

    if (isCatalog && this.props.subjectPrivilege !== PRIVILEGE_CODES.ADMIN) {
      items.push({
        id: 'hide',
        name: this.props.t('rights.hide'),
        color: '#dfe0e0',
      });
    }
    items.push({
      id: 'view',
      name: this.props.t('rights.view'),
      color: '#D3E3FF',
    });
    items.push({
      id: 'edit',
      name: this.props.t('rights.edit'),
      color: '#D0FBBF',
    });

    return (
      <div>
        {items.map((item) => {
          const { id } = item;
          const selected = this.state.value === item.id;
          return (
            <Category
              key={id}
              onClick={() => this.onChangeItem(id)}
              title={item.name}
              checked={selected}
              backgroundColor={item.color}
            />
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(FieldControl);
