import Immutable from 'immutable';
import _ from 'lodash';
import guid from 'guid';

import apiActions from '../actions/apiActions';

export default {
  defaultLimit: 25,
  currentUpdloaded: null,

  loadChats(limit = this.defaultLimit, isGetNew = false) {
    const chatsExists = this.getIn(['chats']);

    if (!chatsExists) {
      this.preparingToLoadingChats();
    }

    const chats = this.getIn(['chats']);

    const query = {
      limit,
    };

    const lastUploadedDate = chats.get('lastUploadedDate');
    if (lastUploadedDate && !isGetNew) {
      query.from = lastUploadedDate;
    }

    if (this.currentUpdloaded && this.currentUpdloaded.lastUploadedDate === lastUploadedDate) {
      return true;
    }
    this.currentUpdloaded = {
      lastUploadedDate,
    };

    this.currentUpdloaded = {
      lastUploadedDate,
    };

    apiActions.getChats({}, query);
    return !chats.get('allLoaded');
  },
  getNewChats() {
    apiActions.getChats({}, { limit: this.defaultLimit });
  },
  getChats(params, query) {
    let chats = this.getIn(['chats']);
    if (chats) {
      if (_.isUndefined(chats.get('loadingMessages'))) {
        chats = chats.set('visibleSkeleton', true);
      }
      chats = chats.set('loadingMessages', true);
      this.setIn(['chats'], chats);
    }

    this.changed('Сообщения для записи');
  },
  getChatsCompleted(body, params, data, query, response, actionParams) {
    let chats = this.getIn(['chats']);
    const { limit } = query;
    chats = this._updateChats(chats, limit, body);
    this.setIn(['chats'], chats);
    this.currentUpdloaded = null;
    this.changed();
  },
  // eslint-disable-next-line default-param-last
  _updateChats(chats, limit = this.maxLimit, body) {
    let lastUploadedDate = chats.get('lastUploadedDate', null);
    const newIds = {};
    let items;

    body.forEach((c) => {
      const key = `${c.catalogId}:${c.recordId}`;
      newIds[key] = true;
    });

    if (body.length) {
      lastUploadedDate = _.last(body).message.createdDate;
    }

    items = chats.get('items') || new Immutable.List();
    items = items.filter((c) => {
      const key = `${c.get('catalogId')}:${c.get('recordId')}`;
      return !newIds[key];
    });
    items = Immutable.fromJS(body).concat(items);
    items = items.sort(
      (a, b) => new Date(b.getIn(['message', 'createdDate'])) - new Date(a.getIn(['message', 'createdDate'])),
    );

    let allLoadedMessages = false;
    if (body.length < limit) {
      allLoadedMessages = true;
    }

    chats = chats.set('items', items);
    chats = chats.set('loadingMessages', false);
    chats = chats.set('visibleSkeleton', false);
    chats = chats.set('allLoadedMessages', allLoadedMessages);

    if (lastUploadedDate) {
      chats = chats.set('lastUploadedDate', lastUploadedDate);
    }

    return chats;
  },
  getChatsFailed(body, params, data, query, res, actionParams) {},

  preparingToLoadingChats() {
    this.setIn(['chats', 'allLoadedMessages'], false);
    this.changed();
  },
};
