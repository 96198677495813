import moment from 'moment';

export default (date) => {
  if (!date) {
    return {};
  }

  const licenseDate = moment(date);
  const diff = moment(licenseDate).toNow(true);
  const diffInDays = Math.abs(moment().diff(licenseDate, 'days'));
  let redTitleDate = false;

  if (diffInDays < 14) {
    redTitleDate = true;
  }

  return {
    diff,
    licenseDate,
    redTitleDate,
  };
};
