import Immutable from 'immutable';
import readXlsxFile from 'read-excel-file';
import _ from 'lodash';
import guid from 'guid';
import moment from 'moment';
import { message } from 'antd';

import {
  RECORD_STATUS_FIELD_ID,
  VALUE_STATUSES,
  EMPTY_FIELD_ID,
  RECORD_STATUSES,
  IMPORT_STATUS,
  ALLOWED_TYPES,
  FIELDS_WITH_UPDATE,
  UPDATE_TIME,
} from '../configs/import';
import * as COLORS from '../configs/colors';
import SCENE_TYPE from '../configs/sceneTypes';
import FIELD_TYPES from '../configs/fieldTypes';

import FieldFactory from '../models/FieldFactory';
import RecordFactory from '../models/RecordFactory';
import recordActions from '../actions/recordActions';
import filtersUtil from '../utils/filters';

import i18n from '../configs/i18n';

export default {
  loadUsersCompleted(params, users) {
    this.setIn(['import', 'users'], users);
    this.changed();
  },

  /* генерация фиктивного поля статусы для отображения статуса конвертации */
  _generateStatusField() {
    return FieldFactory.create({
      id: RECORD_STATUS_FIELD_ID,
      type: FIELD_TYPES.DROPDOWN,
      name: i18n.t('import.statusField.name'),
      config: {
        multiselect: false,
        items: [
          /* добавить статусы по сигнализированию ошибки */
          {
            color: COLORS.WHITE,
            id: '1',
            name: i18n.t('import.statusField.items.parsed') /* какое-то другое слово подобрать */,
          },
          {
            color: COLORS.ORANGE,
            id: '2',
            name: i18n.t('import.statusField.items.failedToParse'),
          },
          {
            color: COLORS.GREEN,
            id: '3',
            name: i18n.t('import.statusField.items.imported'),
          },
          {
            color: COLORS.RED,
            id: '4',
            name: i18n.t('import.statusField.items.failedToImport'),
          },
          {
            color: COLORS.GREY,
            id: '5',
            name: i18n.t('import.statusField.items.doNotImport'),
          },
          {
            color: COLORS.SKY,
            id: '6',
            name: i18n.t('import.statusField.items.inProcess'),
          },
        ],
      },
    });
  },

  _initImportData() {
    this.setIn(['import', 'records'], Immutable.Map({}));
    this.setIn(['import', 'footerStatus'], Immutable.Map({}));
    this.setIn(['import', 'valuesStatus'], Immutable.Map({}));
    this.setIn(['import', 'savingMessages'], Immutable.Map({}));
    this.setIn(['import', 'rowsCount'], 0);
    this.setIn(['import', 'columnsCount'], 0);

    this._createEmptyField();

    /* создаем поле статусов */
    const statusField = this._generateStatusField().set('static', true);

    this.setIn(['import', 'columns'], Immutable.List([statusField]));
    this.setIn(['import', 'filterFields'], Immutable.List([statusField]));
  },

  /* фиктивное поле обозначающее статус невыбрано */
  _createEmptyField() {
    // fieldsFromCatalog = fieldsFromCatalog.filter(field => field.get("type") !== FIELD_TYPES.GROUP);

    const EMPTY_FIELD = FieldFactory.create({
      id: EMPTY_FIELD_ID,
      type: FIELD_TYPES.TEXT,
      name: i18n.t('import.fields.notSelected'),
    });

    this.setIn(['import', EMPTY_FIELD_ID], EMPTY_FIELD);
  },

  /* проверяем действительно ли все данные из excel примитивы */
  _prepareData(rawData) {
    return _.map(rawData, (value) => {
      if (!_.isString(value)) {
        if (value instanceof Date) {
          value = moment(value);

          const timeOffset = moment().utcOffset();

          return value.add(-timeOffset, 'minutes').format('DD.MM.YYYY HH:mm:ss');
        }
        if (value instanceof Object) {
          return value.constructor.name;
        }
      }
      return value;
    });
  },

  /* парсинг excel и создание полей */
  _getFieldsFromExcel(rows) {
    const fields = [];
    if (!rows) {
      return Immutable.List();
    }

    const headerRow = rows[0];

    _.forEach(headerRow, (fieldName, index) => {
      if (!_.isString(fieldName)) {
        if (fieldName instanceof Date) {
          fieldName = fieldName.toString();
        } else if (fieldName instanceof Object) {
          return fieldName.constructor.name;
        }
      }

      const data = {
        id: String(index),
        type: FIELD_TYPES.TEXT,
        name: fieldName,
      };

      const field = FieldFactory.create(data);
      fields.push(field);
    });

    return Immutable.List(fields);
  },

  /* переупаковка данных */
  _prepareValues(rawData, columns) {
    let recordValue = {};

    if (!rawData || !columns) {
      return Immutable.Map();
    }

    const status = RECORD_STATUSES.EXCLUDED_FROM_IMPORT;

    /* skip static fields cause they arent in data */
    const dataColumns = columns.filter((column) => !column.get('static'));
    dataColumns &&
      dataColumns.forEach((field, index) => {
        const fieldId = field.get('id');
        const dataToConvert = rawData[index];
        recordValue[fieldId] = dataToConvert;
      });

    /* static fields */
    recordValue[RECORD_STATUS_FIELD_ID] = Immutable.fromJS([status]);

    recordValue = Immutable.Map(recordValue);

    return recordValue;
  },

  /* парсинг excel и создание значений */
  _getRecordsFromExcelValues(rows, columns, catalogId) {
    const records = {};

    if (!rows || !columns) {
      return Immutable.Map();
    }

    const otherRows = rows.slice(1);

    otherRows &&
      otherRows.forEach((row, rowIndex) => {
        /* EXCLUDED_FROM_IMPORT as defualt value */
        const rawData = this._prepareData(row);

        const data = {
          id: String(rowIndex),
          catalogId,
          isNew: false,
          title: rowIndex,
          values: this._prepareValues(rawData, columns),
        };
        const record = RecordFactory.create(data);

        // record = record.setIn(["originValues"], rawData)
        records[rowIndex] = record;
      });

    return Immutable.Map(records);
  },

  _isColumnUnset(column) {
    const fieldId = column.get('selectedFieldId');

    return fieldId === EMPTY_FIELD_ID;
  },

  /* высчитывание текущего статуса записи, на основании статусов ячеек */
  _calcRecordStatus(recordId) {
    const valuesStatus = this.getIn(['import', 'valuesStatus']);

    const statusByFields = valuesStatus.map((c) => c.get(recordId));

    const failed = statusByFields.some((s) => s == VALUE_STATUSES.INVALID);
    const succeeded = statusByFields.some((s) => s == VALUE_STATUSES.VALID);
    const loading = statusByFields.some((s) => s == VALUE_STATUSES.LOADING);

    if (loading) {
      return RECORD_STATUSES.PARSE_LOADING;
    }
    if (failed) {
      return RECORD_STATUSES.PARSE_FAILED;
    }
    if (succeeded) {
      return RECORD_STATUSES.PARSE_COMPLETED;
    }
    return RECORD_STATUSES.EXCLUDED_FROM_IMPORT;
  },

  /* автоподбор подходящих полей из каталога посредством сопоставления названий столбцов */
  async _mapColumsToFieldsByDefault(catalogId, locale) {
    let fieldsFromCatalog = this.getIn(['catalogs', catalogId, 'fields']) || Immutable.List([]);

    fieldsFromCatalog = fieldsFromCatalog.filter(
      (field) => field.get('type') !== FIELD_TYPES.GROUP && ALLOWED_TYPES.includes(field.get('type')),
    );

    const columns = this.getIn(['import', 'columns']);
    const EMPTY_FIELD = this.getIn(['import', EMPTY_FIELD_ID]);

    const selectedFieldsFromCatalog = [];

    // заполнение массива выбранных полей дефолтными значениями
    await Promise.all(
      columns.map(async (column) => {
        const columnId = column.get('id');
        let alreadySelected = false;
        let catalogFieldId;

        /* определение статичных полей (статус или айди) */
        const staticField = column.get('static');
        if (staticField) {
          return;
        }

        // поиск полей из массива филдов каталога
        const fieldFromCatalog = fieldsFromCatalog.find((field) => field.get('name') == column.get('name'));

        // проверка на уже выбранное поле из каталога
        if (fieldFromCatalog) {
          catalogFieldId = fieldFromCatalog.get('id');
          alreadySelected = selectedFieldsFromCatalog.includes(catalogFieldId);
        }

        if (fieldFromCatalog && !alreadySelected) {
          /* накопление айди полей из каталога, которые уже были выбраны */
          selectedFieldsFromCatalog.push(catalogFieldId);

          // если будет филд каталога, имя которого будет совпадать с именем столбца, то выбираем данный филд, условии что он не выбран
          return await this._changeColumnField(columnId, fieldFromCatalog, catalogId, locale);
        }
        // в противном случае заполнение массива выбранных полей будет выполненно пустыми полями (заглушки)
        // this.changeColumnField(columnId, EMPTY_FIELD);

        this.setColumn(columnId, EMPTY_FIELD);
      }),
    );
  },

  /* высчитываем один раз значение футера для всех полей */
  _setAllFooterStatus() {
    const columns = this.getIn(['import', 'columns']);
    const filteredRecordsSize = this.getIn(['import', 'filteredRecords']).size;

    columns.forEach((column) => {
      const columnId = column && column.get('id');

      if (columnId === RECORD_STATUS_FIELD_ID) {
        this.setFooterStatus(columnId, false);
      } else {
        const selected = !this._isColumnUnset(column);

        this.setFooterStatus(columnId, selected);
      }
    });

    this.setFooterStatus('id', true, filteredRecordsSize);
  },

  /* фильтрация записей и обновление футера */
  filterRecords() {
    this._filterRecords();
    this._setAllFooterStatus();

    this.changed();
  },

  setImportFilters(filterId, fieldId, value) {
    const filtersPath = ['import', 'queryParams', 'filters', fieldId];

    const filter = Immutable.fromJS({
      value,
    });

    const filtersExistAndTheyAreArray = this.getIn(filtersPath) && Immutable.List.isList(this.getIn(filtersPath));

    if (filtersExistAndTheyAreArray) {
      this.setIn([...filtersPath, filterId], filter);
    } else {
      this.setIn(filtersPath, Immutable.fromJS([filter]));
    }

    this.filterRecords();
  },

  setImportSearchText(searchText) {
    const searchTextPath = ['import', 'queryParams', 'searchText'];

    this.setIn(searchTextPath, searchText);
    this.filterRecords();
  },

  /* фильтрация значений по тексту и заданным фильтрам */
  _filterRecords() {
    let filters = this.getIn(['import', 'queryParams', 'filters']);
    const searchText = this.getIn(['import', 'queryParams', 'searchText']);
    let filtersNotEmpty;

    let records = this.getIn(['import', 'records']);

    /* фильтры могут быть сложными, поэтому нужно проверять вложенные свойства */
    filters = filters && filters.toJS ? filters.toJS() : filters;

    filters = _.reduce(
      filters,
      (res, filtersByFiledId, fieldId) => {
        filtersByFiledId = _.filter(filtersByFiledId, ({ value }) => !_.isEmpty(value));

        if (!_.isEmpty(filtersByFiledId)) {
          res[fieldId] = filtersByFiledId;
        }
        return res;
      },
      {},
    );

    filtersNotEmpty = !_.isEmpty(filters);

    if (filtersNotEmpty) {
      records = records.filter((record) => {
        let currentValues = record.get('values');
        currentValues = currentValues && currentValues.toJS ? currentValues.toJS() : currentValues;

        for (const fieldId in currentValues) {
          const filtersByField = filters[fieldId];

          if (!filtersByField) {
            continue;
          }

          const field = this.getIn(['import', 'columns']).find((f) => f.get('id') == fieldId);
          const fieldType = field.get('type');

          const recordValue = currentValues[fieldId];
          const recordFieldValue = filtersUtil.convertFilterValueForRequestByFieldType(recordValue, fieldType, field);

          return _.reduce(
            filtersByField,
            (result, { value }, filterIndex) => {
              const filterMatch = _.reduce(
                value,
                (res, v) => {
                  value = filtersUtil.convertFilterValueForRequestByFieldType(v, fieldType, field);
                  const filterMatch =
                    _.isEqual(v, recordFieldValue) || _.isArray(recordFieldValue)
                      ? _.includes(recordFieldValue, v)
                      : false;
                  return res || filterMatch;
                },
                false,
              );

              return result && filterMatch;
            },
            true,
          );
        }
      });
    }

    if (searchText) {
      records = records.filter((record) => {
        const values = record.get('values');
        return values.some((value) => _.includes(value, searchText));
      });
    }

    if (searchText || filtersNotEmpty) {
      records = records
        .toList()
        .sortBy((r) => r.get('id'))
        .toMap();
    }

    this.setIn(['import', 'filteredRecords'], records);
    this.setIn(['import', 'rowsCount'], records.size);
  },

  /*
    при смене типа поля (changeColumnField) мы заведомо знаем верные у нас данные или нет и нам не нужно проверять их валидность,
    что является причиной не использовать _changeRecordValue
  */
  _setRecordValue(columnId, recordKey, value, multipleChange = false) {
    const records = this.getIn(['import', 'records']);
    const columns = this.getIn(['import', 'columns']);
    const originValue = this.getIn(['import', 'records', recordKey, 'originValues', columnId]);

    const column = columns.find((col) => col.get('id') === columnId);
    const columnIsStatic = column && column.get('static');

    /* высчитываение флага расзрешающего одновременное редактирование нескольких записей */
    const multipleChangeAllowed =
      !columnIsStatic && multipleChange && !(originValue === '' || originValue === null || originValue === undefined);

    recordKey && this.setIn(['import', 'records', recordKey, 'values', columnId], value);

    /*
      одновременное изменение нескольких записей,
      определение записей происходит по одинаковым значениям originValues, тк они не изменяются.
    */
    multipleChangeAllowed &&
      records.forEach((record, recordKey) => {
        const recordOriginValue = record.getIn(['originValues', columnId]);

        if (recordOriginValue === originValue) {
          this._changeRecordValue(recordKey, columnId, value);
        }
      });
  },

  /* запись статуса ячейки в выбранные поля */
  _setValueStatus(column, recordKey, status) {
    const columnId = column.get('id');
    const recordId = this.getIn(['import', 'records', recordKey, 'id']);
    const imported = this._getRecordStatus(recordKey) === IMPORT_STATUS.COMPLETED;

    if (imported) {
      return;
    }

    if (this._isColumnUnset(column)) {
      status = undefined;
    }

    this.setIn(['import', 'valuesStatus', columnId, recordId], status);
  },

  removeRecord(record) {
    const records = this.getIn(['import', 'records']);
    const recordId = record.get('id');
    const recordKey = records.findKey((r) => r.get('id') === recordId);

    const currentRecordStatus = this._getRecordStatus(recordKey);

    if (currentRecordStatus === RECORD_STATUSES.IMPORT_COMPLETED) {
      message.error(i18n.t('import.errors.record.delete'));
      return;
    }

    this.setIn(['import', 'records', recordKey, 'canRestore'], true);
    this._setRecordStatus(recordKey, RECORD_STATUSES.EXCLUDED_FROM_IMPORT);

    this.filterRecords();
  },

  restoreRecord(record) {
    const records = this.getIn(['import', 'records']);
    const recordId = record.get('id');
    const recordKey = records.findKey((r) => r.get('id') === recordId);

    this.deleteIn(['import', 'records', recordKey, 'canRestore']);
    this._setRecordStatus(recordKey);

    this.filterRecords();
  },

  async importData(catalogId, sceneId = null) {
    const importStatus = this.getIn(['import', 'importStatus']);

    if (importStatus === IMPORT_STATUS.SAVING.STATUS) {
      return;
    }

    const columns = this.getIn(['import', 'columns']);
    const selectedColumnsExist = columns.some((column) => !this._isColumnUnset(column) && !column.get('static'));

    if (!selectedColumnsExist) {
      message.error(i18n.t('import.errors.fields.notSelected'));
      return;
    }

    // ставим флаг о начале сохранения записей
    this.setIn(['import', 'importStatus'], IMPORT_STATUS.SAVING.STATUS);
    this.changed();

    let fieldsFromCatalog = this.getIn(['catalogs', catalogId, 'fields']) || Immutable.List([]);

    /* пока что мы не включаем в выбор возможный выбор сложные поля (те что работают с запросами связанные объекты, юзеры, адреса) */
    fieldsFromCatalog = fieldsFromCatalog.filter((field) => ALLOWED_TYPES.includes(field.get('type')));

    const acceptedFields = {};

    columns.forEach((parsedField) => {
      const initialFieldId = parsedField.get('id');
      const staticField = parsedField.get('static');

      /* статичные поля, по типу поля индикации статуса записи незачем в импортируемых данных */
      if (staticField) {
        return;
      }

      /* проверяем существует ли выбранное пользователем поле в каталоге, тк сейчас импорт доступен без модификации структуры каталога */
      const fieldFormCatalog =
        fieldsFromCatalog &&
        fieldsFromCatalog.find((catalogField) => catalogField.get('id') === parsedField.get('selectedFieldId'));
      const selectedFieldId = fieldFormCatalog && fieldFormCatalog.get('id');

      /* соберем все валидные-выбранные пользователем поля в один массив */
      if (selectedFieldId) {
        acceptedFields[initialFieldId] = selectedFieldId;
      }
    });

    await this.importRecords(catalogId, sceneId, acceptedFields);
  },

  _onSaveResultMessage(catalogId, recordKey, text, error = false) {
    let color;
    let messages = this.getIn(['import', 'records', recordKey, 'indicator', 'messages']) || Immutable.List([]);
    messages = messages.push(text);

    if (error) {
      color = 'rgb(200, 45, 45)'; // from variables.less @COLOR_ERROR
    } else {
      color = 'rgb(92, 161, 153)'; // from variables.less @TEXT_ACCENT
    }

    this.setIn(['import', 'records', recordKey, 'indicator', 'messages'], messages);
    this.setIn(['import', 'records', recordKey, 'indicator', 'color'], color);
  },

  _onCleareResultMessage(recordKey) {
    this.deleteIn(['import', 'records', recordKey, 'indicator']);
  },

  _onSave(catalogId, recordKey, text) {
    this._onSaveResultMessage(catalogId, recordKey, text);
  },

  _onError(catalogId, recordKey, text) {
    this._onSaveResultMessage(catalogId, recordKey, text, true);
  },

  _resetChangesOnCatalog(catalogId) {
    const fields = this.getIn(['catalogs', catalogId, 'fields']);

    this.CATALOG_FIELDS_WITH_EVENTS = fields;
    const newFields = fields.map((f) => f.set('eventable', false));

    this.setIn(['catalogs', catalogId, 'fields'], newFields);
  },

  /*
    функции importRecords и importRecord осуществляют выполнение промиссов по цепочке,
    те когда выполнится импорт одной записи начнется импорт следующей,
    такая логика была необходима для возможности останавливать импорт, уверен можно лучше

    TODO:
    СДЕЛАТЬ МЕТОД ИНИЦИАЛИЗАЦИИ ПРОМИСОВ
    ЗАТЕМ ВЫЗЫВАТЬ МЕТОД СТАРТА
  */
  importRecords(catalogId, sceneId, acceptedFields) {
    const key = ['import', 'promises'];
    let records = this.getIn(['import', 'records']) || Immutable.Map({});

    records = records.filter((record) => {
      const values = record.get('values');
      const recordStatus = values.getIn([RECORD_STATUS_FIELD_ID, '0']);

      const recordIsValid = recordStatus === RECORD_STATUSES.PARSE_COMPLETED;
      const recordIsEmpty = values.isEmpty ? values.isEmpty() : _.isEmpty(values);

      return recordIsValid && !recordIsEmpty;
    });

    let columns = this.getIn(['import', 'columns']);
    columns = columns.map((c) => (c.get('static') ? c : c.set('disabled', true)));
    this.setIn(['import', 'columns'], columns);
    this.changed();

    const action = (recordKey) => {
      const recordId = guid.raw();
      const record = records.get(recordKey);
      const recordValues = record.get('values');

      // перепаковка объекта (колонки -> поля)
      const values = recordValues.reduce((newValues, value, key) => {
        const newKey = acceptedFields[key];

        if (newKey) {
          return newValues.set(newKey, value);
        }
        return newValues;
      }, Immutable.Map());

      recordActions.generateNewRecord(catalogId, recordId, values);

      return new Promise((resolve, reject) => {
        // setTimeout(() => {
        recordActions.validateAndSaveRecord(
          {
            catalogId,
            recordId,
          },
          {
            sceneId,
            silent: true,
            onCompleted: (_catalogId, _recordId, text) => {
              this._onSave(catalogId, recordKey, text);
            },
            onFailed: (_catalogId, _recordId, text) => {
              this._onError(catalogId, recordKey, text);
            },
          },
          (result, ...args) => {
            this.deleteIn(['records', catalogId, result.id]);
            resolve(result, ...args);
          },
          (result, ...args) => {
            this.deleteIn(['records', catalogId, recordId]);
            reject(result, ...args);
          },
        );
        // }, 0);
      });
    };

    const onComplited = (recordKey, recordKeyIndex, result) => {
      this._setRecordStatus(recordKey, RECORD_STATUSES.IMPORT_COMPLETED);
    };

    const onFailed = (recordKey, recordKeyIndex, result) => {
      this._setRecordStatus(recordKey, RECORD_STATUSES.IMPORT_FAILED);
    };

    const onProgress = (waitingTime) => {
      this._setWaitingTime(waitingTime);
      this.filterRecords();
    };

    const onFinish = () => {
      this.setIn(['import', 'importStatus'], IMPORT_STATUS.COMPLETED.STATUS);

      let columns = this.getIn(['import', 'columns']);
      columns = columns.map((c) => (c.get('static') ? c : c.delete('disabled')));
      this.setIn(['import', 'columns'], columns);

      this.changed();
    };

    let [...recordsKeys] = records.keys();
    recordsKeys = recordsKeys.sort((a, b) => a - b);

    const queue = Immutable.fromJS({
      map: recordsKeys,
      action,
      onComplited,
      onFailed,
      onProgress,
      onFinish,
      updateTime: 3000,
    });

    this.initQueue(key, queue);

    this.startQueue(key);
  },

  stopImport() {
    let columns = this.getIn(['import', 'columns']);
    columns = columns.map((c) => (c.get('static') ? c : c.delete('disabled')));
    this.setIn(['import', 'columns'], columns);

    this.setIn(['import', 'importStatus'], IMPORT_STATUS.STOPED.STATUS);
    this.stopQueue(['import', 'promises']);
  },

  _getRecordStatus(recordKey) {
    if (!recordKey) return;

    return this.getIn(['import', 'records', recordKey, 'values', RECORD_STATUS_FIELD_ID, '0']);
  },

  _setRecordStatus(recordKey, status) {
    const record = this.getIn(['import', 'records', recordKey]);
    const recordId = record && record.get('id');

    if (!record) return;

    const currentRecordStatus = record.getIn(['values', RECORD_STATUS_FIELD_ID, '0']);

    /* если запись импортирована, то смысла изменять ее статус нет */
    if (currentRecordStatus == RECORD_STATUSES.IMPORT_COMPLETED) {
      return;
    }

    /* если напрямую не передан то сами высчитываем статус записи на основании значений ячеек в данной записи */
    if (!status && recordId) {
      status = this._calcRecordStatus(recordId);
    }
    status = Immutable.List([status]);

    recordKey && this.setIn(['import', 'records', recordKey, 'values', RECORD_STATUS_FIELD_ID], status);
  },

  _setWaitingTime(waitingTime) {
    this.setIn(['import', 'waitingTime'], waitingTime);
  },

  setFooterStatus(columnId, selected, convertStatus) {
    if (!convertStatus) {
      const records = this.getIn(['import', 'filteredRecords']);
      const recordIds = {};
      records.forEach((record) => {
        const recordId = record.get('id');
        recordIds[recordId] = true;
      });

      // отфильтровать valuesStatus по указанным фильтрам
      const convertedValues = this.getIn(['import', 'valuesStatus', columnId]);
      const convertedValuesSize =
        convertedValues &&
        convertedValues.count((status, recordId) => recordIds[recordId] && status === VALUE_STATUSES.VALID);

      const rowsCount = records.size;

      if (convertedValuesSize && rowsCount) {
        convertStatus = Math.ceil((+convertedValuesSize / +rowsCount) * 100);
        convertStatus = _.isNumber(parseFloat(convertStatus)) ? `${convertStatus}%` : '-';
      } else if (columnId === 'id') {
        convertStatus = convertStatus || rowsCount;
      } else {
        convertStatus = selected ? '0%' : '-';
      }
    }

    this.setIn(['import', 'footerStatus', columnId], convertStatus);
  },

  setColumn(columnId, field) {
    const columns = this.getIn(['import', 'columns']);
    const columnIndex = columns.findIndex((col) => col.get('id') === columnId);

    const fieldId = field.get('id');
    field = field.delete('id').delete('name').set('selectedFieldId', fieldId);

    this.mergeIn(['import', 'columns', columnIndex], field);

    return this.getIn(['import', 'columns', columnIndex]);
  },

  _setLoading(condition) {
    const loading = this.getIn(['import', 'importStatus']) === IMPORT_STATUS.LOADING.STATUS;

    let allowedToChangeStatus = true;

    if (condition === loading) {
      allowedToChangeStatus = false;
    } else if (loading && !condition) {
      // check allow to remove loading
      const columns = this.getIn(['import', 'columns']);

      allowedToChangeStatus = !columns.some((c) => c.get('disabled'));
    }

    if (allowedToChangeStatus) {
      condition
        ? this.setIn(['import', 'importStatus'], IMPORT_STATUS.LOADING.STATUS)
        : this.deleteIn(['import', 'importStatus']);
    }
  },

  validateValueByField(value, field) {
    /*
      по какой-то причине не подгружались данные в компоненте recordFields, чему была причина импортирования fieldApi
      и я свалил это на ассинхронность импорта, поэтому использую require
      https://stackoverflow.com/questions/46601580/es6-modules-import-is-undefined
    */
    return require('../models/FieldApi').default.validateValue(field, value);
  },

  /* выбор поля из каталога */
  async changeColumnField(columnId, field, catalogId, locale) {
    // let t = Date.now();

    const importStatus = this.getIn(['import', 'importStatus']);
    if (importStatus === IMPORT_STATUS.LOADING.STATUS || importStatus === IMPORT_STATUS.SAVING.STATUS) {
      return;
    }

    this._setLoading(true);

    const columns = this.getIn(['import', 'columns']);
    const columnIndex = columns.findIndex((column) => column.get('id') == columnId);

    this.setIn(['import', 'columns', columnIndex, 'disabled'], true);
    this.changed();

    // const records = this.getIn(["import", "filteredRecords"])

    // records.forEach((record, recordKey) => {
    //   const notConvertrecord = record.get("canRestore") || this.getIn([
    //     "import",
    //     "records",
    //     recordKey,
    //     "values",
    //     RECORD_STATUS_FIELD_ID,
    //     "0"
    //   ]) === IMPORT_STATUS.COMPLETED

    //   if (!notConvertrecord) {
    //     this.setIn(["import", "filteredRecords", recordKey, "disabled"], true)
    //   }
    // })

    // console.log("step0", t - Date.now()); t = Date.now()

    await this._changeColumnField(columnId, field, catalogId, locale);

    const selectedFieldId = this.getIn(['import', 'columns', columnIndex, 'selectedFieldId']);
    const selected = selectedFieldId != EMPTY_FIELD_ID;

    // console.log("step1", t - Date.now()); t = Date.now()
    this._filterRecords();
    // console.log("step10", t - Date.now()); t = Date.now()
    this.setFooterStatus(columnId, selected);
    this.setFooterStatus('id', true);
    // console.log("step11", t - Date.now()); t = Date.now()
    this.deleteIn(['import', 'columns', columnIndex, 'disabled']);
    this._setLoading(false);
    this.changed();
  },

  /* парсинг исходных значений в тип выбранного поля */
  async _changeColumnField(columnId, field, catalogId, locale) {
    // let t = Date.now();

    const FieldApi = require('../models/FieldApi').default;

    let records = this.getIn(['import', 'records']);

    const column = this.setColumn(columnId, field);
    const fieldType = column.get('type');

    const shouldUpdateByTime = _.includes(FIELDS_WITH_UPDATE, fieldType);

    let timerId;

    /* set loading status for all values */
    if (shouldUpdateByTime) {
      const selected = !this._isColumnUnset(column);

      records &&
        records.forEach((value, recordKey) => {
          this._setValueStatus(column, recordKey, VALUE_STATUSES.LOADING);
          this._setRecordStatus(recordKey);
        });

      this._filterRecords();
      this.setFooterStatus(columnId, selected);
      this.setFooterStatus('id', true);
      this.changed();

      // update records in table by interval
      timerId = setInterval(() => {
        this._filterRecords();
        this.setFooterStatus(columnId, selected);
        this.setFooterStatus('id', true);
        this.changed();
      }, UPDATE_TIME); // 2.5s

      this.setIn(['import', 'timerId'], timerId);
    }

    if (records.isEmpty()) {
      return;
    }

    records = records.filter(
      (record, recordKey) =>
        !record.get('canRestore') && this._getRecordStatus(recordKey) !== RECORD_STATUSES.IMPORT_COMPLETED,
    );
    const settedValues = {};

    records = records && records.toJS();

    for (const recordKey in records) {
      const record = records[recordKey];
      const originValue = _.get(record, ['originValues', columnId]);
      let res;

      this._onCleareResultMessage(recordKey);

      /*
        по какой-то причине не подгружались данные в компоненте recordFields, чему была причина импортирования fieldApi
        и я свалил это на ассинхронность импорта, поэтому использую require
        https://stackoverflow.com/questions/46601580/es6-modules-import-is-undefined
      */

      if (settedValues[originValue]) {
        /* достаем из кэша распаршенные данные */
        res = settedValues[originValue];
      } else {
        /* кэшируем распаршенные данные на случай если данные в столбце повторяются */
        // Так же устанавливаем формат считывания дат на русскую локаль в любом случае
        const formatLocaleForDate = field && field.get('type') == FIELD_TYPES.DATE ? 'ru' : locale;

        res = shouldUpdateByTime
          ? await FieldApi.parseValue(field, originValue, catalogId, formatLocaleForDate)
          : FieldApi.parseValue(field, originValue, catalogId, formatLocaleForDate);

        settedValues[originValue] = res;
      }

      const { value, status } = res;

      // Тут есть исключение с полем типа контакт: с excel файла при импорте к нам прилетает что то типа такого: "123423423, 3452343423, 12312312312"
      // Фактически это три разных поля изначально, просто апишка их парсит в таком виде, чтобы было читаемо в документе excel
      // Теперь нам нужно распарсить обратно относительно индекса, но проблема в том, что при импорте мы достоверно не знаем
      // Заполнил это поле юзер вручную или нет
      // to-do: поменять разделитель при парсе в апишке на более уникальный
      /* const isContactField = field && field.get("type") == FIELD_TYPES.CONTACT && !_.isEmpty(value)

      if (isContactField) {

        const getSplittedValues = (contactValues) => {
          // Так как тут все собрано в одну строку, 
          // то мы просто можем вытащить первое значение, так как они по идее все одинаковые
          contactValues = _.get(contactValues, ["0", "contact"], "");
          return _.chain(contactValues)
            .trim()
            .split(",")
            .value();
        };
      
        let originContactValues = value.toJS();
        let splittedValues = getSplittedValues(originContactValues);

        value = Immutable.List(
          originContactValues.map((currentValue, i) => {
            if (splittedValues[i]) {
              currentValue.contact = splittedValues[i];
            }
            return Immutable.Map(currentValue);
          })
        );
      }
      */

      // const filteredRecords = this.getIn(["import", "filteredRecords"])
      // const filteredRecordKey = filteredRecords && filteredRecords.findKey(r => r.get("id") === record.get("id"))
      // this.deleteIn(["import", "filteredRecords", filteredRecordKey, "disabled"])

      this._setValueStatus(column, recordKey, status);
      this._setRecordValue(columnId, recordKey, value, false);
      this._setRecordStatus(recordKey);
    }

    if (shouldUpdateByTime) {
      clearInterval(timerId);
      this.deleteIn(['import', 'timerId']);
    }
  },

  /* смена значений ячейки */
  changeRecordValue(recordKey, columnId, value) {
    this._changeRecordValue(recordKey, columnId, value, false);
    this._filterRecords(); // filterRecords вызывает changed

    const columns = this.getIn(['import', 'columns']);
    const column = columns && columns.find((c) => c.get('id') === columnId);
    const selected = !this._isColumnUnset(column);

    this.setFooterStatus(columnId, selected);
    this.setFooterStatus('id', true);

    this.changed();
  },

  /* изменения записи должно сопровождаться изменением статусов полей  */
  _changeRecordValue(recordKey, columnId, value, multipleChange = false) {
    this._setRecordValue(columnId, recordKey, value, multipleChange);

    const columns = this.getIn(['import', 'columns']);
    const column = columns.find((field) => field.get('id') == columnId);

    /* валидируем введенные пользователем данные (по сути это незачем) */
    const valueStatus = this.validateValueByField(value, column) ? VALUE_STATUSES.VALID : VALUE_STATUSES.INVALID;

    this._setValueStatus(column, recordKey, valueStatus);
    this._setRecordStatus(recordKey);
  },

  /* получение данных из excel */
  parseExcel(file, catalogId, locale) {
    this.setIn(['import', 'loading'], true);
    this.changed();

    this._initImportData();
    this._resetChangesOnCatalog(catalogId);
    // let t = Date.now()

    /* чтение данных */
    readXlsxFile(file)
      .then((rows) => {
        this.setIn(['import', 'rowsCount'], _.size(rows) - 1);

        // console.log("step1", t - Date.now()); t = Date.now()

        return rows;
      })
      .then((rows) => {
        let columns = this.getIn(['import', 'columns']);
        const columnsFromExcel = this._getFieldsFromExcel(rows);

        columns = columns.concat(columnsFromExcel);

        this.setIn(['import', 'columns'], columns);
        this.setIn(['import', 'columnsCount'], columns.size - 1);
        // console.log("step2", t - Date.now()); t = Date.now()

        return { rows, columns };
      })
      .then(({ rows, columns }) => {
        const records = this._getRecordsFromExcelValues(rows, columns, catalogId);

        // console.log(records && records.toJS())
        this.setIn(['import', 'records'], records);
        this.setIn(['import', 'filteredRecords'], records);
        this.setFooterStatus('id', true, records.size - 1);
        // console.log("step3", t - Date.now()); t = Date.now()
      })
      .then(async () => {
        /* автоматический подбор полей для текущих колонок */
        await this._mapColumsToFieldsByDefault(catalogId, locale);
        // console.log("step4", t - Date.now()); t = Date.now()
        this.setIn(['import', 'loading'], false);
        this.filterRecords();
        // console.log("step5", t - Date.now()); t = Date.now()
      });
  },

  clearImportData(catalogId) {
    const timerId = this.getIn(['import', 'timerId']);
    timerId && clearInterval(timerId);

    this.setIn(['catalogs', catalogId, 'fields'], this.CATALOG_FIELDS_WITH_EVENTS);

    delete this.CATALOG_FIELDS_WITH_EVENTS;

    const allScenes = this.get('scenes').filter(
      (scene) => scene.getIn(['params', 'catalogId']) == catalogId && scene.get('type') == SCENE_TYPE.CATALOG,
    );
    allScenes &&
      allScenes.forEach((scene, sceneId) => {
        this.setShouldReload(sceneId, true);
      });

    this.deleteIn(['import']);

    this.changed();
  },
};
