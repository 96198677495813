import _ from 'lodash';
import Immutable from 'immutable';
import ChangesResultList from '../components/Record/ChangesResultList';
import i18n from '../configs/i18n';

function createChange({ catalogId }, { values }, recordId) {
  const fieldIds = Object.keys(values);
  const parents = this.getIn(['records', catalogId, recordId, 'parents']);

  fieldIds.forEach((fieldId) => this.startChanges(catalogId, recordId, fieldId));

  parents.forEach((parent) =>
    this.startChanges(parent.get('catalogId'), parent.get('recordId'), parent.get('fieldId')),
  );

  this.changed();
}

function createChangeCompleted(body, { catalogId }, { values }, query, res, recordId) {
  const fieldIds = Object.keys(values);
  const parents = this.getIn(['records', catalogId, recordId, 'parents']);

  fieldIds.forEach((fieldId) => this.endChanges(catalogId, recordId, fieldId));

  parents.forEach((parent) => this.endChanges(parent.get('catalogId'), parent.get('recordId'), parent.get('fieldId')));

  const params = { catalogId, recordId };

  this.saveChangesResults(params, body);
  this.changed();
}

function createChangeFailed(err, { catalogId }, { values }, query, recordId) {
  const fieldIds = Object.keys(values);
  const parents = this.getIn(['records', catalogId, recordId, 'parents']);

  fieldIds.forEach((fieldId) => this.endChanges(catalogId, recordId, fieldId));

  parents.forEach((parent) => this.endChanges(parent.get('catalogId'), parent.get('recordId'), parent.get('fieldId')));

  this.changed();
}

function saveChangesResults(params, hooksResults) {
  const records = this.get('records');
  const catalogs = this.get('catalogs');
  _.forEach(hooksResults, (hooksResult) => {
    const record = records.getIn([params.catalogId, params.recordId]);

    const recordName = record.get('isNew') ? i18n.t('record.newRecordCreating') : record.get('title');

    const result = {
      recordName,
      icon: catalogs.getIn([params.catalogId, 'icon']),
      catalogId: params.catalogId,
      recordId: params.recordId,
    };

    if (hooksResult.message) {
      result.message = hooksResult.message;
      this.pushIn(['changesResults'], result);
    } else if (hooksResult.error) {
      result.message = i18n.t('modals.record.changes.errorText');
      this.pushIn(['changesResults'], result);
    }
  });
}

function clearChangesResults() {
  this.set('changesResults', Immutable.List());
  this.changed();
}

function notifyChangesResults() {
  const changesResults = this.get('changesResults');
  if (changesResults && changesResults.size === 1) {
    const { alert } = require('../components/common/Modal');
    const result = changesResults.get(0);
    let { message } = result;
    let messageTitle;
    if (_.isObject(message)) {
      messageTitle = message.title;
      message = message.text;
    }
    alert({
      text: message,
      headerText: messageTitle || i18n.t('modals.record.changes.title'),
      okText: i18n.t('modals.record.changes.okText'),
      onOk: clearChangesResults.bind(this),
      onCancel: clearChangesResults.bind(this),
    });
  } else if (changesResults && changesResults.size > 1) {
    const { alertWrapper } = require('../components/common/Modal');
    alertWrapper(ChangesResultList, {
      onOk: clearChangesResults.bind(this),
      onCancel: clearChangesResults.bind(this),
      headerText: i18n.t('modals.record.changes.title'),
      okText: i18n.t('modals.record.changes.okText'),
      changesResults,
    });
  }
}

function startChanges(catalogId, recordId, fieldId) {
  if (!this.getIn(['records', catalogId, recordId, 'updateProcesses', 'fields'])) {
    this.setIn(['records', catalogId, recordId, 'updateProcesses', 'fields'], Immutable.Map({}));
  }

  this.mergeIn(['records', catalogId, recordId, 'updateProcesses', 'fields', fieldId], {
    inProcess: true,
    shouldProcess: false,
    forceProcess: false,
  });

  this.changeIn(['records', catalogId, recordId, 'updateProcesses', 'count'], (k) => (k ? k + 1 : 1));

  this.setIn(['records', catalogId, recordId, 'updateProcesses', 'should'], false);
}

function endChanges(catalogId, recordId, fieldId) {
  if (!this.getIn(['records', catalogId, recordId, 'updateProcesses', 'fields'])) {
    this.setIn(['records', catalogId, recordId, 'updateProcesses', 'fields'], Immutable.Map({}));
  }

  this.mergeIn(['records', catalogId, recordId, 'updateProcesses', 'fields', fieldId], {
    inProcess: false,
    shouldProcess: false,
    forceProcess: false,
  });

  this.changeIn(['records', catalogId, recordId, 'updateProcesses', 'count'], (k) => (k ? k - 1 : -1));

  this.setIn(['records', catalogId, recordId, 'updateProcesses', 'should'], false);
}

export default {
  saveChangesResults,
  createChange,
  createChangeCompleted,
  createChangeFailed,
  notifyChangesResults,
  startChanges,
  endChanges,
  clearChangesResults,
};
