import React, { lazy, Suspense } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withTranslation } from 'react-i18next';

import Icon from '../../common/UI/Icon';
import apiActions from '../../../actions/apiActions';
import routes from '../../../routes';
import Loading from '../../common/Loading';
import { connect } from '../../StateProvider';
import SCENE_CONTAINER from '../../../configs/sceneContainer';

import styles from './recordBody.less';

// cause app is too heavy
const TabMain = lazy(() => import('./mainTab/TabMain'));
const TabLinkedData = lazy(() => import('./linkedDataTab/TabLinkedData'));
const TabChatController = lazy(() => import('./chatTab/TabChatController'));
const TabHistoryController = lazy(() => import('./historyTab/TabHistoryController'));
const TabRecordsBatchResult = lazy(() => import('../../RecordsBatch/TabRecordsBatchResult'));

class RecordBody extends React.Component {
  componentDidMount() {
    // todo move from this
    if (!this.props.privilegeCodesLoaded && !this.props.privilegeCodesLoading) {
      apiActions.getPrivileges();
    }
  }

  filterFields = () =>
    this.props.catalogs.getIn([this.props.catalogId, 'fields']).filter((field) => !field.get('hidden'));

  batchFields = () => {
    const fields = this.filterFields();
    return fields.map((f) => {
      f = f.set('visible', Immutable.Map());
      f = f.set('visibleRules', Immutable.Map());
      return f;
    });
  };

  render() {
    const { record } = this.props;
    const isNew = this.props.isNew || record.get('isNew');
    const isLoading = !record || record.get('loading');
    const { catalog } = this.props;
    const { catalogId } = this.props;
    const { sectionId } = this.props;
    const { t } = this.props;

    const { sceneId } = this.props;

    const _scene = sceneId ? this.props.scenes && this.props.scenes.get(sceneId) : null;

    const isPopup = _scene && _scene.get('container') === SCENE_CONTAINER.POPUP;

    return (
      <div className={styles.container}>
        {record.get('saving') ? (
          <span className={styles.spinLoader}>
            <Icon type="loading" />
          </span>
        ) : null}

        {isLoading && false ? (
          <div className={styles.loadingOverlay}>
            <Loading fullHeight />
          </div>
        ) : null}

        <Switch>
          <Route
            path={routes.recordMain.path}
            render={(routeProps) => (
              <Suspense fallback={<Loading />}>
                <TabMain
                  sceneId={this.props.sceneId}
                  recordId={record.get('id')}
                  catalogId={catalogId}
                  fields={this.filterFields()}
                  values={record.get('values')}
                  isNewRecord={isNew}
                  readOnly={this.props.readOnly}
                  record={record}
                  catalog={catalog}
                  isPopup={isPopup}
                  t={t}
                  tabId={routeProps.match.params.tabId}
                />
              </Suspense>
            )}
          />
          <Route
            path={routes.recordLinks.path}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TabLinkedData record={record} catalogId={catalogId} sceneId={this.props.sceneId} />
              </Suspense>
            )}
          />
          <Route
            path={routes.recordChat.path}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TabChatController
                  record={record}
                  recordId={record.get('id')}
                  catalog={catalog}
                  sceneId={this.props.sceneId}
                />
              </Suspense>
            )}
          />
          <Route
            path={routes.recordHistory.path}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TabHistoryController
                  record={record}
                  recordId={record.get('id')}
                  catalog={catalog}
                  sceneId={this.props.sceneId}
                />
              </Suspense>
            )}
          />

          {/* batch updating */}
          <Route
            path={routes.batchUpdateMain.path}
            render={(routeProps) => (
              <Suspense fallback={<Loading />}>
                <TabMain
                  sceneId={this.props.sceneId}
                  recordId={record.get('id')}
                  catalogId={catalogId}
                  fields={this.batchFields()}
                  values={record.get('values')}
                  tabId={routeProps.match.params.tabId}
                  onTabChange={this.props.onTabChange}
                  onSaveField={this.props.onSaveField}
                  countingFieldsChanged={this.props.countingFieldsChanged}
                  readOnly={this.props.readOnly}
                />
              </Suspense>
            )}
          />
          <Route
            path={routes.batchUpdateResult.path}
            render={() => (
              <Suspense fallback={<Loading />}>
                <TabRecordsBatchResult
                  recordId={record.get('id')}
                  catalogId={catalogId}
                  sectionId={sectionId}
                  sceneId={this.props.sceneId}
                />
              </Suspense>
            )}
          />
        </Switch>
      </div>
    );
  }
}

RecordBody.propTypes = {
  record: PropTypes.object.isRequired,
  catalogId: PropTypes.string,
  placeHolder: PropTypes.string,
  onSaveField: PropTypes.func,
  onCreate: PropTypes.func,
  isNew: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  changeFieldEditableStatus: PropTypes.func,
  getControllVisibility: PropTypes.func,
  getOptionsForBatchUpdate: PropTypes.func,
  onPlaceHolderClick: PropTypes.func,
  fieldsEditableStatus: ImmutablePropTypes.map,
};

export default connect(
  withRouter(withTranslation()(RecordBody)),
  {
    catalogs: ['catalogs'],
    records: ['records'],
    activeSceneId: ['modal', 'activeScene'],
    scenes: ['scenes'],
    privilegeCodesLoaded: ['privilegeCodesLoaded'],
    privilegeCodesLoading: ['privilegeCodesLoading'],
    userSettings: ['userSettings'],
  },

  (props, state) => {
    const catalog = state.catalogs.get(props.catalogId);
    const sectionId = catalog.get('sectionId');
    const chatInModal = state.userSettings.get('chatInModal');

    return {
      ...props,
      ...state,
      catalog,
      sectionId,
      chatInModal,
    };
  },
);
