import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Stars from '../UI/ControlList/controls/Stars';

import styles from './dataTypes.less';

function StarsField(props) {
  return (
    <div className={cn(props.containerClassName, styles.starsField)}>
      <Stars value={props.value} className={styles.starsItem} editable={false} />
    </div>
  );
}

StarsField.propTypes = {
  value: PropTypes.number,
  config: PropTypes.object,
};

export default StarsField;
