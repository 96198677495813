import React from 'react';
import { withTranslation } from 'react-i18next';

import location from '../../../../../utils/location';
import UserInfo from '.';
import { connect } from '../../../../StateProvider';

function UserInfoContainer({ user, t }) {
  const userName = user && user.get('title');
  const userEmail = user && user.get('email');
  const onClickExit = () => {
    location.logout();
  };
  return <UserInfo onClickExit={onClickExit} userName={userName} userEmail={userEmail} t={t} />;
}

export default withTranslation()(connect(UserInfoContainer, ['user']));
