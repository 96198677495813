import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import ImmutablePropTypes, { record } from 'react-immutable-proptypes';
import { matchPath } from 'react-router';
import { withTranslation } from 'react-i18next';
import Immutable from 'immutable';

import batchRecordsActions from '../../../../actions/batchRecordsActions';
import sceneActions from '../../../../actions/sceneActions';

import Prompt from '../../../common/router/Prompt';
import NavRedirect from '../../../common/router/Redirect';

import RecordsBatchUpdateHeader from '../batchUpdateHeader';
import RecordBody from '../../../Record/RecordBody';
import SCENE_CONTAINER from '../../../../configs/sceneContainer';

import { BATCH_RECORDS_STATUS } from '../../../../configs/recordsBatchStatus';
import routes from '../../../../routes';

import styles from '../../../Record/record.less';
import { connect } from '../../../StateProvider';

class RecordsBatchUpdateRecord extends Component {
  static propTypes = {
    onStartUpdate: PropTypes.func.isRequired,
    onPauseUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    scene: ImmutablePropTypes.map.isRequired,
    sceneId: PropTypes.string.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    catalogId: PropTypes.string.isRequired,
    record: ImmutablePropTypes.map.isRequired,
    loading: PropTypes.bool,
    isWebForm: PropTypes.bool,
  };

  state = {
    fieldsEditableStatus: Immutable.Map({}),
  };

  componentDidUpdate() {
    const { isActive, recordHasBeenEdit, shouldClose, sceneId, updating } = this.props;
    /* в случаях когда смена урла непроизводится, данное условие позволяет удалить неизмененный компонент из очереди сцен */
    if (shouldClose && isActive && !(recordHasBeenEdit || updating)) {
      sceneActions.deleteScene(sceneId);
    }
  }

  onSaveField = (params = {}) => {
    const { sceneId } = this.props;
    batchRecordsActions.changeBatchStatus(sceneId);
  };

  countingFieldsChanged = (fieldId) => {
    const { sceneId, record, catalogId } = this.props;
    const recordId = record.get('id');

    this.setState(
      ({ fieldsEditableStatus }) => {
        const status = fieldsEditableStatus.get(fieldId);
        fieldsEditableStatus = fieldsEditableStatus.set(fieldId, !status);
        batchRecordsActions.changefieldsEditableStatus({ sceneId, fieldId }, !status);

        return {
          fieldsEditableStatus,
        };
      },
      () => {
        const { fieldsEditableStatus } = this.state;

        let values = record.get('values');
        values = values && values.delete(fieldId);

        const prevStatus = fieldsEditableStatus.get(fieldId);

        // обнуляем значения поля, при смене видимости на отрицательну.(звапятая стоит верно)
        if (!prevStatus) {
          batchRecordsActions.updateBatchUpdateRecordValues(catalogId, recordId, values);
        }
      },
    );
  };

  render() {
    const {
      catalog,
      catalogId,
      scene,
      sceneId,

      record,
      recordHasBeenEdit,
      updating,
      isActive,
      shouldClose,
      readOnly,
      loading,
      batchUpdateRecordValues,

      onClose,
      onStartUpdate,
      onPauseUpdate,
      onResumeUpdate,
      isWebForm,
    } = this.props;

    return (
      <div className={styles.container}>
        {/* to show promp && redirect to all records for rightPanel */}
        {shouldClose && isActive ? <NavRedirect route={routes.records} /> : null}

        <Prompt
          when={recordHasBeenEdit || updating}
          message={(location) => {
            const match = matchPath(location.pathname, {
              path: routes.batchUpdate.path,
              exact: false,
              strict: false,
            });

            if (match) {
              return true;
            }
            if (recordHasBeenEdit || updating) {
              return this.props.sceneId;
            }
          }}
        />

        <RecordsBatchUpdateHeader
          catalog={catalog}
          scene={scene}
          record={record}
          hasBeenEdit={recordHasBeenEdit}
          readOnly={readOnly}
          isWebForm={isWebForm}
          isLoading={loading}
          batchUpdateRecordValues={batchUpdateRecordValues}
          onStartUpdate={onStartUpdate}
          onPauseUpdate={onPauseUpdate}
          onResumeUpdate={onResumeUpdate}
          onClose={onClose}
        />
        <RecordBody
          sceneId={sceneId}
          catalogId={catalogId}
          record={record}
          readOnly={readOnly}
          isWebForm={isWebForm}
          onSaveField={this.onSaveField}
          countingFieldsChanged
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    RecordsBatchUpdateRecord,
    {
      modal: ['modal'],
      records: ['records'],
    },
    (props, { modal, records }) => {
      const { record, scene, sceneId, catalogId } = props;

      const recordHasBeenEdit = record.get('hasBeenEdit') || false;
      const updatingStatus = scene.getIn(['data', 'batchStatus']);
      const batchUpdateRecordValues = records.getIn([catalogId, record.get('id'), 'values']);

      const updating =
        updatingStatus === BATCH_RECORDS_STATUS.UPDATING || updatingStatus === BATCH_RECORDS_STATUS.PAUSED;
      const readOnly = updatingStatus !== BATCH_RECORDS_STATUS.READY;
      const loading = updatingStatus === BATCH_RECORDS_STATUS.LOADING;

      const sceneExist = !!scene;
      const shouldClose = sceneExist && scene.get('shouldClose');

      const fields = props.catalog.get('fields');

      const isActive =
        // WINDOW scenes are always active
        (sceneExist && scene.get('container') == SCENE_CONTAINER.WINDOW) ||
        // only one POPUP scene can be active
        (modal && modal.get('activeScene') === sceneId);

      return {
        ...props,
        fields,
        recordHasBeenEdit,
        updating,
        loading,
        isActive,
        shouldClose,
        readOnly,
        batchUpdateRecordValues,
      };
    },
  ),
);
