import _ from 'lodash';
import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import FIELD_TYPES from '../../configs/fieldTypes';
import getFilterComponent from './getFilterComponent';
import FilterItem from './FilterItem';

class FilterList extends React.PureComponent {
  state = {};

  componentDidMount() {
    this.prepareFilters(this.props.fields || []);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields !== this.props.fields) {
      this.prepareFilters(this.props.fields || []);
    }
  }

  prepareFilters = (fields) => {
    fields = this.setFieldsGroup(fields);
    const filterFields =
      fields &&
      fields.filter((field) => {
        const id = field.get('id');
        const type = field.get('type');

        if (!field.get('filterable')) {
          return null;
        }

        if (type == FIELD_TYPES.GROUP) {
          const hasFilterableFields = !!fields.find(
            (f) =>
              f.get('groupId') == id && // in same group
              f.get('id') != id && // not a group
              f.get('filterable'), // field filterable
          );

          if (!hasFilterableFields) {
            return null;
          }
        } else {
          if (field.get('groupId')) {
            const isGroupFilterable =
              field.get('groupId') && !!fields.find((f) => f.get('id') == field.get('groupId') && f.get('filterable'));
            if (!isGroupFilterable) {
              return null;
            }
          }
        }

        const FilterComponent = getFilterComponent(type);
        if (!FilterComponent) {
          return null;
        }

        return true;
      });

    this.setState({ fields: filterFields });
  };

  setFieldsGroup = (fields) => {
    let groupId;
    return (
      fields &&
      fields
        .filter((field) => !field.get('hidden'))
        .map((field) => {
          if (field.get('type') == FIELD_TYPES.GROUP) {
            groupId = field.get('id');
          }
          return field.set('groupId', groupId);
        })
    );
  };

  listFilters = () => {
    const fields = this.state.fields || [];
    const { catalogId, filters, readOnly, ownerId, withExtendedFilters, onSave } = this.props;

    const emptyFilter = Immutable.fromJS([{ value: null }]);

    const listFilters = fields.map((field) => {
      const id = field.get('id');
      const type = field.get('type');
      const config = field.get('config');
      const name = field.get('name');

      let fieldFilters = (filters && filters.get(id)) || emptyFilter;
      if (fieldFilters && fieldFilters.size == 0) {
        fieldFilters = emptyFilter;
      }

      return fieldFilters.map((fieldFilter, filterId) => {
        const value = fieldFilter && fieldFilter.get('value');
        const readOnlyFromFilter = fieldFilter.get('readOnly');
        const key = `${catalogId}:${id}:${filterId}`;
        const FilterComponent = getFilterComponent(type);

        return (
          <FilterItem
            key={key}
            type={type}
            id={id}
            name={name}
            value={value}
            ownerId={ownerId}
            config={config}
            readOnly={readOnlyFromFilter || readOnly}
            onOpen={() => onSave(filterId, id, null)}
            onDrop={() => onSave(filterId, id, null)}
          >
            <FilterComponent
              ownerId={ownerId}
              catalogId={catalogId}
              fieldId={id}
              filterId={filterId}
              config={config}
              value={value}
              readOnly={readOnlyFromFilter || readOnly}
              withExtendedFilters={withExtendedFilters}
              onSave={(id, value) => {
                onSave(filterId, id, value);
              }}
            />
          </FilterItem>
        );
      });
    });

    return listFilters;
  };

  render() {
    return <div className={this.props.className}>{this.listFilters()}</div>;
  }
}

FilterList.propTypes = {
  ownerId: PropTypes.string,
  catalogId: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  filters: PropTypes.object, // Immutable {fieldId: [{value}, ], }
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  withExtendedFilters: PropTypes.bool,
};

export default FilterList;
