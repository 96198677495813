function config({ field, value, catalogId, recordId }) {
  const fieldId = field.get('id');
  const selectedFieldId = field.get('selectedFieldId');

  const params = {
    catalogId,
    fieldId: selectedFieldId || fieldId,
  };

  if (/^\d+$/.test(recordId)) {
    params.recordId = recordId;
  }

  field = field.set('requestParams', params);
  return { field, value };
}

function onChange(values) {
  return values;
}

function onEndEditing(value) {
  return value;
}

export default {
  config,
  onChange,
  onEndEditing,
};
