import { useState } from 'react';

const useDropdownBehaviour = ({ onSelectItem, onVisibleChange, className }) => {
  const [visible, setVisible] = useState(false);

  const selectItem = (item) => {
    onSelectItem && onSelectItem(item);
    setVisible(false);
  };

  const visibleChange = (v) => {
    onVisibleChange && onVisibleChange(v);
    setVisible(v);
  };

  return {
    dropdownClassName: visible ? null : className,
    visible,
    visibleChange,
    selectItem,
  };
};

export default useDropdownBehaviour;
