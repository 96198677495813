import _ from 'lodash';

const sortFn = function (a, b) {
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};

export default function (name, names) {
  const rx = new RegExp(`${name} \\(([0-9]+)\\)$`);
  let indexes = [];

  names.forEach((n) => {
    if (n.indexOf(name) === 0) {
      if (n === name) {
        indexes.push(0);
      } else {
        const m = n.match(rx);
        if (m) {
          indexes.push(parseInt(m[1]));
        }
      }
    }
  });

  indexes = _.uniq(indexes.sort(sortFn));
  let k = 0;

  while (indexes.length && k === indexes.shift()) {
    k += 1;
  }

  return name + (k === 0 ? '' : ` (${k})`);
}
