import React from 'react';
import { Row, Form, Input, Button, Modal, TreeSelect } from 'antd';
import isUrl from 'is-url';
import urlParser from 'url';
import cn from 'classnames';
import Mime from 'mime-types';
import Immutable from 'immutable';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';

import guid from 'guid';
import { fileTypes, fileTypesCategories } from '../fileViewer/getViewerType';

import styles from './modal.less';
import ButtonClose from '../../../ButtonClose';

const { TextArea } = Input;

const UNKNOWN_FILE_TYPE = 'default';
class AttachFile extends React.Component {
  state = {};

  _getFileTypeList() {
    let treeData = [];

    // loop to create a category tree
    treeData = _.chain(fileTypes)
      .map((file, type) => ({ ...file, type })) // mixin type to object
      .sortBy((item) => item.category.priority) // sorting by priority
      .groupBy((i) => {
        const categoryKeys = Object.keys(_.pickBy(fileTypesCategories, (c) => c == i.category && !c.hidden)); // filter by category and hide Proprety
        return categoryKeys.length ? categoryKeys[0] : ''; // categoryKeys is array and thats why return categoryKeys[0]
      })
      .pickBy((types, category) => category !== '') // exclude hidden section from tree
      .map((types, category) => {
        if (category === 'default') {
          return {
            value: category,
            title: fileTypesCategories[category].name,
            selectable: true,
            isLeaf: true,
          };
        }
        const children = _.map(types, (file) => ({
          value: file.type,
          title: file.defenition,
        }));
        return {
          value: category,
          title: fileTypesCategories[category].name,
          children,
          selectable: false,
        };
      })
      .values()
      .value();

    return treeData;
  }

  fileTypeList = this._getFileTypeList();

  treeSearchFunction = (input, node) => node.props.title.toLowerCase().includes(input.toLowerCase());

  showModal = () => {
    this.setState(() => ({
      visible: true,
      url: undefined,
      isUrlInvalid: undefined,
      title: undefined,
      extension: undefined,
    }));
  };

  hideModal = () => {
    this.setState(() => ({
      visible: false,
    }));
  };

  onCancel = () => {
    this.hideModal();
  };

  onOk = () => {
    if (this.isValid()) {
      this.attachFile();
      this.hideModal();
    }
  };

  attachFile = () => {
    const { onChange, onEndEditing, value } = this.props;
    const { url, extension } = this.state;
    let { title } = this.state;

    // add extension to title if not present
    const extensionFromTitle = this._getExtensionByTitle(title);
    if (title && extensionFromTitle != extension) {
      title = `${title}.${extension}`;
    }

    const newFile = {
      temporaryId: guid.raw(),
      mimeType: Mime.lookup(`.${extension}`),
      title,
      size: null,
      url,
      src: url, // todo remove in future version of API
    };

    const newValue = (value || Immutable.List()).push(Immutable.fromJS(newFile));
    onChange(newValue);
    onEndEditing(newValue);
  };

  // treeSelect
  onSelectChange = (value) => {
    this.setState({
      extension: value == UNKNOWN_FILE_TYPE ? '' : value,
    });
  };

  isValid = (e) => {
    const { url } = this.state;
    const isValid = url && isUrl(url);

    // save the target
    const target = e && e.target;

    // so that the button has time to change location
    setTimeout(() => {
      this.setState(() => ({
        isUrlInvalid: !isValid,
      }));
      target && !isValid && target.setCustomValidity('Error');
    }, 200);
    return isValid;
  };

  setUrl = (e) => {
    let url = e.target.value;
    url = _.trim(url, ' \t\r\n');

    this.setState({
      url,
      isUrlInvalid: false,
    });
    e.target.setCustomValidity('');

    if (isUrl(url)) {
      const title = this._getTitleFromUrl(url);
      this.setTitle(title);
    }
  };

  setTitle = (e) => {
    const title = e && e.target ? e.target.value : e;

    // get type by title
    const extensionByTitle = this._getExtensionByTitle(title);
    const extension = fileTypes[extensionByTitle] ? extensionByTitle : '';
    this.setState({
      title,
      extension,
    });
  };

  _getExtensionByTitle(title) {
    title = String(title).split('.');
    return title.length > 1 ? title.at(-1) : '';
  }

  _getTitleFromUrl = (url) => {
    const parsedURL = urlParser.parse(url);
    const pathName = parsedURL.pathname && parsedURL.pathname.split('/');

    if (pathName.at(-1) !== '') {
      // filename by pathname last item
      return pathName.at(-1);
    }
    if (pathName.at(-2) !== '' && pathName.length !== 1) {
      // filename by pathname prelast item
      return pathName.at(-2);
    }
  };

  render() {
    const { visible, url, title, extension, isUrlInvalid } = this.state;
    const { t } = this.props;
    return (
      <>
        <div onClick={this.showModal} className={styles.upload}>
          {t('record.actions.attach')}
        </div>

        {visible ? (
          <Modal
            open={visible}
            maskClosable={false}
            closable={false}
            className={styles.modal}
            footer={[
              <Button key="submit" type="primary" htmlType="submit" onClick={this.onOk} disabled={isUrlInvalid}>
                {t('record.actions.toAttach')}
              </Button>,
              <Button key="close" htmlType="button" onClick={this.onCancel}>
                {t('record.actions.close')}
              </Button>,
            ]}
          >
            <Row type="flex" justify="space-between" align="middle" className={styles.headerModal}>
              <Row type="flex" align="middle">
                <h1 className={styles.headerModalTitle}>{t('record.attachFile.headerTitle')}</h1>
              </Row>
              <ButtonClose large shiftRight onClick={this.onCancel} />
            </Row>

            <Form className={styles.attachForm}>
              <div className={styles.formItem}>
                <label>{t('record.attachFile.url')}:</label>
                <TextArea
                  placeholder={t('record.attachFile.requiredParam')}
                  onBlur={this.isValid}
                  rows={3}
                  name="url"
                  type="url"
                  value={url}
                  onChange={this.setUrl}
                />
                {isUrlInvalid ? <p className={styles.errorText}>{t('record.attachFile.enterUrl')}</p> : null}
              </div>
              <div className={cn(styles.flex, styles.formItem)}>
                <div className={styles.flexItem}>
                  <label htmlFor="title">{t('record.attachFile.title')}:</label>
                  <Input name="title" value={title === undefined ? null : title} onChange={this.setTitle} />
                </div>
                <div className={styles.flexItem}>
                  <label htmlFor="fileType">{t('record.attachFile.fileType')}:</label>
                  <TreeSelect
                    name="fileType"
                    className={styles.tree}
                    dropdownStyle={{ maxHeight: '600px' }}
                    onChange={this.onSelectChange}
                    value={extension || UNKNOWN_FILE_TYPE}
                    treeData={this.fileTypeList}
                    showSearch
                    searchPlaceholder={t('record.attachFile.search')}
                    filterTreeNode={this.treeSearchFunction}
                    suffixIcon={<DownOutlined />}
                  />
                </div>
              </div>
            </Form>
          </Modal>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(AttachFile);
