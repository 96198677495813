import Reflux from 'reflux';
import _ from 'lodash';

const log = require('debug')('CRM:Action:editorActions');

const actions = Reflux.createActions({
  dropTab: {},
  dropField: {},
  dropSection: {},
  dropCollapsedSection: {},
  removeField: {},
  updateField: { sync: true },
  changeFieldConfig: { sync: true },
  validateField: {},
  setFieldName: {},
  setFieldOnTab: {},
  setFieldRequired: {},
  setFieldApiOnly: {},
  setFieldHint: {},
  setFieldAttr: {},
  setCatalogIcon: {},
  setCatalogName: {},
  setCatalogSection: {},
});

_.forEach(actions, (act, name) => {
  act.preEmit = function preEmit(...args) {
    log(name, args);
  };
});

export default actions;
