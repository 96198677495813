import React, { memo } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import ListItem from './ListItem';

import styles from './multiModal.less';
import helpersStyles from './helpersStyles.less';

const COMPONENTS = {
  CONNECT: 'connect',
  CHILD: 'child',
  LAST_CHILD: 'lastChild',
  SEQUENCE: 'sequence',
  NONE: 'none',
};

class ScenesList extends React.Component {
  state = {};

  componentDidMount() {
    const { modalScenes } = this.props;

    const initialScenes = modalScenes.filter((scene) => {
      const parentSceneId = scene.get('parentSceneId');
      const parentSceneIsNotMultimodal = !modalScenes.find((scene) => scene.get('sceneId') === parentSceneId);

      return parentSceneIsNotMultimodal;
    });

    const dataByBasePoint = this.getDataByBasePoin(initialScenes, modalScenes);

    this.setState({ dataByBasePoint });
  }

  componentDidUpdate(prevProps) {
    const { modalScenes } = this.props;
    if (prevProps.modalScenes !== this.props.modalScenes) {
      const initialScenes = modalScenes.filter((scene) => {
        const parentSceneId = scene.get('parentSceneId');
        const parentSceneIsNotMultimodal = !modalScenes.find((scene) => scene.get('sceneId') === parentSceneId);

        return parentSceneIsNotMultimodal;
      });
      const dataByBasePoint = this.getDataByBasePoin(initialScenes, modalScenes);

      this.setState({ dataByBasePoint });
    }
  }

  makeDataTree = (modalScenes, currentSceneId, parentChildrenCount = 1) => {
    // const currentScene = modalScenes.get(currentSceneId);
    const children = modalScenes
      .filter((scene) => scene.get('parentSceneId') === currentSceneId)
      .valueSeq()
      .toJS();
    const childrenCount = children.length;

    let data = [];

    // current
    const current = {
      sceneId: currentSceneId,
    };
    if (childrenCount <= 1 && parentChildrenCount <= 1) {
      // линейно
      data.push({
        ...current,
        sequence: true,
        childs: [],
      });
      if (childrenCount === 1) {
        let sequence = this.makeDataTree(modalScenes, children[0].sceneId, childrenCount);
        sequence = _.map(sequence, (i) => ({ ...i, sequence: true }));
        data = _.concat(data, sequence);
      }
      return data;
    }
    // дерево
    data.push({
      ...current,
      childs: _.flatten(_.map(children, (child) => this.makeDataTree(modalScenes, child.sceneId, childrenCount))),
    });
    return data;
  };

  drawListTree = (data, initialSceneId, prevPointers = []) => {
    const { activeSceneId, switchModal, onClose } = this.props;
    if (!data) {
      return null;
    }
    // modficate previos pointer
    if (prevPointers.length) {
      let prevPointer = prevPointers[prevPointers.length - 1];
      if (prevPointer === COMPONENTS.SEQUENCE) {
        // remove sequence pointer because it has 0 width
        prevPointers = prevPointers.slice(0, prevPointers.length - 1);
      } else {
        prevPointer = prevPointer === COMPONENTS.CHILD ? COMPONENTS.CONNECT : COMPONENTS.NONE;
        prevPointers[prevPointers.length - 1] = prevPointer;
      }
    }

    let nodes = [];
    _.forEach(data, (dataItem, i) => {
      let pointers;
      if (dataItem.sceneId !== initialSceneId) {
        pointers = _.concat(
          prevPointers,
          dataItem.sequence ? COMPONENTS.SEQUENCE : i === data.length - 1 ? COMPONENTS.LAST_CHILD : COMPONENTS.CHILD,
        );
      }
      nodes.push(
        <ListItem
          key={dataItem.sceneId}
          isActive={activeSceneId === dataItem.sceneId}
          sceneId={dataItem.sceneId}
          switchModal={switchModal}
          onClose={onClose}
          pointers={
            pointers &&
            pointers.map((item, key) => <div className={helpersStyles[item]} key={`${dataItem.sceneId}-${key}`} />)
          }
        />,
      );

      // build childs
      nodes = _.concat(nodes, this.drawListTree(dataItem.childs, dataItem.sceneId, pointers));
    });
    return nodes;
  };

  getDataByBasePoin(initialScenes, modalScenes) {
    const dataByBasePoint = {};
    initialScenes.forEach((_scene, sceneId) => {
      dataByBasePoint[sceneId] = this.makeDataTree(modalScenes, sceneId);
    });
    return dataByBasePoint;
  }

  render() {
    const { modalScenes, onCloseModal, t } = this.props;
    const { dataByBasePoint } = this.state;
    const dataByBaseTreePoints = _.map(dataByBasePoint, (data, initialSceneId) =>
      this.drawListTree(data, initialSceneId),
    );

    return (
      <div className={styles.listContainer}>
        <div className={styles.listHeader}>
          <h2 className={styles.listHeaderText}>{t('multiModal.header')}</h2>
        </div>
        <div className={styles.listItemWrapper}>
          {dataByBaseTreePoints.map((data, index) => (
            <div key={index} className="ant-menu-inline">
              {data}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(memo(ScenesList));
