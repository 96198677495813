import { fromJS } from 'immutable';

export default {
  findParentRecord(params, parentCatalogId, parentRecordId, parentFieldId) {
    const parentsKey = ['records', params.catalogId, params.recordId, 'parents'];
    const parents = this.getIn([...parentsKey]);

    return parents.find(
      (parent) =>
        parent.get('catalogId') === parentCatalogId &&
        parent.get('recordId') === parentRecordId &&
        parent.get('fieldId') === parentFieldId,
    );
  },

  addParentToRecord(params, parentCatalogId, parentRecordId, parentFieldId) {
    if (this.findParentRecord(params, parentCatalogId, parentRecordId, parentFieldId)) {
      return;
    }

    const parentsKey = ['records', params.catalogId, params.recordId, 'parents'];
    // const sceneParentsKey = ["scenes", params.sceneId, "records", params.catalogId, params.recordId, "parents"]
    let parents = this.getIn([...parentsKey]);

    const parent = fromJS({
      catalogId: parentCatalogId,
      recordId: parentRecordId,
      fieldId: parentFieldId,
    });

    parents = parents.push(parent);

    this.setIn([...parentsKey], parents);
    // this.mergeIn([...sceneParentsKey], parents)
  },

  removeParentFromRecord(params, parentCatalogId, parentRecordId, parentFieldId) {
    const parentsKey = ['records', params.catalogId, params.recordId, 'parents'];
    let parents = this.getIn([...parentsKey]);

    if (!parents) {
      return;
    }

    parents = parents.filter((parent) => {
      let match = true;

      parentCatalogId && (match = match && parent.get('catalogId') === parentCatalogId);
      parentRecordId && (match = match && parent.get('recordId') === parentRecordId);
      parentFieldId && (match = match && parent.get('fieldId') === parentFieldId);

      return !match;
    });

    this.setIn([...parentsKey], parents);
  },
};
