import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragSource } from 'react-dnd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import dndTargets from '../../../configs/dndTargets';
import FIELD_TYPES from '../../../configs/fieldTypes';
import dragAndDropActions from '../../../actions/dragAndDropActions';
import styles from './fieldsPanel.less';

const typesWithMargin = {
  [FIELD_TYPES.GROUP]: true,
  [FIELD_TYPES.ADDRESS]: true,
  [FIELD_TYPES.FILE]: true,
  [FIELD_TYPES.STARS]: true,
  [FIELD_TYPES.BUTTON]: true,
  [FIELD_TYPES.DATE]: true,
};

const dragSource = DragSource(
  dndTargets.FIELD_TYPE,
  {
    beginDrag(props, monitor, component) {
      const item = {
        fieldType: component.props.type,
        config: component.props.config,
      };
      dragAndDropActions.beginDrag(dndTargets.FIELD_TYPE, item);
      return item;
    },
    endDrag() {
      dragAndDropActions.endDrag();
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
);

class FieldTypesItem extends React.PureComponent {
  render() {
    const { connectDragSource, isDragging, icon, title, type } = this.props;

    const classes = classNames({
      [styles.field]: true,
      [styles.fieldWithMargin]: typesWithMargin[type],
      [styles.section]: this.props.type === FIELD_TYPES.GROUP,
      dragging: isDragging,
    });
    return connectDragSource(
      <div className={classes}>
        <div className={styles.fieldIcon}>
          <div className={`anticon-icon ${icon || 'programing-21'}`} />
        </div>
        <div className={styles.fieldIconBorder} />
        <span className={styles.fieldText}>{this.props.t(title)}</span>
      </div>,
    );
  }
}

FieldTypesItem.propTypes = {
  type: PropTypes.string.isRequired,
};

export default withTranslation()(dragSource(FieldTypesItem));
