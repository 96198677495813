/* eslint-disable no-use-before-define */
import _ from 'lodash';
import appState from '../../../appState';
import FIELD_TYPES from '../../../configs/fieldTypes';

// new version:
// - pass Parent Record to each suitable field in New Record
// - pass each linked record from Parent Record to each suitable field in New Record
// - if linked record suits to many fields, then pass it only to filed with same name

export default function getValuesFromLinkedRecord(fromCatalogId, fromRecordId, toCatalogId, values = {}) {
  // from
  const fromCatalog = appState.getIn(['catalogs', String(fromCatalogId)]);
  let fromFields = appState.getIn(['catalogs', String(fromCatalogId), 'fields']);
  fromFields = fromFields ? fromFields.toJS() : [];
  const fromRecord = appState.getIn(['records', String(fromCatalogId), fromRecordId]);
  let fromValues = fromRecord.getIn(['values']);
  fromValues = fromValues ? fromValues.toJS() : {};
  // to
  let toFields = appState.getIn(['catalogs', toCatalogId, 'fields']);
  toFields = toFields ? toFields.toJS() : [];
  values = values || {};
  // collection with new values
  const newValues = {};

  // copy self record
  if (_.isEmpty(values) && !fromRecord.get('isNew')) {
    const i = {
      catalogId: fromCatalogId,
      catalogTitle: fromCatalog.get('title'),
      catalogIcon: fromCatalog.get('icon'),
      recordId: fromRecordId,
      recordTitle: fromRecord.get('title'),
      recordValues: fromValues,
    };

    const setToFields = _.filter(toFields, (toF) => hasCrossRelation(toF, i));

    // may be several fields
    _.forEach(setToFields, (toF) => {
      // collect items to new record
      newValues[toF.id] = _.concat(newValues[toF.id] || [], i);
    });
  }

  // copy cross relations
  _.forEach(fromFields, (fromF) => {
    if (fromF.type == FIELD_TYPES.OBJECT) {
      const fromV = fromValues[fromF.id] || [];

      _.forEach(fromV, (i) => {
        // get fields with same linked catalogs
        let setToFields = _.filter(toFields, (toF) => hasCrossRelation(toF, i));

        // if many fields suits by cross relations => get with same name only
        // if (setToFields.length > 1) {
        // get fields with same name
        setToFields = _.filter(setToFields, (toF) => toF.name == fromF.name);
        // }

        // still may be several fields
        _.forEach(setToFields, (toF) => {
          // collect items to new record
          newValues[toF.id] = _.concat(newValues[toF.id] || [], i);
        });
      });
    }
  });

  // prepare values
  _.forEach(toFields, (toF) => {
    let value = newValues[toF.id];
    const hasValue = value && !_.isEmpty(value);

    if (!hasValue) {
      return;
    }

    // skip if readonly by access
    // TODO

    // skip if apionly
    // TODO ?

    if (toF.type == FIELD_TYPES.OBJECT) {
      // kill duplicates
      value = _.uniq(value, (v) => `${v.catalogId}:${v.recordId}`);

      // leave only 1 item in !multiselect fields
      if (!toF.config.multiselect) {
        value = value.slice(0, 1);
      }

      newValues[toF.id] = value;
    }
  });

  // set values
  _.forEach(newValues, (value, fId) => {
    const hasValue = values[fId] && !_.isEmpty(values[fId]);
    if (!hasValue) {
      values[fId] = value;
    }
  });

  return values;
}

function hasCrossRelation(field, linkedRecord) {
  if (field.type != FIELD_TYPES.OBJECT) {
    return false;
  }

  let allowedCatalogs = field.config.catalogs.map((c) => c.id);
  allowedCatalogs = _.concat(
    allowedCatalogs,
    field.config.views.map((c) => c.catalogId),
  );
  return _.indexOf(allowedCatalogs, linkedRecord.catalogId) >= 0 && !linkedRecord.isRemoved;
}

/*
// previous version:
// for each field in New Record collect all suiatable linked records from Parent Record

export default function getValuesFromLinkedRecord(
  fromCatalogId,
  fromRecordId,
  toCatalogId,
  values = {}
) {
  // from
  let fromCatalog = appState.getIn(["catalogs", fromCatalogId]);
  let fromFields = appState.getIn(["catalogs", fromCatalogId, "fields"]);
  fromFields = fromFields ? fromFields.toJS() : [];
  let fromRecord = appState.getIn(["records", fromCatalogId, fromRecordId]);
  let fromValues = fromRecord.getIn(["values"]);
  fromValues = fromValues ? fromValues.toJS() : {};
  // to
  let toFields = appState.getIn(["catalogs", toCatalogId, "fields"]);
  toFields = toFields ? toFields.toJS() : [];
  const copySelf = _.isEmpty(values) && !fromRecord.get("isNew");
  values = values || {};

  // copy cross relations
  toFields.forEach(toF => {
    let value = [];

    // skip if readonly by access
    // TODO

    // skip if apionly
    // TODO ?

    if (toF.type == FIELD_TYPES.OBJECT) {
      // all allowed catalogs in field
      let toFCatalogs = toF.config.catalogs.map(c => c.id);
      toFCatalogs = _.concat(
        toFCatalogs,
        toF.config.views.map(c => c.catalogId)
      );

      // set current record to new record
      if (copySelf && _.indexOf(toFCatalogs, fromCatalogId) >= 0) {
        value = [
          {
            catalogId: fromCatalogId,
            catalogTitle: fromCatalog.get("title"),
            catalogIcon: fromCatalog.get("icon"),
            recordId: fromRecordId,
            recordTitle: fromRecord.get("title"),
            isRemoved: false
          }
        ];
      }

      // get all items (by crosss catalog Ids) from all fields of from Catalog
      _.chain(fromFields)
        .filter(fromF => fromF.type === toF.type)
        .value()
        .forEach(fromF => {
          // fromRecord field value
          let fromValue = fromValues[fromF.id];
          // fromRecord field value items with same catalog
          fromValue = _.filter(
            fromValue,
            v => _.indexOf(toFCatalogs, v.catalogId) >= 0 && !v.isRemoved
          );

          // combine items from all fields
          if (fromValue) {
            value = _.concat(value, fromValue);
          }
        });

      // kill duplicates
      value = _.uniq(value, v => v.catalogId + ":" + v.recordId);

      // if only 1 allowed in toRecord field
      if (!toF.config.multiselect) {
        value = value.slice(0, 1);
      }
    }

    // set if field has no data
    if (!_.isEmpty(value) && !values[toF.id]) {
      values[toF.id] = value;
    }
  });

  return values;
}

*/
