import _ from 'lodash';

export default (value) => {
  switch (typeof value) {
    case 'object':
    case 'string':
      return _.isEmpty(value);

    case 'number':
    case 'bigint':
      return value == 0 ? false : !value;

    case 'boolean':
    case 'undefined':
    case 'symbol':
    default:
      return !value;
  }
};
