import Immutable from 'immutable';

export default {
  // fields that rose scripts
  updatedFields: Immutable.Map(),

  addUpdatedFields(catalogId, recordId, fieldId) {
    this.updatedFields = this.updatedFields.setIn([catalogId, recordId, fieldId], true);
  },

  removeUpdatedFields() {
    this.updatedFields = Immutable.Map();
  },

  getUpdatedFields(catalogId, recordId) {
    return this.updatedFields.getIn([catalogId, recordId]) || Immutable.Map();
  },
};
