import _ from 'lodash';
import Immutable from 'immutable';

import FIELD_TYPES from '../../configs/fieldTypes';
import CatalogFactory from '../../models/CatalogFactory';
import sectionActions from '../../actions/sectionActions';

export default {
  setCatalog(data) {
    const catalogKey = ['catalogs', data.id];
    const catalogExists = this.getIn([...catalogKey]);

    let catalog;
    if (!catalogExists) {
      catalog = CatalogFactory.create(data);
      const fields = catalog.get('fields').map((field, i) => field.set('orderId', i));
      catalog = catalog.set('fields', fields);
      this.setIn(catalogKey, catalog);
    } else {
      catalog = CatalogFactory.format(data);
      // combine fields
      catalog = catalog.set('fields', this.mergeFields(catalog));
      // clean old fields before merge
      this.setIn([...catalogKey, 'fields'], Immutable.List());
      // merge
      this.mergeDeepIn(catalogKey, catalog);
    }
    this.setCatalogsFromConfig(catalog.get('id'));
  },

  setCountOfNewMessagesByCatalogsForSection(catalogs) {
    const obj = _.reduce(
      catalogs,
      (acc, catalog, key) => {
        const { sectionId } = catalog;
        const prevValue = acc[sectionId] || 0;
        const newChats = _.get(catalog, ['chat', 'newChats'], 0);
        acc[sectionId] = prevValue + newChats;
        return acc;
      },
      {},
    );

    _.forEach(obj, (value, sectionId) => {
      if (value !== 0) {
        this.setIn(['sections', sectionId, 'newChats'], value);
      }
    });
  },

  mergeFields(newCatalog) {
    const catalogId = newCatalog.get('id');
    let newFields = newCatalog.get('fields');
    const fieldPrivilegeCodes = newCatalog.get('fieldPrivilegeCodes');
    // проставляем orderId для новых полей так как только эти поля будут отображаться в записи
    newFields = newFields.map((field, i) => field.set('orderId', i));

    // ПРИМЕР ХОРОШЕГО КОММЕНТАРИЯ :)
    // у нас может быть уже загружно больше полей каталога, чем мы получаем с сервера при получении каталога
    // такое бывает, так как часть полей могут быть скрыты и они не приходят с сервера
    // при этом эти поля могут быть доступны через связанные каталоги (расширенные поля) другого каталога и поэтому уже загружены
    // это так, потому что связанный каталог хранит расширенные поля не у себя, а в самом обьекте связанного каталога
    // и такие поля важно сохранить, иначе сломаются связанные каталоги - пропадут расширенные поля
    // поэтому мы их оставляет, но помечаем как скрытые и убираем их сортировку (чтобы не сломать порядок в реальных полях)
    // не страшно, если мы пометим лишние поля, пришедшими с сервера мы восстановим эти флаги
    // но бывает так. что часть полей могут быть вообще удалены с сервера, и их нужно удалить в сторе
    // если поля нет в правилах на поля, то оно либо удалено, либо нам и так придет с этим запросом каталога
    // если fieldPrivilegeCodes пустой, то значит ограничений нет и мы получаем все поля, можно все обновить
    const catalogFields = this.getIn(['catalogs', catalogId, 'fields']);
    const currentFields =
      catalogFields &&
      catalogFields
        .filter((field) => fieldPrivilegeCodes.get(field.get('id')))
        .map((field) => field.delete('orderId').set('hidden', true));

    // merge current & new fields from full catalog
    const fieldsById = this.formatById(currentFields).merge(this.formatById(newFields));
    let fields = fieldsById.toList();

    // sort fields
    // fields without orderId (=undefined) are not sortered by sortBy
    fields = fields.sortBy((field) => (field.get('orderId') >= 0 ? field.get('orderId') : Infinity));
    return fields;
  },

  setCatalogsFromConfig(catalogId) {
    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    const extendedCatalogs = this.getExtendedCatalogs(fields);

    extendedCatalogs.forEach((fields, catalogId) => {
      const catalogExist = this.getIn(['catalogs', catalogId]);

      // Проверяем на налочие данного каталога
      if (!catalogExist) {
        // Создаём новый каталог путем форматирования только имеющихся данных
        let catalog = CatalogFactory.format({
          id: catalogId,
          fields: fields.toJS(),
        });

        // сделать все поля связанного каталога как скрытые
        const hiddenFields = catalog.get('fields').map((field) => field.set('hidden', true));
        catalog = catalog.set('fields', hiddenFields);

        this.setIn(['catalogs', catalogId], catalog);
      } else {
        const currentFields = catalogExist.get('fields');
        // нужно добываить в каталог только поля которых там нет
        fields = fields.filter(
          (field) => !currentFields.some((currentField) => currentField.get('id') === field.get('id')),
        );

        fields = fields.map((field) => field.set('hidden', true));

        fields = currentFields.concat(fields);
        this.setIn(['catalogs', catalogId, 'fields'], fields);
      }
    });
  },

  getExtendedCatalogs(fields) {
    // собираем все расшиеренные поля из конфигов
    // среди пришедших филдов

    return fields.reduce((catalogs, field) => {
      const extendedCatalogs = field.getIn(['config', 'fields']);

      if (field.get('type') !== FIELD_TYPES.OBJECT || !extendedCatalogs) {
        return catalogs;
      }

      extendedCatalogs.forEach((extendedFields, id) => {
        const fields = catalogs.get(id);

        catalogs = fields
          ? catalogs.set(id, extendedFields.toMap().merge(fields.toMap()).toList())
          : catalogs.set(id, extendedFields);
      });

      return catalogs;
    }, Immutable.Map());
  },

  formatById(list) {
    let map = Immutable.Map();

    list.forEach((element) => {
      map = map.set(String(element.get('id')), element);
    });

    return map;
  },
};
