import Immutable from 'immutable';
import Reflux from 'reflux';
import debug from 'debug';
import _ from 'lodash';
import userSettingsActions from '../actions/userSettingsActions';
import usUtil from '../utils/userSettings';
import usConst from '../configs/userSettings';

const log = debug('CRM:store:UserSettings');

let state = Immutable.fromJS({
  catalogs: {},
  ui: {},
});

const userSettingsStore = Reflux.createStore({
  listenables: [userSettingsActions],

  // to mixin.
  changed(params) {
    this.trigger(this, state, params);
    log('changed', params);
  },

  setIn(path, val) {
    state = state.setIn(path, val);
  },

  // getters.
  getVisibilityField(params, defaultValue) {
    const key = usUtil.makeKey(params, usConst.VISIBLE);
    const setting = state.getIn(key.split(usUtil.delimiter));
    if (setting) {
      // get value from setting
      return setting.get(usConst.VISIBLE);
    }
    return defaultValue;
  },

  getFieldsOrder({ catalogId }, defaultValue = null) {
    const key = usUtil.makeKey({ catalogId }, usConst.FIELDS_ORDER);
    const setting = state.getIn(key.split(usUtil.delimiter));
    if (setting) {
      // get value from setting
      return setting.get(usConst.FIELDS_ORDER).toJS();
    }
    return defaultValue;
  },

  getSortingRecords({ catalogId }, defaultValue = Immutable.Map({ sortField: 'id', sortType: -1 })) {
    const key = usUtil.makeKey({ catalogId }, usConst.SORTING_RECORDS);
    const setting = state.getIn(key.split(usUtil.delimiter));
    if (setting) {
      // get value from setting
      return setting;
    }
    return defaultValue;
  },

  getWidthsRecords({ catalogId, fieldId }, defaultValue = 90) {
    const key = usUtil.makeKey({ catalogId, fieldId }, usConst.WIDTH);
    const setting = state.getIn(key.split(usUtil.delimiter));
    if (setting) {
      // get value from setting
      return setting.get(usConst.WIDTH);
    }
    return defaultValue;
  },

  // |/////////////|
  // |/ actions ///|
  setKeyCompleted(key, data) {
    // key can be: "var", "var1.var2.var3", ["var1", "var2"]
    key = _.isArray(key) ? key : key.split(usUtil.delimiter);

    // value
    let { value } = data;
    // value usualy is = {"value": ...}
    value = _.isUndefined(value.value) ? value : value.value;

    // set
    state = state.setIn(key, value);

    userSettingsActions.setUserSettingsToAppState(key, value);
  },

  getKeyCompleted(key, data) {
    // key can be: "var", "var1.var2.var3", ["var1", "var2"]
    key = _.isArray(key) ? key : key.split(usUtil.delimiter);

    // can be returned several keys
    // we need to merge them in one object
    _.forEach(data, (s) => {
      // value usualy is = {"value": ...}
      // can be s.value = { "value": false }
      const val = _.isUndefined(s.value.value) ? s.value : s.value.value;
      const obj = usUtil.makeObject(s.key, val);
      state = state.mergeDeep(obj);
    });

    // get full value of key
    const value = state.getIn(key);
    // set
    state = state.setIn(key, value);
    userSettingsActions.setUserSettingsToAppState(key, value);
  },

  getUserSettingsForCatalog({ catalogId }) {
    log('start request for user settings for catalog %s', catalogId);
  },

  /**
   */
  getUserSettingsForCatalogCompleted(data, params) {
    // process all settings for catalog.
    const { catalogId } = params;

    // bad code
    // default sorting for records
    if (!state.getIn(['catalogs', catalogId, 'viewMode', 'table', 'sortingRecords'])) {
      state = state.mergeDeep({
        catalogs: {
          [catalogId]: {
            viewMode: {
              table: {
                sortingRecords: { sortField: 'id', sortType: -1 },
              },
              cards: {
                sortingRecords: { sortField: 'id', sortType: -1 },
              },
            },
          },
        },
      });
    }

    _.forEach(data, (s) => {
      const obj = usUtil.makeObject(s.key, s.value);
      state = state.mergeDeep(obj);
    });
    userSettingsActions.setCatalogUserSettingsToAppState(params, state.getIn(['catalogs', catalogId]));
  },

  setFieldVisibility(params) {
    const visible = params[usConst.VISIBLE];
    const key = usUtil.makeKey(params, usConst.VISIBLE);
    userSettingsActions.setUserSettingsToAppState(
      key.split(usUtil.delimiter),
      Immutable.fromJS({ [usConst.VISIBLE]: visible }),
    );
  },

  setFieldsVisibility(params) {},

  setFieldWidth(params) {
    const width = params[usConst.WIDTH];
    const key = usUtil.makeKey(params, usConst.WIDTH);
    userSettingsActions.setUserSettingsToAppState(
      key.split(usUtil.delimiter),
      Immutable.fromJS({ [usConst.WIDTH]: width }),
    );
  },

  setOption(params) {
    const key = usUtil.makeKey(params);
    userSettingsActions.setUserSettingsToAppState(
      key.split(usUtil.delimiter),
      Immutable.fromJS({ value: params.value }),
    );
  },

  setFieldVisibilityFailed() {
    // TODO: revert visibility if possible, or download fields
  },

  setFieldsOrder(params) {
    const fieldOrder = params[usConst.FIELDS_ORDER];
    const key = usUtil.makeKey(params, usConst.FIELDS_ORDER);
    userSettingsActions.setUserSettingsToAppState(
      key.split(usUtil.delimiter),
      Immutable.fromJS({ [usConst.FIELDS_ORDER]: fieldOrder }),
    );
  },

  setSortingRecords(params, data) {
    const key = usUtil.makeKey(params, usConst.SORTING_RECORDS);
    userSettingsActions.setUserSettingsToAppState(key.split(usUtil.delimiter), Immutable.fromJS(data));
  },
});

export default userSettingsStore;
