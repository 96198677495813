import { useState } from 'react';

import { getItemByCode } from '../lib';

export const useHelperNavigate = (tutorials) => {
  const [showList, setShowList] = useState(true);
  const [currentTutorial, setCurrentTutorial] = useState(null);

  const switchToTutorial = (_code) => {
    const item = getItemByCode(tutorials, _code);
    setCurrentTutorial(item);
    item && setShowList(false);
  };

  const toAllTutorials = () => {
    setShowList(true);
  };

  return {
    currentTutorial,
    setCurrentTutorial,

    switchToTutorial,
    showList,
    setShowList,
    toAllTutorials,
  };
};
