import React from 'react';
import Immutable from 'immutable';
import isUrl from 'is-url';

import _ from 'lodash';
import FIELD_TYPES from '../../configs/fieldTypes';
import * as CONTACT_FIELD_SUB_TYPES from '../../configs/contactFieldSubTypes';
import { VALUE_STATUSES } from '../../configs/import';
import i18n from '../../configs/i18n';
import { Field } from './BaseClass.js';

const delimiters = [',', ';'];

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const splitValue = (values, delimiter) => {
  if (_.isString(values)) {
    values = _.chain(values).split(delimiter).map(_.trim).value();
  }

  return Immutable.List(values);
};

export default class Contact extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.PAIR;

  static components = {
    inline: require('../../components/common/dataTypes/ContactField').default,
    control: require('../../components/common/UI/ControlList/controls/Pair').default,
  };

  static getComponent = (type) => Contact.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static parseValue = (field, value) => {
    value = _.trim(value);

    /* попытка распарсить пустое значение */
    if (_.isEmpty(value)) {
      value = Contact.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (_.isString(value)) {
      let valuesByDelimetr = {};

      /* разделение значения по разделителям */
      _.forEach(delimiters, (delimiter) => {
        const splitedValuesByDelimetr = splitValue(value, delimiter);

        if (splitedValuesByDelimetr && !splitedValuesByDelimetr.isEmpty()) {
          /* для каждого значения проводим валидацию, это нужно для "мягкого" режима отображения */
          const validatedValues = splitedValuesByDelimetr
            .map((contact, id) => {
              contact = Immutable.fromJS({
                comment: '',
                contact: Immutable.fromJS(contact),
              });

              if (Contact.validateValue(field, Immutable.List([contact]))) {
                return contact;
              }
              return null;
            })
            .filter((i) => !_.isNull(i));

          /* заполняем объект удачно распаршенными значениями */
          if (validatedValues && !validatedValues.isEmpty()) {
            valuesByDelimetr[delimiter] = validatedValues;
          }
        }
      });

      valuesByDelimetr = _.sortBy(valuesByDelimetr, (values) => values.size);
      const mostMatchesValues = _.last(valuesByDelimetr);

      if (mostMatchesValues && !mostMatchesValues.isEmpty()) {
        return { value: mostMatchesValues, status: VALUE_STATUSES.VALID };
      }

      // if only one contact in string
      const originContact = Immutable.fromJS([{ contact: value }]);
      const isValid = Contact.validateValue(originContact, field);

      return {
        value: originContact,
        status: isValid ? VALUE_STATUSES.VALID : VALUE_STATUSES.INVALID,
      };
    }

    return { value, status: VALUE_STATUSES.VALID };
  };

  static validateValue = (field, validateValue, outError) => {
    if (!Immutable.List.isList(validateValue)) {
      return false;
    }

    if (!field) {
      return false;
    }

    return validateValue.reduce((acc, value) => {
      value = value.get('contact');
      value = value.replace(/\s/g, '');

      const type = field.getIn(['config', 'type']);

      switch (type) {
        case CONTACT_FIELD_SUB_TYPES.PHONE:
          const numberSymbolTest = /[\d+\-\(\)]/g.test(value);
          const startSumbolTest = /^[+\d]/.test(value);
          const endSumbolTest = /\d$/.test(value);

          return acc && numberSymbolTest && startSumbolTest && endSumbolTest;

        case CONTACT_FIELD_SUB_TYPES.EMAIL:
          const emailTest = validateEmail(value);
          if (outError) {
            return i18n.t('fieldTypes.contact.email.error');
          }
          return acc && emailTest;

        case CONTACT_FIELD_SUB_TYPES.SITE:
          const protocolTest = /^([a-z]+:\/\/|\/\/)/.test(value);
          const siteTest = /^www/.test(value);
          const url = isUrl(value);

          return acc && (siteTest || protocolTest || url);

        default:
          return acc && false;
      }
    }, true);
  };

  static isEmpty = (value) => !(value && value.size);

  static getEmptyValue = () => Immutable.List();

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static createComponent = (field, value, type) => {
    const Component = Contact.components[type];
    return function ({ containerClassName }) {
      if (Contact.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static convertFilterToRecordValue = () => undefined;

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = (value) => {
    let empty = true;
    if (_.isArray(value)) {
      value.map((contact) => {
        if (contact.contact != '' && contact.contact !== undefined) {
          empty = false;
        }
      });
    }
    return empty;
  };

  static boardWitchColor = () => false;

  static visibleRules = (value) => {};

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get('id');
    // sort by contact value
    return records.sortBy((r) => r.getIn(['values', fieldId, 0, 'contact']));
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
