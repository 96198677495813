import React, { useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import TabRecordsBatchResult from '../../TabRecordsBatchResult';
import RecordsBatchDeleteHeader from '../batchDeleteHeader';
import sceneActions from '../../../../actions/sceneActions';
import { BATCH_RECORDS_STATUS } from '../../../../configs/recordsBatchStatus';
import SCENE_CONTAINER from '../../../../configs/sceneContainer';
import { connect } from '../../../StateProvider';

function RecordsBatchDeleteRecord(props) {
  const { isActive, shouldClose, sceneId, deleting } = props;

  useEffect(() => {
    // сцена не закрывается...
    if (shouldClose && isActive && !deleting) {
      sceneActions.deleteScene(sceneId);
    }
  }, [isActive, shouldClose, sceneId, deleting]);

  return (
    <>
      <RecordsBatchDeleteHeader
        catalog={props.catalog}
        scene={props.scene}
        onPauseUpdate={props.onPauseUpdate}
        onResumeUpdate={props.onResumeUpdate}
        onClose={props.onClose}
        isLoading={props.isLoading}
      />
      <TabRecordsBatchResult
        catalogId={props.catalogId}
        sceneId={props.sceneId}
        descriptionText={props.t('batchDeleteRecords.result.description.deleted')}
      />
    </>
  );
}

export default withTranslation()(
  connect(
    RecordsBatchDeleteRecord,
    {
      modal: ['modal'],
    },
    (props, { modal }) => {
      const { scene, sceneId } = props;
      const deletingStatus = scene.getIn(['data', 'batchStatus']);

      const deleting = deletingStatus === BATCH_RECORDS_STATUS.DELETING;
      const isLoading = deletingStatus === BATCH_RECORDS_STATUS.LOADING;
      const sceneExist = !!scene;
      const shouldClose = sceneExist && scene.get('shouldClose');

      const isActive =
        // WINDOW scenes are always active
        (sceneExist && scene.get('container') == SCENE_CONTAINER.WINDOW) ||
        // only one POPUP scene can be active
        (modal && modal.get('activeScene') === sceneId);

      return {
        ...props,
        deleting,
        isActive,
        isLoading,
        shouldClose,
      };
    },
  ),
);
