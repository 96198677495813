import React from 'react';

import FileRow from './FileRow';

export default class DefaultViewer extends React.Component {
  static viewerName = 'DefaultViewer';

  render() {
    return (
      <div className={this.props.containerClassName}>
        {this.props.files.map((file, i) => (
          <FileRow key={i} {...this.props} file={file} />
        ))}
      </div>
    );
  }
}
