// @flow
import inherits from 'inherits';

import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import RenderUtil from 'diagram-js/lib/util/RenderUtil';
import TextUtil from 'diagram-js/lib/util/Text';

import { append as svgAppend, classes as svgClasses, create as svgCreate, attr as svgAttr } from 'tiny-svg';

import { every, some, assign, isObject } from 'lodash';
import { is } from 'bpmn-js/lib/util/ModelUtil';

import findComponent from '../../../helpers/findComponent';

const { componentsToPath } = RenderUtil;
const TASK_BORDER_RADIUS = 10;

export default (components, t) => {
  function BpiumRenderer(eventBus) {
    BaseRenderer.call(this, eventBus, 1500);

    this.canRender = (element) => element.type !== 'label';

    this.drawShape = (parent, shape) => {
      const { type } = shape;
      const { businessObject } = shape;
      let component = findComponent(businessObject, t);

      if (type === 'bpmn:EndEvent' && businessObject.extensionElements) {
        if (businessObject.extensionElements.$type) {
          const testComponent = components.find(({ element }) => element === businessObject.extensionElements.$type);
          if (testComponent) {
            component = testComponent;
          }
        }
      }

      if (type == 'bpmn:BoundaryEvent') {
        const event = getSemantic(shape);
        if (isTypedEvent(event, 'bpmn:TimerEventDefinition')) {
          const { height } = shape;
          const { width } = shape;
          const icon = svgCreate(
            `<foreignObject
                    x="0"
                    y="0"
                    width="${shape.width}"
                    height="${shape.height}"
                  >
                  <i xmlns="http://www.w3.org/1999/xhtml"
                    class="anticon-icon time-4"
                    style="
                      display:flex;
                      align-items: center;
                      justify-content: center;
                      color:#c42e33;
                      width:${width}px;
                      height:${height}px;
                      box-sizing: border-box;
                      margin-left: 0px;
                      margin-top: 0px;
                      font-size: 13.5px;
                      border-width:1.5px;
                      border-style:solid;
                      background-color: #fff;
                      border-radius:64px;
                      text-align: center;
                      padding-top: 0;
                    "
                  />
                </foreignObject>`,
          );
          svgAppend(parent, icon);

          return false;
        }

        if (isTypedEvent(event, 'bpmn:ErrorEventDefinition')) {
          const { height } = shape;
          const { width } = shape;
          const icon = svgCreate(
            `<foreignObject
                    x="0"
                    y="0"
                    width="${shape.width}"
                    height="${shape.height}"
                  >
                  <i xmlns="http://www.w3.org/1999/xhtml"
                    class="anticon-icon edition-66"
                    style="
                      display:flex;
                      align-items: center;
                      justify-content: center;
                      color:#c42e33;
                      width:${width}px;
                      height:${height}px;
                      box-sizing: border-box;
                      margin-left: 0px;
                      margin-top: 0px;
                      font-size:${width * 0.6}px;
                      border-width:1.5px;
                      border-style:solid;
                      background-color: #fff;
                      border-radius:64px;
                      text-align: center;
                      padding-top: 0;
                      "
                  />
                </foreignObject>`,
          );
          svgAppend(parent, icon);

          return false;
        }
      }

      if (type === 'bpmn:TextAnnotation') {
        drawTextAnotationShape(parent, shape);
        return false;
      }

      if (component) {
        const iconSize = (shape.height / 40) * 26;
        const rectProportion = 1 / Math.sqrt(2);
        let { height } = shape;
        let { width } = shape;
        const marginIconLeft = (component.offset_x / 200) * width + 11 - component.border * 2;
        const marginIconTop = (component.offset_y / 200) * height - 1;
        if (component.class === 'gateway') {
          height = shape.height * rectProportion;
          width = shape.width * rectProportion;
        }
        const border = svgCreate(
          `<foreignObject
            x="${component.class === 'gateway' ? '12' : '0'}"
            y="${component.class === 'gateway' ? '1' : '0'}"
            width="${width}"
            height="${height}"
            ${
              component.class === 'gateway'
                ? `transform = 'rotate(45, ${width / 2 + 1}, ${height / 2 + 1})'`
                : `transform = 'rotate(${component.rotate || 0}, ${width / 2 + 1}, ${height / 2 + 1})'`
            }
          >
            <div xmlns="http://www.w3.org/1999/xhtml"
              style="
                border-width: ${component.border * 2}px;
                border-color: ${component.color};
                border-style: solid;
                border-radius: ${component.class === 'event' ? 40 : 8}px;
                height:${height}px;
                width:${width}px;
                background-color: #ffffff;
              "
            >
            </div>
          </foreignObject>`,
        );

        // svg element's transform is used because html elements' transform works incorrect in Safari
        // more details can be found here: https://stackoverflow.com/a/60577028/5402974
        // the playground can be explored here: https://jsfiddle.net/garipov_/6p7uy13o/
        const icon = svgCreate(
          `<foreignObject
            x="${component.class === 'gateway' ? '12' : '0'}"
            y="${component.class === 'gateway' ? '1' : '0'}"
            width="${width}"
            height="${height}"
            transform = 'rotate(${component.icon_rotate || 0}, ${width / 2 + 1}, ${height / 2 + 1})'
          >
            <div xmlns="http://www.w3.org/1999/xhtml"
              style="
                border-width: ${component.border * 2}px;
                border-color: transparent;
                border-style: solid;
                height:${height}px;
                width:${width}px;
              "
            >
              <i
                class="${component.icon}"
                style="
                  box-sizing: border-box;
                  display: block;
                  color: ${component.color};
                  line-height: ${height}px;
                  font-size: ${(component.icon_scale || 1) * iconSize}px;
                  margin-left:${marginIconLeft + ((1 - (component.icon_scale || 1)) * width) / 2}px;
                  margin-top:${marginIconTop}px;
                "></i>
            </div>
          </foreignObject>`,
        );

        svgAppend(parent, border);
        svgAppend(parent, icon);
        return icon;
      }

      const iconSize = (shape.height / 40) * 10;
      const { height } = shape;
      const { width } = shape;
      const icon = svgCreate(
        `<foreignObject
            x="0"
            y="0"
            width="${shape.width}"
            height="${shape.height}"
          >
          <div
          xmlns="http://www.w3.org/1999/xhtml"
          style="
            display:block;
                color:#ccc;
                width:${width}px;
                height:${height}px;
                box-sizing: border-box;
                margin-left: 0px;
                margin-top: 0px;
                border-color:ccc;
                border-width:${1.3}px;
                border-style:solid;
                border-radius:8px;
                padding-top:8px;
                text-align: center;">
              <span>${shape.type || '?'}</span>
              </div>
        </foreignObject>`,
      );
      svgAppend(parent, icon);

      return false;
    };
    this.getShapePath = function (element) {
      if (is(element, 'bpmn:Event')) {
        return getCirclePath(element);
      }

      if (is(element, 'bpmn:Activity')) {
        return getRoundRectPath(element, TASK_BORDER_RADIUS);
      }

      if (is(element, 'bpmn:Gateway')) {
        return getDiamondPath(element);
      }

      return getRectPath(element);
    };
  }
  inherits(BpiumRenderer, BaseRenderer);
  //
  BpiumRenderer.$inject = ['eventBus', 'elementRegistry'];
  return BpiumRenderer;
};
function getCirclePath(shape) {
  const cx = shape.x + shape.width / 2;
  const cy = shape.y + shape.height / 2;
  const radius = shape.width / 2;

  const circlePath = [
    ['M', cx, cy],
    ['m', 0, -radius],
    ['a', radius, radius, 0, 1, 1, 0, 2 * radius],
    ['a', radius, radius, 0, 1, 1, 0, -2 * radius],
    ['z'],
  ];

  return componentsToPath(circlePath);
}

function getRoundRectPath(shape, borderRadius) {
  const x = shape.x + (shape.width - shape.height) / 2;
  const { y } = shape;
  const width = shape.height;
  const { height } = shape;

  const roundRectPath = [
    ['M', x + borderRadius, y],
    ['l', width - borderRadius * 2, 0],
    ['a', borderRadius, borderRadius, 0, 0, 1, borderRadius, borderRadius],
    ['l', 0, height - borderRadius * 2],
    ['a', borderRadius, borderRadius, 0, 0, 1, -borderRadius, borderRadius],
    ['l', borderRadius * 2 - width, 0],
    ['a', borderRadius, borderRadius, 0, 0, 1, -borderRadius, -borderRadius],
    ['l', 0, borderRadius * 2 - height],
    ['a', borderRadius, borderRadius, 0, 0, 1, borderRadius, -borderRadius],
    ['z'],
  ];

  return componentsToPath(roundRectPath);
}

function getDiamondPath(shape) {
  const { width } = shape;
  const { height } = shape;
  const { x } = shape;
  const { y } = shape;
  const halfWidth = width / 2;
  const halfHeight = height / 2;

  const diamondPath = [
    ['M', x + halfWidth, y],
    ['l', halfWidth, halfHeight],
    ['l', -halfWidth, halfHeight],
    ['l', -halfWidth, -halfHeight],
    ['z'],
  ];

  return componentsToPath(diamondPath);
}

function getRectPath(shape) {
  const x = shape.x + (shape.width - shape.height) / 2;
  const { y } = shape;
  const width = shape.height;
  const { height } = shape;

  const rectPath = [['M', x, y], ['l', width, 0], ['l', 0, height], ['l', -width, 0], ['z']];

  return componentsToPath(rectPath);
}

function drawTextAnotationShape(parentGfx, element) {
  const style = {
    fill: 'rgba(220, 211, 13, 10%)',
    stroke: 'none',
  };

  const textElement = drawRect(parentGfx, element.width, element.height, 0, 0, style);

  const text = getSemantic(element).text || '';
  renderLabel(parentGfx, text, { box: element, align: 'left-top', padding: 5 });

  return textElement;
}

// /////// helper functions /////////////////////////////

function renderLabel(parentGfx, label, options) {
  const LABEL_STYLE = {
    fontFamily: 'Arial, sans-serif',
    fontSize: 12,
  };

  const textUtil = new TextUtil({
    style: LABEL_STYLE,
    size: { width: 100 },
  });

  const text = textUtil.createText(label || '', options);
  svgClasses(text).add('djs-label');
  svgAppend(parentGfx, text);

  return text;
}

function drawRect(parentGfx, width, height, r, offset, attrs) {
  if (isObject(offset)) {
    attrs = offset;
    offset = 0;
  }

  offset = offset || 0;

  attrs = assign(
    {
      stroke: 'black',
      strokeWidth: 2,
      fill: 'white',
    },
    attrs,
  );

  const rect = svgCreate('rect');
  svgAttr(rect, {
    x: offset,
    y: offset,
    width: width - offset * 2,
    height: height - offset * 2,
    rx: r,
    ry: r,
  });
  svgAttr(rect, attrs);

  svgAppend(parentGfx, rect);

  return rect;
}

/**
 * Checks if eventDefinition of the given element matches with semantic type.
 *
 * @return {boolean} true if element is of the given semantic type
 */
function isTypedEvent(event, eventDefinitionType, filter) {
  function matches(definition, filter) {
    return every(
      filter,
      (val, key) =>
        // we want a == conversion here, to be able to catch
        // undefined == false and friends
        /* jshint -W116 */
        definition[key] == val,
    );
  }

  return some(
    event.eventDefinitions,
    (definition) => definition.$type === eventDefinitionType && matches(event, filter),
  );
}

function isThrowEvent(event) {
  return event.$type === 'bpmn:IntermediateCatchEvent' || event.$type === 'bpmn:EndEvent';
}

function isCollection(element) {
  const dataObject = element.dataObjectRef;

  return element.isCollection || (dataObject && dataObject.isCollection);
}

function getDi(element) {
  return element.businessObject.di;
}

function getSemantic(element) {
  return element.businessObject;
}
