import { InputMappers, OutputMappers } from '../helpers/mappers';
import i18n from '../../../../../configs/i18n';
import { TIME, DATE, WEEK, MONTH, QUARTER, YEAR } from '../../../../../configs/dateFieldSubTypes';

const Properties = (field, t = i18n.t) => {
  const config = field.get('config');
  const trsPrefix = `catalogEditor.fieldProperties.configProperties.field.${field.get('type')}.`;

  return {
    properties: [
      {
        id: 'configPropertiesSection',
        name: t('catalogEditor.fieldProperties.configProperties.section'),
        type: 'group',
      },
      {
        id: 'config.type',
        name: t(`${trsPrefix}type.label`),
        type: 'radiobutton',
        readOnly: false,
        config: {
          mode: 'dropdown',
          items: [
            { id: DATE, name: t(`${trsPrefix}type.items.${DATE}`) },
            { id: TIME, name: t(`${trsPrefix}type.items.${TIME}`) },
            { id: WEEK, name: t(`${trsPrefix}type.items.${WEEK}`) },
            { id: MONTH, name: t(`${trsPrefix}type.items.${MONTH}`) },
            { id: QUARTER, name: t(`${trsPrefix}type.items.${QUARTER}`) },
            { id: YEAR, name: t(`${trsPrefix}type.items.${YEAR}`) },
          ],
        },
      },
      {
        id: 'config.defaultValue',
        name: t(`${trsPrefix}defaultValue.label`),
        type: 'checkboxes',
        readOnly: false,
        config: {
          items: [
            {
              id: 'true',
              name: t(`${trsPrefix}defaultValue.value`),
            },
          ],
        },
      },
    ],

    values: {
      'config.type': config.get('type') || DATE,
      'config.defaultValue': InputMappers.boolToCheckbox(config.get('defaultValue')),
    },

    getValues: (propery, value) => {
      switch (propery) {
        case 'config.defaultValue':
          value = OutputMappers.checkboxToBool(value);
          break;
      }
      return { [propery]: value };
    },
  };
};

export default Properties;
