import { Link } from 'react-router-dom';
import cn from 'classnames';
import React from 'react';
import _ from 'lodash';

import Icon from '../Icon';
import Badge from '../Badge';
import Menu from '../Menu';
import NavLink from '../../router/Link';

import styles from './styles.less';

function TabsMenu({ ...props }) {
  const {
    activeId,
    items,
    route,
    params,
    extParams = {},
    dragType,
    canDrag,
    canDrop,
    onDrop,
    onMouseEnter,
    onMouseLeave,
    className,
    itemClassName,
    popupClassName,
    filterFn,
    onClick,
  } = props;

  const getIcon = (item) => {
    if (_.isObject(item.get('icon'))) {
      return React.cloneElement(item.get('icon'), { className: cn(styles.icon, item.get('icon').props.className) });
    }
    if (item.get('icon')) {
      return <Icon type={`icon ${item.get('icon')}`} className={styles.icon} />;
    }
  };
  const getTextName = (item) => {
    if (item.get('badge') && item.get('name')) {
      return (
        <Badge dot={item.get('badge') === true} count={item.get('badge')} className={styles.iconBadge}>
          <span
            className={item.get('error') ? styles.textError : styles.text}
            onMouseEnter={() => onMouseEnter && onMouseEnter(item)}
            onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
          >
            {item.get('name')}
          </span>
        </Badge>
      );
    }
    if (item.get('name')) {
      return (
        <span
          className={item.get('error') ? styles.textError : styles.text}
          onMouseEnter={() => onMouseEnter && onMouseEnter(item)}
          onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
        >
          {item.get('name')}
        </span>
      );
    }
  };

  const getNameActive = (item) => (
    <span
      className={styles.text}
      onMouseEnter={() => onMouseEnter && onMouseEnter(item)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
    >
      {item.get('nameActive')}
    </span>
  );
  const getNavLink = (item, linkParams, title, icon, color, textActive, text) => (
    <NavLink
      key={item.get('id')}
      route={item.get('route') || route}
      params={linkParams}
      render={(props) => (
        <Link
          onClick={(e) => onClick && onClick(item, e)}
          to={props.link}
          title={title}
          className={cn(styles.link, {
            [styles.selected]: activeId ? activeId === item.get('id') : props.isActive,
          })}
          style={{ color: color || 'inherit' }}
        >
          <div className={styles.data}>
            {icon}
            {props.isActive ? textActive : text}
          </div>
        </Link>
      )}
    />
  );
  const getNativeLink = (item, title, icon, color, text) => (
    <a
      key={item.get('id')}
      rel="noreferrer"
      className={cn(styles.link, styles.addButton, {
        [styles.selected]: activeId === item.get('id'),
      })}
      style={{ color: color || 'inherit' }}
      onClick={(e) => {
        const callFn = item.get('onClick');
        callFn && callFn();
        onClick && onClick(item.get('id'), item, e);
      }}
      title={title}
    >
      <div className={styles.data}>
        {icon}
        {text}
      </div>
    </a>
  );

  const getItems = (items) =>
    items.map((item, i) => {
      const icon = item.get('icon') ? getIcon(item) : null;
      const title = item.get('title') || item.get('name');
      const text = item.get('name') && getTextName(item);
      const textActive = (item.get('nameActive') && getNameActive(item)) || text;
      const linkWithRoute = item.get('route') || (route && !item.get('onClick'));
      const color = item.get('color');

      if (linkWithRoute) {
        const linkParams = {
          ...extParams,
          ...(item.get('params') && { [item.get('params')]: item.get('id') }),
          ...(params && { [params]: item.get('id') }),
        };
        return getNavLink(item, linkParams, title, icon, color, textActive, text);
      }
      return getNativeLink(item, title, icon, color, textActive, text);
    });

  return (
    <Menu
      items={getItems(items)}
      activeId={activeId}
      className={cn(styles.menu, className)}
      itemClassName={cn(styles.item, itemClassName)}
      popupClassName={cn(styles.popup, popupClassName)}
      filterFn={filterFn}
      dragType={dragType}
      canDrag={canDrag}
      canDrop={canDrop}
      onDrop={onDrop}
    />
  );
}

export default TabsMenu;
