import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import { Row, Button, Popover } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withTranslation } from 'react-i18next';

import Icon from '../../../common/UI/Icon';
import ButtonClose from '../../../common/UI/ButtonClose';
import DebouncedInput from '../../../common/DebouncedInput';
import FilterList from '../../../Filters';
import { connect } from '../../../StateProvider';
import importActions from '../../../../actions/importActions';

import styles from '../ImportStyles.less';

class ImportHeader extends Component {
  static propTypes = {
    catalogId: PropTypes.string,
    filterFields: ImmutablePropTypes.list,
    filters: ImmutablePropTypes.map,
    closeModal: PropTypes.func,
    loading: PropTypes.bool,
    savingMessages: ImmutablePropTypes.map,
  };

  _setImportFilters = _.debounce((...args) => importActions.setImportFilters(...args));

  _setImportSearchText = _.debounce((...args) => importActions.setImportSearchText(...args));

  setSearchText = (searchText) => {
    const { loading } = this.props;

    if (loading) {
      return;
    }

    this._setImportSearchText(searchText);
  };

  setFilters = (filterId, fieldId, value) => {
    const { loading } = this.props;

    if (loading) {
      return;
    }

    this._setImportFilters(filterId, fieldId, value);
  };

  render() {
    const { catalogId, filterFields, filters, closeModal, t } = this.props;

    return (
      <Row type="flex" justify="space-between" className={styles.modalHeader}>
        <div className={styles.headerMainPart}>
          <h2 className={styles.headerTitle}>{t('import.header')}</h2>
          <Popover
            placement="bottomLeft"
            trigger="click"
            // onFocus={this.onFocus}
            getPopupContainer={() => document.getElementById('importTable')}
            content={
              <FilterList
                catalogId={catalogId}
                fields={filterFields}
                filters={filters}
                className={styles.filterList}
                onSave={this.setFilters}
              />
            }
          >
            <Button key="filters" className={styles.filtersPopoverButton} title={t('views.filters')}>
              <Icon className={styles.icon} type="icon content-47" />
            </Button>
          </Popover>
          <DebouncedInput
            key="search"
            placeholder={t('catalogData.quickSearch')}
            className={styles.search}
            onSave={this.setSearchText}
          />
        </div>

        <ButtonClose key="close" large shiftRight onClick={closeModal} />
      </Row>
    );
  }
}

export default connect(withTranslation()(ImportHeader), {
  filterFields: ['import', 'filterFields'],
  queryParams: ['import', 'queryParams', 'filters'],
  loading: ['import', 'loading'],
});
