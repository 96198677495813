import Immutable from 'immutable';
import i18n from '../configs/i18n';

/* not using */
export default {
  getFilterKeysCompleted(keys) {
    this.set('filterKeys', Immutable.fromJS({}));

    let list = new Immutable.List(
      keys
        .filter((key) => key.match(/DATE$/))
        .map((key) =>
          Immutable.fromJS({
            key,
            value: i18n.t(`filter.keys.${key}`),
          }),
        ),
    );
    this.setIn(['filterKeys', 'date_ranges'], list);

    list = new Immutable.List(
      keys
        .filter((key) => key.match(/USER$/))
        .map((key) =>
          Immutable.fromJS({
            key,
            value: i18n.t(`filter.keys.${key}`),
          }),
        ),
    );
    this.setIn(['filterKeys', 'users'], list);

    this.changed();
  },
};
