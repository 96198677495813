import React, { Component, lazy, Suspense } from 'react';
import cn from 'classnames';
import { matchPath, withRouter } from 'react-router';

import qs from 'qs';
import _ from 'lodash';
import { connect } from '../StateProvider';
import routes from '../../routes';
import color from '../../utils/colorFromString';

import LayoutClient from './layoutClient';

import styles from './layout.less';
import location from '../../utils/location';

// import AuthPage from "../authForms";
import Loading from '../common/Loading';

const AuthPage = lazy(() => import('../authForms'));

class Layout extends Component {
  UNSAFE_componentWillMount() {
    const parentStrings = qs.parse(window.location.search);
    const { login, password } = parentStrings;
    if (!_.isEmpty(login) && !_.isEmpty(password)) {
      location.login();
    }
  }

  render() {
    // for styles on page load before css file is loaded
    const __html = `
      html, body, #root {
        width: 100%;
        height: 100vh;
        min-height: 100%;
        padding: 0;
        margin: 0;
      }
      #root {
        position: relative;
        background: ${color.colorAppBackground() || '#404950'};
        transition: 0.5s background;
        opacity: 0;
      }
      #root:before {
        content: "";
        background: radial-gradient(circle, rgba(64, 73, 80, .8) 60%, #404950 100%);
        position: absolute;
        width: 100%;
        height: 100%;
      }
      #root #auth {
        position: relative;
      }
    `;

    // Check if we trying to enter auth routes
    const authMatch = matchPath(this.props.location.pathname, {
      path: routes.auth.path,
      exact: false,
      strict: false,
    });

    return (
      <div
        id="base"
        className={cn(
          {
            'no-select': this.props.dragging,
          },
          styles.section,
        )}
      >
        <style dangerouslySetInnerHTML={{ __html }} />
        {authMatch ? (
          <Suspense fallback={<Loading />}>
            <AuthPage />
          </Suspense>
        ) : (
          <LayoutClient />
        )}
      </div>
    );
  }
}

export default connect(withRouter(Layout), {
  dragging: ['dragging'],
});
