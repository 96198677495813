import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import _ from 'lodash';

import { Row } from 'antd';

import filterActions from '../../../../../../../actions/filterActions';
import { connect } from '../../../../../../StateProvider';
import Filters from '../../../../../../Filters';
import filtersUtils from '../../../../../../../utils/filters';
import UrlParamSync from '../../../../../../UrlParamSync';

import getLink from '../../../../../../common/router/getLink';
import routes from '../../../../../../../routes';

import styles from './catalogBody.less';

class FilterList extends React.Component {
  static propTypes = {
    catalog: PropTypes.object,
    viewId: PropTypes.string,
    sceneId: PropTypes.string,
    additionalFilters: ImmutablePropTypes.map,
    catalogId: PropTypes.string,
    withHeader: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  onSave = (filterId, fieldId, value) => {
    const { sceneId, catalogId, viewId } = this.props;
    filterActions.updateFieldFilter(
      {
        sceneId,
        catalogId,
        viewId,
        fieldId,
        filterId,
      },
      value,
    );
  };

  clearFilters = () => {
    this.onFiltersChange(Immutable.Map());
    this.props.history.push(getLink(this.props.location, routes.records, { viewId: 0 }));
  };

  onFiltersChange = (filters) => {
    const { sceneId, catalogId, viewId } = this.props;
    filterActions.updateFieldFilters({ sceneId, catalogId, viewId }, filters);
  };

  render() {
    const { sceneId, catalog, viewId, fields, withHeader, filters, readOnly, filtersChanged, t } = this.props;

    if (!(fields && fields.size)) {
      return null;
    }

    return (
      <div className={styles.filtersContainer}>
        {withHeader ? (
          <Row align="middle" className={styles.filtersHeader}>
            {t('filter.header')}
          </Row>
        ) : null}
        <div className={styles.filtersData}>
          <Filters
            catalogId={catalog.get('id')}
            ownerId={viewId != '$new' ? viewId : undefined} // to prevent close opened filters
            fields={fields}
            filters={filters}
            readOnly={readOnly}
            withExtendedFilters
            onSave={this.onSave}
          />
        </div>
        <UrlParamSync
          name="filters"
          value={filtersChanged ? filters : undefined}
          base64
          onChange={this.onFiltersChange}
        />
      </div>
    );
  }
}

FilterList.defaultProps = {
  withHeader: true,
  readOnly: false,
};

export default withTranslation()(
  connect(
    withRouter(FilterList),
    {
      scenes: ['scenes'],
    },
    (props, { scenes }) => {
      const { additionalFilters, catalog } = props;
      const catalogId = catalog && catalog.get('id');
      const view = scenes.getIn([props.sceneId, 'views', props.viewId]);
      const fields = catalog && catalog.get('fields');
      const filtersChanged = view && view.get('filtersChanged');
      let filters = view && view.get('filters');

      if (additionalFilters) {
        filters = filtersUtils.mergeFilters(filters, additionalFilters);
      }

      return {
        filters,
        fields,
        catalogId,
        filtersChanged,
        ...props,
      };
    },
  ),
);
