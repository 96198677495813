import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../Icon';
import ButtonTransparent from '../ButtonTransparent';

import styles from './table.less';

export default class SortHeaderCell extends Component {
  static propTypes = {
    sorting: PropTypes.number,
    columnKey: PropTypes.string,
    onSortChange: PropTypes.func,
  };

  state = {
    sorting: this.props.sorting,
    hovering: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sorting !== this.props.sorting) {
      this.setState({
        sorting: nextProps.sorting,
      });
    }
  }

  onSortChange = (e) => {
    if (this.props.onSortChange) {
      this.props.onSortChange(this.props.columnKey, this.state.sorting !== 0 ? -1 * this.state.sorting : 1);
    }
  };

  onHover = (e) => {
    this.setState({ hovering: !this.state.hovering });
  };

  render() {
    const { onSortChange, children, sorting, sortHeaderCellClassName, ...props } = this.props;
    let iconProps;

    sorting === 1 || !sorting
      ? (iconProps = {
          type: 'icon arrows-chevron-medium-1-01',
        })
      : (iconProps = {
          type: 'icon arrows-chevron-medium-2-01',
        });

    let spanProps;
    if (sorting) {
      spanProps = {
        style: { maxWidth: this.props.width - 34 }, // for icon
      };
    } else {
      spanProps = {
        style: { maxWidth: this.props.width },
      };
    }

    return (
      <Cell className={this.props.className} {...props} onMouseEnter={this.onHover} onMouseLeave={this.onHover}>
        <span {...spanProps} className={cn(sortHeaderCellClassName || styles.headerCell)}>
          {children}
        </span>
        {onSortChange && (sorting || this.state.hovering) ? (
          <ButtonTransparent
            className={cn(styles.headerSortButton, { [styles.headerSortButtonSorting]: sorting })}
            onClick={this.onSortChange}
          >
            <Icon {...iconProps} />
          </ButtonTransparent>
        ) : null}
      </Cell>
    );
  }
}
