import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withTranslation } from 'react-i18next';

import trs from '../../../../../../getTranslations';
import i18n from '../../../../../../configs/i18n';

import Dropdown from '../../../../../common/Dropdown';

const EMPTY_VALUE = Symbol('empty value');

function getValueKey({ type, subType, value }) {
  return type + (subType || '') + (value || '');
}

class BasicSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableValues: this._getAvailableValues(this.props.fields),
    };
  }

  _getAvailableValues = (fields) => {
    const values = [];

    const availableValues = this.getAvailableValues(fields).map((value) => ({
      ...value,
      key: getValueKey(value),
      text: value.title,
    }));

    if (!this.getDefaultValue || !availableValues) {
      values.push({
        key: 'null',
        type: EMPTY_VALUE,
        value: null,
        text: i18n.t('records.cards.config.nothing'),
        sort: -1,
      });
    }
    return values.concat(availableValues);
  };

  onChange = (item) => {
    const returnValue =
      item && item.type !== EMPTY_VALUE
        ? Immutable.fromJS({
            type: item.type,
            subType: item.subType,
            value: item.value,
          })
        : null;
    this.props.onChange(returnValue);
  };

  onChangeSelect(item) {
    // const valueKey = e.target.value;
    // const selectedAvailableValue = _.find(this.state.availableValues, {key: valueKey});
    this.onChange(item);
  }

  UNSAFE_componentWillReceiveProps({ fields, value }) {
    if (this.props.fields !== fields) {
      this.setState({
        availableValues: this._getAvailableValues(fields),
      });
    }
  }

  UNSAFE_componentWillMount() {}

  render() {
    let { fields, value } = this.props;
    let selectedValueKey;
    const { availableValues } = this.state;

    if (value) {
      value = value.toJS ? value.toJS() : value;
      selectedValueKey = getValueKey(value);
    } else if (!value && this.getDefaultValue) {
      const defaultValue = this.getDefaultValue(fields);
      selectedValueKey = defaultValue && getValueKey(defaultValue);
    }

    return (
      <Dropdown
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        items={availableValues}
        multiselect={false}
        value={selectedValueKey}
        autocomplete
        withButton
        onSelectItems={([item]) => this.onChangeSelect(item)}
      />
    );
  }
}

BasicSelect.propTypes = {
  fields: ImmutablePropTypes.list.isRequired,
  onChange: PropTypes.func.isRequired,
  value: ImmutablePropTypes.map,
};

export default BasicSelect;
