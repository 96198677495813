import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import Immutable from 'immutable';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import cn from 'classnames';

import LoadingSpin from './LoadingSpin';

import styles from './controls.less';

const { Option } = Select;

class Dropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filteredItems: this.props.items,
      items: [],
      open: undefined,
      valueSearch: undefined,
      isSelect: false,
    };
  }

  componentDidMount() {
    this.prepareItems();
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    const { items: prevItems } = prevProps;

    if (prevItems && prevItems !== items) {
      this.prepareItems();
    }
  }

  toggleList = (isOpen) => {
    if (typeof this.props.onOpenChange === 'function') {
      this.props.onOpenChange(isOpen);
    }
  };

  clearValue = () => this.props.onSelectItems(Immutable.List());

  onFocusInput = () => {
    this.toggleList(true);
  };

  onBlurInput = () => {
    this.toggleList(false);
    this.setState({ open: undefined, isSelect: false });
  };

  onSearch = (e) => {
    if (!this.props.showSearch) return;

    let currentValue = (typeof e === 'string' && e) || (e.target && e.target.value) || '';

    const prevValueSearch = this.state.valueSearch;

    // если до этого было выбрано значение из выпадающего списка,
    // то проверяем, что присходит с полем поиска
    if (this.state.isSelect && prevValueSearch) {
      if (prevValueSearch !== currentValue) {
        // удалили или ввели
        if (currentValue.length > prevValueSearch.length) {
          // тут удаляется старое значение текста в поиске и остается введеное
          currentValue = currentValue.replace(prevValueSearch, '');
        } else {
          currentValue = '';
        }
        this.setState({ valueSearch: currentValue, isSelect: false });
      } else {
        this.setState({ valueSearch: currentValue });
      }

      this.props.onTextChange && this.props.onTextChange(currentValue);
      return;
    }

    this.props.onTextChange && this.props.onTextChange(currentValue);
    this.setState({ valueSearch: currentValue });
  };

  filterOption(inputValue, option) {
    if (option.props.filterable === false) {
      return option;
    }
    const splitedKey = option.key && option.key.split(':');
    const includesInTitile = option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    const includesInParams = splitedKey && splitedKey[1] == inputValue;
    return includesInTitile || includesInParams;
  }

  onChangeSelect = (value, option) => {
    if (option.props && option.props.filterable === false) {
      return;
    }

    if (this.props.multiselect) {
      if (!value.length) return this.clearValue();
      value = value.map((key) => this.props.items.find((item) => item.key === key));
      this.props.onSelectItems(value);
    } else {
      if (!value) return this.clearValue();
      const key = this.valueWithLabel() ? value.key : value;
      value = this.props.items.filter((item) => item.key === key);
      this.props.onSelectItems(value);
    }

    if (this.props.openedSelect) {
      this.setState({ open: false });

      if (this.props.showSearch) {
        // нужно для запоминания введенного значения в поиске селекта, тк
        // при выборе элемента из списка сбрасывается поле поиска
        // doc: "autoClearSearchValue - Whether the current search will be cleared on selecting an item."
        //         ...Only applies when mode is set to multiple or tags"
        const prevValueSearch = this.state.valueSearch;
        setTimeout(() =>
          this.setState({
            open: true,
            valueSearch: prevValueSearch,
            isSelect: true,
          }),
        );
      } else {
        setTimeout(() => this.setState({ open: true, isSelect: true }));
      }
    }
  };

  valueWithLabel = () => {
    const { value } = this.props;
    return !!(value && value.key && value.label);
  };

  prepareItems = () => {
    let { items, optionsClassName } = this.props;
    items =
      items &&
      items
        .filter((item) => !!item)
        .map((item) => {
          if (item.children) {
            return (
              <Option
                key={`add:${item.key}`}
                title={item.text} // на всякий случай
                selectable={item.selectable !== undefined ? item.selectable : true}
                className={optionsClassName}
              >
                {item.children({
                  linkProps: {
                    className: styles.itemClickLink,
                    children: item.text,
                  },
                })}
                &nbsp;
              </Option>
            );
          }
          return (
            <Option
              key={item.key}
              title={item.text}
              selectable={item.selectable !== undefined ? item.selectable : true}
              className={optionsClassName}
            >
              {item.text}
              &nbsp;
              {item.subText && <span className={styles.itemSubText}>({item.subText})</span>}
            </Option>
          );
        });

    this.setState(() => ({
      items,
    }));
  };

  render() {
    const { items, isSelect } = this.state;

    const showArrow = typeof this.props.showArrow !== undefined ? this.props.showArrow : true;

    return (
      <div className={styles.dropdown}>
        <Select
          className={cn(this.props.className, {
            [styles.invisibleInputSearch]: isSelect,
          })}
          bordered={this.props.bordered}
          labelInValue={this.valueWithLabel()}
          defaultOpen={this.props.open}
          open={this.state.open}
          title={this.props.title}
          disabled={this.props.disabled}
          // в antd 4.x 3 состояния "multiple", "tags", "undefined"
          mode={this.props.multiselect ? 'multiple' : undefined}
          popupClassName={this.props.dropdownClassName}
          style={{ width: '100%' }}
          placeholder={this.props.placeholder}
          notFoundContent=""
          // в antd 4.x если value === undefined, то value берется первым значением Option
          value={this.props.value === undefined ? null : this.props.value}
          searchValue={this.state.valueSearch}
          showSearch={this.props.showSearch}
          autoFocus={this.props.autoFocus}
          dropdownMatchSelectWidth={false}
          showArrow={showArrow}
          suffixIcon={<DownOutlined />}
          onFocus={this.onFocusInput}
          onBlur={this.onBlurInput}
          onChange={this.onChangeSelect}
          onSearch={this.onSearch}
          filterOption={this.props.showSearch ? this.filterOption : null} /* filterable */
          getPopupContainer={this.props.getPopupContainer}
          dropdownStyle={{ maxWidth: '300px' }}
        >
          {items}
        </Select>
        {this.props.showLoading && (
          <span className={styles.inputLoadingSpinner}>
            <LoadingSpin />
          </span>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  additionalClickItems: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelectItems: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func,
  onTextChange: PropTypes.func,
  showLoading: PropTypes.bool,
  className: PropTypes.string,
  showArrow: PropTypes.bool,
};

export default Dropdown;
