import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ButtonTransparent from '../../UI/ButtonTransparent';
import uiActions from '../../../../actions/uiActions';
import { connect } from '../../../StateProvider';

import styles from './styles/helper.less';

function HelperToggler({ isOpen, href = '', code = '' }) {
  const { t } = useTranslation();
  const onClick = () => {
    uiActions.toggleHelper(!isOpen);
    code && uiActions.setHelperCode(code);
  };

  if (href) {
    return (
      <ButtonTransparent className={styles.mainWidgetButton} light title={t('helper.title')}>
        <a href={href} target="_blank" className={cn('anticon-icon interface-56', styles.icon)} rel="noreferrer" />
      </ButtonTransparent>
    );
  }

  return (
    <ButtonTransparent className={styles.mainWidgetButton} light title={t('helper.title')} onClick={onClick}>
      <span className={cn('anticon-icon interface-56', styles.icon)} />
    </ButtonTransparent>
  );
}

export default connect(HelperToggler, { isOpen: ['ui', 'helper', 'isOpen'] });
