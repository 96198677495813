import Immutable from 'immutable';
import _ from 'lodash';
import CatalogFactory from '../models/CatalogFactory';
import ViewFactory from '../models/ViewFactory';
import apiActions from '../actions/apiActions';
import modalsActions from '../actions/modalsActions';
import viewActions from '../actions/viewActions';
import antiCapitalize from '../utils/antiCapitalize';
import i18n from '../configs/i18n';
import userSettingsActions from '../actions/userSettingsActions';

import appState from '../appState';
import SCENE_TYPE from '../configs/sceneTypes';

let init = false;

// let createView = _.debounce(function (catalogId, data) {
//   apiActions.createView({ catalogId }, data);
// }, 1);
const saveDebouncedView = _.debounce((catalogId, viewId, data, sectionId) => {
  apiActions.updateView({ catalogId, viewId }, data, sectionId);
}, 1);

export default {
  init() {
    if (init) return;
    // // view mixin
    // this.listenTo(viewActions.selectView, this.selectView);
    // this.listenTo(viewActions.preGetView, this.preGetView);
    this.listenToMany(viewActions);

    // this.joinTrailing(
    //  apiActions.getCatalog.completed,
    //  apiActions.getViews.completed,
    //  this.getCatalogAndViewsCompleted
    // );
    init = true;
  },

  // getCurrentView() {
  //   let currViewId = this.getIn(['currentCatalog', 'currentViewId']);
  //   let view = this.getIn(['currentCatalog', 'views']).filter(v => v.get('id') !== currViewId);
  //   // todo: or if new ?
  //   return view;
  // },

  preGetView({ viewId, catalogId, sceneId }) {
    // delete newView if selected other view
    if (viewId !== '$new') {
      this.deleteIn(['scenes', sceneId, 'views', '$new']);
      this.changed();
    }

    // clear filtersChanged on each view except this
    const views = this.getIn(['scenes', sceneId, 'views']);
    views &&
      views.forEach((view) => {
        if (view.get('id') !== viewId && view.get('filtersChanged')) {
          this.setIn(['scenes', sceneId, 'views', view.get('id'), 'filtersChanged'], false);
        }
      });

    // call getView when we know that view not changing
    // eslint-disable-next-line no-empty
    if (Number(viewId) === 0 || viewId === '$new') {
    } else if (!this.getIn(['scenes', sceneId, 'views', viewId, 'filtersChanged'])) {
      // pass apiActions
      apiActions.getView({ viewId, catalogId }, undefined, sceneId);
    }
  },

  getView({ catalogId, viewId }) {},

  getViewCompleted(body, { catalogId, viewId }) {
    // create catalog if not exists
    let catalog = this.getIn(['catalogs', catalogId]);
    if (!catalog) {
      catalog = CatalogFactory.create({ id: catalogId });
      this.setIn(['catalogs', catalogId], catalog);
    }

    // create views if not exists
    if (!this.getIn(['catalogs', catalogId, 'views'])) {
      this.setIn(['catalogs', catalogId, 'views'], Immutable.Map());
    }

    body.filters = this._parseFilters(body.filters);

    // set view to catalog
    body.catalogId = catalogId;
    let view = ViewFactory.create(body);
    this.setIn(['catalogs', catalogId, 'views', viewId], view);

    // set view to all scenes that has same view (but not modified)
    this.get('scenes').map((scene, key) => {
      if (scene) {
        const sceneCatalogId = scene.getIn(['params', 'catalogId']);
        const changed = scene.getIn(['views', viewId, 'filtersChanged']);
        const filters = scene.getIn(['views', viewId]);

        const sceneExist = this.getIn(['scenes', key]);
        // const hasView = this.getIn(["views", viewId]);

        if (catalogId === sceneCatalogId && sceneExist) {
          // при обновлении старничники с query параментров приходят актуальные фильтры, поэтому их мерджим.
          if (changed) {
            view = view.merge(filters);
            this.setIn(['scenes', key, 'views', viewId], view);
          } else {
            this.setIn(['scenes', key, 'views', viewId], view);
          }
        }
      }
    });
    this.changed();
  },

  /**
   * Create "virtual" view and set on top.
   */
  getViewsCompleted(body, params, data, query, res, actionParams) {
    const { catalogId } = params;
    let views = Immutable.Map();

    if (this.getIn(['scenes', actionParams.sceneId])) {
      this.mergeDeepIn(['scenes', actionParams.sceneId, 'views'], views);
    }

    // create map of views
    body.forEach((v, i) => {
      v.catalogId = catalogId;
      const id = v.id.toString();
      v.index = i;
      v = ViewFactory.create(v);
      views = views.set(id, v);
    });
    this.mergeDeepIn(['catalogs', catalogId, 'views'], views);
    // this.mergeDeepIn(["scenes", actionParams.sceneId, "views"], views);
    this.setIn(['catalogs', catalogId, 'viewsLoaded'], true); /* ??? */
    this.changed();
  },

  getAllViewsCompleted(data) {},

  _parseFilters(filters) {
    /**
     * filters: filters map
     * convert filters like: [{attr|fieldId: "10", value: "test"}, ] -> {10: "test", }
     * "attr" is obsoled, fieldId is new correct param
     */
    const that = this;
    const result = _.reduce(
      filters,
      (hash, { value, attr, fieldId }) => {
        fieldId = fieldId || attr;

        // extended filters
        if (_.isArray(value)) {
          value = _.map(value, (i) => {
            if (i.filters) {
              i.filters = this._parseFilters(i.filters);
            }
            return i;
          });
        }

        if (hash[fieldId]) {
          hash[fieldId].push({ value });
        } else {
          hash[fieldId] = [
            {
              value,
            },
          ];
        }
        return hash;
      },
      {},
    );

    return result;
  },

  _prepareFilters(filters) {
    /**
     * filters: immutable filters map
     * convert filters like: {10: "test", } -> [{attr|fieldId: "10", value: "test"}, ]
     * * "attr" is obsoled, fieldId is new correct param
     */
    const _filters = filters.toJS ? filters.toJS() : filters;

    const result = _.reduce(
      _filters,
      (result, filter, fieldId) => {
        _.forEach(filter, ({ value }) => {
          // extended filters
          if (_.isArray(value)) {
            value = _.map(value, (i) => {
              if (i.filters) {
                i.filters = this._prepareFilters(i.filters);
              }
              return i;
            });
          }

          result.push({
            fieldId,
            attr: fieldId, // to support prev versions of API. TODO: Need to be remove
            value,
          });
        });

        return result;
      },
      [],
    );
    return result;
  },

  /**
   * request on create new view filters.
   */

  createNewView(sceneId, viewId, data, resolveFn) {
    const filters = this.getSceneFilters({ sceneId, viewId });
    if (filters) {
      data.filters = this._prepareFilters(filters);

      const catalogId = this.getIn(['scenes', sceneId, 'params', 'catalogId']);
      apiActions.createView({ catalogId }, data, { sceneId }).then(resolveFn);
    }
  },

  updateExistingView(sceneId, view, params, filters, resolveFn) {
    const viewId = view.get('id');
    const data = { ...params };

    const catalogId = this.getIn(['scenes', sceneId, 'params', 'catalogId']);
    filters = filters.toJS ? filters.toJS() : filters;
    data.filters = this._prepareFilters(filters);

    apiActions
      .updateView(
        {
          catalogId,
          viewId,
          forRights: view.get('forRights'),
        },
        data,
        { sceneId },
      )
      .then(resolveFn);
  },

  createViewCompleted(body, params, data, query, res, actionParams) {
    // Update new ViewItem.
    const { catalogId } = params;
    const { sceneId } = actionParams;

    let newView = this.getIn(['scenes', sceneId, 'views', '$new']) || Immutable.Map();

    if (this.getIn(['scenes', sceneId, 'views', '$new'])) {
      this.deleteIn(['scenes', sceneId, 'views', '$new']);
    }

    const { id: viewId, name, fieldPrivilegeCodes, forRights, originName } = body;

    newView = newView
      .set('id', viewId)
      .set('catalogId', catalogId)
      .set('name', name)
      .set('originName', originName || name)
      .set('displayName', newView.get('isAdmin') ? originName : name)
      .set('fieldPrivilegeCodes', fieldPrivilegeCodes)
      .set('forRights', forRights);

    // set created view to catalog
    this.setIn(['catalogs', catalogId, 'views', viewId], newView);

    /* устанавливаем новый вид во все сцены, с определенным каталогом */
    this.get('scenes').forEach((scene) => {
      if (scene.getIn('params', 'catalogId') === catalogId) {
        this.setIn(['scenes', sceneId, 'views', viewId], newView);
      }
    });
    /* 
    // set created view to scene, from which it was created
    if (this.getIn(["scenes", sceneId])) {
      this.setIn(["scenes", sceneId, "views", viewId], newView);
    } */

    // open modalAccess if it's rightsView.
    if (data.forRights) {
      modalsActions.openViewAccessModal(newView, false);
    }

    this.changed();
  },

  // find view by id and update name and rights.
  updateViewCompleted(body, params, data, query, res, actionParams, resolveFn) {
    const { catalogId } = params;
    const { viewId } = params;
    const { sceneId } = actionParams;

    const isAdmin = this.getIn(['catalogs', catalogId, 'views', viewId, 'isAdmin']);

    const view = this.getIn(['catalogs', catalogId, 'views', viewId]);
    // changed forRights:  private -> rights
    const rightsChanged = body ? !params.forRights && body.forRights : false;

    if (rightsChanged) {
      modalsActions.openViewAccessModal(view, false);
    }
    this.setIn(['scenes', sceneId, 'views', viewId, 'filtersChanged'], false);
    // тк ответ на этот запрос не всегда включает себя параметр body (хз почему), обновим вид, получив его заново
    apiActions.getView({ catalogId, viewId }, null, actionParams);
  },

  getCatalogCompleted(catalogData, { catalogId }) {
    const catalogName = antiCapitalize(String(catalogData.name).trim());

    if (this.getIn(['catalogs', catalogId, 'views', '0'])) {
      this.setIn(['catalogs', catalogId, 'views', '0', 'name'], `${i18n.t('views.list.all')} ${catalogName}`);
    }
    this.changed();
  },

  truncateView(params) {
    // after update viewId we can doubled viewIds in store
    const viewsCatalogKey = ['catalogs', params.catalogId, 'views'];
    this.deleteIn([...viewsCatalogKey, params.viewId]);
    // remove scene view
    if (params.sceneId) {
      const catalogSceneViewPath = ['scenes', params.sceneId, 'views'];
      this.deleteIn([...catalogSceneViewPath, params.viewId]);
    }
    this.changed();
  },

  deleteView({ catalogId, viewId, sceneId }) {
    const viewsCatalogKey = ['catalogs', catalogId, 'views'];
    const viewsSceneKey = ['scenes', sceneId, 'views'];
    /*  
    // remove deleted view from state.
    let newViews = this.getIn(["catalogs", catalogId, "views"]);
    newViews = newViews.deleteIn([viewId]); */

    this.deleteIn([...viewsCatalogKey, viewId]);

    // change defaultViewId on all values
    userSettingsActions.setOption({
      catalogId,
      option: 'defaultViewId',
      value: '0',
    });

    // remove scene
    const catalogScenes = this.get('scenes').filter(
      (scene) => scene.getIn(['params', 'catalogId']) == catalogId && scene.get('type') == SCENE_TYPE.CATALOG,
    );
    catalogScenes &&
      catalogScenes.forEach((scene) => {
        const sceneHasView = scene.getIn(['views', viewId]);
        if (sceneHasView) {
          this.deleteIn([...viewsSceneKey, viewId]);
        }
      });
    this.changed();
  },

  deleteCatalogFailed(err, { catalogId }) {},
  /**
   * request on create new iew filters.
   */
  saveView(sceneId, catalogId, viewId, data) {
    saveDebouncedView(catalogId, viewId, data, sceneId);
  },

  resetView(sceneId, viewId) {
    let sceneView = this.getIn(['scenes', sceneId, 'views', viewId]);
    const catalogId = sceneView.getIn(['catalogId']);
    const view = this.getIn(['catalogs', catalogId, 'views', viewId]);

    // copy original filters
    // in future view display propertiews
    sceneView = sceneView.set('filters', view.get('filters'));
    sceneView = sceneView.set('filtersChanged', false);

    this.setIn(['scenes', sceneId, 'views', viewId], sceneView);
    this.changed();
  },

  /**
   * request on create new view filters.
   */
  setField(fieldId, value) {
    if (value !== 'inherit') {
      appState.setIn(['currentCatalog', 'currentView', 'fieldPrivilegeCodes', fieldId], value);
    } else {
      appState.deleteIn(['currentCatalog', 'currentView', 'fieldPrivilegeCodes', fieldId]);
    }
  },

  setViewProperty(name, value) {
    appState.setIn(['currentCatalog', 'currentView', name], value);
  },

  restoreViewToDefault({ sceneId, catalogId, viewId }) {
    // action for reset changed params

    if (viewId) {
      this.mergeIn(['scenes', sceneId, 'views', viewId], this.getIn(['catalogs', catalogId, 'views', viewId]));
    } else {
      this.mergeIn(['scenes', sceneId, 'views'], this.getIn(['catalogs', catalogId, 'views']));
    }
  },
};
