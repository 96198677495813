import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import Immutable from 'immutable';
import cn from 'classnames';
import { Row, Input } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';

import { SortAscendingOutlined } from '@ant-design/icons';
import { connect } from '../../../StateProvider';

import routes from '../../../../routes';
import mapRouteParams from '../../../common/router/mapRouteParams';

import { checkAccessOnObject } from '../../../../utils/rights';
import RESOURCE_TYPES from '../../../../configs/resourceTypes';
import PRIVILEGE_CODES from '../../../../configs/privilegeCodes';

import SectionsMenu from '../SectionsMenu/menu';
import CatalogsMenu from '../../AppBody/Section/CatalogsMenu/menu';
import SectionItem from './SectionItem';
import CatalogItem from './CatalogItem';

import ButtonTransparent from '../../../common/UI/ButtonTransparent';

import userSettingsActions from '../../../../actions/userSettingsActions';

import styles from './fullMenu.less';

function Menu(props) {
  let { sections, catalogs, openedSections, sortAlphabet, favoriteCatalogs, isAdmin, visible, hideMenu } = props;
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [items, setItems] = useState(Immutable.List());
  const [_catalogs, setCatalogs] = useState(Immutable.List());
  const [searchText, setSearchText] = useState('');
  const [openedKeys, setOpenedKeys] = useState(Immutable.List());
  const [_sortAlphabet, setSortAlphabet] = useState(false);

  useEffect(() => {
    if (!favoriteCatalogs) {
      favoriteCatalogs = Immutable.List();
    }
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    setOpenedKeys(openedSections);
  }, [openedSections]);

  useEffect(() => {
    setSortAlphabet(sortAlphabet);
  }, [sortAlphabet]);

  // focus search input
  useEffect(() => {
    visible &&
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      });
  }, [visible]);

  // PREPARE: group Catalogs by Sections
  useEffect(() => {
    // favorite section
    /* if (favoriteCatalogs && favoriteCatalogs.size) {
        sections = sections.set("$favorite", 
          Immutable.Map({
            id: "$favorite",
            icon: "vote-38",
            name: t("section.favorite"),
            opened: true,
            className: styles.favorite,
            priority: -Infinity
          })
        );
      } */

    // prepare
    sections = sections
      .valueSeq()
      .filter((section) => section.get('privilegeCode') !== 'available')
      .sortBy((s) => s.get('priority'));

    // проверка на то, чтобы права были больше, чем "available"
    // если права уровня "available" и ниже, то скрываем саталог
    catalogs = catalogs.filter((catalog) =>
      checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.SEARCH),
    );

    // search
    if (searchText.length) {
      catalogs = catalogs.filter((catalog) => {
        let name = catalog.get('name');
        let found = name && name.toLowerCase().includes(searchText.toLowerCase());
        if (found) return true;

        const sectionId = catalog.get('sectionId');
        const section = sections.find((s) => s.get('id') == sectionId);
        name = section && section.get('name');
        found = name && name.toLowerCase().includes(searchText.toLowerCase());
        if (found) return true;
      });

      // get sections with found catalogs
      let sectionIds = catalogs.map((c) => c.get('sectionId'));
      const hasFavoriteCatalog = catalogs.find((c) => favoriteCatalogs?.indexOf(c.get('id')) > -1);
      if (hasFavoriteCatalog) {
        sectionIds = sectionIds.set('$favorite', '$favorite');
      }

      // only found sections
      sections = sections.filter((s) => sectionIds.includes(s.get('id')));
    }

    // add catalogs to section
    sections = sections.map((section) => {
      const sectionId = section.get('id');
      const sectionCatalogs = catalogs
        .valueSeq()
        .filter((c) =>
          sectionId === '$favorite' ? favoriteCatalogs.indexOf(c.get('id')) > -1 : c.get('sectionId') === sectionId,
        )
        .sortBy((c) => c.get('index'));
      return section.set('catalogs', sectionCatalogs);
    });

    // set opened keys
    sections = sections.map((section) => {
      const opened = openedKeys ? openedKeys.indexOf(section.get('id')) !== -1 : false;
      return section.set('opened', opened);
    });

    // sort
    if (_sortAlphabet) {
      sections = sections.sortBy((section) => section.get('name'));
      sections = sections.map((section, index) => section.set('priority', index));
    }

    // set items
    setCatalogs(catalogs);
    setItems(sections);
  }, [sections, catalogs, searchText, favoriteCatalogs, openedKeys, _sortAlphabet]);

  /* SECTIONS */
  const renderSection = (item, activeId) => {
    const id = item.get('id');
    const onClick = item.get('onClick') || onSectionClick;
    let opened = searchText.length ? true : item.get('opened');
    let catalogs = item.get('catalogs');

    // kostyl
    // "$favorite" is not in section list, it is created inside SectionMenu
    if (id === '$favorite') {
      // classname
      item = item.set('className', styles.favorite);

      // catalogs
      catalogs = _catalogs
        .valueSeq()
        .filter((c) => favoriteCatalogs.indexOf(c.get('id')) > -1)
        .sortBy((c) => c.get('index'));

      // open state
      const defaultOpenState = true;
      opened = opened || (openedKeys ? openedKeys.indexOf(id) !== -1 : defaultOpenState);
    }

    return (
      <SectionItem key={id} item={item} selected={id === activeId} opened={opened} onClick={(e) => onClick(id, e)}>
        {opened && (
          <CatalogsMenu
            sectionId={id}
            catalogs={catalogs}
            className={styles.catalogsMenu}
            direction="column"
            renderItem={renderCatalog}
          />
        )}
      </SectionItem>
    );
  };
  const onSectionClick = (id) => {
    // do not save open satte in search mode
    if (searchText.length) return;

    let keys = openedKeys;
    // open/collapse section
    if (_.isUndefined(keys)) {
      keys = Immutable.List();
    }
    const index = keys ? keys.indexOf(id) : -1;

    /**
     * Depends on item existance in the opened array
     * delete or push item using index
     */
    keys = index !== -1 ? keys.splice(index, 1) : keys.push(id);

    setOpenedKeys(keys);
    userSettingsActions.setKey(['ui', 'menu', 'openedSections'], keys);
  };
  const sectionCanDrag = (dragItem, dropItem) => !_sortAlphabet;

  const sectionCanDrop = (dragItem, dropItem) => {
    const section = items.find((i) => i.get('id') == dropItem.key);

    // если перетаскивают каталог и с екция отекрыта, то хотят в секцию класть нельзя, иначе нельзя делать реордер каталогов
    if (dragItem.dragType == RESOURCE_TYPES.CATALOG && section.get('opened')) {
      return false;
    }

    return true;
  };

  /* CATALOGS */
  const renderCatalog = (item, activeId) => {
    const id = item.get('id');
    const isFavorite = favoriteCatalogs && favoriteCatalogs.indexOf(id) > -1;
    return (
      <CatalogItem
        key={id}
        item={item}
        isFavorite={isFavorite}
        onClick={onCatalogClick}
        onFavoriteClick={onCatalogFavoriteClick}
      />
    );
  };
  const onCatalogClick = (id) => {
    hideMenu();
  };
  const onCatalogFavoriteClick = (id) => {
    if (_.isUndefined(favoriteCatalogs)) {
      favoriteCatalogs = Immutable.List();
    }

    const index = favoriteCatalogs ? favoriteCatalogs.indexOf(id) : -1;

    /**
     * Depends on item existance in the favorite array
     * delete or push item using index
     */
    favoriteCatalogs = index !== -1 ? favoriteCatalogs.splice(index, 1) : favoriteCatalogs.push(id);

    favoriteCatalogs = favoriteCatalogs.filter((i) => !!i);
    userSettingsActions.setKey(['ui', 'favoriteCatalogs'], favoriteCatalogs);
  };

  // Extra actions
  // search
  const onSearch = (e) => {
    const text = e.target.value;
    setSearchText(text);
  };
  const onSortClick = () => {
    const sortAlphabet = !_sortAlphabet;
    setSortAlphabet(sortAlphabet);
    userSettingsActions.setKey(['ui', 'menu', 'sortAlphabet'], sortAlphabet);
  };

  return (
    <div className={styles.menu}>
      <div className={styles.menuHeader}>
        <Row>
          <Input
            ref={inputRef}
            allowClear
            bordered={false}
            placeholder={t('menuPopover.searchCatalog')}
            className={styles.inputSearch}
            value={searchText}
            onChange={onSearch}
          />
          <ButtonTransparent
            title={!_sortAlphabet ? t('menuPopover.sortAlphabet') : t('menuPopover.sortCustom')}
            className={cn(styles.sortButton, {
              [styles.sortButtonAlphabet]: !!_sortAlphabet,
            })}
            onClick={onSortClick}
          >
            <SortAscendingOutlined className={styles.sortIcon} />
          </ButtonTransparent>
        </Row>
      </div>
      <div className={styles.menuBody}>
        <SectionsMenu
          sections={items}
          activeId={props.sectionId}
          direction="column"
          renderItem={renderSection}
          canDrag={sectionCanDrag}
          canDrop={sectionCanDrop}
        />
        {!!searchText.length && !items.first() && <div className={styles.notFound}>{t('menuPopover.searchEmpty')}</div>}
      </div>
    </div>
  );
}

export default connect(mapRouteParams(withTranslation()(Menu), routes.catalog), {
  sections: ['sections'],
  catalogs: ['catalogs'],
  openedSections: ['userSettings', 'ui', 'menu', 'openedSections'],
  sortAlphabet: ['userSettings', 'ui', 'menu', 'sortAlphabet'],
  favoriteCatalogs: ['userSettings', 'ui', 'favoriteCatalogs'],
});
