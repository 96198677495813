export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'parser',
  title: t('script.controlls.parser.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon content-47',
  offset_x: 0,
  offset_y: -15,
  priority: 5,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.parser.title'),
        type: 'text',
        theme: 'script',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        theme: 'script',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section',
      name: t('script.controlls.parser.find'),
      type: 'group',
    },
    {
      id: 'source',
      name: t('script.controlls.parser.data'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'type',
      name: t('script.controlls.parser.searchMethod'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'json',
            name: 'JSONPath',
            color: 'E7E9ED',
          },
          {
            id: 'xpath',
            name: 'XPath',
            color: 'E7E9ED',
          },
          {
            id: 'jquery',
            name: t('script.controlls.parser.jqSelector'),
            color: 'E7E9ED',
          },
          {
            id: 'regex',
            name: 'Regex',
            color: 'E7E9ED',
          },
        ],
        defaultValue: false,
        multiselect: false,
        defaultEmptyValue: ['json'],
        map: 'input',
      },
    },
    {
      id: 'search',
      name: t('script.controlls.parser.searchString'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'multiline',
        theme: 'script',
        map: 'input',
      },
    },
    {
      id: 'return',
      name: t('script.controlls.parser.returnValue'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        type: {
          $include: 'regex',
        },
      },
    },
    {
      id: 'returnElement',
      name: t('script.controlls.parser.return'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'first',
            name: t('script.controlls.parser.firstElement'),
            color: 'E7E9ED',
          },
          {
            id: 'last',
            name: t('script.controlls.parser.lastElement'),
            color: 'E7E9ED',
          },
          {
            id: 'all',
            name: t('script.controlls.parser.allElements'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: false,
        multiselect: false,
        defaultEmptyValue: ['first'],
        map: 'input',
      },
    },
    {
      id: 'returnType',
      name: t('script.controlls.parser.returnValue'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'html',
            name: t('script.controlls.parser.itemHtml'),
            color: 'E7E9ED',
          },
          {
            id: 'text',
            name: t('script.controlls.parser.itemText'),
            color: 'E7E9ED',
          },
          {
            id: 'value',
            name: t('script.controlls.parser.itemValue'),
            color: 'E7E9ED',
          },
          {
            id: 'element',
            name: t('script.controlls.parser.item'),
            color: 'E7E9ED',
          },
          {
            id: 'attr',
            name: t('script.controlls.parser.attr'),
            color: 'E7E9ED',
          },
        ],
        multiselect: false,
        defaultEmptyValue: ['html'],
        map: 'input',
      },
      visible: {
        type: {
          $include: 'jquery',
        },
      },
    },
    {
      id: 'attr',
      name: t('script.controlls.parser.attr'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        returnType: {
          $include: 'attr',
        },
      },
    },
    {
      id: 'section1',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'output',
      name: t('script.common.saveTo'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
    },
  ],
  exits: [],
});
