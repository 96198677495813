import _ from 'lodash';
import moment from 'moment';

import i18n from '../configs/i18n';
import * as AXIS_SUB_TYPES from '../configs/reports/widget/axisSubTypes';
import { TIME, DATE, WEEK, MONTH, QUARTER, YEAR } from '../configs/dateFieldSubTypes';

const log = require('debug')('CRM:Utils:formatDate');

const CURRENT_YEAR = new Date().getFullYear();

const removeYearFromFormat = _.memoize(
  (format) =>
    format
      .replace(/([\,\.\/ ]*Y+[\,\.\/ ]*)/, ' ') // remove year
      .replace(/ +/g, ' ') // remove double spaces
      .replace(/(^ | $)/g, ''), // trim
);

function getFormatWithoutYear(format, locale = moment.locale()) {
  // and others but need test
  if (/ru|en|cs/.test(locale)) {
    return removeYearFromFormat(moment().localeData().longDateFormat(format));
  }

  return format;
}

export function formatDate(date, type = DATE) {
  const locale = i18n.language;
  moment.locale(locale);
  if (!date) {
    log('empty date');
    return '';
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }

  const formats = {
    [TIME]: 'll',
    [DATE]: 'll',
    [WEEK]: 'YYYY-ww',
    [MONTH]: 'MMM YYYY',
    [QUARTER]: 'YYYY/Q',
    [YEAR]: 'YYYY',
  };
  let format = formats[type] || 'll';

  // remove current year if needed
  if (type === TIME || type === DATE) {
    if (date.year() === CURRENT_YEAR) {
      format = getFormatWithoutYear(format, locale);
    }
  }

  return date.format(format);
}
export function formatDateTime(date) {
  const locale = i18n.language;
  moment.locale(locale);
  if (!date) {
    log('empty date');
    return '';
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }

  let format = 'lll';

  if (date.year() === CURRENT_YEAR) {
    format = getFormatWithoutYear(format, locale);
  }

  return date.format(format);
}

export function formatTime(date) {
  if (!date) {
    log('empty date');
    return '';
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }
  return date.format('LT');
}

export function formatDateWithSec(date, locale, withTime = false) {
  moment.locale(locale);
  if (!date) {
    log('empty date');
    return '';
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }

  return date.format('LL LTS');
}

export function formatAxisDate(axis, mode) {
  if (axis === null) {
    return null;
  }
  switch (mode) {
    case AXIS_SUB_TYPES.HOUR:
      return axis && formatDateTime(axis);
    case AXIS_SUB_TYPES.DAY:
      return axis && formatDate(axis, DATE);
    case AXIS_SUB_TYPES.WEEK:
      return axis && formatDate(axis, WEEK);
    case AXIS_SUB_TYPES.DAY_OF_WEEK:
      // axis == 1 - is sunday
      return moment.weekdaysShort(axis - moment().localeData().firstDayOfWeek()) || `{day: ${axis}}`;
    case AXIS_SUB_TYPES.MONTH:
      const date = moment(new Date(`${axis}-01`));
      if (date.isValid()) {
        if (date.year() === CURRENT_YEAR) {
          return date.format('MMM');
        }
        return date.format('MMM YY');
      }
      return `{month: ${axis}}`;
    case AXIS_SUB_TYPES.MONTH_OF_YEAR:
      return moment.monthsShort(axis - 1) || `{month: ${axis}}`;
    case AXIS_SUB_TYPES.QUARTER:
      return axis && formatDate(axis, QUARTER);
    case AXIS_SUB_TYPES.QUARTER_OF_YEAR:
    case AXIS_SUB_TYPES.HOUR_OF_DAY:
    case AXIS_SUB_TYPES.WEEK_OF_YEAR:
    default:
      return axis;
  }
}
