import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/ru';

moment.locale('ru');

moment.updateLocale('ru', {
  // remove dot from months names
  monthsShort: {
    format: moment()
      .localeData()
      ._monthsShort.format.map((name) => name.replace('.', '')),
    standalone: moment()
      .localeData()
      ._monthsShort.standalone.map((name) => _.capitalize(name.replace('.', ''))),
  },

  // remove year postfix from locale date format
  // it's method is applicable to others languages, but it need testing
  longDateFormat: {
    ...['LL', 'LLL', 'LLLL', 'll', 'lll', 'llll'].reduce((obj, key) => {
      const format = moment().localeData().longDateFormat(key);
      obj[key] = format.replace(/(Y)[^A-Za-z,]+/g, '$1');
      return obj;
    }, {}),
  },
});
