export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'sendMail',
  title: t('script.controlls.sendMail.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon communication-59',
  offset_x: 0,
  offset_y: -15,
  priority: 7,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.sendMail.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'connectionsSection',
      name: t('script.controlls.getMail.connection'),
      type: 'group',
    },
    {
      id: 'connectionProtocol',
      name: t('script.controlls.getMail.protocol'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'smtp',
            name: 'SMTP',
            color: 'E7E9ED',
          },
          {
            id: 'exchange',
            name: 'Exchange',
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['smtp'],
        map: 'input',
      },
    },
    {
      id: 'connectionType',
      name: t('script.controlls.getMail.connectionType'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'parameters',
            name: t('script.common.params'),
            color: 'E7E9ED',
          },
          {
            id: 'connection',
            name: t('script.common.connectionString'),
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['parameters'],
        map: 'input',
      },
      visible: {
        connectionProtocol: {
          $include: 'smtp',
        },
      },
    },
    {
      id: 'smtphost',
      name: t('script.controlls.getMail.host'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.variableName'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'smtpport',
      name: t('script.controlls.getMail.port'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.numberExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'smtpencription',
      name: t('script.controlls.getMail.encryption'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'encrypted',
            name: t('script.controlls.getMail.encrypted'),
            color: 'E7E9ED',
          },
          {
            id: 'plain',
            name: t('script.controlls.getMail.plain'),
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['encrypted'],
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'smtpencriptionselfsign',
      name: t('script.controlls.getMail.encriptionselfsign'),
      type: 'dropdown',
      readOnly: false,
      visible: true,
      config: {
        items: [
          {
            id: 'yes',
            name: t('script.common.yes'),
            color: 'E7E9ED',
          },
          {
            id: 'no',
            name: t('script.common.no'),
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['no'],
      },
    },
    {
      id: 'smtplogin',
      name: t('script.controlls.getMail.login'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'smtppass',
      name: t('script.controlls.getMail.password'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'connectionString',
      name: t('script.common.connectionString'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        map: 'input',
        type: 'multiline',
        theme: 'script',
      },
      visible: {
        connectionType: {
          $include: 'connection',
        },
      },
    },
    {
      id: 'exchangeHost',
      name: t('script.controlls.getMail.host'),
      hint: t('script.controlls.sendMail.includeProtocol'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionProtocol: {
          $include: 'exchange',
        },
      },
    },
    {
      id: 'exchangeUsername',
      name: t('script.controlls.getMail.login'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionProtocol: {
          $include: 'exchange',
        },
      },
    },
    {
      id: 'exchangePass',
      name: t('script.controlls.getMail.password'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionProtocol: {
          $include: 'exchange',
        },
      },
    },
    {
      id: 'exchangeAuth',
      name: t('script.controlls.sendMail.auth'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'ntlm',
            name: 'NTLM',
            color: 'E7E9ED',
          },
          {
            id: 'basic',
            name: 'Basic Auth',
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['ntlm'],
      },
      visible: {
        connectionProtocol: {
          $include: 'exchange',
        },
      },
    },
    {
      id: 'letterSection',
      name: t('script.controlls.sendMail.message'),
      type: 'group',
    },
    {
      id: 'from',
      name: t('script.controlls.sendMail.from'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sendMail.fromHint'),
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'replyTo',
      name: t('script.controlls.sendMail.replyTo'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sendMail.replyToHint'),
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'inReplyTo',
      name: t('script.controlls.sendMail.inReplyTo'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sendMail.inReplyToHint'),
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'to',
      name: t('script.controlls.sendMail.to'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sendMail.toHint'),
      config: {
        map: 'input',
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'bcc',
      name: t('script.controlls.sendMail.bcc'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sendMail.hiddenCopyHint'),
      config: {
        map: 'input',
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'subject',
      name: t('script.controlls.sendMail.subject'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'bodyType',
      name: t('script.controlls.sendMail.format'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'text',
            name: t('script.controlls.sendMail.formatText'),
            color: 'E7E9ED',
          },
          {
            id: 'html',
            name: 'HTML (text/html)',
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['text'],
      },
    },
    {
      id: 'body',
      name: t('script.controlls.sendMail.text'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'attachments',
      name: t('script.controlls.sendMail.saveMessage'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sendMail.saveAttachments'),
      config: {
        map: 'input',
        type: 'multiline',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'section_output',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'output_messageId',
      name: t('script.controlls.sendMail.saveAttachmentsHint'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
    },
  ],
  exits: [],
});
