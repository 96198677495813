import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import Icon from '../UI/Icon';
import LinkedItem from '../UI/LinkedItem';

import styles from './dataTypes.less';

function UserField(props) {
  const { t } = useTranslation();
  const values = props.value || Immutable.List();
  if (!values.size) {
    return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
  }

  const text = values.map((u) => u && u.get('title'));

  if (props.extended) {
    // multi items

    const className = cn(props.containerClassName, styles.listField);
    const listItemClassNames = cn(props.listItemClassNames, styles.listItem);

    return (
      <ul className={className}>
        {values.map((user, i) => (
          <li className={listItemClassNames} key={user.get('id')}>
            <LinkedItem
              item={{
                icon: 'users-1',
                text: (user && user.get('title')) || t('emptyUser'),
              }}
              simple
            />
          </li>
        ))}
      </ul>
    );
  }
  // simple as 1 item
  return (
    <span className={cn(props.containerClassName, styles.simpleField)}>
      <Icon type="icon users-1" className={styles.icon} />
      {values.size > 1 ? <span className={styles.prefixData}>{values.size}: </span> : null}
      {text.join(', ')}
    </span>
  );
}

UserField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  listItemClassNames: PropTypes.string,
  isSingle: PropTypes.bool,
  extended: PropTypes.bool,
};

export default UserField;
