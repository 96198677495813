import Immutable from 'immutable';

const ST_KEY = 'dropdownCollections';

const availableLinkedRecordMixin = {
  _clearLinkedCollection(type, storeKey) {
    this.deleteIn([ST_KEY, type, storeKey]);
  },

  clearAvailableItems(type, storeKey) {
    this._clearLinkedCollection(type, storeKey);
  },

  loadAvailableItems(type, storeKey) {
    this.setIn([ST_KEY, type, storeKey, 'loading'], true);
    this.changed();
  },

  loadAvailableItemsCompleted(type, storeKey, params, items) {
    this.setIn(
      [ST_KEY, type, storeKey],
      Immutable.fromJS({
        items,
        loading: false,
      }),
    );
    this.changed();
  },

  loadAvailableItemsFialed(type, storeKey, params, err) {
    this.setIn(
      [ST_KEY, type, storeKey],
      Immutable.fromJS({
        loading: false,
        items: [],
      }),
    );
    this.changed();
  },
};

export default availableLinkedRecordMixin;
