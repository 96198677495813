import i18n from '../../../../../configs/i18n';
import { TEXT, MASKTEXT, MULTILINE, TEXT_EDITOR } from '../../../../../configs/textFieldSubTypes';

const Properties = (field, t = i18n.t) => {
  const config = field.get('config');
  let type = config.get('type');
  const trsPrefix = `catalogEditor.fieldProperties.configProperties.field.${field.get('type')}.`;

  // type (add extra type: MASKTEXT)
  type = type == TEXT && config.get('mask') !== null ? MASKTEXT : type;
  return {
    properties: [
      {
        id: 'configPropertiesSection',
        name: t('catalogEditor.fieldProperties.configProperties.section'),
        type: 'group',
      },
      {
        id: 'config.type',
        name: t(`${trsPrefix}type.label`),
        type: 'radiobutton',
        readOnly: false,
        config: {
          mode: 'dropdown',
          items: [
            { id: TEXT, name: t(`${trsPrefix}type.items.${TEXT}`) },
            { id: MASKTEXT, name: t(`${trsPrefix}type.items.${MASKTEXT}`) },
            { id: MULTILINE, name: t(`${trsPrefix}type.items.${MULTILINE}`) },
            { id: TEXT_EDITOR, name: t(`${trsPrefix}type.items.${TEXT_EDITOR}`) },
          ],
        },
      },
      {
        id: 'config.mask',
        name: t(`${trsPrefix}mask.label`),
        type: 'text',
        readOnly: false,
        hint: t(`${trsPrefix}mask.hint`),
        config: { type: TEXT },
        visibleRules: {
          'config.type': {
            $in: [MASKTEXT],
          },
        },
      },
    ],
    values: {
      'config.type': type,
      'config.mask': config.get('mask'),
    },
    getValues: (propery, value) => {
      switch (propery) {
        case 'config.type':
          if (value == MASKTEXT) {
            return { 'config.type': TEXT, 'config.mask': '111-111' };
          }
          return { 'config.type': value, 'config.mask': null };
      }
      return { [propery]: value };
    },
  };
};

export default Properties;
