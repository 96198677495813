import React, { Component } from 'react';
import ModalViewer from '../Modal';

const viewerComponent = (file) => {
  const url = `//sharecad.org/cadframe/load?url=${encodeURIComponent(file.url)}`;

  const iframe = <iframe src={url} style={{ width: '100%', height: '100%' }} frameBorder="no" />;

  return iframe;
};

export default class CADViewer extends Component {
  static priority = 5;

  static viewerName = 'CADViewer';

  render() {
    return <ModalViewer viewerComponent={viewerComponent} {...this.props} />;
  }
}
