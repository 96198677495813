import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import styles from './selectors.less';

const { Option } = Select;

function RadioSelector(props) {
  const onChange = (value) => {
    props.onChange && props.onChange(value);
    props.onEndEditing && props.onEndEditing();
  };

  const onEndEditing = (visible) => {
    if (!visible) {
      props.onEndEditing && props.onEndEditing();
    }
  };

  const filterOption = (inputValue, option) => option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  const additionalItems = props.config.get('items').map((item) => (
    <Option style={{ minHeight: '30px' }} key={item.get('id')} className={styles.option} title={item.get('name')}>
      {item.get('name')}
    </Option>
  ));

  return (
    <Select
      className={props.className}
      popupClassName={styles.selectorDropdown}
      style={{ width: '100%' }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ maxWidth: '300px' }}
      placeholder={props.config.get('name')}
      value={props.value}
      autoFocus={props.autoFocus}
      defaultOpen={props.autoFocus}
      onChange={onChange}
      onDropdownVisibleChange={onEndEditing}
      onInputKeyDown={props.onKeyPress}
      filterOption={filterOption}
      showArrow={false}
      showSearch
    >
      {additionalItems}
    </Select>
  );
}

RadioSelector.propTypes = {
  value: PropTypes.object || PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
  onKeyPress: PropTypes.func,
};

export default RadioSelector;
