import React from 'react';
import _ from 'lodash';
import Immutable from 'immutable';
import guid from 'guid';

import i18n from '../../../../../configs/i18n';
import AppState from '../../../../../appState';
import PRIVILEGE_CODES from '../../../../../configs/privilegeCodes';
import RESOURCE_TYPES from '../../../../../configs/resourceTypes';
import availableLinkedRecordActions from '../../../../../actions/availableLinkedRecordActions';

import LinkedRecordCreate from '../../../LinkedRecordCreate';

import FIELD_TYPES from '../../../../../configs/fieldTypes';
import { checkAccessOnObject } from '../../../../../utils/rights';
import recordActions from '../../../../../actions/recordActions';
import sceneActions from '../../../../../actions/sceneActions';

const outMapper = ({ item }) => Immutable.fromJS(item);

const getAvailableRecordsFilterValuesByLinkedRecord = (fieldId, record) => {
  let values = record && record.getIn(['values', fieldId]);
  values = values && values.map((v) => ({ catalogId: v.get('catalogId'), recordId: v.get('recordId') }));
  values = values && values.toJS();
  return values || [];
};

const getAvailableRecordsFilterValuesByUser = (fieldId, record) => {
  let values = record && record.getIn(['values', fieldId]);
  values = values && values.map((v) => v.get('id'));
  values = values && values.toJS();
  return values || [];
};

const getAvailableRecordsFilters = (catalog, record, field) => {
  const recordsFilters = [];
  const config = field.get('config');

  // if catalog is not loaded yet
  if (!catalog) {
    return [];
  }

  // parse catalogs
  !config.get('catalogs').includes('*') &&
    config.get('catalogs').forEach((c) => {
      const catalogId = c.get('id');
      const dynamicFilters = c.getIn(['dynamicFilters']);

      let filters =
        dynamicFilters &&
        !_.isEmpty(dynamicFilters) &&
        dynamicFilters.map((f) => {
          const fieldId = f.get('fieldId');
          const filterFieldId = f.getIn(['value', 0, 'fieldId']);

          const filterField = catalog.get('fields').find((f) => f.get('id') === filterFieldId);
          let value;
          if (filterField && filterField.get('type') === FIELD_TYPES.OBJECT) {
            value = getAvailableRecordsFilterValuesByLinkedRecord(filterFieldId, record);
          } else if (filterField && filterField.get('type') === FIELD_TYPES.USER) {
            value = getAvailableRecordsFilterValuesByUser(filterFieldId, record);
          }

          return { fieldId, value };
        });
      // remove empty filters
      filters = filters && filters.filter((f) => f.value && f.value.length);

      if (filters && filters.size) {
        recordsFilters.push({ catalogId, filters: filters.toJS() });
      }
    });

  // // parse views
  config.get('views').forEach((c) => {
    const viewId = c.get('id');
    const dynamicFilters = c.getIn(['dynamicFilters']);

    let filters =
      dynamicFilters &&
      !_.isEmpty(dynamicFilters) &&
      dynamicFilters.map((f) => {
        const fieldId = f.get('fieldId');
        const filterFieldId = f.getIn(['value', 0, 'fieldId']);

        const filterField = catalog.get('fields').find((f) => f.get('id') === filterFieldId);

        let value;
        if (filterField && filterField.get('type') === FIELD_TYPES.OBJECT) {
          value = getAvailableRecordsFilterValuesByLinkedRecord(filterFieldId, record);
        } else if (filterField && filterField.get('type') === FIELD_TYPES.USER) {
          value = getAvailableRecordsFilterValuesByUser(filterFieldId, record);
        }

        return { fieldId, value };
      });
    // remove empty filters
    filters = filters && filters.filter((f) => f.value && f.value.length);

    if (filters && filters.size) {
      recordsFilters.push({ viewId, filters: filters.toJS() });
    }
  });

  return recordsFilters;
};

const config = ({ sceneId, field, value, catalogId, recordId, onChange, onEndEditing, t = i18n.t }) => {
  const fieldId = field.get('id');
  const catalogs = AppState.get('catalogs');
  const records = AppState.get('records');
  const catalog = catalogs.get(catalogId);
  const record = records.getIn([catalogId, recordId]);
  const selectedFieldId = field.get('selectedFieldId');

  const onCreate = (item, newRecord) => {
    const newLinkedRecord = {
      // structure for outMapper
      item: {
        recordId: newRecord.id,
        recordTitle: newRecord.title,
        catalogId: item.key,
        catalogIcon: item.icon,
        catalogTitle: item.text,
        recordValues: newRecord.values,
        isNew: !!newRecord.isNew,
        isRemoved: false,
      },
    };

    if (value) {
      value = value.concat(newLinkedRecord);
    } else {
      value = [newLinkedRecord];
    }
    onChange && onChange(fieldId, value);
    onEndEditing && onEndEditing(fieldId, value);

    newRecord.sceneId && sceneActions.closeScene(newRecord.sceneId);
  };

  if (value) {
    value = value.map((item) => ({
      key: `${item.get('catalogId')}:${item.get('recordId')}`,
      text: item.get('recordTitle'),
      icon: item.get('catalogIcon'),
      catalogId: item.get('catalogId'),
      recordId: item.get('recordId'),
      isNew: item.get('isNew'),
      item,
    }));
  }

  field = field.set('requestParams', {
    fieldId: selectedFieldId || fieldId,
    catalogId,
    recordsFilters: getAvailableRecordsFilters(catalog, record, field),
  });

  field = field.set('loadAvailableItems', (...args) => {
    availableLinkedRecordActions.loadAvailableItems(...args);
  });

  field = field.set('clearAvailableItems', (...args) => {
    availableLinkedRecordActions.clearAvailableItems(...args);
  });

  const config = field.get('config');

  const enableCreate = _.isBoolean(field.getIn(['config', 'enableCreate']))
    ? field.getIn(['config', 'enableCreate'])
    : true;

  // items: create new recod in catalog
  if (enableCreate) {
    const availableCatalogsByAccess =
      config.get('catalogs') && !config.get('catalogs').includes('*')
        ? config
            .get('catalogs')
            .filter((c) => {
              const catalog = catalogs.get(c.get('id'));
              if (!catalog) {
                return true;
              }

              // check rights on Catalog
              let hasAccess = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.CREATE);

              // check rights on catalog Views
              if (!hasAccess) {
                // todo: delete this check when catalog will have rights by bits
                // when views are not loaded yet it is inpossible to check is there is a right to create in catalog by views
                if (!catalog.get('viewsLoaded')) {
                  return true;
                }

                if (catalog.get('views')) {
                  catalog.get('views').forEach((v) => {
                    if (checkAccessOnObject(RESOURCE_TYPES.VIEW, v, PRIVILEGE_CODES.CREATE)) {
                      hasAccess = true;
                    }
                  });
                }
              }

              return hasAccess;
            })
            .toJS()
        : [];

    const startCatalogs =
      availableCatalogsByAccess.map((catalog) => ({
        ...catalog,
        key: catalog.id,
        text: `${t('fieldTypes.dropdown.AddInCatalog')} «${catalog.title}»`,
      })) || [];

    const startViews =
      (config.get('views') &&
        config
          .get('views')
          .map((view) => {
            const catalogId = view.get('catalogId');
            return {
              key: catalogId,
              id: catalogId,
              text: `${t('fieldTypes.dropdown.AddInCatalog')} «${view.get('catalogTitle')}»`,
            };
          })
          .toJS()) ||
      [];

    let startItems = startCatalogs.concat(startViews);
    startItems = _.uniqBy(startItems, 'key')
      .filter((item) => catalogs && catalogs.getIn([item.key, 'sectionId']))
      .map((item) => {
        item.children = ({ linkProps }) => {
          const extendedFields = config.getIn(['fields', item.id]);

          /*       const needOpenModal = !extendedFields || !extendedFields.size || !config.get("enableUnsaved"); */

          const dontOpenInModal = extendedFields && extendedFields.size > 0 && config.get('enableUnsaved');

          return (
            <LinkedRecordCreate
              silent={dontOpenInModal}
              fromCatalogId={catalogId}
              fromRecordId={recordId}
              parentSceneId={sceneId}
              catalogId={item.key}
              linkProps={linkProps}
              onCreate={(newRecord) => onCreate(item, newRecord)}
            />
          );
        };
        item.sort = -1;
        item.filterable = false;
        item.selectable = false;
        return item;
      });

    let additionalClickItems = field.get('additionalClickItems');

    additionalClickItems = additionalClickItems || [];
    additionalClickItems = additionalClickItems.concat(startItems);

    field = field.set('additionalClickItems', additionalClickItems);
  }

  field = field.set('elementsRemoteGroup', 'linkedObjects');
  return { field, value };
};

const removeRecordParent = (newValues, controlId, record) => {
  if (!record) {
    return;
  }

  const currentValues = record.getIn(['values', controlId]);

  if (!currentValues || !Immutable.List.isList(currentValues)) {
    return;
  }

  const recordId = record.get('id');
  const catalogId = record.get('catalogId');

  const deletedLinkedRecords =
    currentValues && currentValues.filter((value) => !newValues.some((newValue) => Immutable.is(value, newValue)));

  if (!(deletedLinkedRecords && deletedLinkedRecords.size)) {
    return;
  }

  deletedLinkedRecords.forEach((linkedRecord) => {
    const params = {
      catalogId: linkedRecord.get('catalogId'),
      recordId: linkedRecord.get('recordId'),
    };
    recordActions.removeParent(params, catalogId, recordId, controlId);
  });
};

const onChange = (values, controlId, record) => {
  values = values.map(outMapper);
  removeRecordParent(values, controlId, record);
  return Immutable.fromJS(values);
};

const onEndEditing = (values) => values.map(outMapper);

export default {
  config,
  onChange,
  onEndEditing,
};
