import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';

import apiActions from '../../../../../actions/apiActions';
import { filterByPrivilege, isVisibleByPrivilegeCode } from '../lib';

export const useGetTutorials = (privilegeCode, locale = 'ru') => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tutorials, setTutorials] = useState([]);

  const getTutorials = async () => {
    /**
     * Get tutorials using api
     * repo link: https://github.com/Bpium/tutorial
     */
    setLoading(true);
    apiActions
      .getHelpInformation(locale)
      .then((response) => {
        setLoading(false);
        const body = JSON.parse(response.text);

        // Prepare tutorials
        const filteredTutorials = filterByPrivilege(body, privilegeCode);

        setError(null);
        setTutorials(filteredTutorials);
      })
      .catch((err) => {
        setLoading(false);
        if (err.status == 404) {
          setError(err);
        } else {
        }
      });
  };

  /**
   * Return tutorial items for ones user has acces
   *
   * @param {array} tutorials - json with info about tutorials
   * @param {boolean} privilegeAdmin - if user has admin right for at least 1 secion
   * @returns flatten array with tutorials if any
   */
  const getTutorialItems = (tutorials) => {
    if (!tutorials) return;
    const result = [];
    tutorials.forEach((item) => {
      item.items &&
        item.items.forEach((item) => {
          item.items &&
            item.items.forEach((item) => {
              // if privilege admin add all, if user show only users tutorial
              if (isVisibleByPrivilegeCode(privilegeCode, item)) {
                result.push(item);
              }
            });
        });
    });
    return result;
  };

  const getLazyTutorialItems = useMemo(() => getTutorialItems(tutorials), [tutorials]);

  useEffect(() => {
    getTutorials();
  }, [locale]);

  return {
    loading,
    error,
    tutorials,
    getLazyTutorialItems,
  };
};
