import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from '../controls.less';

function RecordFiledHint({ text, className }) {
  if (!text) {
    return null;
  }

  const classes = cn(className, styles.hint);

  return <div className={classes}>{text}</div>;
}

RecordFiledHint.propTypes = {
  text: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default RecordFiledHint;
