import React, { Component } from 'react';
import ModalViewer from '../Modal';

const viewerComponent = (file) => {
  const url = `//view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(file.url)}`;

  const iframe = <iframe src={url} style={{ width: '100%', height: '100%' }} frameBorder="no" />;

  return iframe;
};

export default class OfficeViewer extends Component {
  static viewerName = 'OfficeViewer';

  static priority = 3;

  render() {
    return <ModalViewer viewerComponent={viewerComponent} {...this.props} />;
  }
}
