import { MemoryRouter } from 'react-router';

export default class HashMemoryRouter extends MemoryRouter {
  constructor(...args) {
    super(...args);
    this.history.createHref = (function (originalFn) {
      return function MyCreateHref(...args) {
        return `#${originalFn.apply(this, args)}`;
      };
    })(this.history.createHref);
  }
}
