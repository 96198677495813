import _ from 'lodash';

export const mentionsToHtml = (text, highlight, messageGuid = '') => {
  let messageText = text && text.trim();

  const returnedObj = {
    isMentions: false,
    formatedText: '',
  };

  if (messageText) {
    messageText = messageText.replace(/<@([^;]+)?;([\s\S]*?)\/@>/g, (match, id, name) => {
      returnedObj.isMentions = true;
      returnedObj.id = id;

      if (highlight) {
        return `<xxx id=${id} class='message_mention ${_.isEmpty(messageGuid) ? '' : messageGuid}'>${name}</xxx>`;
      }
      return `<xxx>${name}</xxx>`;
    });
    returnedObj.formatedText = messageText;
  }
  return returnedObj;
};
