/* eslint-disable no-bitwise */
import _ from 'lodash';
import Reflux from 'reflux';

import makeApiRequest from '../utils/makeApiRequest';
import antiCapitalize from '../utils/antiCapitalize';

export const TYPES = {
  SECTIONS: 'sections',
  CATALOGS: 'catalogs',
  USERS: 'users',
  LINKED_OBJECTS: 'linkedObjects',
  SUBJECTS: 'subjects',
  LINKED_OBJECTS_WITH_FILTERS: 'linkedObjectsWithFilters',
  RECORDS: 'linkedRecords',
  VIEWS: 'views',
};

const actions = {};

const dropdownHandlers = {
  [TYPES.SECTIONS](...args) {
    return makeApiRequest('sections', {}, true).then((res) =>
      res.body.map((section) => ({
        key: section.id,
        text: section.name,
      })),
    );
  },

  [TYPES.CATALOGS](...args) {
    return makeApiRequest('catalogs', {}, true).then((res) =>
      res.body.map((catalog) => ({
        key: catalog.id,
        text: catalog.name,
        icon: catalog.icon,
      })),
    );
  },

  [TYPES.USERS](params) {
    const query = {};
    if (params.title) {
      query.title = params.title;
    }
    return makeApiRequest('users', { query }, true).then((res) =>
      res.body.map((o) => ({
        key: o.id,
        text: o.title,
        icon: 'users-1',
        alwaysVisible: o.alwaysVisible,
        sort: ~~o.sort,
      })),
    );
  },

  [TYPES.LINKED_OBJECTS](params) {
    const query = {};
    if (params.title) {
      query.title = params.title;
    }
    if (params.linkedCatalogId) {
      query.catalogId = params.linkedCatalogId;
    }
    if (!_.isEmpty(params.recordsFilters)) {
      query.recordsFilters = JSON.stringify(params.recordsFilters);
    }
    return makeApiRequest(
      `catalogs/${params.catalogId}/fields/${params.fieldId}/availableRecords`,
      { query },
      true,
    ).then((res) =>
      res.body.map((o) => ({
        key: `${o.catalogId}:${o.recordId}`,
        text: o.recordTitle,
        icon: o.catalogIcon,
        item: o,
      })),
    );
  },

  [TYPES.SUBJECTS](params) {
    let opts;
    const title = params && params.title;

    if (title) {
      opts = { query: { title: params.title } };
    }

    return makeApiRequest('availableRightSubjects', opts, true).then((res) =>
      res.body.map((c) => ({
        key: `${c.userAttr}:${c.catalogId}:${c.recordId}`,
        text: c.recordTitle,
        subText: c.userAttrTitle && antiCapitalize(c.userAttrTitle),
        icon: c.catalogIcon,
        subject: c,
      })),
    );
  },

  [TYPES.RECORDS](params) {
    return makeApiRequest(`catalogs/${params.catalogId}/records`, { query: { searchText: params.title } }, true).then(
      (res) =>
        res.body.map((item) => ({
          key: item.id,
          text: item.title,
          item,
        })),
    );
  },

  [TYPES.LINKED_OBJECTS_WITH_FILTERS](params) {
    return makeApiRequest(
      `catalogs/${params.catalogId}/fields/${params.fieldId}/availableFilterRecords`,
      { query: { title: params.title } },
      true,
    ).then((res) =>
      res.body.map((item) => ({
        key: `${item.catalogId}:${item.recordId}`,
        text: item.recordTitle,
        icon: item.catalogIcon,
        item,
      })),
    );
  },

  [TYPES.VIEWS](params) {
    return makeApiRequest('views', {}, true).then((res) =>
      res.body
        .filter((v) => v.forRights)
        .map((view) => ({
          key: `view:${view.id}`,
          text: `${view.catalogTitle} / ${view.originName ? view.originName : view.name}`,
          catalogId: String(view.catalogId),
          icon: view.catalogIcon,
        })),
    );
  },
};

actions.clearAvailableItems = Reflux.createAction();

actions.loadAvailableItems = Reflux.createAction({ asyncResult: true });
actions.loadAvailableItems.listen(function (type, storeKey, params) {
  if (!dropdownHandlers[type]) {
    throw new Error(`No dropdownHandlers for type '${type}'`);
  }

  return dropdownHandlers[type]
    .call(this, params)
    .then((items) => {
      this.completed(type, storeKey, params, items);
    })
    .catch((err) => {
      this.failed(type, storeKey, params, err);
    });
});

export default actions;
