// original code https://github.com/feathersjs/feathers-commons/blob/ac3d36f8499026b519a8e950f3ba39ad988c74b3/src/utils.js#L146

import _ from 'lodash';

function customMatch(objValue, srcValue) {
  return String(objValue) === String(srcValue);
}
const isMatchWith = (object, source) => _.isMatchWith(object, source, customMatch);

const matchSimilar = (arr, matchArr) => {
  // если сломалась условная видимость, то это тут
  if (arr.length && _.isObject(arr[0])) {
    // compare by properties
    return _.intersectionWith(matchArr, arr, isMatchWith);
  }
  // compare by values
  return _.intersectionBy(matchArr, arr, String);
};
const matchEmpty = (arr, matchArr) => {
  let hasEmpty = false;
  const emptyValue = '$empty';

  // check
  if (matchArr.length) {
    return false;
  }

  // find empty key
  _.forEach(arr, (item) => {
    if (_.isObject(item)) {
      _.forEach(item, (property) => {
        if (!_.isObject(property)) {
          property = String(property).toLowerCase();
          if (property == emptyValue) {
            hasEmpty = true;
          }
        }
      });
    } else {
      item = String(item).toLowerCase();
      if (item == emptyValue) {
        hasEmpty = true;
      }
    }
  });

  return hasEmpty;
};
const matchHas = (arr, matchArr) => {
  let has = false;
  const hasValue = '$has';

  // check
  if (!matchArr.length) {
    return false;
  }

  _.forEach(arr, (item) => {
    item = String(item).toLowerCase();
    if (item == hasValue) {
      has = true;
    }
  });

  return has;
};

const matchSet = (value) => value !== '' && value !== null && value !== undefined;

export const specialFilters = {
  // $in: [], value: [] => false
  // $in: [1, 2], value: 1 => true
  // $in: [{recordId: 1}], value: [{recordId: 1, title: ''}] => true
  // $in: [1, 2], value: x => false
  // $in: [1, 2, 3], value: [1, 2, 4] => true
  // $in: [1, 2], value: [3, 4] => false
  // EMPTY
  // $in: [$EMPTY], value: [] => true
  // $in: [{recordId: '$EMPTY'}], value: [] => true
  // $in: [$EMPTY], value: [1] => false
  // $in: [$EMPTY, 1], value: [] => true
  // $in: [$EMPTY, 1], value: [1] => true
  $in(key, items) {
    return (item) => {
      const value = matchSet(item[key]) ? [].concat(item[key]) : [];

      items = [].concat(items);

      const conditions = [];
      conditions.push(matchEmpty(items, value));
      conditions.push(matchHas(items, value));
      conditions.push(matchSimilar(items, value).length > 0);

      return conditions.includes(true);
    };
  },

  $nin(key, items) {
    return (item) => {
      const value = matchSet(item[key]) ? [].concat(item[key]) : [];
      items = [].concat(items);

      const conditions = [];
      conditions.push(matchEmpty(items, value));
      conditions.push(matchHas(items, value));
      conditions.push(matchSimilar(items, value).length > 0);

      return !conditions.includes(true);
    };
  },

  $lt(key, value) {
    return (item) => matchSet(item[key]) && item[key] < value;
  },

  $lte(key, value) {
    return (item) => matchSet(item[key]) && item[key] <= value;
  },

  $gt(key, value) {
    return (item) => matchSet(item[key]) && item[key] > value;
  },

  $gte(key, value) {
    return (item) => matchSet(item[key]) && item[key] >= value;
  },

  $ne(key, value) {
    return (item) => item[key] != value;
  },

  $eq(key, value) {
    return (item) => item[key] == value;
  },

  // $include: [], value: [] => true
  // $include: [1], value: 1 => true
  // $include: [{recordId: 1}], value: [{recordId: 1, title: ''}] => true
  // $include: [1, 2, 3], value: [1, 2] => false
  // $include: [1, 2], value: [1, 2, 3] => true
  // $include: [1, 2, 3], value: [1, 2, 4] => false
  // EMPTY
  // $include: [$EMPTY], value: [] => true
  // $include: [{recordId: '$EMPTY'}], value: [] => true
  // $include: [$EMPTY], value: [1] => false
  // $include: [$EMPTY, 1], value: [] => false
  // $include: [$EMPTY, 1], value: [1] => false
  $include(key, items) {
    items = [].concat(items);
    return (item) => {
      const value = matchSet(item[key]) ? [].concat(item[key]) : [];
      items = [].concat(items);

      const conditions = [];
      conditions.push(items.length == 1 && matchEmpty(items, value));
      conditions.push(items.length == 1 && matchHas(items, value));
      conditions.push(matchSimilar(items, value).length === items.length);

      return conditions.includes(true);
    };
  },
};

export default function matcher(originalQuery, additionalChecker = (r) => r) {
  const query = _.omit(originalQuery, '$limit', '$skip', '$sort', '$select');

  return function (item) {
    if (query.$or && _.some(query.$or, (or) => matcher(or)(item))) {
      return true;
    }

    return _.every(query, (value, key) => {
      if (value !== null && typeof value === 'object') {
        return _.every(value, (target, filterType) => {
          if (specialFilters[filterType]) {
            const filter = specialFilters[filterType](key, target);
            return additionalChecker(filter(item), key);
          }

          return false;
        });
      }
      if (typeof item[key] !== 'undefined') {
        return additionalChecker(_.isEqual(item[key], query[key]), key);
      }

      return false;
    });
  };
}
