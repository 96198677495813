import Immutable from 'immutable';

export default {
  /**
   * Получение пользователя с сервера
   */

  getProfileCompleted(data) {
    this.setIn(['user'], Immutable.fromJS(data));
    this.changed();
  },
};
