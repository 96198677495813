import _ from 'lodash';
import i18n from './configs/i18n';

// const log = require('debug')('CRM:Translations');

const cache = {};

const translations = {
  ru: require('../translations/ru.json'),
};

try {
  // eslint-disable-next-line import/no-unresolved
  translations.en = require('../translations/en.json');
} catch (err) {}

export default function (path, count) {
  let result = translations[i18n.language || 'ru'];

  if (!path) {
    return result;
  }

  const pathWithCount = path + (count !== null ? count : '');

  if (cache[pathWithCount]) {
    return cache[pathWithCount];
  }

  const pathArr = path.split('.');

  while (pathArr.length && result) {
    result = result[pathArr.shift()];
  }

  if (count != null) {
    let _count = count % 100;
    if (_count >= 20) {
      _count %= 10;
    }
    let text;
    _.forEach(result, (t, c) => {
      if (parseInt(c, 10) <= _count) {
        text = t;
      }
    });
    result = `${count} ${text}`;
  }

  if (!result) {
    // log(`No translation for '${path}'`);
  } else {
    cache[pathWithCount] = result;
  }

  if (result === undefined) {
    return `NO TRANSLATION (${path})`;
  }

  return result;
}
