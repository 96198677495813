export default {
  VISIBLE: 'visible',

  WIDTH: 'width',

  // order of the columns.
  FIELDS_ORDER: 'fieldsOrder',

  SORTING_RECORDS: 'sortingRecords',

  //
};
