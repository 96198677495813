import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { dragSource, dropTarget } from './dndSourceTarget';

class MenuItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    dropType: PropTypes.any,
    dropInfo: PropTypes.any,
  };

  render() {
    const {
      item,
      className,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      isDraggingOver,
      canDrop,
    } = this.props;

    let draggingStyle = null;
    if (isDragging) {
      draggingStyle = { opacity: '0.1' };
    }
    if (isDraggingOver && canDrop) {
      draggingStyle = { opacity: '0.1' };
    }

    return _.flow(
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
    )(
      <div className={className} style={draggingStyle}>
        {item}
      </div>,
    );
  }
}

export default _.flow(dragSource, dropTarget)(MenuItem);
