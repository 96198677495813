import Immutable from 'immutable';

export default {
  getUsers(params, query) {},
  getUsersCompleted(body, params, data, query, res, actionParams) {
    const userMetions = Immutable.fromJS(body);
    this.set('users', userMetions);
  },
  getUsersFailed(body, params, data, query, res, actionParams) {},
};
