import debug from 'debug';
import _ from 'lodash';
import Immutable from 'immutable';
import { message } from 'antd';

import apiActions from '../../actions/apiActions';
import recordActions from '../../actions/recordActions';
import CatalogFactory from '../../models/CatalogFactory';
import i18n from '../../configs/i18n';
import helpers from './helpers';

const log = debug('CRM:catalogsMixin');

const catalogMixin = {
  getCatalogs() {
    this.set('catalogsLoading', true);
    this.set('catalogsLoadError', null);
    this.changed();
  },

  getCatalogsCompleted(res, params, data, query) {
    // prepare data
    const catalogsById = {};
    res.forEach((catalog, i) => {
      catalog.id = catalog.id.toString();
      catalog.index = i;
      catalogsById[catalog.id] = catalog;
    });

    // remove deleted catalogs
    this.get('catalogs') &&
      this.get('catalogs').forEach((c) => {
        const id = c.get('id') && c.get('id').toString();
        if (!query.sectionId || (query.sectionId && query.sectionId == c.get('sectionId'))) {
          if (!catalogsById[id]) {
            this.deleteIn(['catalogs', id]);
          }
        }
      });

    // set catalogs
    this.setCountOfNewMessagesByCatalogsForSection(catalogsById);
    _.forEach(catalogsById, (catalog) => this.setCatalog(catalog));
    this.set('catalogsLoading', false);
    this.set('catalogsLoadError', null);

    this.changed();

    // catalogs = catalogs.map((cat)=> CatalogFactory.create(cat));
    // catalogs = _.sortBy(catalogs, (t)=> t.get('order'));
    // this.set('catalogs', Immutable.fromJS(catalogs));
  },

  getCatalogsFailed(e) {
    this.set('catalogsLoading', false);
    this.set('catalogsLoadError', e);
    this.changed();
  },

  checkCreateCatalogPrivilegeCompleted(granted) {
    this.set('createCatalogAllowed', granted || false);
    this.changed();
  },

  getCatalogFieldsCompleted({ catalogId }, data) {
    let catalog = this.getIn(['catalogs', catalogId]);
    if (!catalog) {
      return;
    }

    catalog = CatalogFactory.setCatalogFields(catalog, data.fields);
    this.setIn(['catalogs', catalogId], catalog);
    this.changed();
  },

  /* ============================
   * Get Catalog
   */

  getCatalogCompleted(data, { catalogId }, params, query, res, actionParams) {
    // is edit catalog mode?
    const catalogEdit = actionParams.catalogEdit === 'catalogEdit'; // && this.getIn(["editingCatalogs", data.sectionId, "id"]) !== catalog.get("id");

    // clear previous fields, may have deleted
    let catalog = this.getIn(['catalogs', data.id]);
    if (catalogEdit && catalog) {
      // when enter catalog edit mode you can be sure that all fields are real
      this.setIn(['catalogs', data.id, 'fields'], Immutable.List([]));
    }

    // set catalog with merge data to current
    this.setCatalog(data);

    // set catalog to edit mode
    if (catalogEdit) {
      catalog = this.getIn(['catalogs', data.id]);
      catalog = catalog.set('originalFields', catalog.get('fields'));
      this.setIn(['editingCatalogs', data.sectionId], catalog);
    }

    this.changed();
  },

  getCatalogFailed(err, { catalogId }) {
    const { alert } = require('../../components/common/Modal');

    const errText = _.isObject(err) && err.text ? err.text : '';
    const isAccessDenied = err && err.status == 403;
    const notFound = err && err.status == 403;
    const serverError = err ? err.status && err.status[0] === 5 : false;

    if (isAccessDenied) {
      /* можно разные текста использовать */
      message.error(errText || i18n.t('modals.denied.record.read.text'));
    } else if (notFound) {
      message.error(errText || i18n.t('modals.notFound.catalog.text'));
    } else if (serverError) {
      message.error(errText || i18n.t('modals.loadRecordError.text'));
    } else {
      alert({
        headerText: (err && err.title) || i18n.t('modals.loadRecordError.headerText'),
        text: errText || i18n.t('modals.loadRecordError.text'),
        okText: i18n.t('modals.loadRecordError.okText'),
      });
    }
    this.changed();
  },

  /* ============================
   * Create Catalog
   */
  createCatalog({ sectionId }) {
    this.setIn(['editingCatalogs', sectionId, 'creating'], true);
    this.setIn(['editingCatalogs', sectionId, 'createError'], null);
    this.setIn(['editingCatalogs', sectionId, 'sectionId'], sectionId);
    this.changed();
  },

  createCatalogCompleted({ id: catalogId }, { sectionId }) {
    this.deleteIn(['editingCatalogs', sectionId]);
    this.changed();
    /* apiActions.getCatalogs().then(() => {
      const fields = this.getIn(["catalogs", catalogId, "fields"]);
      fields.forEach(field => {
        userSettingsActions.setFieldVisibility({
          catalogId: catalogId,
          viewMode: "table",
          fieldId: field.get("id"),
          visible: true
        });
      });
    }); */
  },

  createCatalogFailed(err, { sectionId }) {
    this.setIn(['editingCatalogs', sectionId, 'creating'], false);
    this.setIn(['editingCatalogs', sectionId, 'createError'], err || true);
    this.changed();
  },

  /* ================================
   * Update Catalog
   */
  updateCatalog({ sectionId }) {
    this.setIn(['editingCatalogs', sectionId, 'updating'], true);
    this.setIn(['editingCatalogs', sectionId, 'updateError'], null);
    this.changed();
  },

  updateCatalogCompleted(res, { catalogId, sectionId }) {
    this.deleteIn(['editingCatalogs', sectionId]);
    this.changed();
    const newCatalogId = res?.id;
    catalogId = newCatalogId || catalogId;
    apiActions.getCatalog({ catalogId, sectionId });
  },

  updateCatalogFailed(err, { sectionId }) {
    this.setIn(['editingCatalogs', sectionId, 'updating'], false);
    this.setIn(['editingCatalogs', sectionId, 'updateError'], err || true);
    this.changed();
  },

  /**
   * Delete Catalog
   */
  deleteCatalog({ catalogId }) {},

  deleteCatalogCompleted(result, { catalogId }, data, query, res, actionParams) {
    recordActions.clearAllRecords(catalogId, actionParams.sceneId);
    this.deleteIn(['records', catalogId]);
    this.deleteIn(['catalogs', catalogId]);

    this.changed();

    apiActions.getCatalogs();
  },

  deleteCatalogFailed(err, { catalogId }) {},

  changeSortIndex(order) {
    order.forEach((id, index) => {
      this.setIn(['catalogs', id, 'index'], index);
    });
    this.changed();
  },

  /**
   * add catalog
   */
  addCatalog({ sectionId, t = i18n.t }) {
    const catalog = CatalogFactory.create({
      isNew: true,
      icon: 'content-11',
      sectionId,
      t,
    });
    this.setIn(['editingCatalogs', sectionId], catalog);
    this.changed();
  },

  /**
   * clear editing catalog
   */
  clearEditingCatalog({ sectionId }) {
    this.deleteIn(['editingCatalogs', sectionId]);
    this.changed();
  },

  /**
   * clear catalogs
   */

  clearCatalog(catalogId) {
    // this.setIn(["catalogs", catalogId, "records"], Immutable.List());
    this.setIn(['catalogs', catalogId, 'boards'], Immutable.List());
    this.changed();
  },

  /*
   * truncate catalog from store
   */
  truncateCatalogFromStore({ catalogId }) {
    this.deleteIn(['catalogs', catalogId]);
    this.deleteIn(['userSettings', 'catalogs', catalogId]);
    this.changed();
  },
};

export default Object.assign(catalogMixin, helpers);
