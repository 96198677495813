import _ from 'lodash';

export default function getDefaultValues(filters, catalogs, catalogId) {
  const FieldApi = require('../models/FieldApi').default;
  const fields = catalogs.getIn([catalogId, 'fields']);
  const defaultValues = {};

  _.forEach(filters, (fieldFilter, fieldId) => {
    // ищем поле по id поля
    const field = fields.find((f) => f.get('id') == fieldId);
    // получаем дефолтные значения.
    let values = FieldApi.getDefaultValue(field);
    // приводим значения в js формат
    values = values && values.toJS ? values.toJS() : values;
    // не сохраненяем значения пустых массивов и объектов
    if (!_.size(values)) return;
    // // применение значечий из записи
    defaultValues[fieldId] = values;
  });

  return defaultValues;
}
