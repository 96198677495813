import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { matchPath, withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { HistoryOutlined } from '@ant-design/icons';
import _ from 'lodash';

import apiActions from '../../../actions/apiActions';
import modalsActions from '../../../actions/modalsActions';
import Icon from '../../common/UI/Icon';
import { confirm } from '../../common/Modal';
import getLink from '../../common/router/getLink';
import routes from '../../../routes';

import PRIVILEGE_CODES from '../../../configs/privilegeCodes';
import RESOURCE_TYPES from '../../../configs/resourceTypes';
import { checkAccessOnObject } from '../../../utils/rights';

import Header from '../Header';

import styles from './recordHeader.less';
import { connect } from '../../StateProvider';

const TABS_IDS = {
  MAIN: 'main',
  LINKS: 'links',
  CHAT: 'chat',
  HISTORY: 'history',
};

class RecordHeader extends React.Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    scene: PropTypes.object.isRequired,
    hasBeenEdit: PropTypes.bool.isRequired,
    onRefresh: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onCreateRecord: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    isWebForm: PropTypes.bool,
    isNew: PropTypes.bool,
    isLoading: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: this.filterFields(),
      tabs: Immutable.List([]),
      tabId: '0',
      errorTabId: null,
    };
  }

  getExtParams = (extraData = {}) => {
    const { match, record, catalog } = this.props;
    const { sectionId } = match.params;

    return {
      sectionId,
      catalogId: catalog.get('id'),
      recordId: record.get('isNew') ? '$new' : record.get('id'),
      ...extraData,
    };
  };

  onClickAccess = () => {
    const recordId = this.props.record.get('id');
    const catalogId = this.props.catalog.get('id');
    if (catalogId && recordId) {
      const isAdmin = checkAccessOnObject(RESOURCE_TYPES.RECORD, this.props.record, PRIVILEGE_CODES.ADMIN);
      const readOnly = !checkAccessOnObject(RESOURCE_TYPES.RECORD, this.props.record, PRIVILEGE_CODES.ACCESS);
      const object = { catalogId, recordId };
      const parents = [
        {
          sectionId: this.props.catalog.get('sectionId'),
        },
        {
          catalogId,
        },
      ];
      modalsActions.openAccessModal({ object, parents }, RESOURCE_TYPES.RECORD, { readOnly, isAdmin });
    }
  };

  onRemove = () => {
    const { scene, t, userId } = this.props;
    const sceneId = scene && scene.get('sceneId');
    const isYourself = userId === this.props.record.get('id');

    // эта проверка означает когда в каталоге мы пытаемся удалить себя

    confirm({
      headerText: isYourself ? t('modals.removeYourselfConfirm.header') : t('modals.removeRecordConfirm.header'),
      text: isYourself ? t('modals.removeYourselfConfirm.text') : t('modals.removeRecordConfirm.text'),
      okText: isYourself ? t('modals.removeYourselfConfirm.okText') : t('modals.removeRecordConfirm.okText'),
      cancelText: isYourself
        ? t('modals.removeYourselfConfirm.cancelText')
        : t('modals.removeRecordConfirm.cancelText'),
      onOk: () => {
        apiActions
          .deleteRecord(
            {
              catalogId: this.props.catalog.get('id'),
              recordId: this.props.record.get('id'),
            },
            {
              sceneId,
              viewId: this.props.match && this.props.match.params.viewId,
            },
          )
          .then(() => {
            this.props.onDelete && this.props.onDelete(sceneId);
          });
      },
    });
  };

  onClose = () => {
    const { scene } = this.props;
    const sceneId = scene && scene.get('sceneId');

    this.props.onClose && this.props.onClose(sceneId);
  };

  createTabs = () => {
    // Обновляем tabs
    const { t } = this.props;
    const tabs = this.calculateTabs();
    if (tabs.size > 0) {
      this.setState({ tabs });
    } else {
      // если в записи нет вкладок создаем новую вкладку для всех записей "Анкета"
      this.setState({
        tabs: Immutable.List([
          Immutable.Map({
            id: '0',
            name: t('record.tabs.main'),
            route: routes.recordMain,
            params: 'tabId',
          }),
        ]),
      });
    }
  };

  calculateTabs = () => {
    const { hiddenFields, catalog, record, errors } = this.props;
    const { fields } = this.state;
    const errorTabsId = [];
    const catalogId = catalog.get('id');
    const recordId = record.get('id');
    let tab;

    const filteredFields = fields.filter((field, index) => {
      const nextField = fields.get(index + 1);
      const fieldId = field.get('id');
      const hidden = hiddenFields.getIn([catalogId, recordId, 'hiddenFields', fieldId]);
      if (
        field.getIn(['config', 'tab']) && // возвращаем если вкладка не пустая
        nextField && // возвращаем если следующий элемент существует
        !hidden && // возвращаем если вкладка видимая
        !nextField.getIn(['config', 'tab']) // возвращаем если во вкладке есть контролы
      ) {
        tab = fieldId;
        return true;
        // находим вкладки в которых находятся обязательные поля
      }
      if (errors && errors.get(fieldId)) {
        errorTabsId.push(tab);
        return false;
      }
    });
    this.setState({
      errorTabId: errorTabsId[0],
    });

    return filteredFields.map((field) =>
      Immutable.Map({
        id: field.get('id'),
        name: field.get('name'),
        route: routes.recordMain,
        params: 'tabId',
        error: errorTabsId.includes(field.get('id')),
      }),
    );
  };

  getTabFromUrl = () => {
    let tab;
    let tabId;
    // берем параметры из урла

    // tab
    let match = matchPath(this.props.location.pathname, {
      path: routes.recordMain.path,
      exact: true,
      strict: false,
    });
    if (match) {
      tab = 'Main';
      tabId = match.params.tabId;
    }

    // links
    match = matchPath(this.props.location.pathname, {
      path: routes.recordLinks.path,
      exact: true,
      strict: false,
    });
    if (match) {
      tab = 'Links';
      tabId = 'links';
    }
    // chat
    match = matchPath(this.props.location.pathname, {
      path: routes.recordChat.path,
      exact: true,
      strict: false,
    });
    if (match) {
      tab = 'Chat';
      tabId = 'chat';
    }
    // history
    match = matchPath(this.props.location.pathname, {
      path: routes.recordHistory.path,
      exact: true,
      strict: false,
    });
    if (match) {
      tab = 'History';
      tabId = 'history';
    }

    return { tab, tabId };
  };

  openTab = (tabId) => {
    let { tabId: currentTabId, tab: currentTab } = this.getTabFromUrl();

    if (!currentTab) {
      currentTab = 'Main';
    }

    if (_.isUndefined(tabId)) {
      tabId = currentTabId || '0';
    }

    if (!currentTabId) {
      const { record, catalog, hiddenFields } = this.props;
      const fields = this.filterFields();
      const catalogId = catalog.get('id');
      const recordId = record.get('id');

      for (const field of fields) {
        const fieldId = field.get('id');
        const hidden = hiddenFields.getIn([catalogId, recordId, 'hiddenFields', fieldId]);

        // находим первую вкладку которая будет открыта
        if (field.getIn(['config', 'tab']) && !hidden) {
          tabId = field.get('id');
          break;
        }
      }
    }

    // переключится на нужную вкладку
    if (currentTabId !== tabId) {
      const extParams = this.getExtParams && this.getExtParams({ tabId });
      this.props.history.push(getLink(this.props.location, routes[`record${currentTab}`], extParams));
    }

    return tabId;
  };

  // фильтрует "скрытые" поля. Это такие поля, до которых у сотрудника нет доступа, но он их видит через связанные записи в других каталогах
  filterFields = () => this.props.catalog && this.props.catalog.get('fields').filter((field) => !field.get('hidden'));

  componentDidMount() {
    this.setState({ fields: this.filterFields() });
    // создаем вкладки анкеты
    this.createTabs();

    // переходим на вкладку по умолчанию
    this.openTab();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.catalog.get('fields') !== prevProps.catalog.get('fields')) {
      this.setState({ fields: this.filterFields() });
    }

    // Проверяем, изменились ли необходимые свойства, влияющие на tabs
    if (
      prevProps.recordId !== this.props.recordId ||
      prevProps.catalogId !== this.props.catalogId ||
      prevProps.hiddenFields !== this.props.hiddenFields ||
      prevState.errorTabId !== this.state.errorTabId
    ) {
      // создаем вкладки анкеты
      this.createTabs();
      // переходим на вкладку с ошибкой
      this.openTab(this.state.errorTabId && this.state.errorTabId);
    }
  }

  render() {
    const {
      record,
      isWebForm,
      scene,
      catalog,
      match,
      history,
      hasBeenEdit,
      onRefresh,
      onCreateRecord,
      onSave,
      isLoading,
      t,
      onTabChange,
      fields,
    } = this.props;

    const messagesCount = record.getIn(['chatOptions', 'messagesCount']);
    const newMessages = record.getIn(['chatOptions', 'newMessages']);
    const isNew = record.get('isNew');
    const headerText = isNew ? t('record.newRecord') : record.get('title');

    const { tabs } = this.state; // вкладки из записи
    // redirect to default tab
    const tabId = this.openTab();

    const baseTabs = Immutable.List([
      Immutable.Map({
        id: TABS_IDS.LINKS,
        name: '',
        icon: <Icon className={styles.iconTabs} type="icon edition-50" style={{ verticalAlign: '-1px' }} />,
        title: t(`record.tabs.${TABS_IDS.LINKS}`),
        route: routes.recordLinks,
      }),
      Immutable.Map({
        id: TABS_IDS.CHAT,
        name: messagesCount || '',
        badge: !!newMessages,
        icon: (
          <Icon
            className={styles.iconTabs}
            type={`icon ${messagesCount > 0 ? 'communication-91' : 'communication-87'}`}
            style={{ verticalAlign: '-2px' }}
          />
        ),
        title: t(`record.tabs.${TABS_IDS.CHAT}`),
        route: routes.recordChat,
      }),
      Immutable.Map({
        id: TABS_IDS.HISTORY,
        name: '',
        icon: <HistoryOutlined className={styles.iconTabs} style={{ verticalAlign: '-3px' }} />,
        title: t(`record.tabs.${TABS_IDS.HISTORY}`),
        route: routes.recordHistory,
      }),
    ]);

    return (
      <Header
        tabs={tabs}
        baseTabs={baseTabs}
        tabId={tabId}
        record={record}
        isNew={isNew}
        catalog={catalog}
        scene={scene}
        viewId={match && match.params.viewId}
        hasBeenEdit={hasBeenEdit}
        withTabsMenu={!isNew}
        isWebForm={isWebForm}
        headerText={headerText}
        getExtParams={this.getExtParams}
        onClose={this.onClose}
        //
        history={history}
        onSave={onSave}
        isLoading={isLoading}
        onRefresh={onRefresh}
        onRemove={this.onRemove}
        onClickCreate={onCreateRecord}
        onClickAccess={this.onClickAccess}
        onClear={this.props.onClear}
        fields={fields}
        onTabChange={onTabChange}
      />
    );
  }
}

export default connect(
  withTranslation()(withRouter(RecordHeader)),
  {
    hiddenFields: ['records'],
  },
  (props, { hiddenFields }) => {
    const catalogId = props.catalog.get('id');
    const recordId = props.record.get('id');
    const errors = hiddenFields.getIn([catalogId, recordId, 'errors']);
    return {
      ...props,
      errors,
      hiddenFields,
    };
  },
);
