import { useState } from 'react';

export { useState } from 'react';

export const usePrompt = (props) => {
  const [value, setValue] = useState('');
  const isValid = (value) => props.value !== value;
  const onSave = (e) => {
    e.preventDefault();
    props.onOk(value);
  };

  const onChange = (e) => {
    const value = e.target.value.trim();
    setValue(value);
  };
  return { value, onSave, onChange, isValid };
};
