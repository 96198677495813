import _ from 'lodash';
import { matchPath } from 'react-router';
import routes from '../../../routes';

const _getRouteParams = _.memoize((pathname) => {
  for (const key in routes) {
    const route = routes[key];
    const match = matchPath(pathname, {
      path: route.path,
      exact: true,
      strict: false,
    });
    if (match) {
      return { route, match };
    }
  }
});

export default function getRouteParams(location) {
  return location ? _getRouteParams(location.pathname) : null;
}
