import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import batchRecordsActions from '../../../actions/batchRecordsActions';
import RecordsBatchDeleteRecord from './RecordsBatchDeleteForm';
import routes from '../../../routes';
import getLink from '../../common/router/getLink';
import { BATCH_RECORDS_STATUS } from '../../../configs/recordsBatchStatus';
import { connect } from '../../StateProvider';

function RecordsBatchDeleteControler(props) {
  RecordsBatchDeleteControler.propTypes = {
    viewId: PropTypes.string,
    catalogId: PropTypes.string.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    filter: ImmutablePropTypes.map.isRequired,
    sectionId: PropTypes.string,
    scene: ImmutablePropTypes.map.isRequired,
    sceneId: PropTypes.string.isRequired,
    batchDeleteRecord: ImmutablePropTypes.map.isRequired,
    openRecordsInModal: PropTypes.bool,
    onClose: PropTypes.func,
  };
  const mounted = useRef();
  const prevScene = useRef();

  const getRecords = () => {
    const { catalogId, sceneId, viewId } = props;
    batchRecordsActions.loadBatchRecords(sceneId, catalogId, viewId);
  };

  const onStartDelete = () => {
    const { catalogId, sceneId, viewId } = props;
    batchRecordsActions.startBatchDeleteRecords(sceneId, catalogId, viewId);
  };

  const onResumeUpdate = () => {
    const { catalogId, sceneId, viewId } = props;
    batchRecordsActions.startBatchDeleteRecords(sceneId, catalogId, viewId);
  };

  const onPauseUpdate = () => {
    const { sceneId } = props;
    batchRecordsActions.pauseBatchRecords(sceneId);
  };

  useEffect(() => {
    if (props.isReady) {
      onStartDelete();
    }
  }, [props.isReady]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else if (mounted.current && !props.scene && prevScene.current) {
      /* в случаях удаления записи должен быть произведен редирект, тк сам компонент записи недоступен */
      props.history.push(getLink(props.location, routes.records));
    }
    prevScene.current = props.scene;
  }, [props.scene]);

  useEffect(() => {
    // получаем все записи по фильтрам если изменились фильтры соответсвенно изменилось количество записей, а может и нет, но нужно убедиться...
    mounted.current && props.filter && getRecords();
  }, [props.filter]);

  useEffect(() => {
    // получаем все записи по фильтрам
    getRecords();
    return () => {
      const { catalogId, sceneId } = props;
      batchRecordsActions.clearBatch(catalogId, sceneId);
    };
  }, []);

  return (
    <RecordsBatchDeleteRecord
      sceneId={props.sceneId}
      catalogId={props.catalogId}
      scene={props.scene}
      catalog={props.catalog}
      onPauseUpdate={onPauseUpdate}
      onResumeUpdate={onResumeUpdate}
      onClose={props.onClose}
    />
  );
}

export default connect(
  withRouter(RecordsBatchDeleteControler),
  {
    scenes: ['scenes'],
    catalogs: ['catalogs'],
    records: ['records'],
  },
  (props, { scenes, records, catalogs }) => {
    const { sceneId, catalogId } = props;

    const scene = scenes.get(sceneId);

    const deletingStatus = scene.getIn(['data', 'batchStatus']);
    const isReady = deletingStatus === BATCH_RECORDS_STATUS.READY;

    const filter = scene.getIn(['data', 'filters']);
    const catalog = catalogs.get(catalogId);

    return {
      ...props,
      scene,
      catalog,
      isReady,
      filter,
    };
  },
);
