import React, { Component } from 'react';
import { Row } from 'antd';

import apiActions from '../../../actions/apiActions';
import FullMenu from './FullMenu';
import SectionsMenu from './SectionsMenu/index';
import Settings from './Settings';
import Widgets from './Widgets';
import RecordsCountIndicator from './RecordsCountIndicator';

import styles from './appHeader.less';

class AppHeader extends Component {
  componentDidMount() {
    apiActions.getVendor();
    apiActions.getProfile();
    apiActions.getCompanies();
    apiActions.getPrivileges();
    apiActions.getCompanyInfo();
    apiActions.getLicense();
    apiActions.getUsers();
    apiActions.getCatalogs(null);
  }

  render() {
    return (
      <Row type="flex" justify="start" align="middle" className={styles.container}>
        <Row type="flex" align="middle" className={styles.logoContainer}>
          <FullMenu />
        </Row>
        <RecordsCountIndicator />
        <SectionsMenu />
        <Row type="flex" align="middle" className={styles.containerSubmenu}>
          <Widgets />
          <Settings />
        </Row>
      </Row>
    );
  }
}

export default AppHeader;
