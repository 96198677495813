import React from 'react';
import cn from 'classnames';

import styles from './appHeader.less';

const Logo = function ({ ...props }) {
  return <img className={cn(styles.logoImg, props.className)} src="/logo/emblem" alt="" />;
};

export default Logo;
