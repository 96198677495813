import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { connect } from '../../../StateProvider';
import SCENE_CONTAINER from '../../../../configs/sceneContainer';
import SCENE_TYPE from '../../../../configs/sceneTypes';
import ModalSceneContainer from './ModalSceneContainer';
import Base from '../index';
import ScenesList from './ScenesList';
import sceneActions from '../../../../actions/sceneActions';

import styles from './multiModal.less';

class MultiModal extends React.PureComponent {
  onCloseModal = () => {
    sceneActions.setVisible(false);
  };

  onClose = (sceneId) => {
    sceneActions.closeScene(sceneId);
  };

  switchModal = (sceneId) => {
    sceneActions.switchModal(sceneId);
  };

  componentDidMount() {
    const { activeScene } = this.props;

    if (!activeScene) {
    }
  }

  render() {
    const { visible, modalScenes, activeScene, activeSceneId, initialSceneId, isWebForm } = this.props;

    if (!activeScene) {
      return null;
    }

    return (
      <Base onCancel={this.onCloseModal} visible={visible} wrapClassName={isWebForm ? styles.inWebForm : null}>
        {!isWebForm && (
          <div className={cn(styles.list)}>
            <ScenesList
              switchModal={this.switchModal}
              onClose={this.onClose}
              onCloseModal={this.onCloseModal}
              modalScenes={modalScenes}
              activeSceneId={activeSceneId}
              initialSceneId={initialSceneId}
            />
          </div>
        )}

        <div className={styles.record}>
          {modalScenes
            ? modalScenes.valueSeq().map((scene) => {
                const sceneId = scene.get('sceneId');
                const isActive = sceneId === activeSceneId;
                return (
                  <ModalSceneContainer
                    key={sceneId}
                    onClose={this.onClose}
                    isActive={isActive}
                    sceneId={sceneId}
                    isWebForm={isWebForm}
                  />
                );
              })
            : null}
        </div>
      </Base>
    );
  }
}

export default connect(
  MultiModal,
  {
    activeSceneId: ['modal', 'activeScene'],
    visible: ['modal', 'visible'],
    scenes: ['scenes'],
  },
  (props, { activeSceneId, visible, scenes }) => {
    const modalScenes = scenes && scenes.filter((scene) => scene && scene.get('container') === SCENE_CONTAINER.POPUP);
    const initialScene =
      modalScenes &&
      modalScenes.find((scene) => {
        const parentSceneId = scene.get('parentSceneId');
        const parentSceneContainer = scenes.getIn([parentSceneId, 'container']);
        return parentSceneContainer !== SCENE_CONTAINER.POPUP;
      });
    const initialSceneId = initialScene && initialScene.get('sceneId');
    const shouldClose = initialScene && initialScene.get('shouldClose');
    const activeScene = modalScenes && modalScenes.get(activeSceneId);
    return {
      ...props,
      visible,
      activeScene,
      activeSceneId,
      modalScenes,
      initialSceneId,
      shouldClose,
    };
  },
);
