import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import _ from 'lodash';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { Row, Col, Input } from 'antd';
import {
  AppstoreOutlined,
  CalendarOutlined,
  FundOutlined,
  HistoryOutlined,
  TableOutlined,
  LayoutOutlined,
} from '@ant-design/icons';

import { Switch, Route, withRouter } from 'react-router';
import { connect } from '../../../../../../../StateProvider';
import NavRoute from '../../../../../../../common/router/Route';
import NavRedirect from '../../../../../../../common/router/Redirect';
import routes from '../../../../../../../../routes';
import SCENE_CONTAINER from '../../../../../../../../configs/sceneContainer';

import ButtonClose from '../../../../../../../common/UI/ButtonClose';
import TabsMenu from '../../../../../../../common/UI/TabsMenu';
import UrlParamSync from '../../../../../../../UrlParamSync';

import CatalogActions from '../../CatalogHeader/catalogActions';
import ViewSelector from './ViewSelector';
import RecordsActivities from '../../../../../../../Records/RecordsActivities';
import PopoverFilter from './filterPopover';
import FieldFilter from '../../../../../../../History/FieldFilter';
import HistoryUserFilter from '../../../../../../../History/HistoryUserFilter';
import HistoryActionFilter from '../../../../../../../History/HistoryActionFilter';
import BoardViewMode from '../../../../../../../Reports/Header/BoardViewMode';

import filterActions from '../../../../../../../../actions/filterActions';
import userSettingsActions from '../../../../../../../../actions/userSettingsActions';

import styles from './viewHeader.less';

class ViewHeader extends Component {
  static propTypes = {
    catalog: PropTypes.object,
    viewId: PropTypes.string,
    isWebForm: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: this.searchText(),
      expandedQuickSearch: false, // Состояние, описывающее, раскрыт ли быстрый поиск
    };
  }

  getDefaultRoute(tabs) {
    let defaultViewMode;
    let defaultTab;

    // get from scene data
    const { scene } = this.props;
    defaultViewMode = scene.getIn(['data', 'viewMode']);

    // get from props
    if (!defaultViewMode) {
      defaultViewMode = this.props.defaultViewMode;
      defaultViewMode = defaultViewMode && defaultViewMode.get('value');
    }

    // get tab
    if (defaultViewMode) {
      defaultTab = tabs.find((t) => t.get('id') === defaultViewMode);
    }
    defaultTab = defaultTab || tabs.getIn([0]);
    return defaultTab.get('route');
  }

  setDefaultRoute(tab) {
    const tabId = tab && tab.get('id');
    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      option: 'defaultViewMode',
      value: tabId,
    });
  }

  searchText(props = this.props) {
    return props.scene ? props.scene.get('searchText') : '';
  }

  handleInputFocus = (e) => {
    // функция, изменяющая состояние
    this.setState(() => ({ expandedQuickSearch: true }));
  };

  handleInputBlur = () => {
    const text = this.state.searchText;
    const hasText = text || text === '0';
    this.setState(() => ({
      expandedQuickSearch: hasText,
    }));
  };

  onChangeSearch(text, viewMode) {
    this.setState(
      {
        searchText: text,
      },
      () => {
        filterActions.searchByText(
          this.props.sceneId,
          text,
          {
            viewId: this.props.viewId,
          },
          viewMode,
        );
      },
    );
  }

  onTableChangeSearch = (e) => {
    const text = e.target.value;
    this.onChangeSearch(text, 'table');
  };

  onTableChangeSearchFromURL = (text) => {
    this.onChangeSearch(text, 'table');
  };

  onCardsChangeSearch = (e) => {
    const text = e.target.value;
    this.onChangeSearch(text, 'cards');
  };

  onCardsChangeSearchFromURL = (text) => {
    this.onChangeSearch(text, 'cards');
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSearchText = this.searchText(nextProps);
    const searchText = this.searchText();
    if (this.state.searchText) {
      this.setState({ expandedQuickSearch: true });
    }

    if (newSearchText !== searchText) {
      this.setState({ searchText: newSearchText });
    }
  }

  onClose = () => {
    this.props.onClose && this.props.onClose(this.props.sceneId);
  };

  getCountRecord = () => this.props.scene && this.props.scene.get('recordsCount');

  checkDateFieldsInCatalog = () => {
    const { catalog } = this.props;
    const fields = catalog && catalog.get('fields');
    return fields && fields.some((field) => field.get('type') === 'date');
  };

  getTabsItems = () => {
    const { t } = this.props;

    let tabs = Immutable.List([
      Immutable.Map({
        id: 'table',
        icon: <TableOutlined />,
        color: '#429fc3',
        title: t('catalogData.types.table'),
        nameActive: t('catalogData.types.table'),
        route: routes.records,
      }),
      Immutable.Map({
        id: 'cards',
        icon: <AppstoreOutlined />,
        color: '#429fc3',
        title: t('catalogData.types.cards'),
        nameActive: t('catalogData.types.cards'),
        route: routes.cards,
      }),
    ]);

    if (this.checkDateFieldsInCatalog()) {
      tabs = tabs.concat(
        Immutable.List([
          Immutable.Map({
            id: 'calendar',
            icon: <CalendarOutlined style={{ verticalAlign: '-2px' }} />,
            color: '#28bb5d',
            title: t('catalogData.types.calendar'),
            nameActive: t('catalogData.types.calendar'),
            route: routes.calendar,
          }),
        ]),
      );
    }

    tabs = tabs.concat(
      Immutable.List([
        Immutable.Map({
          id: 'reports',
          icon: <FundOutlined />,
          color: '#c79e26',
          title: t('catalogData.types.reports'),
          nameActive: t('catalogData.types.reports'),
          route: routes.reports,
        }),

        Immutable.Map({
          id: 'pivot',
          icon: <LayoutOutlined />,
          color: '#c79e26',
          title: t('catalogData.types.pivotTables'),
          nameActive: t('catalogData.types.pivotTables'),
          route: routes.pivot,
        }),

        Immutable.Map({
          id: 'count',
          icon: (
            <span
              style={{
                position: 'relative',
                top: '-2px',
              }}
            >
              Σ
            </span>
          ),
          color: '#c79e26',
          title: t('catalogData.types.statistics'),
          nameActive: t('catalogData.types.statistics'),
          route: routes.count,
        }),

        Immutable.Map({
          id: 'history',
          icon: <HistoryOutlined />,
          color: '#d56e7c',
          title: t('catalogData.types.history'),
          nameActive: t('catalogData.types.history'),
          route: routes.history,
        }),
      ]),
    );

    return tabs;
  };

  render() {
    const { sceneId, scene, catalogId, catalog, viewId, filter, openRecordsInModal, isWebForm, leftPanel, t } =
      this.props;
    const fields = catalog && catalog.get('fields');
    const tabs = this.getTabsItems();

    const quickSearch = (
      <Col
        className={cn(styles.quickSearchContainer, {
          [styles.collapsed]: !this.state.expandedQuickSearch,
        })}
      >
        <Input
          allowClear
          placeholder={
            this.state.expandedQuickSearch
              ? this.props.t('catalogData.expandedQuickSearch')
              : this.props.t('catalogData.quickSearch')
          }
          className={styles.quickSearchInput}
          value={this.state.searchText}
          onChange={this.onTableChangeSearch}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
        />
        <UrlParamSync name="search" value={this.state.searchText} onChange={this.onCardsChangeSearchFromURL} />
      </Col>
    );

    const recordsCount = this.getCountRecord();

    const recordsCountText = !isNaN(recordsCount) ? t('record.groupRecords.count', { count: recordsCount }) : '';

    const inPopup = scene.get('container') === SCENE_CONTAINER.POPUP;
    const withPanel = inPopup ? false : leftPanel;
    const showTabs = !(isWebForm && scene.getIn(['data', 'viewMode']) && !inPopup); // not allow to sleect view mode in webform with strict viewMode. Allow only in popup

    return (
      <div className={styles.container}>
        <Row type="flex" justify="space-between" align="middle" className={styles.containerHeader}>
          {/* редирект на режим отображения */}
          <NavRoute
            route={routes.view}
            exact
            render={(props) => (
              /* добавил viewId в качестве параметра */
              <NavRedirect
                route={this.getDefaultRoute(tabs, {
                  viewId: this.props.viewId,
                })}
              />
            )}
          />

          {/* TITLE */}
          <Row align="middle">
            {!withPanel && <h2 className={styles.catalogName}>{catalog && catalog.get('name')}</h2>}
            {!leftPanel && !inPopup && <CatalogActions sceneId={sceneId} catalog={catalog} />}
            {!withPanel && <span className={styles.splitter} />}
            <span className={styles.recordsCount}>{recordsCountText}</span>
          </Row>

          {/* ACTIONS */}
          <Col className={styles.rightActions}>
            <Row>
              {catalog && (
                <Switch>
                  <Route
                    path={routes.records.path}
                    render={(props) => (
                      <RecordsActivities
                        catalog={catalog}
                        sceneId={sceneId}
                        viewId={viewId}
                        viewMode="table"
                        isWebForm={isWebForm}
                        openRecordsInModal={openRecordsInModal}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={routes.cards.path}
                    render={(props) => (
                      <RecordsActivities
                        catalog={catalog}
                        sceneId={sceneId}
                        viewId={viewId}
                        viewMode="cards"
                        isWebForm={isWebForm}
                        openRecordsInModal={openRecordsInModal}
                        createInPopup
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={routes.count.path}
                    render={(props) => (
                      <RecordsActivities
                        catalog={catalog}
                        sceneId={sceneId}
                        viewId={viewId}
                        viewMode="count"
                        isWebForm={isWebForm}
                        openRecordsInModal={openRecordsInModal}
                        createInPopup
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={routes.calendar.path}
                    render={(props) => (
                      <div style={{ display: 'flex' }}>
                        <RecordsActivities
                          catalog={catalog}
                          sceneId={sceneId}
                          viewId={viewId}
                          viewMode="calendar"
                          isWebForm={isWebForm}
                          openRecordsInModal={openRecordsInModal}
                          createInPopup
                          {...props}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={routes.reports.path}
                    render={(props) => (
                      <div className={styles.widgetsActivities}>
                        <BoardViewMode
                          catalog={catalog}
                          toggleColsWidthMode={this.props.toggleColsWidthMode}
                          colsMode={this.props.colsMode}
                          scene={scene}
                          sceneId={sceneId}
                          {...props}
                        />
                        <div className={styles.reportsActivities}>
                          <RecordsActivities
                            catalog={catalog}
                            sceneId={sceneId}
                            viewId={viewId}
                            viewMode="reports"
                            isWebForm={isWebForm}
                            openRecordsInModal={openRecordsInModal}
                            createInPopup
                            {...props}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path={routes.pivot.path}
                    render={(props) => (
                      <RecordsActivities
                        catalog={catalog}
                        sceneId={sceneId}
                        viewId={viewId}
                        viewMode="pivot"
                        isWebForm={isWebForm}
                        openRecordsInModal={openRecordsInModal}
                        createInPopup
                        {...props}
                      />
                    )}
                  />
                </Switch>
              )}
              {inPopup && <ButtonClose onClick={this.onClose} shiftRight />}
            </Row>
          </Col>
        </Row>
        <Row type="flex" align="middle" className={styles.containerFilter}>
          {/* TABS (view mode) */}
          {showTabs && (
            <NavRoute route={routes.record}>
              {(props) =>
                !props.match && (
                  <Col className={styles.tabsMenu}>
                    <TabsMenu items={tabs} onClick={_.bind(this.setDefaultRoute, this)} />
                  </Col>
                )
              }
            </NavRoute>
          )}

          {/* Views & Filters */}
          {!withPanel && (
            <Col flex="auto">
              <Row>
                <ViewSelector
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  catalog={catalog}
                  className={styles.viewsSelector}
                />
                <PopoverFilter sceneId={sceneId} catalogId={catalogId} viewId={viewId} catalog={catalog} />
              </Row>
            </Col>
          )}

          {/* SEARCH */}
          <NavRoute route={routes.records} render={(props) => quickSearch} />
          <NavRoute route={routes.cards} render={(props) => quickSearch} />
          <NavRoute route={routes.calendar} render={(props) => quickSearch} />
          <NavRoute route={routes.reports} render={(props) => quickSearch} />
          <NavRoute route={routes.count} render={(props) => quickSearch} />
          <NavRoute route={routes.pivot} render={(props) => quickSearch} />

          {/* EXTRA OPTIONS */}
          <Route
            path={routes.history.path}
            render={(props) => (
              <Col className={styles.rightActions}>
                <Row className={styles.historyFilterContainer}>
                  <HistoryActionFilter
                    filter={filter}
                    catalogId={this.props.catalogId}
                    sceneId={this.props.sceneId}
                    t={t}
                  />
                  <HistoryUserFilter filter={filter} catalogId={this.props.catalogId} sceneId={this.props.sceneId} />
                  <FieldFilter
                    getFieldsToRender={this.props.getFieldsToRender}
                    filter={filter}
                    sceneId={sceneId}
                    catalogId={this.props.catalogId}
                    fields={fields}
                    viewMode="catalogHistory"
                  />
                </Row>
              </Col>
            )}
          />
        </Row>
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    withRouter(ViewHeader),
    {
      catalogsHistory: ['catalogsHistory'],
      scenes: ['scenes'],
    },
    (props, { catalogsHistory, scenes }) => {
      const scene = scenes.get(props.sceneId);

      const filter = catalogsHistory.getIn([props.catalogId, 'filter']);

      return {
        scene,
        filter,
        ...props,
      };
    },
  ),
);
