import _ from 'lodash';
import Immutable from 'immutable';
import BasicSelect from './Basic';

import FIELD_TYPES from '../../../../../../configs/fieldTypes';

import * as AXIS_TYPES from '../../../../../../configs/reports/widget/axisTypes';

class SelectAxis extends BasicSelect {
  getAvailableValues(fields) {
    return _.compact(
      _.flatten(
        fields.toArray().map((field) => {
          const fieldId = field.get('id');
          const fieldName = field.get('name');

          switch (field.get('type')) {
            case FIELD_TYPES.DROPDOWN:
              return {
                type: AXIS_TYPES.FIELD,
                value: fieldId,
                title: fieldName,
              };
          }
        }),
      ),
    );
  }
}

export default SelectAxis;
