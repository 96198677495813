import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cn from 'classnames';
import Immutable from 'immutable';
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';

import styles from './styles.less';

const DEFAULT_ICON = 'interface-57'; // "communication-98"

function Indicator(props) {
  const { t } = useTranslation();
  const [content, setContent] = useState();
  const [icon, setIcon] = useState();

  useEffect(() => {
    const messages = props.indicator.get('messages');
    let content =
      messages &&
      messages.map((message) => {
        let headerText;
        let text;

        if (Immutable.Map.isMap(message)) {
          headerText = message.get('headerText') || t('import.errors.record.create');
          text = message.get('text');
        } else if (_.isObject(message)) {
          headerText = message.headerText || t('import.errors.record.create');
          text = message.text;
        } else {
          text = message;
        }

        return headerText && text ? `${headerText}\n${text}` : headerText || text;
      });

    if (content) {
      content = content.join('\n\n');
    }

    setContent(content);
  }, [props.indicator.get('messages')]);

  useEffect(() => {
    const icon = props.indicator.get('icon');

    setIcon(`icon ${icon || DEFAULT_ICON}`);
  }, [props.indicator.get('icon')]);

  if (!props.indicator) {
    return null;
  }

  const color = props.indicator.get('color') ? props.indicator.get('color') : null;
  const style = {};

  if (color) {
    style.color = tinycolor(color);
  }

  return (
    // count && <Badge
    //     count={count}
    //   >
    content ? (
      <Icon
        className={cn(props.iconClassName, styles.icon)}
        type={icon}
        title={content}
        style={style}
        onClick={props.onClick && props.onClick()}
      />
    ) : null
    // </Badge>
  );
}

Indicator.propTypes = {
  indicator: ImmutablePropTypes.map,
  onClick: PropTypes.func,
  iconClassName: PropTypes.string,
};

export default Indicator;
