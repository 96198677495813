// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__videoPlayer___g\\+zkZ {\n  cursor: pointer;\n  position: relative;\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: contain;\n  background-color: #000;\n  text-align: center;\n}\n.styles__playBtn___xKnsZ {\n  position: absolute !important;\n  left: 50% !important;\n  top: 50% !important;\n  transform: translate(-50%, -50%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/UI/VideoPlayer/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;AACF;AACA;EACE,6BAAA;EACA,oBAAA;EACA,mBAAA;EACA,gCAAA;AACF","sourcesContent":[".videoPlayer {\n  cursor: pointer;\n  position: relative;\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: contain;\n  background-color: #000;\n  text-align: center;\n}\n.playBtn {\n  position: absolute !important;\n  left: 50% !important;\n  top: 50% !important;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoPlayer": "styles__videoPlayer___g+zkZ",
	"playBtn": "styles__playBtn___xKnsZ"
};
export default ___CSS_LOADER_EXPORT___;
