import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import $ from 'jquery';
import styles from './controls.less';

class ProgressField extends React.PureComponent {
  constructor(props) {
    super(props);

    const val = this.getPercents(this.props.value) || 0;

    this.state = {
      isActive: false,
      value: val,
      savedValue: val,
    };

    this.refBar = React.createRef(null);
  }

  getPercents = (val) => Math.round(val * 0.2) * 5 || 0;

  onMouseDown = (e) => {
    if (!this.props.editable) {
      return;
    }

    this.setState({ isActive: true });
    // $('body').addClass('crm-user-select-none');
    $('body').on('mousemove', this.onMouseMove);
    $('body').on('mouseup', this.onMouseUp);
    this.onMouseMove(e);
  };

  onMouseMove = (e) => {
    const el = $(this.refBar.current);
    const { left } = el.offset();
    const width = el.width();
    const pos = e.clientX - left;
    const preValue = (Math.min(width, Math.max(0, pos)) / width) * 100;
    this.setState({ value: this.getPercents(preValue) });
  };

  onMouseUp = (e) => {
    // $('body').removeClass('crm-user-select-none');
    $('body').off('mousemove', this.onMouseMove);
    $('body').off('mouseup', this.onMouseUp);
    this.setState({
      isActive: false,
    });
    if (this.state.value !== this.state.savedValue) {
      this.props.onChange && this.props.onChange(this.state.value);
      this.props.onEndEditing && this.props.onEndEditing(this.state.value);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      const val = this.getPercents(nextProps.value);
      this.setState({
        value: val,
        savedValue: val,
      });
    }
  }

  componentWillUnmount() {
    $('body').off('mousemove', this.onMouseMove);
    $('body').off('mouseup', this.onMouseUp);
  }

  render() {
    const val = `${this.state.value}%`;
    return (
      <div style={this.props.style} className={cn(styles.progressContainer, this.props.wrapperClassName)}>
        <div className={styles.progressText}>{val}</div>
        <div ref={this.refBar} className={styles.progressBar} onMouseDown={this.onMouseDown}>
          <div className={styles.progressSlider} style={{ width: val }} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
          <div className={styles.progressDivider} />
        </div>
      </div>
    );
  }
}

ProgressField.propTypes = {
  value: PropTypes.number,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
};

export default ProgressField;
