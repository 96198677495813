import React from 'react';
import cn from 'classnames';

import Icon from './UI/Icon';

import styles from './controls.less';

function LoadingSpin(props) {
  let { className, spin, ...newProps } = props;

  spin = typeof spin === 'undefined' ? true : spin;
  if (spin) {
    className = cn(styles.loadingSpin, className);
  }

  return <Icon className={className} {...newProps} type="icon transfers-74" />;
}

export default LoadingSpin;
