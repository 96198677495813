import _ from 'lodash';

import { isVisibleByPrivilegeCode } from './isVisibleByPrivilegeCode';

export const filterByPrivilege = (tutorials, privilege) => {
  const result = _.each(tutorials, (item) =>
    _.each(item.items, (e) => (e.items = _.filter(e.items, (item) => isVisibleByPrivilegeCode(privilege, item)))),
  );
  return result;
};
