import { assign, forEach, isArray, get } from 'lodash';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isExpanded, isEventSubProcess } from 'bpmn-js/lib/util/DiUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';
import { getChildLanes } from 'bpmn-js/lib/features/modeling/util/LaneUtil';

import { hasPrimaryModifier } from 'diagram-js/lib/util/Mouse';

/**
 * A provider for BPMN 2.0 elements context pad
 */
export default function ContextPadProvider(
  eventBus,
  contextPad,
  modeling,
  elementFactory,
  bpmnFactory,
  connect,
  create,
  popupMenu,
  canvas,
  rules,
  translate,
  moddle,
) {
  contextPad.registerProvider(this);

  this._contextPad = contextPad;

  this._modeling = modeling;
  this._moddle = moddle;

  this._elementFactory = elementFactory;
  this._bpmnFactory = bpmnFactory;
  this._connect = connect;
  this._create = create;
  this._popupMenu = popupMenu;
  this._canvas = canvas;
  this._rules = rules;
  this._translate = translate;

  eventBus.on('create.end', 250, (event) => {
    const { shape } = event.context;

    if (!hasPrimaryModifier(event)) {
      return;
    }

    const entries = contextPad.getEntries(shape);

    if (entries.replace) {
      entries.replace.action.click(event, shape);
    }
  });
}

ContextPadProvider.$inject = [
  'eventBus',
  'contextPad',
  'modeling',
  'elementFactory',
  'bpmnFactory',
  'connect',
  'create',
  'popupMenu',
  'canvas',
  'rules',
  'translate',
  'moddle',
];

ContextPadProvider.prototype.getContextPadEntries = function (element) {
  const contextPad = this._contextPad;
  const modeling = this._modeling;
  const elementFactory = this._elementFactory;
  const bpmnFactory = this._bpmnFactory;
  const connect = this._connect;
  const create = this._create;
  const popupMenu = this._popupMenu;
  const canvas = this._canvas;
  const rules = this._rules;
  const translate = this._translate;
  const moddle = this._moddle;

  const actions = {};

  if (element.type === 'label') {
    return actions;
  }

  const { businessObject } = element;

  function startConnect(event, element, autoActivate) {
    // eslint-disable-line
    connect.start(event, element, autoActivate);
  }

  function removeElement(e) {
    modeling.removeElements([element]);
  }

  function getReplaceMenuPosition(element) {
    // eslint-disable-line
    const Y_OFFSET = 5;

    const diagramContainer = canvas.getContainer();
    const pad = contextPad.getPad(element).html;

    const diagramRect = diagramContainer.getBoundingClientRect();
    const padRect = pad.getBoundingClientRect();

    const top = padRect.top - diagramRect.top;
    const left = padRect.left - diagramRect.left;

    const pos = {
      x: left,
      y: top + padRect.height + Y_OFFSET,
    };

    return pos;
  }

  /**
   * Create an append action
   *
   * @param {String} type
   * @param {String} className
   * @param {String} [title]
   * @param {Object} [options]
   *
   * @return {Object} descriptor
   */
  function appendAction(type, className, title, options) {
    if (typeof title !== 'string') {
      options = title;
      switch (type) {
        case 'bpmn:TimerEventDefinition':
          title = 'Добавить выход по таймауту';
          break;
        case 'bpmn:ErrorEventDefinition':
          title = 'Добавить выход по ошибке';
          break;
        case 'bpmn:TextAnnotation':
          title = 'Добавить комментарий';
          break;
        default:
          title = translate('Добавить {type}', {
            type: type.replace(/^bpmn\:/, ''),
          });
      }
    }

    function appendListener(event, shape) {
      // eslint-disable-line
      let newShape = elementFactory.createShape(assign({ type }, options));
      const attachedToRef = shape.businessObject;
      if (type === 'bpmn:TimerEventDefinition') {
        const timerBusinessObject = bpmnFactory.create('bpmn:BoundaryEvent', {
          attachedToRef,
          eventDefinitions: [moddle.create('bpmn:TimerEventDefinition')],
        });
        newShape = elementFactory.createShape({
          type: 'bpmn:BoundaryEvent',
          businessObject: timerBusinessObject,
        });
        newShape.host = shape;
        newShape.width = 24;
        newShape.height = 24;
        create.start(event, newShape);
        return;
      }
      if (type === 'bpmn:ErrorEventDefinition') {
        newShape = elementFactory.createShape({
          type: 'bpmn:BoundaryEvent',
          businessObject: bpmnFactory.create('bpmn:BoundaryEvent', {
            attachedToRef,
            eventDefinitions: [moddle.create('bpmn:ErrorEventDefinition')],
          }),
        });

        newShape.host = shape;
        newShape.width = 24;
        newShape.height = 24;
        create.start(event, newShape);
        return;
      }
      create.start(event, newShape);
    }

    return {
      group: 'model',
      className,
      title,
      action: {
        dragstart: appendListener,
        click: appendListener,
      },
    };
  }

  function splitLaneHandler(count) {
    return (event, element) => {
      // eslint-disable-line
      // actual split
      modeling.splitLane(element, count);

      // refresh context pad after split to
      // get rid of split icons
      contextPad.open(element, true);
    };
  }

  if (isAny(businessObject, ['bpmn:Lane', 'bpmn:Participant']) && isExpanded(businessObject)) {
    const childLanes = getChildLanes(element);

    assign(actions, {
      'lane-insert-above': {
        group: 'lane-insert-above',
        className: 'bpmn-icon-lane-insert-above',
        title: translate('Add Lane above'),
        action: {
          click: (event, element) => {
            // eslint-disable-line
            modeling.addLane(element, 'top');
          },
        },
      },
    });

    if (childLanes.length < 2) {
      if (element.height >= 120) {
        assign(actions, {
          'lane-divide-two': {
            group: 'lane-divide',
            className: 'bpmn-icon-lane-divide-two',
            title: translate('Divide into two Lanes'),
            action: {
              click: splitLaneHandler(2),
            },
          },
        });
      }

      if (element.height >= 180) {
        assign(actions, {
          'lane-divide-three': {
            group: 'lane-divide',
            className: 'bpmn-icon-lane-divide-three',
            title: translate('Divide into three Lanes'),
            action: {
              click: splitLaneHandler(3),
            },
          },
        });
      }
    }

    assign(actions, {
      'lane-insert-below': {
        group: 'lane-insert-below',
        className: 'bpmn-icon-lane-insert-below',
        title: translate('Add Lane below'),
        action: {
          click: (event, element) => {
            // eslint-disable-line
            modeling.addLane(element, 'bottom');
          },
        },
      },
    });
  }

  // if (is(businessObject, 'bpmn:FlowNode')) {
  //   if (is(businessObject, 'bpmn:EventBasedGateway')) {
  //     assign(actions, {
  //       'append.receive-task': appendAction(
  //         'bpmn:ReceiveTask',
  //         'bpmn-icon-receive-task'
  //       ),
  //       'append.message-intermediate-event': appendAction(
  //         'bpmn:IntermediateCatchEvent',
  //         'bpmn-icon-intermediate-event-catch-message',
  //         { eventDefinitionType: 'bpmn:MessageEventDefinition' }
  //       ),
  //       'append.timer-intermediate-event': appendAction(
  //         'bpmn:IntermediateCatchEvent',
  //         'bpmn-icon-intermediate-event-catch-timer',
  //         { eventDefinitionType: 'bpmn:TimerEventDefinition' }
  //       ),
  //       'append.condtion-intermediate-event': appendAction(
  //         'bpmn:IntermediateCatchEvent',
  //         'bpmn-icon-intermediate-event-catch-condition',
  //         { eventDefinitionType: 'bpmn:ConditionalEventDefinition' }
  //       ),
  //       'append.signal-intermediate-event': appendAction(
  //         'bpmn:IntermediateCatchEvent',
  //         'bpmn-icon-intermediate-event-catch-signal',
  //         { eventDefinitionType: 'bpmn:SignalEventDefinition' }
  //       )
  //     });
  //   } else if (
  //     isEventType(
  //       businessObject,
  //       'bpmn:BoundaryEvent',
  //       'bpmn:CompensateEventDefinition'
  //     )
  //   ) {
  //     assign(actions, {
  //       'append.compensation-activity': appendAction(
  //         'bpmn:Task',
  //         'bpmn-icon-task',
  //         translate('Append compensation activity'),
  //         {
  //           isForCompensation: true
  //         }
  //       )
  //     });
  //   } else
  if (is(businessObject, 'bpmn:ServiceTask')) {
    assign(actions, {
      'append.timer-event': appendAction('bpmn:TimerEventDefinition', 'anticon-icon time-4'),
      'append.error-event': appendAction('bpmn:ErrorEventDefinition', 'anticon-icon edition-66'),
    });
  }

  // flows after Gateways
  if (isAny(businessObject, ['bpmn:SequenceFlow'])) {
    const CONDITIONAL_SOURCES = [
      'bpmn:Activity',
      'bpmn:ExclusiveGateway',
      'bpmn:InclusiveGateway',
      'bpmn:ComplexGateway',
    ];
    if (CONDITIONAL_SOURCES.includes(get(element.source, 'type'))) {
      let replaceMenu;

      if (popupMenu._providers['bpmn-replace']) {
        replaceMenu = popupMenu.create('bpmn-replace', element);
      }

      if (replaceMenu && !replaceMenu.isEmpty()) {
        // Replace menu entry
        assign(actions, {
          replace: {
            group: 'edit',
            className: 'anticon-icon setting-13',
            title: 'Изменить тип',
            action: {
              click: (event, element) => {
                // eslint-disable-line
                replaceMenu.open(
                  assign(getReplaceMenuPosition(element), {
                    cursor: { x: event.x, y: event.y },
                  }),
                  element,
                );
              },
            },
          },
        });
      }
    }
  }

  if (
    isAny(businessObject, [
      'bpmn:FlowNode',
      'bpmn:InteractionNode',
      'bpmn:DataObjectReference',
      'bpmn:DataStoreReference',
    ])
  ) {
    assign(actions, {
      'append.text-annotation': appendAction('bpmn:TextAnnotation', 'anticon-icon communication-81'),

      connect: {
        group: 'connect',
        className: 'anticon-icon keyboard-15',
        title: translate('Добавить связь'),
        action: {
          click: startConnect,
          dragstart: startConnect,
        },
      },
    });
  }

  if (isAny(businessObject, ['bpmn:DataObjectReference', 'bpmn:DataStoreReference'])) {
    assign(actions, {
      connect: {
        group: 'connect',
        className: 'bpmn-icon-connection-multi',
        title: translate('Добавить связь'),
        action: {
          click: startConnect,
          dragstart: startConnect,
        },
      },
    });
  }

  // delete element entry, only show if allowed by rules
  let deleteAllowed = rules.allowed('elements.delete', { elements: [element] });

  if (isArray(deleteAllowed)) {
    // was the element returned as a deletion candidate?
    deleteAllowed = deleteAllowed[0] === element;
  }
  if (deleteAllowed) {
    assign(actions, {
      delete: {
        group: 'edit',
        className: 'anticon-icon edition-43',
        title: translate('Удалить'),
        action: {
          click: removeElement,
          dragstart: removeElement,
        },
      },
    });
  }

  return actions;
};

function isEventType(eventBo, type, definition) {
  const isType = eventBo.$instanceOf(type);
  let isDefinition = false;

  const definitions = eventBo.eventDefinitions || [];
  forEach(definitions, (def) => {
    if (def.$type === definition) {
      isDefinition = true;
    }
  });

  return isType && isDefinition;
}
