import React, { useEffect } from 'react';
import { Menu, Dropdown } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ButtonTransparent from '../../UI/ButtonTransparent';
import i18n from '../../../../configs/i18n';
import styles from './styles.less';

const langMap = [
  { extCode: 'ru-RU', target: 'ru', title: 'Русский' },
  { extCode: 'en-US', target: 'en', title: 'English' },
];

function LanguageSwitcher() {
  const { i18n: i18nInner } = useTranslation();

  useEffect(() => {
    /**
     * By default lang detector set "ru-RU" code instead of "ru"
     * To get correct translations we switch to "ru" if needed
     */
    for (const langObj of langMap) {
      if (i18nInner.language === langObj.extCode) {
        i18n.changeLanguage(langObj.target);
        break;
      }
    }
  }, [i18nInner.language]);

  const changeLanguage = (lng) => {
    // Reload application after language changed
    window.location.reload();
    i18n.changeLanguage(lng);
  };

  const menu = (
    <Menu>
      {langMap.map((lang) => (
        <Menu.Item key={lang.target} onClick={() => changeLanguage(lang.target)}>
          {lang.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown dropdownRender={() => menu} trigger={['click']} overlayClassName={styles.langWidgetMenu}>
      <ButtonTransparent className={cn('ant-dropdown-link', styles.widgetButton)} onClick={(e) => e.preventDefault()}>
        <p className={styles.langPreview}>{i18n.language}</p>
      </ButtonTransparent>
    </Dropdown>
  );
}

export default LanguageSwitcher;
