const codes = {
  AVAILABLE: 'available',
  SEARCH: 'search',
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
  EXPORT: 'export',
  DELETE: 'delete',
  ACCESS: 'access',
  ADMIN: 'admin',
  DENY: 'deny',
};

export default codes;

export const privilegePriorities = [
  codes.AVAILABLE,
  codes.SEARCH,
  codes.VIEW,
  codes.EDIT,
  codes.CREATE,
  codes.EXPORT,
  codes.DELETE,
  codes.ACCESS,
  codes.ADMIN,
  codes.DENY,
];
