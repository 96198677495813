import _ from 'lodash';
import getRouteParams from './getRouteParams';

export default function link(location, route, params = {}) {
  const { match, route: _route } = getRouteParams(location) || {
    match: { params: {}, route: { path: '' } },
  };

  const curentCatalogId = _.get(match, ['params', 'catalogId']);
  const targetCatalogId =
    _.get(params, ['catalogId']) || _.get(match, ['params', 'catalogId']) || _.get(route, ['defaults', 'catalogId']);

  const sameCatalog = curentCatalogId && targetCatalogId && curentCatalogId == targetCatalogId;
  const addQueryParams = sameCatalog;

  const search = location && location.search;

  route = route || _route;
  const link = route.path
    .split('/')
    .map((path) => {
      if (path[0] === ':') {
        const paramName = path.slice(1);
        return !_.isUndefined(params[paramName])
          ? params[paramName]
          : !_.isUndefined(match.params[paramName])
            ? match.params[paramName]
            : route.defaults[paramName];
      }
      return path;
    })
    .join('/');

  return addQueryParams
    ? {
        pathname: link,
        search,
      }
    : { pathname: link };
}
