import React, { Component } from 'react';
import { Modal, Button, Col, Input, Row } from 'antd';
import ButtonClose from '../UI/ButtonClose';
import styles from './modal.less';

class Confirm extends Component {
  render() {
    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        footer={[
          <Button key="submit" type="danger" onClick={this.props.onOk}>
            {this.props.okText}
          </Button>,
          <Button key="back" type="default" onClick={this.props.onCancel}>
            {this.props.cancelText}
          </Button>,
        ]}
      >
        <Row type="flex" justify="space-between" align="middle" className={styles.header}>
          <h1>{this.props.headerText}</h1>
          <ButtonClose large className={styles.close} shiftRight onClick={this.props.onCancel} />
        </Row>
        <Col className={styles.content}>
          <p>{this.props.text}</p>
          {this.props.confirmation && <Input type="text" />}
        </Col>
      </Modal>
    );
  }
}

export default Confirm;
