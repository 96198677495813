import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Row } from 'antd';

import { connect } from '../../StateProvider';
import TabsMenu from '../../common/UI/TabsMenu';
import Properties from './Properties';
import Visibility from './Visibility';
import editorActions from '../../../actions/editorActions';

import styles from './propertyPanel.less';

class PropertyPanel extends PureComponent {
  static propTypes = {
    sectionId: PropTypes.string.isRequired,
    catalog: PropTypes.object.isRequired,
    fieldIndex: PropTypes.any,
    field: PropTypes.object,
  };

  state = {
    tab: 'properties',
  };

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  onTabChange(tabId) {
    this.setState({ tab: tabId });
  }

  onSaveField = (field) => {
    editorActions.updateField(this.props.sectionId, this.props.fieldIndex, field);
  };

  render() {
    const { field } = this.props;

    // tabs
    const { tab } = this.state;
    const visibilityCount = (field && field.get('visible').size) || '';

    const tabs = Immutable.List([
      Immutable.Map({
        id: 'properties',
        name: this.props.t('catalogEditor.fieldProperties.tabs.properties'),
      }),
      Immutable.Map({
        id: 'visibility',
        name: `${this.props.t('catalogEditor.fieldProperties.tabs.visibility')} ${visibilityCount}`,
      }),
    ]);

    // tab data
    let TabData;
    if (!this.props.field) {
      TabData = (
        <div className={styles.noFieldSelected}>{this.props.t('catalogEditor.fieldProperties.noFieldSelected')}</div>
      );
    } else if (tab == 'properties') {
      TabData = <Properties catalog={this.props.catalog} field={field} onSaveField={this.onSaveField} />;
    } else if (tab == 'visibility') {
      TabData = <Visibility catalog={this.props.catalog} field={this.props.field} onSaveField={this.onSaveField} />;
    }
    return (
      <div className={styles.container}>
        <Row type="flex" align="middle" className={styles.header}>
          <TabsMenu activeId={tab} items={tabs} onClick={(tab) => this.onTabChange(tab)} />
        </Row>
        <div className={styles.tabData}>{TabData}</div>
      </div>
    );
  }
}

export default withTranslation()(
  connect(PropertyPanel, ['editingCatalogs'], ({ ...props }, { editingCatalogs }) => {
    const { sectionId } = props.match.params;
    const fieldIndex = props.selectedFieldIndex;
    const catalog = editingCatalogs.getIn([sectionId]);
    const field = catalog.getIn(['fields', fieldIndex]);
    return {
      ...props,
      sectionId,
      catalog,
      fieldIndex,
      field,
    };
  }),
);
