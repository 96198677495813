import { format } from './parameter';

export { parseInput, parseOutput } from './parameters';
// export const parseInput = params => parse(params);
// export const parseOutput = params => parse(params);

export const formatInput = (factory, businessObject, parameter) =>
  format(factory, businessObject, 'camunda:InputParameter', parameter);

export const formatOutput = (factory, businessObject, parameter) =>
  format(factory, businessObject, 'camunda:OutputParameter', parameter);
