import React, { useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from './history.less';
import historyActions from '../../actions/historyActions';

function HistoryActionFilter({ catalogId, recordId, sceneId, filter, t }) {
  const [selectedActions, setSelectedActions] = useState();

  const allActions = [
    {
      value: 'allActions',
      label: t('catalogData.historyActions.allActions'),
    },
    {
      value: 'create',
      label: t('catalogData.historyActions.create'),
    },
    {
      value: 'update',
      label: t('catalogData.historyActions.update'),
    },
    {
      value: 'remove',
      label: t('catalogData.historyActions.remove'),
    },
  ];

  const onSelectAction = (value) => {
    if (value === 'allActions') {
      setSelectedActions(value);
      historyActions.setFilter({ ...filter, actionType: null }, { catalogId, recordId });
      historyActions.loadHistory(sceneId, catalogId, recordId, {}, true);
    } else {
      setSelectedActions(value);
      historyActions.setFilter({ ...filter, actionType: [value] }, { catalogId, recordId });
      historyActions.loadHistory(sceneId, catalogId, recordId, {}, true);
    }
  };

  return (
    <Select
      defaultValue="allActions"
      onChange={onSelectAction}
      value={selectedActions}
      options={allActions}
      className={styles.actionSelect}
      size="middle"
    />
  );
}

HistoryActionFilter.propTypes = {
  catalogId: PropTypes.string,
  recordId: PropTypes.string,
};

export default withTranslation()(HistoryActionFilter);
