import _ from 'lodash';

import * as COLORS from '../colors';
import FIELD_TYPES from '../fieldTypes';

export const RECORD_STATUSES = {
  PARSE_COMPLETED: '1',
  PARSE_FAILED: '2',
  IMPORT_COMPLETED: '3',
  IMPORT_FAILED: '4',
  EXCLUDED_FROM_IMPORT: '5',
  PARSE_LOADING: '6',
};

export const VALUE_STATUSES = {
  LOADING: 'loading',
  VALID: 'valid',
  INVALID: 'invalid',
};

export const IMPORT_STATUS = {
  STOPED: {
    STATUS: 'stoped',
    COLOR: COLORS.RED,
  },
  SAVING: {
    STATUS: 'saving',
    COLOR: COLORS.BLUE,
  },
  COMPLETED: {
    STATUS: 'completed',
    COLOR: COLORS.GREEN,
  },
  LOADING: {
    STATUS: 'loading',
    COLOR: COLORS.GREEN,
  },
};

export const UPDATE_TIME = 2500; // 2.5s for update records in table

export const FIELDS_WITH_UPDATE = [FIELD_TYPES.ADDRESS, FIELD_TYPES.OBJECT, FIELD_TYPES.USER];

export const ALLOWED_TYPES = [
  FIELD_TYPES.ADDRESS,
  FIELD_TYPES.OBJECT,
  FIELD_TYPES.USER,
  FIELD_TYPES.TEXT,
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.DATE,
  FIELD_TYPES.CONTACT,
  FIELD_TYPES.DROPDOWN,
  FIELD_TYPES.SWITCH,
  FIELD_TYPES.BUTTON,
  FIELD_TYPES.CHECKBOXES,
  FIELD_TYPES.RADIOBUTTON,
  FIELD_TYPES.PROGRESS,
  FIELD_TYPES.STARS,
  FIELD_TYPES.FILE,
  FIELD_TYPES.PAIR,
  FIELD_TYPES.GROUP,
];
export const DEFAULT_PROGRESS_COLOR = COLORS.BLUE;
export const EMPTY_FIELD_ID = '$EMPTY_FIELD_ID';
export const RECORD_STATUS_FIELD_ID = '$RECORD_STATUS';
export const DEFAULT_GROUP_ID = '$DEFAULT_GROUP_ID';
