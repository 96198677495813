import Immutable from 'immutable';

import i18n from '../../../../../configs/i18n';
import FIELD_TYPES from '../../../../../configs/fieldTypes';

function outMapper({ id, value, subValue }) {
  return Immutable.fromJS({
    id,
    contact: value,
    comment: subValue,
  });
}

function config({ field, value }) {
  if (value) {
    value = value.map((item) =>
      Immutable.fromJS({
        id: item.get('id'),
        value: item.get('contact'),
        subValue: item.get('comment'),
      }),
    );
  }
  field = field.setIn(['config', 'placeholderSubValue'], i18n.t('record.fields.contact.commentPlaceHolder'));

  field = field.set('type', FIELD_TYPES.PAIR);

  return { field, value };
}

function onChange(values) {
  return Immutable.fromJS(values.map(outMapper));
}

function onEndEditing(values) {
  return Immutable.fromJS(values.map(outMapper));
}

export default {
  config,
  onChange,
  onEndEditing,
};
