import FIELD_TYPES from './fieldTypes';
import { RADIOBUTTON_LIST, CONTACT_LIST, TEXT_LIST } from './fieldSubTypes';

export default {
  [FIELD_TYPES.GROUP]: 'text-56',
  [FIELD_TYPES.TAB]: 'programing-24',

  [FIELD_TYPES.TEXT]: 'text-27',
  [FIELD_TYPES.CONTACT]: 'communication-2',
  [FIELD_TYPES.ADDRESS]: 'places-40',
  [FIELD_TYPES.NUMBER]: 'social-threewords',
  [FIELD_TYPES.DATE]: 'time-2',

  [FIELD_TYPES.DROPDOWN]: 'business-20',
  [FIELD_TYPES.SWITCH]: 'health-5',
  [FIELD_TYPES.BUTTON]: 'edition-66',
  [FIELD_TYPES.CHECKBOXES]: 'status-2',
  [FIELD_TYPES.RADIOBUTTON]: 'text-36',

  [FIELD_TYPES.USER]: 'users-1',
  [FIELD_TYPES.OBJECT]: 'edition-50',
  [FIELD_TYPES.FILE]: 'files-13',

  [FIELD_TYPES.PROGRESS]: 'business-24',
  [FIELD_TYPES.STARS]: 'vote-38',

  [CONTACT_LIST.EMAIL]: 'communication-61',
  [CONTACT_LIST.PHONE]: 'communication-2',
  [CONTACT_LIST.SITE]: 'interface-3',

  [RADIOBUTTON_LIST.RADIOBUTTONGROUP]: 'social-orkut',
  [RADIOBUTTON_LIST.RADIOBUTTONSELECT]: 'arrows-chevron-medium-1-01',

  [TEXT_LIST.TEXTEDITOR]: 'text-16',
};
