import _ from 'lodash';
import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from '../StateProvider';
import AccessModal from './AccessModal';

class AccessModalController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      parents: new Immutable.List(),
      rules: null,
      isLoading: null,
    };
  }

  updateStateFromProps(props) {
    let parentsLoadingComplete = true;
    let parentCatalog = null;
    let parentSection = null;

    const objectInfo = this.getInfoForObject(props, props.object);

    // parent objects info
    let newParents = this.state.parents;
    if (props.parents) {
      const parentRights = props.parents.map((object) => {
        const parentRight = this.getInfoForObject(props, object);
        parentRight.object = object;
        parentsLoadingComplete = parentsLoadingComplete && parentRight.loadingComplete;

        if (object.sectionId) {
          parentSection = this.props.sections.find((o) => o.get('id') === object.sectionId);
        } else if (object.catalogId) {
          parentCatalog = this.props.catalogs.find((o) => o.get('id') === object.catalogId);
        }

        return parentRight;
      });

      newParents = newParents.mergeDeep(parentRights);

      newParents.forEach((o, i) => {
        const rules = o.get('rules');
        if (rules) {
          const newParentObj = o.set('rules', rules.setSize(_.get(parentRights, [i, 'rules', 'size'], 0)));
          newParents = newParents.set(i, newParentObj);
        }
      });
    }

    newParents = newParents.setSize(_.get(props, 'parents.length', 0));

    this.setState({
      parents: newParents,
      rules: objectInfo.rules,
      loadingComplete: objectInfo.loadingComplete && parentsLoadingComplete,
      parentCatalog,
      parentSection,
    });
  }

  UNSAFE_componentWillMount() {
    this.updateStateFromProps(this.props);
  }

  getInfoForObject = (props, object) => {
    const rightsCollection = props.rights;

    const rightsObject = rightsCollection.find((ro) => {
      let roObject = ro.getIn(['object']);
      roObject = roObject && roObject.toJS ? roObject.toJS() : roObject;
      return _.isEqual(roObject, object);
    });
    const rules = rightsObject && rightsObject.getIn(['rules']);
    const loadingComplete = rightsObject && rightsObject.getIn(['loadingComplete']);

    return { rules, loadingComplete };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateStateFromProps(nextProps);
  }

  render() {
    const { rules, loadingComplete, parents, parentCatalog, parentSection } = this.state;

    const { resource } = this.props;
    const privilegeCodes = this.props.privilegeCodesByResource.get(resource);
    return (
      <AccessModal
        resource={resource}
        privilegeCodes={privilegeCodes}
        object={this.props.object}
        rules={rules}
        parents={parents}
        readOnly={this.props.readOnly}
        hasAdminRule={this.props.hasAdminRule}
        isAdmin={this.props.isAdmin}
        isLoading={!loadingComplete}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        parentCatalog={parentCatalog}
        parentSection={parentSection}
        ref={this.props.modalRef}
        catalogs={this.props.catalogs}
        isValidRules={this.props.isValidRules}
      />
    );
  }
}

AccessModalController.propTypes = {
  object: PropTypes.object.isRequired,
  parents: PropTypes.array,
  resource: PropTypes.string.isRequired,
  isValidRules: PropTypes.func,
};

export default withTranslation()(
  connect(AccessModalController, ['sections', 'catalogs', 'privilegeCodesByResource', 'rights']),
);
