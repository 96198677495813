import { InputMappers, OutputMappers } from './helpers/mappers';
import i18n from '../../../../configs/i18n';

const Properties = (field, t = i18n.t) => ({
  properties: [
    {
      id: 'generalPropertiesSection',
      name: t('catalogEditor.fieldProperties.generalProperties.section'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('catalogEditor.fieldProperties.generalProperties.name'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
      },
    },
    {
      id: 'hint',
      name: t('catalogEditor.fieldProperties.generalProperties.hint'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
      },
    },
    {
      id: 'required',
      name: '',
      type: 'checkboxes',
      readOnly: false,
      config: {
        items: [
          {
            id: 'true',
            name: t('catalogEditor.fieldProperties.generalProperties.required'),
          },
        ],
      },
    },
    {
      id: 'filterable',
      name: t('catalogEditor.fieldProperties.technicalProperties.filterable.label'),
      type: 'checkboxes',
      readOnly: false,
      config: {
        items: [
          {
            id: 'true',
            name: t('catalogEditor.fieldProperties.technicalProperties.filterable.value'),
          },
        ],
      },
    },
    {
      id: 'history',
      name: t('catalogEditor.fieldProperties.technicalProperties.history.label'),
      type: 'checkboxes',
      readOnly: false,
      config: {
        items: [
          {
            id: 'true',
            name: t('catalogEditor.fieldProperties.technicalProperties.history.value'),
          },
        ],
      },
    },
    {
      id: 'apiOnly',
      name: t('catalogEditor.fieldProperties.technicalProperties.apiOnly.label'),
      type: 'checkboxes',
      readOnly: false,
      config: {
        items: [
          {
            id: 'true',
            name: t('catalogEditor.fieldProperties.technicalProperties.apiOnly.value'),
          },
        ],
      },
    },
  ],

  values: {
    name: field.get('name'),
    hint: field.get('hint'),
    required: InputMappers.boolToCheckbox(field.get('required')),
  },

  getValues: (property, value) => {
    switch (property) {
      case 'required':
        value = OutputMappers.checkboxToBool(value);
        break;
    }
    return { [property]: value };
  },
});

export default Properties;
