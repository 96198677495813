import Mime from 'mime-types';
import debug from 'debug';
import Immutable from 'immutable';
import _ from 'lodash';

import apiActions from '../actions/apiActions';

const log = debug('CRM:Store:fileMixin');

export default {
  uploadFile(props) {
    const {
      file,
      xhrs,
      uploadId = Math.random(),
      updateProgress,
      pushUploadListeners,
      uploadCanceled,
      completeUpload,
      removeUploadingFile,
      controlConfig,
      setAttachments,
      setErrorOnFile,
      array = false,
    } = props;

    log('start uploading file', uploadId);

    const params = {
      name: file.name,
      size: file.size,
      mimeType: file.type || Mime.lookup(file.title),
      typeStorage: 'remoteStorage',
    };

    return apiActions
      .uploadFileRecord((controlConfig && controlConfig) || {}, params, uploadId)
      .then((data) => {
        const { fileId } = data;
        log('Link upload file', uploadId, data);

        const fd = new FormData();
        fd.append('key', data.fileKey);
        fd.append('acl', data.acl);
        fd.append('Content-Type', file.type ? file.type : '');
        fd.append('AWSAccessKeyId', data.AWSAccessKeyId);
        fd.append('Policy', data.police);
        fd.append('Signature', data.signature);
        fd.append('file', file);

        const xhr = new XMLHttpRequest();
        xhrs[uploadId] = xhr;

        const progressListener = (progress) => {
          // todo: progressListener colbeck
          log('progress file', uploadId, progress);
          updateProgress && updateProgress(uploadId, ((progress.loaded / progress.total) * 100).toFixed(2));
        };

        xhr.upload.addEventListener('progress', progressListener, false);
        pushUploadListeners && pushUploadListeners('progress', progressListener);
        const _data = data;

        const loadListener = (res) => {
          const data = {
            name: file.name,
            size: file.size,
            mimeType: file.type,
            url: _data.fileKey,
          };
          apiActions.updateFileRecord({ fileId }, data).then((res, ...args) => {
            const newFile = Immutable.Map({
              id: res.id,
              title: res.title,
              mimeType: res.mimeType,
              size: file.size,
              url: res.url,
              loading: false,
            });
            if (array) {
              const index = array.findIndex((f) => f.get('id') == uploadId);
              if (index !== -1) {
                setAttachments((prevState) => prevState.push(newFile));
                uploadCanceled();
              }
            }
            completeUpload && completeUpload(uploadId, newFile);
          });
        };

        xhr.addEventListener('load', loadListener, false);
        pushUploadListeners && pushUploadListeners('load', loadListener);

        const errorListener = (data) => {
          setErrorOnFile && setErrorOnFile(uploadId);
        };

        xhr.addEventListener('error', errorListener, false);
        pushUploadListeners && pushUploadListeners('error', errorListener);

        xhr.addEventListener('abort', uploadCanceled && uploadCanceled(data), false);

        xhr.open('POST', data.uploadUrl, true);
        xhr.send(fd);
      })
      .catch(() => {
        // remove if failed create.
        removeUploadingFile && removeUploadingFile({ id: uploadId });
        uploadCanceled && uploadCanceled();
      });
  },
};
