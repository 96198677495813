import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import Icon from '../../common/UI/Icon';
import dndContext from '../../../services/dndContext';
import FieldTypesItem from './FieldTypesItem';
import { extendedFieldTypes } from '../../../configs/extendedFieldTypes';
import styles from './fieldsPanel.less';

class FieldsPanel extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.fieldInfo}>
          {this.props.t('catalogEditor.dragFieldToEditor')}
          <Icon type="icon interface-6" />
        </div>

        <div>
          {_.map(extendedFieldTypes, (fieldType) => (
            <FieldTypesItem
              key={`${fieldType.type}:${fieldType.title}`}
              type={fieldType.type}
              icon={fieldType.icon}
              title={fieldType.title}
              config={fieldType.config}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(dndContext(FieldsPanel));
