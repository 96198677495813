import React from 'react';
import Immutable from 'immutable';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Section from './Section';
import FIELD_TYPES from '../../../configs/fieldTypes';

class RightsFields extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
    };
  }

  componentDidMount() {
    this.setState({
      values: this.props.values,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.values) {
      this.setState({
        values: nextProps.values,
      });
    }
  }

  render() {
    const sections = [];
    let _curGroup;
    const { fields } = this.props;
    if (fields) {
      fields.forEach((field) => {
        if (field.get('type') === FIELD_TYPES.GROUP) {
          _curGroup = {
            id: field.get('id'),
            section: field,
            fields: [],
          };
          sections.push(_curGroup);
        } else {
          if (!_curGroup) {
            _curGroup = {
              id: '',
              section: Immutable.fromJS({ name: '', type: FIELD_TYPES.GROUP }),
              fields: [],
            };
            sections.push(_curGroup);
          }
          _curGroup.fields.push(field);
        }
      });
    }

    const sectionsComponents = sections.map((sec) => (
      <Section
        key={sec.id}
        catalogId={this.props.catalogId}
        object={this.props.object}
        subjectPrivilege={this.props.subjectPrivilege}
        section={sec.section}
        fields={sec.fields}
        values={this.state.values}
        onSaveField={this.props.onSaveField}
        basePrivilege={this.props.basePrivilege}
      />
    ));
    return <div>{sectionsComponents}</div>;
  }
}

RightsFields.propTypes = {
  catalogId: PropTypes.string,
  object: PropTypes.object,
  fields: PropTypes.object,
  onSaveField: PropTypes.func,
  basePrivilege: PropTypes.string,
};

export default RightsFields;
