import debug from 'debug';
import _ from 'lodash';
import Immutable from 'immutable';
// import apiActions from '../actions/apiActions';
import filterActions from '../actions/filterActions';
import filtersUtil from '../utils/filters';
import recordActions from '../actions/recordActions';
import ViewFactory from '../models/ViewFactory';
// import historyActions from '../actions/historyActions';
import i18n from '../configs/i18n';

const log = debug('CRM:store:filtersMixin');

export default {
  init() {
    this.listenToMany(filterActions);
  },

  _updateViewFilter(params, value) {
    if (value !== null) {
      this.$setViewFilter(params, value);
    } else {
      this.$removeViewFilter(params);
    }

    // set View changed
    const newView = Number(params.viewId) === 0 || params.viewId === '$new';
    if (!newView) {
      this.setIn(['scenes', params.sceneId, 'views', params.viewId, 'filtersChanged'], true);
    }
  },

  _updateSceneFilters(params, value) {
    if (value !== null) {
      this.$setSceneFilter(params, value);
    } else {
      this.$removeSceneFilter(params);
    }
  },

  /**
   * @param params Object(fieldId, catalogId)
   * @param value Any
   */
  updateFieldFilter(params, value) {
    if (!params.viewId) {
      this._updateSceneFilters(params, value);
    } else {
      this._updateViewFilter(params, value);
    }

    this.changed();
  },

  _createNewView(sceneId, catalogId) {
    let newView = this.getIn(['scenes', sceneId, 'views', '$new']);
    if (!newView) {
      newView = ViewFactory.create({
        id: '$new',
        index: Infinity,
        name: i18n.t('views.newView'),
        catalogId,
        filtersChanged: true,
      });
      this.setIn(['scenes', sceneId, 'views', '$new'], newView);
    }
  },

  updateFieldFilters(params, filters) {
    // из query парметров приходит js объект далее мы его переводим в имутабельный... Как это сделано в остальных экшенах.
    // из query параметров приходят значения их мы сетаем вместо тех которые были
    let path = filtersUtil.getViewFilterPath(params);
    // getViewFilterPath получем массив пути до конкретного фильтра,
    // а нам нужен путь до всех фильтров, поэтому последний элемент, который undefined уничтожаем
    path = path.filter((item) => item !== undefined);
    // создание нового вида
    const newView = Number(params.viewId) === 0 || params.viewId === '$new';
    if (newView) {
      this._createNewView(params.sceneId, params.catalogId);
    } else {
      this.setIn(['scenes', params.sceneId, 'views', params.viewId, 'filtersChanged'], true);
    }
    this.setIn(path, Immutable.fromJS(filters));
    this.changed();
  },

  searchByText(sceneId, searchText, { viewId }, viewMode = 'table') {
    // save search text to store.
    const catalogId = this.getIn(['scenes', sceneId, 'params', 'catalogId']);
    this.setIn(['scenes', sceneId, 'searchText'], searchText);
    recordActions.requestForRecords(catalogId, sceneId, { viewId }, viewMode);
  },

  filterByNewMessages(sceneId, filter, { viewId }, viewMode = 'table') {
    // save flag whether to filter by new messages to store.
    const catalogId = this.getIn(['scenes', sceneId, 'params', 'catalogId']);
    this.setIn(['scenes', sceneId, 'filterNewMessages'], filter);
    recordActions.requestForRecords(catalogId, sceneId, { viewId }, viewMode);
  },

  $setViewFilter(path, value) {
    if (!this.getIn(['scenes', path.sceneId, 'views'])) {
      return;
    }

    // create new view
    const newView = Number(path.viewId) === 0 || path.viewId === '$new';
    if (newView) {
      this._createNewView(path.sceneId, path.catalogId);
    }

    // log('Set field', value, path);
    const filterPath = filtersUtil.getViewFilterPath(path);
    const filter = Immutable.fromJS({
      value,
    });

    const filtersExistAndTheyAreArray = this.getIn(filterPath) && Immutable.List.isList(this.getIn(filterPath));

    if (filtersExistAndTheyAreArray) {
      this.setIn([...filterPath, path.filterId], filter);
    } else {
      this.setIn(filterPath, Immutable.fromJS([filter]));
    }
  },

  $removeViewFilter(path) {
    const fieldFilters = this.getIn(['scenes', path.sceneId, 'views', path.viewId, 'filters', path.fieldId]);
    if (fieldFilters) {
      if (fieldFilters.size > 1) {
        this.deleteIn([...filtersUtil.getViewFilterPath(path), path.filterId]);
      } else {
        this.deleteIn(filtersUtil.getViewFilterPath(path));
      }
    }
    log('FILTER_STORE remove', this.toJS());
  },

  $setSceneFilter(path, value) {
    const filterPath = [...filtersUtil.getFiltersPath(path), path.fieldId];
    const filter = Immutable.fromJS({
      value,
    });

    const filtersExistAndTheyAreArray = this.getIn(filterPath) && Immutable.List.isList(this.getIn(filterPath));

    if (filtersExistAndTheyAreArray) {
      this.setIn([...filterPath, path.filterId], filter);
    } else {
      this.setIn(filterPath, Immutable.fromJS([filter]));
    }
  },

  $removeSceneFilter(path) {
    const filtersPath = filtersUtil.getFiltersPath(path);

    const fieldFilters = this.getIn(filtersPath);

    if (fieldFilters) {
      if (fieldFilters.size > 1) {
        this.deleteIn([...filtersUtil.getFiltersPath(path), path.filterId]);
      } else {
        this.deleteIn(filtersUtil.getFiltersPath(path));
      }
    }
  },

  // removeAllFilters(isChange = false, catalogId) {
  //   this.deleteIn(filtersUtil.getCatalogFields(catalogId));
  //   if (isChange) {
  //     this.changed();
  //   }
  // },

  getSceneFilters({ sceneId, viewId }) {
    const result = this.getIn(filtersUtil.getViewFiltersPath({ sceneId, viewId }));

    return result || Immutable.Map({});
  },

  getSceneAdditionalFilters({ sceneId }) {
    const result = this.getIn([...filtersUtil.getFiltersPath({ sceneId })]);

    return result || Immutable.Map({});
  },

  getFiltersForRequest(params) {
    const { sceneId, viewId = undefined } = params;

    let sceneFilters;
    if (viewId) {
      sceneFilters = this.getSceneFilters({ sceneId, viewId });
    } else {
      sceneFilters = this.getSceneAdditionalFilters({ sceneId });
    }

    if (sceneFilters) {
      const catalogId = this.getIn(['scenes', sceneId, 'params', 'catalogId']);
      sceneFilters = sceneFilters && sceneFilters.toJS() ? sceneFilters.toJS() : sceneFilters;
      return filtersUtil.getFiltersForRequest(sceneFilters, this.getIn(['catalogs', catalogId, 'fields']));
    }
    log('Filters are empty. Nothing happens.');
    return [];
  },

  getSearchText(sceneId) {
    return this.getIn(['scenes', sceneId, 'searchText']);
  },

  getFilterNewMessagess(sceneId) {
    return this.getIn(['scenes', sceneId, 'filterNewMessages']);
  },

  // -> apiActions
  // todo: refactor!!!!
  // change FilterStore through actionsFilter
  // - removeAllFilterCatalog
  // - $setViewFilter
  // getViewCompleted(view, {viewId, catalogId}) {
  //  // merge view.filter into values.
  //  this.removeAllFilterCatalog(catalogId);
  //  if (view.filters) {
  //    _.map(view.filters, (filter) => {
  //      let fieldId = filter.attr;
  //      // get fieldType by fieldId from current catalog.
  //      let fieldType = Appthis.getIn(['currentCatalog', 'fields'])
  //        .find(f => fieldId == f.get('id'))
  //        .get('type');
  //      this.$setViewFilter({catalogId, fieldId}, {
  //        type: fieldType,
  //        value: filter.value});
  //    });
  //  }
  //  let filters = this.getFiltersForRequest(catalogId);
  //  this.trigger({catalogId}, filters);
  // },
};
