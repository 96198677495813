import Immutable from 'immutable';
import _ from 'lodash';

import i18n from '../configs/i18n';
import FIELD_TYPES, { OBJECT_MODS } from '../configs/fieldTypes';
import { TEXT } from '../configs/textFieldSubTypes';
import { PHONE } from '../configs/contactFieldSubTypes';
import { DATE } from '../configs/dateFieldSubTypes';

import fieldVisibleRules from './utils/fieldVisibleRules';
// const log = require('debug')('CRM:Component:FieldFactory');

const defaultConfigs = {
  [FIELD_TYPES.TEXT]: { type: TEXT },
  [FIELD_TYPES.CONTACT]: { type: PHONE },
  [FIELD_TYPES.ADDRESS]: { token: '' },
  [FIELD_TYPES.NUMBER]: { unit: '' },
  [FIELD_TYPES.DATE]: { type: DATE },
  [FIELD_TYPES.DROPDOWN]: { multiselect: false, items: [] },
  [FIELD_TYPES.SWITCH]: { value: false },
  [FIELD_TYPES.BUTTON]: { items: [] },
  [FIELD_TYPES.CHECKBOXES]: { items: [] },
  [FIELD_TYPES.RADIOBUTTON]: { items: [] },
  [FIELD_TYPES.USER]: { multiselect: false },
  [FIELD_TYPES.OBJECT]: {
    enableSelect: true,
    enableCreate: true,
    mode: OBJECT_MODS.LIST,
    catalogs: [],
    enableUnsaved: false,
  },
  [FIELD_TYPES.FILE]: { multiselect: false },
};

const nameByType = {};
_.forEach(FIELD_TYPES, (id, name) => (nameByType[id] = name.toLowerCase()));

export default {
  create(data, allFields, t = i18n.t) {
    data = data || {};

    const Field = {
      // __name: 'Field',
      id: data.id,
      uuid: Math.random(),
      hint: data.hint || '',
      required: data.required || false,
      type: data.type || '',
      history: data.history !== undefined ? data.history : true,
      filterable: data.filterable !== undefined ? data.filterable : true,
      apiOnly: data.apiOnly || false,
      duplicateResultWithPrevId: data.duplicateResultWithPrevId || false,
      prevId: data.prevId || null,
      comment: data.comment || '',
      eventable: data.eventable || false,
      hidden: data.hidden || false,
      visible: data.visible || {},
    };

    // prepare visible rules for ControlList format
    Field.visibleRules = fieldVisibleRules(Field.visible, allFields);

    if (data.name) {
      Field.name = data.name;
    } else {
      Field.name = (Field.type && t(`fieldTypes.${nameByType[Field.type]}.name`)) || '';
    }
    // eslint-disable-next-line no-multi-assign
    const config = (data.config = _.assign({}, defaultConfigs[Field.type] || {}, data.config || {}));

    config.fields && (config.fields = this.mapExtendedFields(config.fields, allFields));
    Field.config = config;

    return Immutable.fromJS(Field);
  },

  mapExtendedFields(linkedCatalogs) {
    const newLinkedCatalogs = {};

    Object.keys(linkedCatalogs).forEach((key) => {
      const fields = linkedCatalogs[key];
      newLinkedCatalogs[key] = fields.map((field) => {
        field.visibleRules = fieldVisibleRules(field.visible, fields);
        return field;
      });
    });

    return newLinkedCatalogs;
  },
};
