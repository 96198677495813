import Immutable from 'immutable';
import _ from 'lodash';
import apiActions from '../../actions/apiActions';
import FIELD_TYPES from '../../configs/fieldTypes';
import calcVisibleControls from '../../utils/calcVisibleControls';

const recordMixinPublic = {
  async getRecordIsNotLoaded({ catalogId, recordId }) {
    const dataRecordsState = this.getIn(['records', catalogId, recordId, 'values']);
    const loadError = this.getIn(['records', catalogId, recordId, 'loadError']);

    /* условие нужно для того чтобы запрос отправлялся только в случае создания новой записи и с фильтрами установленными,
    и если уже отправлялся запрос и у нас в стейте уже есть данные запрос не нужно отправлять, 
    иначе у нас не будет показываться алерт когда нет прав, 
    проблема была в том что при создании новой записи у нас нет в стейте данных для отображение в режиме карточки и таблицы 
    поэтому нам нужно отправлять запрос только тогда когда их нет и запрос не был отправлен. */

    if (!loadError && !_.isNull(loadError) && dataRecordsState && !dataRecordsState.size) {
      await apiActions.getRecord(
        {
          catalogId,
          recordId,
          notShowAlertWhenCreatingRecordWithRelatedRecordFiltersWhenThereAreNoRighhts: true,
        },
        { withFieldsAdditional: true },
      );
    }
  },
  generateNewRecord(catalogId, newRecordId, values, onCreate) {
    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    const data = {
      id: newRecordId,
      isNew: true,
      fields: fields && fields.toJS(),
      values,
    };

    const params = {
      catalogId,
      recordId: newRecordId,
    };

    this.setRecord(params, data, false, true);

    this.setIn(['newRecordId', catalogId], newRecordId);

    if (onCreate) {
      let record = this.getIn(['records', catalogId, newRecordId]);
      // return in plain object like simular method scenes.openNewRecord.onCreateRecord do
      record = record.toJS();
      onCreate(record);
    }
  },

  generateRecord(params, data) {
    if (!this.getIn(['records', params.catalogId, params.recordId])) {
      this.setRecord(params, data, true);
      this.changed();
    }
  },

  // onCompleted, onFailed - нужны для кастомной обработки результата запроса, к примеру в импорте нам не нужны алерты
  async validateAndSaveRecord(params, actionParams, resolve, reject, checkChangeYourself = false) {
    const { catalogId, recordId } = params;
    const { sceneId, onCompleted, onFailed } = actionParams;

    const values = this.getIn(['records', catalogId, recordId, 'values']);
    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    const allValues = values ? values.toJS() : {};
    const error = this.recordHasErrors(catalogId, recordId, allValues, fields, true, checkChangeYourself);

    if (error) {
      onFailed && onFailed(catalogId, recordId, error);
      reject && reject(error);
      return;
    }

    await this.saveRecord(params, actionParams)
      .then((result) => {
        resolve && resolve(result);
      })
      .catch((error) => {
        reject && reject(error);
      });
  },

  saveRecord(params, actionParams) {
    const { catalogId, recordId } = params;

    const values = this.getIn(['records', catalogId, recordId, 'values']);
    const newLinkedRecords = this.getLinkedRecords(catalogId, values).filter((record) => record.get('isNew'));

    return Promise.all(
      newLinkedRecords.map((linkedRecord) => {
        const catalogId = linkedRecord.get('catalogId');
        const recordId = linkedRecord.get('recordId');
        // при создании записи без всплытия у связанной записи не будет сцены, поэтому все сопутсвующие подписки будут вызваны для родительской сцены
        return this.saveRecord({ catalogId, recordId });
      }),
    ).then(() => {
      const record = this.getIn(['records', catalogId, recordId]);
      if (!record) {
        return;
      }

      const isNew = record.get('isNew') || false;

      // get changed values with linked record values
      const values = record.get('values');
      let valuesToSave = this.getChangedValues(catalogId, recordId, true, true);
      valuesToSave = valuesToSave ? valuesToSave.toJS() : {};

      this.setIn(['records', catalogId, recordId, 'errors'], Immutable.Map());

      // Delete default values for hide and readonly fields
      Object.keys(values).forEach((fieldId) => {
        /* fieldPrivilegeCodes - перенести в scenes? */
        const catalogPrivilege = this.getIn(['catalogs', catalogId, 'fieldPrivilegeCodes', fieldId]);

        if (catalogPrivilege === 'hide' || catalogPrivilege === 'view') {
          delete values[fieldId];
        }
      });

      return isNew
        ? apiActions.createRecord({ catalogId }, { values: valuesToSave }, { recordId, ...actionParams })
        : apiActions.updateRecord({ catalogId, recordId }, { values: valuesToSave }, { ...actionParams });
    });
  },

  cloneRecord(catalogId, recordId, newRecordId) {
    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    let values = this.getIn(['records', catalogId, recordId, 'values']);

    // remove files and id from contacts
    fields.forEach((field) => {
      switch (field.get('type')) {
        case FIELD_TYPES.FILE:
          values = values.removeIn([field.get('id')]);
          break;
        case FIELD_TYPES.CONTACT:
          const mapIn = (path, mapFn) => {
            const val = values.getIn(path);
            return values.setIn(path, val && val.map(mapFn));
          };
          values = mapIn([field.get('id')], (val) => val.remove('id'));
          break;
        default:
          break;
      }
    });

    const data = {
      id: newRecordId,
      fields: fields && fields.toJS(),
      isNew: true,
      values,
    };

    const params = {
      catalogId,
      recordId: newRecordId,
    };

    this.setRecord(params, data, false);
    this.setIn(['records', catalogId, recordId, 'isDefaultReceived'], true);

    this.changed();
  },

  clearRecord(catalogId, recordId) {
    recordId !== '$new' && this.clearRecordStore(catalogId, recordId);
    this.changed();
  },

  // need when we delete or clear catalog
  clearAllRecords(catalogId, sceneId) {
    const records = this.getIn(['records', catalogId]);

    records &&
      records.forEach((record) => {
        const recordId = record.get('id');

        this.modifyItselfInLinks({ catalogId, recordId, sceneId }, (linkedRecord) =>
          linkedRecord.set('isRemoved', true),
        );

        this.clearRecordStore(catalogId, recordId);
      });

    this.changed();
  },

  updateValues(catalogId, recordId, values) {
    const params = { catalogId, recordId };

    this.updateRecordValues(params, values, false /* fromChanges */);
  },

  async raiseChanges(catalogId, recordId, values, onSuccess, onError) {
    try {
      const params = { catalogId, recordId };
      await this.checkAllowToRaiseRecordChanges(params, values);
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  },

  setDefaultValues(catalogId, recordId, prevHiddenFields, isNew = false) {
    const recordKey = ['records', catalogId, recordId];
    const fieldKey = ['catalogs', catalogId, 'fields'];

    const record = this.getIn(recordKey);
    if (!record) {
      return;
    }

    const fields = this.getIn(fieldKey);
    let values = record.get('values');
    let changedValues = Immutable.fromJS({});
    let valuesChanged = true;

    if (fields) {
      const FieldApi = require('../../models/FieldApi').default;
      const visibleFields = calcVisibleControls(values, fields);

      while (valuesChanged) {
        const changedFields = fields.map((field, index) => {
          const fieldId = field.get('id');
          const visible = visibleFields && visibleFields[fieldId];

          const wasHidden = prevHiddenFields && prevHiddenFields.get(fieldId);
          const becameVisibleFromHidden = isNew ? visible : wasHidden && visible;

          const defaultEmptyValue = FieldApi.getDefaultValue(field) || field.getIn(['config', 'defaultEmptyValue']);

          let defaultValue = FieldApi.getDefaultValue(field) || field.getIn(['config', 'defaultValue']);
          defaultValue = _.isObject(defaultValue) ? !defaultValue.isEmpty() : defaultValue;

          const recordValue = values.get(fieldId);
          const valueIsEmpty = FieldApi.isEmpty(field, recordValue);

          const recordOriginValue = record.getIn(['originValues', fieldId]);
          const originValueIsEmpty = FieldApi.isEmpty(field, recordOriginValue);

          const alreadyChecked = changedValues.get(fieldId);

          /* 
            установка дефолтных значений, при переключении видимости поля (видно -> не видно)
          */
          if (defaultValue && valueIsEmpty && becameVisibleFromHidden && !alreadyChecked) {
            // Если не это условие, то приложение уходит в луп
            // Происходит это, только если апишка не отдает дефолтные поля
            // Это условие здесь на всякий случай
            // Лучше вызвать временную потерю дефолтных значений, чем постоянный луп при открытии
            if (!defaultEmptyValue) {
              return false;
            }

            this.setIn([...recordKey, 'values', fieldId], defaultEmptyValue);
            values = values.set(fieldId, defaultEmptyValue);

            /* 
              запись в originValues приводит к тому, что в запрос на изменение записи не отправляются дефолтные значения полей, 
              что позволяет актуализировать их на стороне сервера.
            */
            if (originValueIsEmpty) {
              /* что-то странное с immutable, потому что при создании записи не создается объект originValues, точнее он undefined. */
              let originValues = this.getIn([...recordKey, 'originValues']);
              originValues = originValues
                ? originValues.set(fieldId, defaultEmptyValue)
                : Immutable.fromJS({ fieldId: defaultEmptyValue });

              this.setIn([...recordKey, 'originValues'], originValues);
            }

            // changedValues
            changedValues = changedValues.set(fieldId, defaultEmptyValue);
            return true;
          }
          if (isNew && recordValue && !alreadyChecked) {
            /* 
              когда мы создаем запись через связи записи, у нас могут проставляться дефолтные значения (связанный объект), 
              такие ситуации нужно обрабатывать отдельно 
            */
            changedValues = changedValues.set(fieldId, recordValue);
            return true;
          }
          return false;
        });

        valuesChanged = !!changedFields.find((f) => f);
      }

      if (!changedValues.isEmpty()) {
        this.checkAllowToRaiseRecordChanges({ catalogId, recordId }, changedValues);
      }
    }
  },

  setRecordHiddenFields(catalogId, recordId, hiddenFields) {
    const recordKey = ['records', catalogId, recordId];
    const record = this.getIn(recordKey);

    hiddenFields = Immutable.fromJS(hiddenFields);
    const prevHiddenFields = record && record.get('hiddenFields');

    if (record) {
      this.setIn([...recordKey, 'hiddenFields'], hiddenFields);
    }

    this.setDefaultValues(catalogId, recordId, prevHiddenFields);
    this.changed();
  },

  calcRecordHiddenFields(catalogId, recordId) {
    this.calcHiddenFields({ catalogId, recordId });
    this.changed();
  },

  setShouldReload(sceneId, shouldReload) {
    const scene = this.getIn(['scenes', sceneId]);
    if (scene) {
      this.setIn(['scenes', sceneId, 'shouldReload'], shouldReload);
      this.changed();
    }
  },

  setTimeUntilTimerEnd(sceneId, date) {
    const scene = this.getIn(['scenes', sceneId]);
    if (scene) {
      this.setIn(['scenes', sceneId, 'timeUntilTimerEnd'], date);
      this.changed();
    }
  },

  removeParent(...args) {
    this.removeParentFromRecord(...args);
    this.changed();
  },

  backUpRecordValue(catalogId, recordId, fieldId) {
    const record = this.getIn(['records', catalogId, recordId]);

    if (!record) {
      return;
    }

    const values = Immutable.fromJS({
      [fieldId]: record.getIn(['originValues', fieldId]),
    });
    this.updateValues(catalogId, recordId, values);
  },
};

export default recordMixinPublic;
