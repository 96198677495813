import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { matchPath, withRouter } from 'react-router';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import routes from '../../../../routes';
import Header from '../../../Record/Header';
import getLink from '../../../common/router/getLink';
import { STATUSES_TO_NOT_SHOW_RESULT } from '../../../../configs/recordsBatchStatus';

class RecordsBatchUpdateHeader extends Component {
  static propTypes = {
    record: ImmutablePropTypes.map.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    scene: ImmutablePropTypes.map.isRequired,
    hasBeenEdit: PropTypes.bool.isRequired,
    isWebForm: PropTypes.bool,
    readOnly: PropTypes.bool,
    fieldsEditableStatus: ImmutablePropTypes.map,
  };

  state = {
    // tabs
    tabs: Immutable.List([
      Immutable.Map({
        id: 'batchUpdateMain',
        name: this.props.t('batchUpdateRecords.tabs.main'),
        route: routes.batchUpdateMain,
      }),
    ]),
  };

  getExtParams = (props = this.props) => {
    const { record, catalog } = props;
    return {
      sectionId: catalog.get('sectionId'),
      catalogId: catalog.get('id'),
      recordId: record.get('id'),
    };
  };

  onClose = () => {
    const { scene } = this.props;
    const sceneId = scene && scene.get('sceneId');

    this.props.onClose && this.props.onClose(sceneId);
  };

  componentDidMount() {
    const extParams = this.getExtParams && this.getExtParams();

    const match =
      matchPath(this.props.location.pathname, {
        path: routes.batchUpdate.path,
        exact: true,
        strict: false,
      }) ||
      matchPath(this.props.location.pathname, {
        // For WebFrom and Modals
        path: '/',
        exact: true,
        strict: false,
      });
    // If not select one of tab
    if (match) {
      this.props.history.push(getLink(this.props.location, routes.batchUpdateMain, extParams));
    }
  }

  componentDidUpdate(prevProps) {
    const extParams = this.getExtParams && this.getExtParams();

    const newRecordId = this.props.record.get('id');
    const prevRecordId = prevProps.record.get('id');

    const status = this.props.scene.getIn(['data', 'batchStatus']);
    const prevStatus = prevProps.scene.getIn(['data', 'batchStatus']);

    if (
      newRecordId !== prevRecordId ||
      matchPath(this.props.location.pathname, {
        // if was click repeated on selected record
        path: routes.batchUpdate.path,
        exact: true,
        strict: false,
      })
    ) {
      this.props.history.push(getLink(this.props.location, routes.batchUpdateMain, extParams));
    }

    if (_.includes(STATUSES_TO_NOT_SHOW_RESULT, prevStatus) && !_.includes(STATUSES_TO_NOT_SHOW_RESULT, status)) {
      let { tabs } = this.state;

      tabs = tabs.push(
        Immutable.Map({
          id: 'batchUpdateResult',
          name: this.props.t('batchUpdateRecords.tabs.result'),
          route: routes.batchUpdateResult,
        }),
      );

      this.setState({
        tabs,
      });
    }
  }

  render() {
    const {
      record,
      catalog,
      scene,
      hasBeenEdit,
      isWebForm,
      onStartUpdate,
      onPauseUpdate,
      onResumeUpdate,
      isLoading,
      batchUpdateRecordValues,
      t,
    } = this.props;

    if (!scene) return null;

    const sceneId = scene.get('sceneId');
    const recordsCount = scene.get('recordsCount') || 0;
    const headerText = `${t('batchUpdateRecords.title')} ${t('record.groupRecords.count', { count: +recordsCount })}`;

    const selectedFieldsCount = batchUpdateRecordValues ? batchUpdateRecordValues.size : 0;

    return (
      <Header
        tabs={this.state.tabs}
        record={record}
        catalog={catalog}
        scene={scene}
        hasBeenEdit={hasBeenEdit}
        isLoading={isLoading}
        withTabsMenu
        isWebForm={isWebForm}
        headerText={headerText}
        selectedFieldsCount={selectedFieldsCount}
        getExtParams={this.getExtParams}
        onClose={this.onClose}
        onStartUpdate={onStartUpdate}
        onPauseUpdate={onPauseUpdate}
        onResumeUpdate={onResumeUpdate}
      />
    );
  }
}

export default withTranslation()(withRouter(RecordsBatchUpdateHeader));
