import Immutable from 'immutable';
import _ from 'lodash';

import FieldApi from '../FieldApi';

export default function fieldVisibleRules(visible, allFields) {
  return _.mapValues(visible, (v, fieldId) => {
    const newTypeOfFilters = _.isArray(v) && _.get(v, ['0', 'value']);
    if (newTypeOfFilters) {
      v = _.get(v, ['0', 'value']);
    }

    const field = allFields.find((f) => f.id == fieldId);
    if (!field) {
      return {};
    }

    const rule = FieldApi.visibleRules(Immutable.fromJS(field), v);
    return Immutable.fromJS(rule);
  });
}
