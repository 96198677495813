export default (t) => ({
  hidden: false,
  element: 'bpmn:ServiceTask',
  service: 'runProcess',
  title: t('script.controlls.runProcess.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon content-34',
  offset_x: 0,
  offset_y: -15,
  priority: 8,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.runProcess.runSubprocess'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section',
      name: t('script.controlls.runProcess.run'),
      type: 'group',
    },
    {
      id: 'scriptFormat',
      name: t('script.controlls.runProcess.setScript'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'script',
            name: t('script.common.fromList'),
            color: 'E7E9ED',
          },
          {
            id: 'var',
            name: t('script.common.fromVariables'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['script'],
        map: 'input',
      },
    },
    {
      id: 'script',
      name: t('script.common.script'),
      type: 'object',
      readOnly: false,
      config: {
        catalogId: '$scripts',
        multiselect: false,
        enableCreate: true,
        enableSelect: true,
        view: 'list',
        map: 'input',
      },
      visible: {
        scriptFormat: {
          $include: 'script',
        },
      },
    },
    {
      id: 'scriptIdFromVar',
      name: t('script.common.scriptId'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.numberExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        scriptFormat: {
          $include: 'var',
        },
      },
    },
    {
      id: 'format',
      name: t('script.common.valuesFormat'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'keyvalue',
            name: t('script.common.fieldValue'),
            color: 'E7E9ED',
          },
          {
            id: 'json',
            name: 'JSON',
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['keyvalue'],
        map: 'input',
      },
    },
    {
      id: 'runParams',
      name: t('script.controlls.runProcess.inputParams'),
      type: 'pair',
      readOnly: false,
      config: {
        placeholderValue: t('script.controlls.runProcess.param'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        map: 'input',
      },
      visible: {
        format: {
          $include: 'keyvalue',
        },
      },
    },
    {
      id: 'runParamsJson',
      name: t('script.controlls.runProcess.inputParams'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.runProcess.fromJsonHint'),
      config: {
        type: 'multiline',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.expression'),
      },
      visible: {
        format: {
          $include: 'json',
        },
      },
    },
    {
      id: 'async',
      name: t('script.controlls.runProcess.runMode'),
      type: 'dropdown',
      readOnly: false,
      hint: t('script.controlls.runProcess.asyncHint'),
      config: {
        items: [
          {
            id: 'sync',
            name: t('script.controlls.runProcess.sync'),
            color: 'E7E9ED',
          },
          {
            id: 'async',
            name: t('script.controlls.runProcess.async'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['async'],
        map: 'input',
      },
    },
    {
      id: 'section1',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'output_process_id',
      name: t('script.controlls.runProcess.saveProcessId'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
    },
    {
      id: 'output_process_result',
      name: t('script.controlls.runProcess.saveResponse'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.runProcess.outputResultHint'),
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        async: {
          $include: 'sync',
        },
      },
    },
  ],
  exits: [],
});
