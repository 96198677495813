import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import routes from '../../../../routes';

import Badge from '../../../common/UI/Badge';
import Icon from '../../../common/UI/Icon';
import NavLink from '../../../common/router/Link';
import ButtonTransparent from '../../../common/UI/ButtonTransparent';

import styles from './fullMenu.less';

function CatalogItem(props) {
  const { item, isFavorite, onClick, onFavoriteClick } = props;
  const { t } = useTranslation();

  const id = item.get('id');
  const name = item.get('name');
  const badge = item.get('newChats');
  const route = item.get('route') || routes.catalog;
  const sectionId = item.get('sectionId');
  const isAdd = id == '$add';

  const _onFavoriteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFavoriteClick(id);
  };

  return (
    <NavLink
      key={id}
      route={route}
      params={{ sectionId, catalogId: id }}
      render={(props) => (
        <Link
          to={props.link}
          className={cn(styles.catalog, {
            [styles.selected]: props.isActive,
            [styles.favorite]: isFavorite,
            [styles.addButton]: isAdd,
          })}
          title={name}
          onClick={onClick}
        >
          <Icon type={`icon ${item.get('icon')}`} className={cn(styles.icon)} />
          <Badge count={badge} className={styles.badge}>
            <span className={styles.text}>{name}</span>
          </Badge>

          {!isAdd && (
            <ButtonTransparent
              title={isFavorite ? t('menuPopover.removeFavorite') : t('menuPopover.addFavorite')}
              className={cn(styles.favoriteButton, {
                [styles.favoriteButtonSelected]: isFavorite,
              })}
              onClick={_onFavoriteClick}
            >
              <Icon type="icon vote-38" className={styles.favoriteIcon} />
            </ButtonTransparent>
          )}
        </Link>
      )}
    />
  );
}

export default CatalogItem;
