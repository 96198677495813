import React, { lazy, Suspense } from 'react';
import cn from 'classnames';
import { Switch, Route } from 'react-router';
import { connect } from '../../../../StateProvider';
import routes from '../../../../../routes';

import DefaultRedirect from '../../../../common/router/DefaultRedirect';

import CatalogEditor from '../../../../catalogEditor';
import Loading from '../../../../common/Loading';

import uiActions from '../../../../../actions/uiActions';

import styles from './sectionBody.less';

const CatalogSceneController = lazy(() => import('./CatalogSceneController'));

function SectionBody(props) {
  const closeGhost = () => {
    uiActions.sectionMenuHover(null);
  };

  const recordsLimit = props.license.get('records') || 0;

  const { ghostMode } = props;
  const { firstCatalog } = props;

  const invisible = !firstCatalog && !ghostMode;

  return (
    <div
      className={cn(styles.bodyWrapper, {
        [styles.ghostMode]: ghostMode,
        [styles.recordsLimit]: recordsLimit > 0,
      })}
    >
      <Switch>
        <Route
          path={routes.catalogEdit.path}
          render={(props) => (
            <div className={styles.body}>
              <CatalogEditor isStateEditCatalog {...props} />
            </div>
          )}
        />

        <Route
          path={routes.catalogAdd.path}
          render={(props) => (
            <div className={styles.body}>
              <CatalogEditor isStateAddCatalog {...props} />
            </div>
          )}
        />

        <Route
          path={routes.catalog.path}
          render={(props) => (
            <Suspense fallback={<Loading />}>
              <div className={cn(styles.body, { [styles.invisible]: invisible })}>
                <CatalogSceneController {...props} />
              </div>
            </Suspense>
          )}
        />

        {!ghostMode && (
          <Route
            route={routes.catalog}
            render={(props) => <DefaultRedirect route={routes.catalog} params="catalogId" object={firstCatalog} />}
          />
        )}
      </Switch>

      {/* click to close catalogs menu overlay */}
      {ghostMode && <div className={styles.ghostOverlay} onClick={closeGhost} />}
    </div>
  );
}

export default connect(SectionBody, ['license']);
