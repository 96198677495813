import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import DefaultRedirect from '../../../common/router/DefaultRedirect';
import routes from '../../../../routes';
import mapRouteParams from '../../../common/router/mapRouteParams';
import apiActions from '../../../../actions/apiActions';
import { connect } from '../../../StateProvider';
import Badge from '../../../common/UI/Badge';
import Icon from '../../../common/UI/Icon';

import uiActions from '../../../../actions/uiActions';

import Menu from './menu';

import styles from './styles.less';

class SectionsMenu extends React.PureComponent {
  componentDidMount() {
    apiActions.getSections();
  }

  /* render menu items */
  renderItem = (item, activeId) => {
    const id = item.get('id');
    const name = item.get('name');
    const title = item.get('title') || name;
    const badge = item.get('newChats');

    let icon = item.get('icon');
    icon = icon && <Icon type={`icon ${icon}`} className={styles.icon} />;

    return (
      <Link
        key={id}
        to={`/section/${id}`}
        className={cn(styles.link, item.get('className') || null, {
          [styles.selected]: activeId === id,
        })}
        title={title}
        onClick={(e) => {
          const callFn = item.get('onClick') || this.onClick;
          callFn && callFn(item, e);
        }}
      >
        <div className={styles.data}>
          <Badge dot={badge === true} count={badge} className={styles.iconBadge}>
            {icon}
            <span className={styles.text}>{name}</span>
          </Badge>
        </div>
      </Link>
    );
  };

  onClick(item, e) {
    uiActions.sectionMenuHover(item.get('id'));
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  render() {
    const { sections } = this.props;
    const firstSection = sections && sections.first();
    const activeId = this.props.hoveredSectionId || this.props.sectionId || (firstSection && firstSection.get('id'));

    return (
      <div className={styles.container}>
        {firstSection && <DefaultRedirect route={routes.section} params="sectionId" object={firstSection} />}
        <Menu sections={sections} activeId={activeId} renderItem={this.renderItem} onClick={this.onClick} />
      </div>
    );
  }
}

export default connect(mapRouteParams(SectionsMenu, routes.section), {
  sections: ['sections'],
  hoveredSectionId: ['ui', 'hoveredSectionId'],
});
