import React, { Component } from 'react';
import { Modal } from 'antd';
import cn from 'classnames';
import trs from '../../../../../../../../getTranslations';
import LinkedItem from '../../../../../LinkedItem';
import LoadingSpin from '../../../../../../LoadingSpin';
import Script from '../../../../../../../Script';
import Icon from '../../../../../Icon';

import styles from '../../../controls.less';

function toProgress(file) {
  if (isNaN(file.loading) || Number.isNaN(file.loading)) {
    return '';
  }
  return `${parseInt(file.loading, 10)}%`;
}

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  onRemove = () => {
    this.props.removeFn && this.props.removeFn(this.props.file);
  };

  toggleModal() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    const { params } = this.props;
    const { file } = this.props;
    const item = {};
    if (file.loading && !file.error) {
      item.icon = <LoadingSpin />;
    } else {
      item.icon = `icon ${file.error ? 'interface-54' : 'content-36'}`;
    }
    item.text = file.title;
    item.subText =
      file.size && file.loading ? (
        <span className={styles.viewerSize}>{toProgress(file)}</span>
      ) : (
        <a download target="_blank" className={styles.downloadLink} href={file.url} rel="noreferrer">
          <Icon type="icon interface-40" title={trs('record.fields.file.download')} />
        </a>
      );
    return (
      <div>
        <LinkedItem
          title={file.title}
          removable={!this.props.readOnly}
          item={item}
          titleOnRemove={trs('record.fields.file.remove')}
          onClick={this.toggleModal}
          onClickRemove={this.onRemove}
          className={this.props.itemWrapperClassName}
          classNameItem={cn(this.props.itemСlassName, { [styles.uploadError]: file.error })}
        />
        <Modal
          open={this.state ? this.state.visible : false}
          maskClosable={false}
          closable={false}
          footer=""
          width="auto"
          wrapClassName="large-modal-bpium"
        >
          <Script params={params} file={this.props.file} saveFn={this.props.saveFn} onCancel={this.toggleModal} />
        </Modal>
      </div>
    );
  }
}

function newTitle(title = '') {
  if (!title) {
    return 'version.1.bpmn';
  }

  if (title) {
    // asd.bpmn -> asd.1.bpmn
    // asd.1.3.bpmn -> asd.1.4.bpmn
    // asd.1.asd.bpmn -> asd.1.asd.1.bpmn
    // asd -> asd.1.bpmn
    // asd.1 -> asd.1.1.bpmn
    // asd.1. -> asd.1.1.bpmn
    // asd2 -> asd2.1.bpmn
    const parts = title.split('.');
    if (/bpmn/i.test(parts[parts.length - 1])) {
      const version = Number(parts[parts.length - 2]);
      if (version) {
        parts[parts.length - 2] = version + 1;
      } else {
        parts.splice(-1, 0, '1');
      }
    } else {
      parts.push('1.bpmn');
    }
    return parts.join('.');
  }
}

function newFile(file = {}) {
  return {
    ...file,
    title: newTitle(file.title),
    type: 'application/bpmn+xml',
  };
}

export default class BpmnViewer extends Component {
  static viewerName = 'BpmnViewer';

  state = {
    visible: false,
  };

  onClick = () => this.setState({ visible: true });

  onCancel = () => this.setState({ visible: false });

  render() {
    const { files, saveFn, readOnly, params, ...props } = this.props;

    if (!this.props.files) {
      return (
        <div>
          <LinkedItem
            item={{
              icon: 'design-21',
              text: trs('record.fields.file.create'),
            }}
            onClick={this.onClick}
            small={this.props.small}
          />
          <Modal
            open={this.state.visible}
            maskClosable={false}
            closable={false}
            footer=""
            width="auto"
            wrapClassName="large-modal-bpium"
          >
            <Script
              params={params}
              file={newFile()}
              saveFn={(newContent) => {
                saveFn(newFile(), newContent);
                this.onCancel();
              }}
              onCancel={this.onCancel}
            />
          </Modal>
        </div>
      );
    }
    return (
      <div className={this.props.containerClassName}>
        {this.props.files.map((file, i) => (
          <Row
            key={i}
            {...this.props}
            file={file}
            onClick={this.onClick}
            saveFn={!readOnly && ((newContent) => saveFn(newFile(file), newContent))}
          />
        ))}
      </div>
    );
  }
}
