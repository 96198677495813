import Reflux from 'reflux';
import _ from 'lodash';
import qs from 'qs';
import debug from 'debug';
import Immutable from 'immutable';
import recordActions from '../actions/recordActions';
import apiActions from '../actions/apiActions';
import AppState from '../appState';

import { API_PREFIX } from '../configs/records';
import getRecordsArgs from '../utils/getRecordsArgs';

const log = debug('CRM:Store:RequestRecordStore');

const RequestRecordStore = Reflux.createStore({
  listenables: [recordActions],

  init() {
    // need pass getRecords without debounce.
    this.$getRecordsDebounce = _.debounce(apiActions.getRecords, 400);
    this.$getCalendarRecordsDebounce = _.debounce(apiActions.getRecords, 400);
  },

  requestForRecordsImmediate(...args) {
    return apiActions.getRecords(...getRecordsArgs._prepareGetRecordsArgs(...args));
  },

  // aggregation action.
  requestForRecords(...args) {
    // pass getRecords with the debounce.
    return this.$getRecordsDebounce(...getRecordsArgs._prepareGetRecordsArgs(...args));
  },

  requestForExportRecords({ sceneId, viewId = 0 }, request = {}, viewMode = 'table') {
    if (!sceneId) throw new Error('Undefined sceneId for request records!');

    if (!AppState.getIn(['scenes', sceneId])) {
      return;
    }

    const catalogId = AppState.getIn(['scenes', sceneId, 'params', 'catalogId']);

    // sorting
    const sortParams = getRecordsArgs.getSortParams(catalogId, viewMode);
    request = Object.assign(request, sortParams);

    request.searchText = AppState.getSearchText(sceneId);

    const filterNewMessages = AppState.getFilterNewMessagess(sceneId);
    if (filterNewMessages) {
      request.filterNewMessages = filterNewMessages;
    }

    const filtersChanged = AppState.getIn(['scenes', sceneId, 'views', viewId, 'filtersChanged']);

    const filtersFromView = getRecordsArgs.getFilterParams(sceneId, viewId) || []; // фильтры вида
    const filtersFromScene = getRecordsArgs.getAdditionalFilterParams(sceneId) || []; // фильтры ("data", "filters")

    let allFilters = request.filters || [];

    if (filtersFromScene) {
      allFilters = _.concat(allFilters, filtersFromScene);
    }
    if (filtersChanged) {
      allFilters = _.concat(allFilters, filtersFromView);
    }

    if (filtersChanged || viewId === '$new' || !_.isEmpty(allFilters)) {
      _.extend(request, {
        filters: allFilters,
      });
    }

    request.viewId = viewId;

    if (viewId === '0' || viewId === '$new' || filtersChanged) {
      delete request.viewId; // удаление вида (потому что сервер не знает о 0 и "$new" видах)
    }

    // fields
    if (!_.isEmpty(request.fields)) {
      request.fields = JSON.stringify(request.fields);
    }

    // limit of records
    // request.limit = DEFAULTS.RECORDS_LIMIT;

    // refactor: !
    // make query on export.
    // console.log("request", request);

    _.extend(request, { timezoneOffset: -new Date().getTimezoneOffset() });

    const querySting = qs.stringify(request);
    const path = `${API_PREFIX}catalogs/${AppState.getIn(['scenes', sceneId, 'params', 'catalogId'])}/exports?${querySting}`;

    window.open(path);
    log(path);
  },

  /**
   * @return viewId
   */
  // getCurrentViewId() {
  //   return AppState.getIn(['currentCatalog', 'currentViewId']);
  // }

  clearRecordsCollection(sceneId) {
    AppState.setIn(['scenes', sceneId, 'records'], Immutable.Map());
    AppState.changed();
  },
});

export default RequestRecordStore;
