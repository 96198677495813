import qs from 'qs';
import request from 'superagent';
import _ from 'lodash';
import location from './location';

import i18n from '../configs/i18n';
import { API_PREFIX } from '../configs/records';

const log = require('debug')('makeApiRequest');

const HttpStatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
  PaymentRequired: 402,
  BadRequest: 400,
  TooManyRequests: 429,
};

// kill concurent GET requests to same resource
let activeRequests = [];

function getDefaultOptions() {
  return {
    method: 'get',
    query: {
      timezoneOffset: -new Date().getTimezoneOffset(),
      skipPrevId: true,
    },
    headers: {
      // 'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: null,
  };
}
function closeRequest(req) {
  activeRequests = _.filter(activeRequests, (r) => r !== req);
}
function storeRequest(method, path, r) {
  const req = { method, path, r };
  activeRequests.push(req);
  return req;
}

function abortRequest(req) {
  req.r.abort();
  closeRequest(req);
}
function abortSameGetRequests(path) {
  _.forEach(activeRequests, (req) => {
    if (req.method == 'get' && req.path === path) {
      abortRequest(req);
    }
  });
}
// eslint-disable-next-line default-param-last
export function makeRequestWithCredentials(prefix, path, opts = {}, abourtCurrentGET) {
  const nextOpts = _.cloneDeep(opts);
  const nextPath = _.cloneDeep(path);

  if (_.isObject(path)) {
    opts = path;
    path = opts.path;
  }
  const defaultOptions = getDefaultOptions();
  let method = opts.method || defaultOptions.method;
  const query = _.assign({}, defaultOptions.query, opts.query || {});

  const headers = _.assign({}, defaultOptions.headers, opts.headers || {});

  if (method === 'delete') {
    method = 'del';
  }

  if (path[0] === '/') {
    path = path.slice(1);
  }
  log(`${method} ${path}`, opts.body);

  // abort previous same GET requests
  if (abourtCurrentGET && method == 'get') {
    abortSameGetRequests(path);
  }

  // prepare
  let r = request[method](prefix + path)
    .withCredentials()
    .set(headers)
    .query(qs.stringify(query));
  if (opts.body) {
    r = r.send(opts.body);
  }

  // store active records
  const req = storeRequest(method, path, r);

  return new Promise((resolve, reject) => {
    r.end(async (err, res) => {
      // remove from store of active requests
      closeRequest(req);
      if (err) {
        log(`error ${path}`, err.status ? `${err.status} ${err.response.statusMessage}` : err);

        let errorText;
        let errorTitle;
        const { status } = err;

        // get full data from error
        const { response } = err;
        let errorData = response && response.text;
        try {
          errorData = JSON.parse(errorData);
        } catch (e) {
          errorData = {};
        }

        if (status === HttpStatusCode.BadRequest) {
          if (errorData) {
            try {
              const { message } = errorData;

              // kostil: show real error for api validation errors
              if (message == '"values" error: validation error' && errorData.data) {
                if (_.isObject(errorData.data)) {
                  errorText = JSON.stringify(opts.body.data.values);
                } else {
                  errorText = errorData.data;
                }
              } else if (message) {
                if (_.isObject(message)) {
                  errorText = message.text;
                  errorTitle = message.title;
                } else {
                  errorText = message;
                }
              }
            } catch (e) {
              errorText = response.text;
            }
          }
        }
        err.title = errorTitle;
        err.text = errorText;
        if (status === HttpStatusCode.Unauthorized) {
          // location
          location.authorize();
          return reject(err);
        }
        if (status === HttpStatusCode.Forbidden) {
          // todo correct alert
        } else if (status === HttpStatusCode.PaymentRequired) {
          const errParam = _.get(errorData, 'data.param');
          err.text = errParam ? i18n.t(`errors.license.${errParam}`) : _.get(errorData, 'message');

          if (!err.text) {
            location.paymentRequired();
          }
        } else if (status === HttpStatusCode.TooManyRequests) {
          // время повтора запроса в секундах
          const retryAfter = _.get(response, ['header', 'retry-after']);

          // повторение запроса при превышении их количества retryAfter + 10%
          retryAfter &&
            (await new Promise((resolve) => {
              setTimeout(resolve, retryAfter * 1000 + retryAfter * 100);
            }));

          retryAfter &&
            (await makeRequestWithCredentials(prefix, nextPath, nextOpts, abourtCurrentGET)
              .then((res) => resolve(res))
              .catch((err) => reject(err)));
        }

        return reject(err);
      }
      log(`success ${path}`);
      resolve(res);
    });
  });
}

export default function makeApiRequest(path, opts, abourtCurrentGET = false) {
  return makeRequestWithCredentials(API_PREFIX, path, opts, abourtCurrentGET);
}
