export default (t) => ({
  element: 'bpmn:IntermediateCatchEvent',
  service: '',
  title: t('script.controlls.intermediateCatchEvent.title'),
  helplink: '',
  class: 'event',
  color: '#7d7f80',
  border: 2,
  icon: 'anticon-icon time-4',
  offset_x: 1,
  offset_y: -17,
  toolbar_offset_x: 0,
  toolbar_offset_y: -14,
  priority: 1,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.intermediateCatchEvent.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section',
      name: t('script.controlls.intermediateCatchEvent.title'),
      type: 'group',
    },
    {
      id: 'timeout',
      name: t('script.controlls.intermediateCatchEvent.timeoutName'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.intermediateCatchEvent.timeoutHint'),
      config: {
        type: 'text',
        theme: 'script',
        map: 'timer',
      },
    },
  ],
  exits: [],
});
