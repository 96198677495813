import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { Component } from 'react';
import { Row } from 'antd';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import _ from 'lodash';

import TabsMenu from '../../common/UI/TabsMenu';
import ButtonClose from '../../common/UI/ButtonClose';
import LoadingSpin from '../../common/LoadingSpin';
import SCENE_TYPES from '../../../configs/sceneTypes';
import SCENE_CONTAINER from '../../../configs/sceneContainer';
import ProcessNotify from '../../common/UI/ProcessNotify';

import styles from './header.less';

class Header extends Component {
  static propTypes = {
    tabs: ImmutablePropTypes.list,
    headerText: PropTypes.string,
    record: ImmutablePropTypes.map.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    scene: ImmutablePropTypes.map.isRequired,
    getExtParams: PropTypes.func.isRequired,
    hasBeenEdit: PropTypes.bool.isRequired,
    withTabsMenu: PropTypes.bool,
    isWebForm: PropTypes.bool,
    onClose: PropTypes.func,
  };

  getRecordActivitiesComponent = () => {
    const { scene } = this.props;

    const sceneType = scene && scene.get('type');

    switch (sceneType) {
      case SCENE_TYPES.RECORD:
        return require('../RecordHeader/RecordActivities').default;

      case SCENE_TYPES.RECORDS_BATCH_UPDATE:
        return require('../../RecordsBatch/RecordsBatchUpdate/batchUpdateHeader/RecordsBatchUpdateActivities').default;

      case SCENE_TYPES.RECORDS_BATCH_DELETE:
        return require('../../RecordsBatch/RecordsBatchDelete/batchDeleteHeader/RecordsBatchDeleteActivities').default;

      default:
        return require('../RecordHeader/RecordActivities').default;
    }
  };

  _getExtParams = () => {
    const { getExtParams } = this.props;

    if (getExtParams) {
      return getExtParams();
    }
    const { record, catalog, tabId } = this.props;

    return {
      sectionId: catalog.get('sectionId'),
      catalogId: catalog.get('id'),
      recordId: record && (record.get('isNew') ? '$new' : record.get('id')),
      tabId,
    };
  };

  render() {
    const {
      tabs,
      record,
      catalog,
      scene,
      withTabsMenu,
      isWebForm,
      hasBeenEdit,
      headerText,
      onClose,
      FilterComponent,
      onClear,
      isLoading,
      isDeleting,
      tabId,
      onTabChange,
      fields,
      openTab,
      t,
      baseTabs,
      isNew,
      batchDelete,
      ...props
    } = this.props;

    const sceneId = scene && scene.get('sceneId');
    const isBatchRecords =
      scene &&
      (scene.get('type') === SCENE_TYPES.RECORDS_BATCH_UPDATE ||
        scene.get('type') === SCENE_TYPES.RECORDS_BATCH_DELETE);
    const Activities = this.getRecordActivitiesComponent();
    const extParams = this._getExtParams();
    const isPopup = scene && scene.get('container') === SCENE_CONTAINER.POPUP;

    let updateProcess;

    const changedFields =
      record && record.getIn(['updateProcesses', 'fields']) && record.getIn(['updateProcesses', 'fields']).toJS();

    if (!_.isEmpty(changedFields)) {
      updateProcess = changedFields && Object.values(changedFields).some((f) => f.inProcess);
    }

    return (
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          {FilterComponent}

          <div className={styles.headerTextWrapper}>
            <h2 className={styles.headerText} title={headerText}>
              {headerText}
            </h2>
          </div>

          <Row type="flex" align="middle" className={styles.headerActivitiesWrapper}>
            {isLoading && isBatchRecords && <LoadingSpin className={styles.loadingSpinHeaderText} />}
            <Activities
              record={record}
              catalog={catalog}
              sceneId={sceneId}
              hasBeenEdit={hasBeenEdit}
              withTabsMenu={withTabsMenu}
              isWebForm={isWebForm}
              onClear={onClear}
              onClose={onClose}
              {...props}
            />
            {(!isWebForm || isPopup) && (
              <ButtonClose
                className={cn({
                  [styles.buttonCloseDisabled]: isDeleting,
                })}
                onClick={onClose}
                shiftRight
              />
            )}
          </Row>
        </div>
        {!batchDelete && (
          <div className={styles.tabsContainer}>
            <div className={styles.recordTabs}>
              <TabsMenu items={tabs} activeId={tabId} extParams={extParams} />
            </div>
            {!isNew && baseTabs && !isWebForm && (
              <div className={styles.baseTabse}>
                <TabsMenu items={baseTabs} activeId={tabId} extParams={extParams} />
              </div>
            )}
          </div>
        )}
        <ProcessNotify show={updateProcess} />
      </div>
    );
  }
}

export default withTranslation()(Header);
