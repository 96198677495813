import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';

import raf from 'raf';
import FieldApi from '../../../../models/FieldApi';
import FIELD_TYPES from '../../../../configs/fieldTypes';
import getCellType, { CELL_TYPES } from './getCellType';

import FieldPopover from './FieldPopover';
import ValueCell from './ValueCell';
import fieldsConfigMappers from './fieldsConfigMappers';

import styles from './table.less';

const controlFieldMappers = {
  [FIELD_TYPES.NUMBER]: require('../../../Record/RecordBody/mainTab/fields/Number').default,
  [FIELD_TYPES.OBJECT]: require('../../../Record/RecordBody/mainTab/fields/Object').default,
  [FIELD_TYPES.USER]: require('../../../Record/RecordBody/mainTab/fields/User').default,
  [FIELD_TYPES.CONTACT]: require('../../../Record/RecordBody/mainTab/fields/Contact').default,
  [FIELD_TYPES.FILE]: require('../../../Record/RecordBody/mainTab/fields/File').default,
};

class CellData extends React.PureComponent {
  state = {
    active: false,
  };

  static propTypes = {
    sceneId: PropTypes.string,
    catalogId: PropTypes.string,
    recordId: PropTypes.string,
    currentRecord: PropTypes.bool,
    field: PropTypes.object,
    record: PropTypes.object,
    onChange: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onStartEditing: PropTypes.func,
    onEndEditing: PropTypes.func,
    onRefuseEditing: PropTypes.func,
  };

  mapField = (field, value) => {
    const { catalogId, recordId, recordExist } = this.props;

    // table special field config mapper
    field = fieldsConfigMappers(field);

    let data = {
      catalogId,
      field,
      value,
    };
    if (recordExist) {
      data.recordId = recordId;
    }

    // base field mapper
    const type = field.get('type');
    const mapper = controlFieldMappers[type];
    if (mapper) {
      data = mapper.config(data);
    }

    return data;
  };

  prepareFieldComponent = (canEdit) => {
    let { field, value, sceneId, record, validateValueByField } = this.props;
    const fieldId = field.get('id');

    const fieldIsValid = validateValueByField ? validateValueByField(value, field) : true;

    const cellType = getCellType(field);

    canEdit = _.isUndefined(canEdit) ? true : canEdit; // to allow edit before get right to edit
    const pending = _.isUndefined(canEdit);

    let Component;

    const componentProps = {
      sceneId,
      field,
      fieldId: field.get('id'),
      config: field.get('config'),
      fieldType: field.get('type'),
      value,
      onChange: this.onChangeValue,
      onBlur: this.onBlur,
      onEndEditing: this.onEndEditing,
    };

    // while current value is loading
    if (this.state.active && pending) {
      Component = FieldApi.getComponent(field, 'inline');
      return <Component {...componentProps} />;
    }

    if (this.state.active) {
      // check if field is valid render boilerplate
      if (!fieldIsValid) {
        value = FieldApi.getEmptyValue(field);
        componentProps.value = value;
      }

      // map field config
      const object = this.mapField(field, value);
      if (object) {
        componentProps.controlConfig = object.field;
        componentProps.value = object.value;
      }

      Component =
        cellType !== CELL_TYPES.POPOVER ? FieldApi.getComponent(object ? object.field : field, cellType) : FieldPopover;
    } else {
      /* если поле сконвертированно, то отображаем как обычно, в противном случае инлайн элементы отображаем в текстовом варианте (нужно для импорта) */
      if (!fieldIsValid) {
        const fieldWithDefaultType = field.set('type', FIELD_TYPES.TEXT);
        const originValue = record && record.getIn(['originValues', fieldId]);

        componentProps.value = originValue;

        Component = FieldApi.getComponent(fieldWithDefaultType, 'inline');
      } else {
        Component = FieldApi.getComponent(field, 'inline');
      }
    }

    if (this.state.active) {
      switch (cellType) {
        case CELL_TYPES.SELECTOR: // dropdowns
          componentProps.originalValue = value;
          componentProps.autoFocus = true;
          break;
        case CELL_TYPES.CONTROL: // inputs
          componentProps.autoFocus = true;
          componentProps.className = styles.cellControl;
          break;
        case CELL_TYPES.POPOVER:
          componentProps.originalValue = value;
          componentProps.className = styles.cellPopover;
          break;
        default:
          break;
      }
    }

    if (this.state.active) {
      componentProps.editable = canEdit;
    }

    return <Component {...componentProps} />;
  };

  startEditing = () => {
    this.setState({ active: true });

    // функция может быть ассинхронной, например загрузка записи
    this.props.onStartEditing(this.props.record, this.props.field);
  };

  onChangeValue = (value) => {
    const fieldId = this.props.field.get('id');
    const fieldType = this.props.field.get('type');
    const { recordId } = this.props;
    const { record } = this.props;

    const mapper = controlFieldMappers[fieldType];

    if (mapper) {
      value = mapper.onChange(value, fieldId, record);
    }

    this.props.onChange && this.props.onChange(recordId, fieldId, value);
  };

  onEndEditing = () => {
    raf(() => {
      const { value, fieldId, recordId, onEndEditing } = this.props;

      this.setState({ active: false });

      onEndEditing && onEndEditing(recordId, fieldId, value);
    });
  };

  onBlur = () => {
    this.setState({ active: false });
  };

  refuseEditing = () => {
    const { recordId, fieldId } = this.props;

    this.setState({
      active: false,
    });

    this.props.onRefuseEditing && this.props.onRefuseEditing(recordId, fieldId);
    this.onEndEditing();
  };

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.state;

    const { editable } = this.props;
    const prevEditable = prevProps.editable;

    const { fieldId } = this.props;
    const prevFieldId = prevProps.fieldId;

    const { recordId } = this.props;
    const prevRecordId = prevProps.recordId;

    if (!active) {
      return;
    }

    if (!editable) {
      return this.refuseEditing();
    }

    if (recordId !== prevRecordId || fieldId !== prevFieldId) {
      return this.onEndEditing(prevProps);
    }
  }

  componentWillUnmount() {
    if (this.state.active) {
      this.onEndEditing();
    }
  }

  render() {
    const {
      saving,
      inProcess,
      field,
      record,
      getCellClassName,
      recordId,
      fieldId,
      editable,
      onSelectCell,
      onDoubleClick,
      ...props
    } = this.props;

    const { active } = this.state;
    const pending = active && _.isUndefined(editable);

    const extraClassName = getCellClassName && getCellClassName(field, record);
    const className = cn(
      this.props.className,
      {
        [styles.cellSelected]: active,
        [styles.cellProcess]: inProcess,
      },
      extraClassName,
    );

    return (
      <ValueCell
        columnKey={props.columnKey}
        className={className}
        height={props.heigth}
        width={props.width}
        // cellType={getCellType(field)} // need for edit icon button
        editable={editable}
        inProcess={inProcess}
        onActive={this.startEditing}
        onDoubleClick={onDoubleClick}
      >
        {this.prepareFieldComponent(editable)}
      </ValueCell>
    );
  }
}

export default CellData;
