import Immutable, { fromJS } from 'immutable';
import _ from 'lodash';

import apiActions from '../../../actions/apiActions';
import changesActions from '../../../actions/changesActions';
import FIELD_TYPES from '../../../configs/fieldTypes';

export default {
  /* hasParent - означает, функция вызывается от изначально измененной записи (изменяемая человеком запись, а не каскадом) */
  checkAllowToRaiseRecordChanges(params, newValues, hasParent) {
    const { catalogId, recordId } = params;

    // check on eventable and was raise earler
    const valuesNeedToRaise = this.getValuesNeedToRaise(params, newValues, this.updatedFields);

    // if values remain raise changes
    // else raise changes for records when this record located as linkedRecord
    if (valuesNeedToRaise && valuesNeedToRaise.size) {
      const values = this.arrangeRecordValuesToObjects(catalogId, valuesNeedToRaise);
      return this.raiseRecordChanges(params, values, hasParent);
    }
    let parentsWillRaise = Immutable.Map();

    const parents = this.getIn(['records', catalogId, recordId, 'parents']);

    parents &&
      parents.forEach((parent) => {
        const parentKey = `${parent.get('catalogId')}:${parent.get('recordId')}`;

        const params = {
          catalogId: parent.get('catalogId'),
          recordId: parent.get('recordId'),
        };

        const fields = this.getIn(['catalogs', parent.get('catalogId'), 'fields']);
        const parentField = fields.find((field) => field.get('id') === parent.get('fieldId'));

        if (!this.fieldNeedToRise(params, this.updatedFields, parentField)) {
          return;
        }

        const recordParent = this.getIn(['records', parent.get('catalogId'), parent.get('recordId')]);
        const updatedFields = this.getUpdatedFields(catalogId, recordId).map((_, fieldId) =>
          this.getIn(['records', catalogId, recordId, 'values', fieldId]),
        );

        let recordValues = updatedFields.merge(newValues);

        const filterParams = {
          catalogId: parent.get('catalogId'),
          fieldId: parent.get('fieldId'),
          linkedCatalogId: catalogId,
        };

        recordValues = this.filterExtendedValues(filterParams, recordValues);
        if (!(recordValues && recordValues.size)) {
          return;
        }

        let value = recordParent.getIn(['values', parent.get('fieldId')]);

        value = value.map((linkedRecord) => {
          const linkedCatalogId = linkedRecord.get('catalogId');
          const linkedRecordId = linkedRecord.get('recordId');

          return linkedCatalogId === catalogId && linkedRecordId === recordId
            ? linkedRecord.set('recordValues', recordValues)
            : linkedRecord;
        });

        if (!parentsWillRaise.get(parentKey)) {
          parentsWillRaise = parentsWillRaise.set(
            parentKey,
            fromJS({
              catalogId: parent.get('catalogId'),
              recordId: parent.get('recordId'),
            }),
          );
        }

        parentsWillRaise = parentsWillRaise.setIn([parentKey, 'newValues', parent.get('fieldId')], value);
      });

    // !parents.size - means that changes cycle through this record is complited
    // and we can allow raise changes on these fields again
    if (!(parentsWillRaise && parentsWillRaise.size) && !hasParent) {
      this.removeUpdatedFields();
    }
    /* создание цепочки промисов, с помощью рекрсивного вызова checkAllowToRaiseRecordChanges, через raiseRecordChanges */
    return (
      parentsWillRaise // alternately raise changes for parents
        .reduce(
          (promise, parent) =>
            promise.then(() => {
              const params = {
                catalogId: parent.get('catalogId'),
                recordId: parent.get('recordId'),
              };

              const newValues = parent.get('newValues');

              return this.raiseRecordChanges(params, newValues, true);
            }),
          Promise.resolve(),
        )
        /* объявление об успешном завершении всей цепочки промисов */
        .then(() => {
          if (!hasParent) {
            this.removeUpdatedFields();

            /* снятие флага "should" со всех записей, изменяемых по каскаду от изначальной */
            this.clearShouldUpdateProcess(catalogId, recordId);

            /* вызов алерта */
            changesActions.notifyChangesResults();
          }
        })
    );
  },

  raiseRecordChanges(params, values, hasParent) {
    const { catalogId, recordId } = params;

    const record = this.getIn(['records', catalogId, recordId]);
    if (!record) {
      return;
    }

    let allValues = this.arrangeRecordValuesToObjects(catalogId, record.get('values'));

    allValues = allValues.toJS();
    values = values.toJS();

    return apiActions
      .createChange({ catalogId, recordId: record.get('isNew') ? 'new' : recordId }, { values, allValues }, recordId)
      .then((hookResults) => {
        let hookValues = Immutable.Map();

        _.forEach(hookResults, (hookResult) => {
          hookValues = hookValues.merge(fromJS(hookResult.values));
        });

        Object.keys(values).forEach((fieldId) => this.addUpdatedFields(catalogId, recordId, fieldId));

        // clear Button fields values
        hookValues = this.clearValuesForButtonFields(catalogId, values, hookValues);

        // set values
        this.updateRecordValues(params, hookValues, true);

        // raise cascade changes
        return this.checkAllowToRaiseRecordChanges(params, hookValues, hasParent);
      });
  },

  /* После нажатия на кнопку у ТИПА ПОЛЯ КНОПКА обнуляем его значение */
  clearValuesForButtonFields(catalogId, raisedValues, returnedValues) {
    const fields = this.getIn(['catalogs', catalogId, 'fields']);

    _.forEach(raisedValues, (v, fieldId) => {
      const field = fields.find((f) => f.get('id') == fieldId);
      if (field && field.get('type') == FIELD_TYPES.BUTTON) {
        returnedValues = returnedValues.set(fieldId, null);
      }
    });

    return returnedValues;
  },

  /* should - флаг по которому блокируются компоненты, при начале действия лайф сценариев  */
  shouldUpdateProcess(catalogId, recordId, should = true) {
    const recordKey = ['records', catalogId, recordId];

    const currentShould = this.getIn([...recordKey, 'updateProcesses', 'should']);

    if (currentShould === should) {
      return;
    }

    if (this.getIn(recordKey)) {
      this.mergeIn([...recordKey, 'updateProcesses'], {
        should,
      });
    }

    const parents = this.getIn([...recordKey, 'parents']);

    parents &&
      parents.forEach((parent) => this.shouldUpdateProcess(parent.get('catalogId'), parent.get('recordId'), should));

    this.changed();
  },

  clearShouldUpdateProcess(catalogId, recordId) {
    this.shouldUpdateProcess(catalogId, recordId, false);
  },
};
