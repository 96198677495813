export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'webRequest',
  title: t('script.controlls.webRequest.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon transfers-51',
  offset_x: 0,
  offset_y: -15,
  priority: 3,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.webRequest.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'paramsSection',
      name: t('script.controlls.webRequest.request'),
      type: 'group',
    },
    {
      id: 'method',
      name: t('script.controlls.webRequest.method'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'GET',
            name: 'GET',
            color: 'FFF2C4',
          },
          {
            id: 'POST',
            name: 'POST',
            color: 'D0FBBF',
          },
          {
            id: 'PATCH',
            name: 'PATCH',
            color: 'C9FDE5',
          },
          {
            id: 'PUT',
            name: 'PUT',
            color: 'D3E3FF',
          },
          {
            id: 'DELETE',
            name: 'DELETE',
            color: 'F9C2C2',
          },
          {
            id: 'HEAD',
            name: 'HEAD',
            color: 'EEFFB8',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['GET'],
        map: 'input',
      },
    },
    {
      id: 'query',
      name: t('script.controlls.webRequest.query'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
    },
    {
      id: 'format',
      name: t('script.controlls.webRequest.requestFormat'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'keyvalue',
            name: t('script.common.keyValue'),
            color: 'E7E9ED',
          },
          {
            id: 'body',
            name: t('script.controlls.webRequest.requestBody'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['keyvalue'],
        map: 'input',
      },
      visible: {
        method: {
          $in: ['POST', 'PUT', 'PATCH'],
        },
      },
    },
    {
      id: 'params',
      name: t('script.controlls.webRequest.params'),
      type: 'pair',
      readOnly: false,
      config: {
        placeholderValue: t('script.controlls.webRequest.paramsPlaceholder'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        map: 'input',
      },
      visible: {
        format: {
          $include: 'keyvalue',
        },
      },
    },
    {
      id: 'body',
      name: t('script.controlls.webRequest.body'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'multiline',
        theme: 'script',
        map: 'input',
      },
      visible: {
        format: {
          $include: 'body',
        },
      },
    },
    {
      id: 'type',
      name: t('script.controlls.webRequest.dataType'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'application/json',
            name: 'JSON',
            color: 'E7E9ED',
          },
          {
            id: 'application/xml',
            name: 'XML',
            color: 'E7E9ED',
          },
          {
            id: 'application/soap+xml',
            name: 'XML+SOAP',
            color: 'E7E9ED',
          },
          {
            id: 'text/plain',
            name: 'Plain text',
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['application/json'],
        map: 'input',
      },
      visible: {
        format: {
          $include: 'body',
        },
      },
    },
    {
      id: 'headers',
      name: t('script.controlls.webRequest.headers'),
      type: 'pair',
      readOnly: false,
      config: {
        placeholderValue: t('script.controlls.webRequest.header'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        map: 'input',
      },
    },
    {
      id: 'auth',
      name: t('script.controlls.webRequest.auth'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'noauth',
            name: t('script.controlls.webRequest.authWithout'),
            color: 'E7E9ED',
          },
          {
            id: 'basic',
            name: t('script.controlls.webRequest.authBasic'),
            color: 'E7E9ED',
          },
          {
            id: 'oauth2',
            name: 'OAuth2',
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['noauth'],
        map: 'input',
      },
    },
    {
      id: 'login',
      name: t('script.controlls.webRequest.login'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        auth: {
          $include: 'basic',
        },
      },
    },
    {
      id: 'password',
      name: t('script.controlls.webRequest.password'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        auth: {
          $include: 'basic',
        },
      },
    },
    {
      id: 'auth_token',
      name: t('script.controlls.webRequest.token'),
      type: 'object',
      readOnly: false,
      config: {
        catalogId: '$authServices',
        multiselect: false,
        enableCreate: true,
        enableSelect: true,
        view: 'list',
        map: 'input',
      },
      visible: {
        auth: {
          $include: 'oauth2',
        },
      },
    },
    {
      id: 'section1',
      name: t('script.controlls.webRequest.response'),
      type: 'group',
    },
    {
      id: 'async',
      name: t('script.controlls.webRequest.responseWait'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'sync',
            name: t('script.common.yes'),
            color: 'E7E9ED',
          },
          {
            id: 'async',
            name: t('script.common.no'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['sync'],
        map: 'input',
      },
    },
    {
      id: 'output_status',
      name: t('script.controlls.webRequest.status'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        async: {
          $include: 'sync',
        },
      },
    },
    {
      id: 'output_format',
      name: t('script.controlls.webRequest.answerFormat'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'text',
            name: t('script.controlls.webRequest.text'),
            color: 'E7E9ED',
          },
          {
            id: 'json',
            name: 'JSON',
            color: 'E7E9ED',
          },
          {
            id: 'binary',
            name: t('script.controlls.webRequest.binaryData'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['text'],
        map: 'input',
      },
      visible: {
        async: {
          $include: 'sync',
        },
      },
    },
    {
      id: 'output_body',
      name: t('script.controlls.webRequest.outputBody'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        async: {
          $include: 'sync',
        },
      },
    },
    {
      id: 'output_headers',
      name: t('script.controlls.webRequest.outputHeaders'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.webRequest.outputHeadersHint'),
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        async: {
          $include: 'sync',
        },
      },
    },
  ],
  exits: [],
});
