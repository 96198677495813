/* eslint-disable camelcase */
import _ from 'lodash';
import React from 'react';
import Immutable from 'immutable';
import cn from 'classnames';
import { Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Highlighter from 'react-highlight-words';
import { DownOutlined } from '@ant-design/icons';

import { parseDadata } from './Address/utils';
import styles from './controls.less';

const { Option } = Select;

class AddressControl extends React.PureComponent {
  static propTypes = {
    value: PropTypes.object,
    controlConfig: PropTypes.object,
    editable: PropTypes.bool,
    config: PropTypes.object,
    onChange: PropTypes.func,
    onEndEditing: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.textInput = null;
    this.fetch = debounce(this.fetch, 800);
  }

  xhr = new XMLHttpRequest();

  state = {
    suggestions: [],
    value: Immutable.fromJS({}),
    fetching: false,
  };

  fetch = (query) => {
    this.xhr.abort();

    const valueText = this.state.value && this.state.value.get('value');
    if (valueText) {
      query = `${valueText} ${query}`;
    }
    const payload = {
      query,
      count: 10,
    };

    this.xhr.open('POST', `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`);
    this.xhr.setRequestHeader('Accept', 'application/json');
    this.xhr.setRequestHeader('Authorization', `Token ${this.props.config.get('token')}`);
    this.xhr.setRequestHeader('Content-Type', 'application/json');
    this.xhr.send(JSON.stringify(payload));

    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState !== 4) {
        return;
      }

      if (this.xhr.status === 200) {
        let { suggestions } = JSON.parse(this.xhr.response);
        suggestions = this.parseFetchedData(suggestions);

        if (!this.state.focused) {
          this.setValueFromSuggestions(suggestions);
          this.setState({ suggestions, fetching: false });
        } else {
          this.setState({ suggestions, fetching: false });
        }
      }
    };

    this.setState({ query, fetching: true });
  };

  parseFetchedData = (data) => _.map(data || [], (d) => parseDadata(d));

  parseDataToText = (data) => {
    const country = (data.postal_code ? `${data.postal_code}, ` : '') + (data.country ? data.country : '');
    const region = data.region_with_type;
    const country_region = country + (region ? `, ${region}` : '');
    const area = data.area_with_type;
    const city = data.city_with_type;
    const settlement = data.settlement_with_type;
    const street = data.street_with_type;
    const house = (data.house_type ? `${data.house_type} ` : '') + (data.house ? data.house : '');
    const block = (data.block_type ? `${data.block_type} ` : '') + (data.block ? ` ${data.block}` : '');
    const flat = (data.flat_type ? `${data.flat_type} ` : '') + (data.flat ? data.flat : '');
    const house_flat = house + (block ? `, ${block}` : '') + (flat ? `, ${flat}` : '');

    return (
      <div>
        {country_region ? <p>{country_region}</p> : null}
        {area ? <p>{area}</p> : null}
        {city ? <p>{city}</p> : null}
        {settlement ? <p>{settlement}</p> : null}
        {street ? <p>{street}</p> : null}
        {house_flat ? <p>{house_flat}</p> : null}
      </div>
    );
  };

  onSelect = (valueText) => {
    const suggestions = this.state.suggestions || [];
    const value = valueText ? _.find(suggestions, (f) => f.value == valueText) : { value: valueText };

    const currentValue = this.state.value.get('value');

    if (currentValue == valueText || this.state.suggestions.length == 1) {
      // end edit
      this.setValue(value, false);
      this.setState({
        suggestions: [],
      });
    } else if (this.state.suggestions.length > 1) {
      this.fetch(' '); // continue enter address
      this.setValue(value, true);
    }
  };

  onDeselect = (value) => {
    this.setState({
      suggestions: [],
    });

    // remove item from current value
    let valueText = this.state.value.get('value');

    if (typeof valueText === 'string') {
      valueText = valueText ? valueText.split(',').map((v) => v.trim()) : [];
      valueText = valueText.filter((v) => v != value);
      valueText = valueText.join(', ');
    } else {
      // temp fix for when value is object
      valueText = '';
    }
    const newValue = {
      value: valueText,
      data: {},
    };
    this.setValue(newValue, true);

    this.fetch(' '); // continue enter address
  };

  setValue = (value, endEditing) => {
    value = Immutable.Map.isMap(value) ? value : Immutable.fromJS(value);

    // raise events
    this.props.onChange && this.props.onChange(value);
    if (endEditing) {
      this.props.onEndEditing && this.props.onEndEditing(value);
    }
  };

  setValueFromSuggestions = (suggestions) => {
    const valueText = this.state.value.get('value');
    const data = this.state.value.get('data');

    let suggestData = _.find(suggestions, (f) => f.value == valueText);
    suggestData = suggestData || (suggestions ? suggestions[0] : {});
    suggestData = suggestData || {};

    if (_.isEmpty(data) && !_.isEmpty(suggestData)) {
      const value = {
        value: suggestData ? suggestData.value : valueText,
        data: suggestData ? suggestData.data : {},
      };
      this.setValue(value, true);
    }
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onBlur = () => {
    const suggestions = this.state.suggestions || [];
    this.setValueFromSuggestions(suggestions);

    this.setState({
      focused: false,
    });
  };

  getHighlightWords = (value) => {
    if (!value) return [];

    const wordsToPass = ['г', 'респ', 'ул', 'р-н', 'село', 'деревня', 'поселок', 'пр-д', 'пл', 'к', 'кв', 'обл', 'д'];
    const words = value.replace(',', '').split(' ');
    const filteredWords = words.filter((word) => wordsToPass.indexOf(word) < 0);
    return filteredWords;
  };

  shortenSuggesion = (value) => {
    // remove item from current value
    const valueText = value;
    value = value ? valueText.split(',').map((v) => v.trim()) : [];
    value = _.slice(value, Math.max(value.length - 3, 0), value.length);
    value = value.join(', ');

    return value;
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      value: value || Immutable.fromJS({}),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newValue = nextProps.value && nextProps.value.toJS();
    const oldValue = this.props.value && this.props.value.toJS();

    if (!_.isEqual(newValue, oldValue)) {
      this.setState({
        value: nextProps.value || Immutable.fromJS({}),
      });
    }
  }

  render() {
    const { editable } = this.props;

    const { query, fetching, suggestions, value } = this.state;

    const valueText = value && value.get('value');
    const valueTags = valueText ? valueText.split(',').map((v) => v.trim()) : [];
    let valueData = value && value.get('data');
    valueData = valueData ? valueData.toJS() : {};

    return (
      <div className={this.props.wrapperClassName}>
        {editable ? (
          <Select
            autoFocus={this.props.autoFocus}
            mode="multiple"
            showSearch
            disabled={!editable}
            value={valueTags}
            placeholder="Введите адрес"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={this.fetch}
            onChange={this.onChange}
            onSelect={this.onSelect}
            onDeselect={this.onDeselect}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            style={{ width: '100%' }}
            optionLabelProp="title"
            suffixIcon={<DownOutlined />}
          >
            {suggestions &&
              suggestions.map((d, index) => (
                <Option key={d.value} title={d.value}>
                  <Highlighter
                    highlightClassName="react-dadata--highlighted"
                    searchWords={this.getHighlightWords(query)}
                    textToHighlight={this.shortenSuggesion(d.value)}
                    autoEscape
                  />
                </Option>
              ))}
          </Select>
        ) : null}
        {!_.isEmpty(valueData) ? (
          <div
            className={cn(styles.addressExtra, {
              [styles.inputReadOnly]: !editable,
            })}
          >
            {this.parseDataToText(valueData)}
          </div>
        ) : null}
      </div>
    );
  }
}

export default AddressControl;
