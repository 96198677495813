import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Radio } from 'antd';

class SelectSortDirection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableItems: this.getAvailableItems(),
    };
  }

  getAvailableItems() {
    const items = [1, -1];
    return items;
  }

  UNSAFE_componentWillReceiveProps({ catalog }) {
    if (this.props.catalog !== catalog) {
      this.setState({
        availableItems: this.getAvailableItems(),
      });
    }
  }

  UNSAFE_componentWillMount() {}

  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { style, value } = this.props;
    const { availableItems } = this.state;

    return (
      <Radio.Group onChange={this.onChange} value={value} style={style}>
        {availableItems.map((item) => (
          <Radio.Button key={item} value={item}>
            {this.props.t(`records.cards.config.sortDirection.${String(item) == '-1' ? 'desc' : 'asc'}`)}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  }
}

SelectSortDirection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default withTranslation()(SelectSortDirection);
