export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'bpiumDelete',
  title: t('script.controlls.bpiumDelete.title'),
  helplink: '',
  class: 'bpium',
  color: '#7cab9e',
  border: 2,
  icon: 'anticon-icon edition-41',
  icon_scale: 0.9,
  offset_x: 0,
  offset_y: -15,
  priority: 1,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.bpiumDelete.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'paramsSection',
      name: t('script.controlls.bpiumDelete.title'),
      type: 'group',
    },
    {
      id: 'catalogFormat',
      name: t('script.common.enterCatalog'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'catalog',
            name: t('script.common.fromList'),
            color: 'E7E9ED',
          },
          {
            id: 'var',
            name: t('script.common.fromVariables'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['catalog'],
        map: 'input',
      },
    },
    {
      id: 'catalogId',
      name: t('script.common.catalog'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'catalogs',
        map: 'input',
      },
      visible: {
        catalogFormat: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'catalogIdFromVar',
      name: t('script.common.catalogId'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.numberExpression'),
        type: 'text',
        theme: 'script',
        map: 'input',
      },
      visible: {
        catalogFormat: {
          $include: 'var',
        },
      },
    },
    {
      id: 'recordId',
      name: t('script.common.recordId'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
    },
  ],
  exits: [],
});
