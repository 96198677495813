import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

require('dotenv').config({
  silent: true,
});
// List of plural groups in different languages
// https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_plural_rules.html

const DEBUG = process.env.REACT_APP_TRANSLATE_DEBUG || false;

const resources = {};
try {
  const ru = require('../../translations/ru.json');
  resources.ru = { translation: ru };
} catch (err) {
  // eslint-disable-next-line no-console
  console.log('No ru translation');
}

try {
  // eslint-disable-next-line import/no-unresolved
  const en = require('../../translations/en.json');
  resources.en = { translation: en };
} catch (err) {
  // eslint-disable-next-line no-console
  console.log('No en translation');
}

function setCookie(name, value) {
  // get 2-level domain for cookie
  let domain = window.location.host.split('.');
  domain = domain.length > 2 ? `.${domain.splice(0, domain.length - 2).join('.')}` : window.location.host;

  document.cookie = `${name}=${value || ''}; path=/; Domain=${domain}`;
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: DEBUG,
    fallbackLng: 'ru',
    supportedLngs: ['ru', 'en'],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    resources,
    detection: {
      lookupCookie: 'lng',
      lookupLocalStorage: 'lng',
      lookupSessionStorage: 'lng',
      caches: ['cookie', 'localStorage'],
    },
  });
i18n.on('languageChanged', (lng) => {
  // standart save via i18next-browser-languagedetector is not working some how
  setCookie('lng', lng);
});

export default i18n;
