import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Checkbox } from 'antd';

export default class Checkboxes extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { checked, onChange, readOnly, title, className, disabled } = this.props;
    return (
      <Checkbox
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        // style={{ cursor: "pointer" }}
        checked={checked}
        onChange={onChange}
        className={cn(className, { readonly: readOnly || disabled })}
      >
        {title}
      </Checkbox>
    );
  }
}
