function config({ field, value }) {
  field = field.setIn(['config', 'unit'], null);

  return { field, value };
}

function onChange(values) {
  return values;
}

function onEndEditing(values) {
  return values;
}

export default {
  config,
  onChange,
  onEndEditing,
};
