import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import { Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';

function getValuesMap(values) {
  const map = {};

  Immutable.fromJS(values || []).forEach((v) => {
    v = v.toJS ? v.toJS() : v;
    map[v] = true;
  });

  return Immutable.fromJS(map);
}

class CheckboxesField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: getValuesMap(this.props.value),
    };
  }

  onChangeItem = (itemId) => {
    const { onSave, fieldId } = this.props;

    const newValues = this.state.values.set(itemId, !this.state.values.get(itemId));
    this.setState({
      values: newValues,
    });

    const values = [];
    newValues.forEach((v, id) => {
      if (v) {
        values.push(id);
      }
    });

    onSave(fieldId, values);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.value, this.props.value)) {
      this.setState({
        values: getValuesMap(nextProps.value),
      });
    }
  }

  render() {
    // get deleted values from
    let deletedValues = null;
    const { t } = this.props;

    if (this.state.values) {
      let idsFromFilter = this.state.values.keySeq().toArray();
      idsFromFilter = idsFromFilter.filter((i) => i !== '$EMPTY');

      const idsFromFieldConfig = this.props.config
        .get('items')
        .map((item) => item.get('id'))
        .toArray();

      deletedValues = _.difference(idsFromFilter, idsFromFieldConfig).map((id) => (
        <Checkbox key={id} checked onChange={_.bind(this.onChangeItem, this, id)}>
          {t('filter.field.removed')}
        </Checkbox>
      ));
    }

    let items = this.props.config.get('items') || Immutable.fromJS([]);
    items = items.push(
      Immutable.fromJS({
        id: '$EMPTY',
        name: t('filter.undefined'),
      }),
    );

    return (
      <div className="record-radio">
        {items.map((item) => {
          const id = item.get('id');
          const selected = this.state.values.get(id);
          return (
            <Checkbox
              key={id}
              disabled={this.props.readOnly}
              checked={selected}
              onChange={_.bind(this.onChangeItem, this, id)}
            >
              {item.get('name')}
            </Checkbox>
          );
        })}

        {deletedValues}
      </div>
    );
  }
}

CheckboxesField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default withTranslation()(CheckboxesField);
