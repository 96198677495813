import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { Component } from 'react';
import { Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import Icon from '../../common/UI/Icon';

export default class Activities extends Component {
  static propTypes = {
    singleButton: PropTypes.bool,
    dropDownButtonItems: PropTypes.array,
    mainButton: ImmutablePropTypes.map.isRequired,
  };

  render() {
    const { singleButton, dropDownButtonItems, mainButton, clearButton } = this.props;

    const dropDownButtonItemsLength = dropDownButtonItems && dropDownButtonItems.length;
    if (!mainButton) {
      return null;
    }

    if (singleButton || !dropDownButtonItemsLength) {
      return (
        <>
          {clearButton && (
            <Button className={clearButton.get('className')} onClick={clearButton.get('onClick')}>
              {clearButton.get('text')}
            </Button>
          )}
          <Button
            disabled={mainButton.get('disabled')}
            type={mainButton.get('type')}
            onClick={mainButton.get('onClick')}
          >
            {mainButton.get('text')}
          </Button>
        </>
      );
    }
    const dropdownMenu = dropDownButtonItems.map((item, i) => ({
      key: i,
      label: item,
    }));

    return (
      <Dropdown.Button
        trigger={['click']}
        type={mainButton.get('type')}
        className={mainButton.get('className')}
        disabled={mainButton.get('disabled')}
        onClick={mainButton.get('onClick')}
        menu={{ items: dropdownMenu }}
        icon={<DownOutlined />}
      >
        {mainButton.get('icon') ? (
          <Icon type={`icon ${mainButton.get('icon')}`} className={mainButton.get('iconClassName')} />
        ) : null}
        {mainButton.get('text')}
      </Dropdown.Button>
    );
  }
}
