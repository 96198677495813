import Reflux from 'reflux';

export default Reflux.createActions({
  changeSortIndex: {},
  addCatalog: {},
  catalogEdit: {},
  clearCatalog: {},
  clearEditingCatalog: {},
  truncateCatalogFromStore: {},
});
