import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import cn from 'classnames';
import styles from './styles.less';

function VideoJSPlayer({ url, mimeType, readOnly, className, height, width }) {
  const videoContainerRef = useRef(null); // Контейнер для плеера
  const playerRef = useRef(null); // Ссылка на инстанс Video.js

  // fix: controls styles
  // https://stackoverflow.com/questions/66847167/why-are-video-js-controls-showing-weirdly
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://vjs.zencdn.net/7.10.2/video-js.css';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    if (!playerRef.current) {
      // Создаём элемент video-js внутри контейнера
      const videoElement = document.createElement('video-js');
      videoElement.classList.add(styles.videoElement);
      if (videoContainerRef.current) {
        videoContainerRef.current.appendChild(videoElement);

        // Инициализируем Video.js с опциями
        playerRef.current = videojs(videoElement, {
          controls: !readOnly,
          autoplay: false,
          preload: 'auto',
          responsive: true,
          aspectRatio: '16:9',
          // fluid: true, // Адаптивность
          sources: [
            {
              src: url,
              type: mimeType || 'video/mp4',
            },
          ],
        });
      }
      // Вешаем класс на иконку запуска видео
      videoElement.querySelector('.vjs-big-play-button').classList.add(styles.playBtn);
    } else {
      // Обновляем источник видео, если уже есть инстанс плеера
      const player = playerRef.current;
      player.src({ src: url, type: mimeType || 'video/mp4' });
    }

    return () => {
      // Уничтожаем плеер при размонтировании компоненты
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [url, mimeType, readOnly]);

  return (
    <div data-vjs-player className={cn(className)}>
      <div ref={videoContainerRef} style={{ width, height }} className={styles.videoPlayer} />
    </div>
  );
}

export default VideoJSPlayer;
