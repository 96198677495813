import React from 'react';
import FIELD_TYPES from '../../../../configs/fieldTypes';
import ObjectField from '../../../common/dataTypes/ObjectField';
import UserField from '../../../common/dataTypes/UserField';

function SelectLabel({ type, config, value }) {
  switch (type) {
    case FIELD_TYPES.OBJECT:
      return <ObjectField value={value} config={config} />;

    case FIELD_TYPES.USER:
      return <UserField value={value} config={config} isSingle />;

    default:
      return null;
  }
}

export default SelectLabel;
