import React from 'react';
import Base from './Base';
import MultiModal from './MultiModal';
import renderComponentToBody from '../../../helpers/renderComponentToBody';
import RecordModal from '../../Record/RecordModal';
import routes from '../../../routes';
import AlertWrapper from './AlertWrapper';

import Confirm from './Confirm';
import Alert from './Alert';
import Prompt from './Prompt';
import PromptConfirm from './PromptConfirm';
import PromptModal from './PromptModal';

export default Base;

export function renderModaltoBody(Component, props) {
  function afterClose() {
    destroyFn();
  }

  function onOk(...args) {
    afterClose();
    if (props.onOk) {
      props.onOk.call(this, ...args);
    }
  }

  function onRemove(...args) {
    afterClose();
    if (props.onRemove) {
      props.onRemove.call(this, ...args);
    }
  }

  function onCancel(...args) {
    afterClose();
    if (props.onCancel) {
      props.onCancel.call(this, ...args);
    }
  }

  const destroyFn = renderComponentToBody(Component, {
    ...props,
    onOk,
    onRemove: props.onRemove && onRemove,
    onCancel,
    visible: true,
  });
}

function getRenderComponentToBodyFn(Component) {
  return function (props) {
    renderModaltoBody(Component, props);
  };
}

export const promptModal = getRenderComponentToBodyFn(PromptModal);
export const confirm = getRenderComponentToBodyFn(Confirm);
export const alert = getRenderComponentToBodyFn(Alert);
export const prompt = getRenderComponentToBodyFn(Prompt);
export const promptConfirm = getRenderComponentToBodyFn(PromptConfirm);

export function alertWrapper(Component, props) {
  renderModaltoBody(AlertWrapper, {
    children: <Component {...props} />,
    onOk: props.onOk,
    onRemove: props.onRemove && props.onRemove,
    onCancel: props.onCancel,
    headerText: props.headerText,
    okText: props.okText,
  });
}

export function multiModal() {
  renderModaltoBody(RecordModal, {
    Child: MultiModal,
    params: {},
    route: routes.record,
  });
}
