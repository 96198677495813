import React from 'react';
import Immutable from 'immutable';
import _ from 'lodash';

import FIELD_TYPES from '../../configs/fieldTypes';
import { MAX_STAR_SIZE } from '../../components/common/UI/ControlList/controls/Stars';
import { VALUE_STATUSES } from '../../configs/import';
import { Field } from './BaseClass.js';

export default class Stars extends Field {
  constructor() {
    super();
  }

  static type = FIELD_TYPES.STARS;

  static components = {
    inline: require('../../components/common/dataTypes/StarsField').default,
    control: require('../../components/common/UI/ControlList/controls/Stars').default,
  };

  static getComponent = (type) => Stars.components[type];

  static getCanComponentExpandWidthOrHeight = (value) => {};

  static parseValue = (field, value) => {
    // clean
    value = _.trim(value);

    // try parse as empty value
    if (Stars.isEmpty(value)) {
      value = Stars.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    /* парс значений в качестве чисел */
    const parsedValue = parseFloat(value);
    if (_.isNumber(parsedValue)) {
      if (Stars.validateValue(field, parsedValue)) {
        return { value: parsedValue, status: VALUE_STATUSES.VALID };
      }
    }

    /* парс значений как одинаковых элементов к примеру **** */
    const allValuesSame = _.isString(value) && _.find(value, (v) => v == _.first(value));

    if (allValuesSame) {
      const valueSize = _.size(value);
      if (Stars.validateValue(null, valueSize)) {
        return { value: valueSize, status: VALUE_STATUSES.VALID };
      }
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, value) =>
    /* если в конфигах есть значение, айди или тело статуса, то значит значения валидны */
    value < MAX_STAR_SIZE && value > 0;

  static sortValues = (_, values) => values.sort();

  static calcId = (value) => value;

  static valueIs = (value, type) => typeof value === type;

  static isEmpty = (value) => !value;

  static removeItem = () => null;

  static setValue = (value, newValue) => newValue;

  static hasItem = (value, item) => value && value.some((i) => i === item);

  static convertIdToValue = (field, id) => id;

  static receivePossibleItems = (field, records, filters) => filters || Immutable.List([1, 2, 3, 4, 5]);

  static getEmptyValue = () => null;

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static createComponent = (field, value, type) => {
    const Component = Stars.components[type];
    return function ({ containerClassName }) {
      if (Stars.isEmpty(value)) {
        return null;
      }
      return <Component config={field.get('config')} value={value} containerClassName={containerClassName} />;
    };
  };

  static convertFilterToRecordValue = (field, fieldFilters) => {
    if (!fieldFilters) return;

    let values = [];

    fieldFilters.forEach((filterValues) => {
      if (!filterValues) return;
      const filterValue = filterValues.value;
      if (!filterValue) return;
      values = _.chain(filterValue)
        .filter((i) => !!i)
        .uniq()
        .value();
    });

    return values[0];
  };

  static getDefaultValue = (field) => {
    const defaultValue = field.getIn(['config', 'defaultEmptyValue']);
    return defaultValue;
  };

  static validateRequired = () => {};

  static boardWitchColor = () => false;

  static visibleRules = (v) => ({ $in: v });

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get('id');
    // sort by value
    return records.sortBy((r) => r.getIn(['values', fieldId]));
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(['config', 'type']);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}
