import Immutable, { Map } from 'immutable';
import _ from 'lodash';

import FIELD_TYPES from '../../../configs/fieldTypes';

export default {
  // return only linkedRecords with extend fields
  getLinkedRecords(catalogId, values, withFieldId = false) {
    let linkedRecords = Immutable.List();

    if (!values || !values.size) return linkedRecords;

    const catalogFields = this.getIn(['catalogs', catalogId, 'fields']);

    catalogFields &&
      catalogFields.forEach((field) => {
        if (field && field.get('type') !== FIELD_TYPES.OBJECT) return;

        const fieldId = field.get('id');
        const value = values.get(fieldId);

        value &&
          value.forEach((linkedRecord) => {
            if (this.isObjectWithExtendedFields(catalogId, fieldId, linkedRecord)) {
              let recordValues = linkedRecord.get('recordValues');
              recordValues =
                recordValues &&
                recordValues.filter((_, fieldId) => {
                  const extFields = field.getIn(['config', 'fields', linkedRecord.get('catalogId')]);
                  return extFields.some((extField) => extField.get('id') === fieldId);
                });

              recordValues && (linkedRecord = linkedRecord.set('recordValues', recordValues));
              if (!linkedRecord.get('isRemoved')) {
                linkedRecords = withFieldId
                  ? linkedRecords.push({ linkedRecord, fieldId })
                  : linkedRecords.push(linkedRecord);
              }
            }
          });
      });

    return linkedRecords;
  },

  сutRecordValuesFromObjects(catalogId, values) {
    // cut recordValues from linked records for saving
    const catalogFields = this.getIn(['catalogs', catalogId, 'fields']);

    catalogFields &&
      catalogFields.forEach((field) => {
        if (field.get('type') === FIELD_TYPES.OBJECT && field.getIn(['config', 'fields'])) {
          const fieldId = field.get('id');
          let value = values.get(fieldId);

          if (value) {
            value = value.map((linkedRecord) => linkedRecord.delete('recordValues'));
            values = values.set(fieldId, value);
          }
        }
      });
    return values;
  },

  arrangeRecordValuesToObjects(catalogId, values) {
    // arrange real values to extended linked records
    // it's necessary when saving or raising changes

    const catalogFields = this.getIn(['catalogs', catalogId, 'fields']);

    catalogFields &&
      catalogFields.forEach((field) => {
        if (field.get('type') === FIELD_TYPES.OBJECT && field.getIn(['config', 'fields'])) {
          const fieldId = field.get('id');
          let value = values.get(fieldId);

          if (!value) {
            return;
          }

          value = value.map((linkedRecord) => {
            const catalogId = linkedRecord.get('catalogId');
            const recordId = linkedRecord.get('recordId');

            const record = this.getIn(['records', catalogId, recordId]);
            const extFields = field.getIn(['config', 'fields', catalogId]);
            if (record && extFields) {
              let recordValues = Immutable.Map();
              extFields.forEach((extField) => {
                const extFieldId = extField.get('id');
                const recordValue = record.getIn(['values', extFieldId]);
                recordValues = recordValues.set(extFieldId, recordValue);
              });

              linkedRecord = linkedRecord.set('recordValues', recordValues);
            }
            return linkedRecord;
          });
          values = values.set(fieldId, value);
        }
      });

    return values;
  },

  getChangedValues(catalogId, recordId, checkLink = true, returnRecordValues = false) {
    // check record
    const record = this.getIn(['records', catalogId, recordId]);
    if (!record) {
      return Immutable.List();
    }

    const catalogFields = this.getIn(['catalogs', catalogId, 'fields']);
    const originValues = record.get('originValues') || Immutable.Map();
    let newValues = record.get('values') || Immutable.Map();
    // const hiddenFields = record.get("hiddenFields");

    // filter values that can be changed by record fields rights
    if (checkLink) {
      newValues = this.filterEditableValues({ catalogId, recordId }, newValues);
    }

    // filter fields that has been changed
    newValues = newValues.map((newValue, fieldId) => {
      /*
      // HIDDEN FIELDS
      // deleted because we allowed to save values in hidden fields
      if (hiddenFields && hiddenFields.get(fieldId)) {
        return false;
      } */

      // compare value to origin
      const originValue = originValues.get(fieldId);
      const diffValues = this.compareValues(originValue, newValue);

      // check if field is Linked Object
      const field = catalogFields && catalogFields.find((field) => field.get('id') === fieldId);
      const isObjectField = field && field.get('type') === FIELD_TYPES.OBJECT;

      let fieldHasChangedExtValues = false;
      if (newValue && checkLink && isObjectField) {
        newValue = newValue.map((linkedRecord) => {
          if (this.isObjectWithExtendedFields(catalogId, fieldId, linkedRecord)) {
            const linkedCatalogId = linkedRecord.get('catalogId');
            const linkedRecordId = linkedRecord.get('recordId');

            // get only changed values
            let recordValues = this.getChangedValues(linkedCatalogId, linkedRecordId, false, false);

            // get only values from list of extended fields & with edit right
            recordValues = this.filterExtendedValues({ catalogId, recordId, fieldId, linkedCatalogId }, recordValues);

            /* filter hidden fields */
            /* 
            // не фильтровать скрытые поля те сохранять вносимые изменения 
            recordValues = this.filterHiddenFields(
              { catalogId: linkedCatalogId, recordId: linkedRecordId },
              recordValues
            ); 
            */
            const hasChangedExtValues = !!(recordValues && recordValues.size);
            fieldHasChangedExtValues = fieldHasChangedExtValues || hasChangedExtValues;

            if (returnRecordValues && hasChangedExtValues) {
              linkedRecord = linkedRecord.set('recordValues', recordValues);
            }
          }
          return linkedRecord;
        });
      }

      if (diffValues || fieldHasChangedExtValues) {
        return newValue;
      }
      return undefined;
    });

    newValues = newValues.filter((newValue) => newValue !== undefined);

    return newValues;
  },

  compareValues(value, newValue) {
    return _.isObject(value) ? !Immutable.is(value, newValue) : value !== newValue;
  },

  filterEditableValues(params, values) {
    const record = this.getIn(['records', params.catalogId, params.recordId]);
    // const hiddenFields = record.get("hiddenFields");

    const catalog = this.getIn(['catalogs', params.catalogId]);

    const fieldPrivilegeCodes = record.get('isNew')
      ? catalog && catalog.get('fieldPrivilegeCodes')
      : record.get('fieldPrivilegeCodes');

    const fields = catalog && catalog.get('fields');

    return values.filter((_, fieldId) => {
      const field = fields && fields.find((field) => field.get('id') === fieldId);
      const privelege = fieldPrivilegeCodes && fieldPrivilegeCodes.get(fieldId);

      return (
        field &&
        !field.get('readOnly') &&
        // !(hiddenFields && hiddenFields.get(fieldId)) &&
        (!privelege || privelege === 'edit')
      );
    });
  },

  getValuesNeedToRaise(params, values, updatedFields) {
    const catalogFields = this.getIn(['catalogs', params.catalogId, 'fields']);
    return (
      values &&
      values.filter((_, fieldId) => {
        if (!catalogFields) {
          return;
        }
        const field = catalogFields.find((field) => field.get('id') === fieldId);
        return this.fieldNeedToRise(params, updatedFields, field);
      })
    );
  },

  fieldNeedToRise(params, updatedFields, field) {
    return (
      field &&
      field.get('eventable') &&
      // allow raise script by this field if it wasn't rose earlier
      !updatedFields.getIn([params.catalogId, params.recordId, field.get('id')])
    );
  },

  isObjectWithExtendedFields(catalogId, fieldId, object) {
    if (!object || !_.isObject(object)) {
      return false;
    }
    const objectCatalogId = object.get('catalogId');
    const catalogFields = this.getIn(['catalogs', catalogId, 'fields']);
    const field = catalogFields.find((f) => f.get('id') === fieldId);

    if (!field) {
      return false;
    }

    const isObject = field.get('type') === FIELD_TYPES.OBJECT;
    const extFields = field.getIn(['config', 'fields']);
    const hasExtFields = extFields.get(objectCatalogId) && extFields.get(objectCatalogId).size;

    return isObject && hasExtFields;
  },

  modifyItselfInLinks(params, callback) {
    const { catalogId, recordId } = params;

    const record = this.getIn([
      /*       "scenes",
      sceneId, */
      'records',
      catalogId,
      recordId,
    ]);

    if (!record) {
      return;
    }

    record.get('parents') &&
      record.get('parents').forEach((parent) => {
        const parentRecordKey = [
          /*           "scenes",
          sceneId, */
          'records',
          parent.get('catalogId'),
          parent.get('recordId'),
        ];

        const parentRecord = this.getIn([...parentRecordKey]);

        const linkedRecords = parentRecord.getIn(['values', parent.get('fieldId')]);

        let idx;
        const linkedRecord = linkedRecords.find(
          (linkedRecord, i) =>
            linkedRecord.get('catalogId') === catalogId &&
            linkedRecord.get('recordId') === recordId &&
            (idx = i.toString()),
        );

        if (linkedRecord) {
          this.setIn([...parentRecordKey, 'values', parent.get('fieldId'), idx], callback(linkedRecord));
        }
      });
  },

  // this function filter values by available extended fields
  filterExtendedValues(params, values) {
    const { catalogId, fieldId, linkedCatalogId } = params;

    // get field
    const fields = this.getIn(['catalogs', catalogId, 'fields']);
    const field = fields && fields.find((field) => field.get('id') === fieldId);
    if (!field) {
      return values;
    }

    // extended fields rights
    let extFields = field.getIn(['config', 'fields', linkedCatalogId]);
    extFields = extFields.toMap().mapEntries(([key, value]) => {
      const fieldId = value.get('id');
      const privilege = value.get('editable') ? 'edit' : 'view';
      return [fieldId, privilege];
    });

    // return only allowed to edit fields
    return values.filter((value, extFieldId) => extFields.get(extFieldId) === 'edit');
  },

  filterHiddenFields(params, values) {
    const hiddenFields = this.getIn(['records', params.catalogId, params.recordId, 'hiddenFields']);

    if (!hiddenFields) {
      return values;
    }

    return values.filter((_, fieldId) => !hiddenFields.get(fieldId));
  },
};
