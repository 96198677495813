import _ from 'lodash';
import React from 'react';

import Icon from '../../common/UI/Icon';
import { connect } from '../../StateProvider';

import styles from './changesResultList.less';

function ChangesResultList(props) {
  return (
    <div className={styles.listWrapper}>
      {props.changesResults.map((result, index) => {
        let { message } = result;
        let messageTitle;
        if (_.isObject(message)) {
          messageTitle = (
            <b>
              {message.title}!<br />
            </b>
          );
          message = message.text;
        }

        return (
          <div key={`${result.catalogId}:${index}`} className={styles.changesResultItem}>
            <Icon type={`icon ${result.icon}`} className={styles.changesResultItemIcon} />
            <div className={styles.changesResultItemInfo}>
              <h3 className={styles.changesResultItemTitle}>{result.recordName}</h3>
              <p>
                {messageTitle}
                {message}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default connect(ChangesResultList);
