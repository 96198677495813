import React from 'react';
import { Modal, Button, Row, Input, Col } from 'antd';
import { usePrompt } from './prompt.hook';

import ButtonClose from '../UI/ButtonClose';

import styles from './modal.less';

export default function (props) {
  const { value, onChange, onSave } = usePrompt(props);

  const disabled = value !== props.value;

  return (
    <Modal
      open
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="submit" disabled={disabled} type="danger" onClick={onSave}>
          {props.okText}
        </Button>,
        <Button key="back" type="default" onClick={props.onCancel}>
          {props.cancelText}
        </Button>,
      ]}
    >
      <Row type="flex" justify="space-between" align="middle" className={styles.header}>
        <h1>{props.headerText}</h1>
        <ButtonClose large className={styles.close} shiftRight onClick={props.onCancel} />
      </Row>
      <Col className={styles.content}>
        <p className={styles.text}>{props.text}</p>
        <form onSubmit={(e) => e.preventDefault()}>
          <Input type="text" autoFocus onChange={onChange} placeholder={props.placeHolder} />
        </form>
      </Col>
    </Modal>
  );
}
