import { TIME, DATE, WEEK, MONTH, QUARTER, YEAR } from './dateFieldSubTypes';

export const formats = {
  [TIME]: 'L',
  [DATE]: 'L',
  [WEEK]: 'YYYY-ww',
  [MONTH]: 'MMM YYYY',
  [QUARTER]: 'YYYY/Q',
  [YEAR]: 'YYYY',
};
export const subFormats = {
  [TIME]: [],
  [DATE]: [],
  [WEEK]: ['YYYY ww', 'YYYY.ww', 'YYYY,ww', 'L'],
  [MONTH]: ['MM YYYY', 'MM,YYYY', 'MM.YYYY', 'L'],
  [QUARTER]: ['YYYY.Q', 'YYYY,Q', 'YYYY Q', 'L'],
  [YEAR]: ['L'],
};

export const timeFormats = ['HH:mm', 'HHmm', 'HH mm', 'HH.mm', 'HH,mm'];
