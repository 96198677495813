import _ from 'lodash';
import matcher from './matcher';

function save(visible, controlId, result) {
  return (visible[controlId] = result);
}

/**
 * @typedef {Object} Context
 * @property {Object} values
 * @property {Object} conditions
 * @property {Object} parentsGroupId
 * @property {Object} [visible]
 * @property {Array} [chain]
 */

/**
 *
 * @param {String} controlId
 * @param {Context} context
 */
function calcVisible(controlId, context) {
  const {
    // input
    values,
    conditions,
    parentsGroupId,
    // mutable
    visible = {},
    chain = [],
  } = context;

  const parentGroupId = parentsGroupId[controlId];

  // if calculated - return result
  if (_.isBoolean(visible[controlId])) {
    return visible[controlId];
  }

  // check parent group visibility
  if (
    parentGroupId &&
    !save(
      visible,
      parentGroupId,
      calcVisible(parentGroupId, {
        ...context,
        chain: chain.concat(parentGroupId),
      }),
    )
  ) {
    return false;
  }

  const condition = conditions[controlId];

  if (_.isBoolean(condition)) {
    return condition;
  }

  if (!condition) {
    return true;
  }

  // check "зацикленность"
  if (_.filter(chain, (c) => c === controlId).length > 1) {
    return false;
  }

  // check conditions
  const result = matcher(condition, (result, dependencyControlId) => {
    if (!result) {
      return false;
    }

    return calcVisible(dependencyControlId, {
      ...context,
      chain: chain.concat(dependencyControlId),
    });
  })(values);

  return save(visible, controlId, result);
}

export default function calcVisibleControls(values, configs) {
  const visible = {};
  const parentsGroupId = {};
  const conditions = {};

  // calc parent groups
  // calc conditions

  let parentGroupId = false;

  configs &&
    configs.forEach((config) => {
      const id = config.get('id');
      const condition = config.get('visibleRules');
      conditions[id] = condition && _.isObject(condition) ? condition.toJS() : condition;

      if (config.get('type') === 'group') {
        parentGroupId = id;
      } else {
        parentsGroupId[id] = parentGroupId;
      }
    });

  const context = {
    values: values ? values.toJS() : values,
    conditions,
    parentsGroupId,
    visible,
  };

  configs &&
    configs.forEach((config) => {
      const id = config.get('id');
      visible[id] = calcVisible(id, context);
    });

  return visible;
}
