import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Input, InputNumber } from 'antd';

import PropTypes from 'prop-types';

import KEYS from '../../configs/keys';

const { TextArea } = Input;

const log = require('debug')('CRM:Component:Record:DebouncedInput');

const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 2 ** 53 - 1;
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -MAX_SAFE_INTEGER;

class DebouncedInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      lastSavedValue: this.props.value,
    };
    this.refTextArea = React.createRef(null);
    this.refTextAreaWrapper = React.createRef(null);
  }

  save(value = this.state.value) {
    clearTimeout(this._saveTimer);
    if (value !== this.props.value || value !== this.state.lastSavedValue) {
      log('save', value);
      this.props.onSave(value);
      this.setState({
        lastSavedValue: value,
      });
    }
  }

  debouncedSave = (val) => {
    clearTimeout(this._saveTimer);

    if (this.props.disableDebounce) {
      this.save(val);
    } else {
      this._saveTimer = setTimeout(() => {
        this.save();
      }, 500);
    }
  };

  onChange = (e) => {
    let val;

    if (this.props.type === 'number') {
      val = e; // hack for inputNumber component

      // moved to logic to <NumberField/>
      // if (val !== "") {
      //   val = val.replace(/,/g, ".");
      //   if (
      //     isNaN(Number(val)) ||
      //     !_.inRange(Number(val), MIN_SAFE_INTEGER, MAX_SAFE_INTEGER)
      //   ) {
      //     this.setState({
      //       value: val
      //     });
      //     return;
      //   }
      // }
    } else {
      val = e.target.value;
    }

    log('set val', val);
    this.setState({
      value: val,
    });

    this.debouncedSave(val);
  };

  onBlur = (e) => {
    this.save();
    this.props.onBlur && this.props.onBlur(e);
  };

  onKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
    if (e.keyCode === KEYS.ENTER) {
      e.target.blur();
    }
  };

  onKeyDownMultiline = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  componentWillUnmount() {
    // this.save();
    clearTimeout(this._saveTimer);
  }

  componentDidMount() {
    if (this.refTextArea.current) {
      this.resize();
    }
  }

  componentDidUpdate() {
    if (this.refTextArea.current) {
      this.resize();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && this.state.value === this.state.lastSavedValue) {
      let newValue = nextProps.value;
      if (this.props.type === 'number') {
        if (!nextProps.value && !_.isNull(nextProps.value)) {
          newValue = 0;
        }
      }
      this.setState({
        value: newValue,
        lastSavedValue: newValue,
      });
    }
  }

  resize() {
    const textareaDomNode = this.refTextArea.current;
    const textAreaWrapperDomNode = this.refTextAreaWrapper.current;

    this.setOverfow('scroll');
    if (textareaDomNode.scrollHeight > 500 && textareaDomNode.style.height == 500) {
    }
    // let rows = textareaDomNode.rows || 1;
    // textareaDomNode.style.height = '1px';
    // let height = textareaDomNode.scrollHeight;
    // height = Math.min(500, height);
    // height = Math.max((rows * 20 + 6), height);
    // textAreaWrapperDomNode.style.height = height + 'px';
    // textareaDomNode.style.height = height + 'px';
    // if (textareaDomNode.scrollHeight <= 500) {
    //   this.setOverfow('hidden');
    // }
  }

  setOverfow = (overflow) => {
    // Задание overflow с насильным redraw для Chrome/Safari
    const textareaDomNode = this.refTextArea.current;
    const { width } = textareaDomNode.style;
    textareaDomNode.style.width = '0px';
    textareaDomNode.style.width = width;
    textareaDomNode.style.overflowY = overflow;
  };

  render() {
    const props = {
      className: classnames(this.props.className), // 'debounced-input', {
      // 'debounced-input--empty': _.isNull(this.state.value),
      // 'debounced-input--number': (this.props.type == 'number'),
      // 'debounced-input--inline': !this.props.multiline
      // }),
      size: this.props.size,
      placeholder: this.props.placeholder,
      parser: this.props.parser,
      value: this.state.value,
      onBlur: this.onBlur,
      onChange: this.onChange,
      onKeyDown: this.props.multiline ? this.onKeyDownMultiline : this.onKeyDown,
      readOnly: this.props.readOnly,
      disabled: this.props.readOnly,
    };

    const wrapperClassName = `textarea--wrapper ${this.props.wrapperClassName || ''}`;
    if (props.style) {
      props.style.resize = 'none';
    }

    // remove onSave from props, because it cause warning in react
    const { onSave, ...restProps } = props;

    if (this.props.type == 'number') {
      return <InputNumber {...restProps} />;
    }
    if (this.props.multiline) {
      return (
        <div ref={this.refTextAreaWrapper} className={wrapperClassName}>
          <TextArea ref={this.refTextArea} type="textarea" {...restProps} autoSize />
        </div>
      );
    }
    return <Input ref="input" {...restProps} />;
  }
}

DebouncedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  multiline: PropTypes.bool,
  disableDebounce: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
};

export default DebouncedInput;
