import React, { useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';

import ChatItem from './ChatItem/ChatItem';
import chatsActions from '../../../../../actions/chatsActions';
import SkeletonItems from '../../../../common/UI/SkeletonItems';

function ChatsItems({ items, allLoadedMessages, loadingMessages, visibleSkeleton, openRecordParams, user, t }) {
  const chatItem = (index, chat) => {
    const params = {
      sectionId: chat.sectionId,
      catalogId: chat.catalogId,
      recordId: chat.recordId,
    };

    const isActive =
      openRecordParams.get('visible') &&
      chat.catalogId === openRecordParams.get('catalogId') &&
      chat.recordId === openRecordParams.get('recordId');

    if (chat.message && chat.message.deleted) {
      chat.message.text = t('record.chat.message.messageDeleted');
    }

    return (
      <ChatItem
        params={params}
        message={chat.message}
        icon={chat.catalogIcon}
        title={chat.title}
        isActive={isActive}
        me={user}
        t={t}
      />
    );
  };

  const loadMore = useCallback(() => {
    if (!allLoadedMessages) {
      chatsActions.loadChats(25);
    }
  }, [allLoadedMessages]);
  if (loadingMessages && visibleSkeleton) {
    return <SkeletonItems countSkeleton={15} />;
  }

  return (
    <Virtuoso
      style={{ height: '100%', width: '100%' }}
      endReached={loadMore}
      overscan={200}
      data={items.toJS()}
      itemContent={chatItem}
    />
  );
}

export default ChatsItems;
