import _ from 'lodash';
import debug from 'debug';
import Immutable from 'immutable';
import apiActions from '../actions/apiActions';
import appState from '../appState';

const log = debug('CRM:Store:historyMixin');

export default {
  limit: 50,

  currentLoading: null,

  loadHistory(sceneId, catalogId, recordId = null, request = {}, forceReload = false) {
    log('loading history', catalogId, recordId);
    this.forceReload({ catalogId, recordId }, forceReload);
    if (recordId) {
      const history = this.getIn(['records', catalogId, recordId, 'history']);
      if (history && (!history.get('allLoaded') || forceReload)) {
        const query = {
          catalogId,
          recordId,
          limit: this.limit,
        };

        const lastId = history.get('lastId');
        if (lastId && !forceReload) {
          query.from = lastId;
        }
        if (
          this.currentLoading &&
          this.currentLoading.catalogId === catalogId &&
          this.currentLoading.recordId === recordId &&
          this.currentLoading.lastId === lastId
        ) {
          return true;
        }
        this.currentLoading = {
          catalogId,
          recordId,
          lastId,
        };

        const historyFilter = history.get('filter');
        if (historyFilter) {
          _.merge(query, historyFilter);
        }
        apiActions.getHistories(null, query);
        return !history.get('allLoaded');
      }
      return false;
    }
    let history = this.getIn(['catalogsHistory', catalogId]);
    if (!history) {
      this.setIn(['catalogsHistory', catalogId], Immutable.Map());
      history = Immutable.Map();
    }

    if (history && (!history.get('allLoaded') || forceReload)) {
      const query = {
        catalogId,
        limit: this.limit,
      };
      const lastId = history.get('lastId');
      if (lastId && !forceReload) {
        query.from = lastId;
      }

      const { viewId } = request; // this.getIn(['currentCatalog', 'currentViewId']);
      if (viewId && viewId !== 0) {
        const filters = appState.getFiltersForRequest({ sceneId, viewId });
        request = { filters };
        query.recordsFilter = request;
      }
      if (this.currentLoading && this.currentLoading.catalogId === catalogId && this.currentLoading.lastId === lastId) {
        return true;
      }
      this.currentLoading = {
        catalogId,
        lastId,
      };

      const historyFilter = history.get('filter');
      if (historyFilter) {
        _.merge(query, historyFilter);
      }

      apiActions.getHistories(null, query);
      return !history.get('allLoaded');
    }
    return false;
  },

  createHistoryCompleted(result, params, data, query, actionParams) {
    const { sceneId } = actionParams;
    this.setIn(['records', data.catalogId, data.recordId, 'history', 'forceReload'], true);
    this.changed();
    this.loadHistory(sceneId, data.catalogId, data.recordId, {}, true);
  },

  clearHistory(catalogId, recordId = null) {
    let object;
    if (recordId) {
      object = this.getIn(['records', catalogId, recordId, 'history']);
    } else {
      object = this.getIn(['catalogsHistory', catalogId]);
    }

    object = object.set('allLoaded', false);
    object = object.set('forceReload', false);
    object = object.set('loading', false);
    object = object.set('lastId', null);
    object = object.set('items', []);

    if (recordId) {
      this.setIn(['records', catalogId, recordId, 'history'], object);
    } else {
      this.setIn(['catalogsHistory', catalogId], object);
    }
    this.changed();
  },

  setFilter(filter, data) {
    if (data.recordId) {
      // История для одной записи
      let history = this.getIn(['records', data.catalogId, data.recordId, 'history']);
      history = this._setFilterToObject(history, filter);
      this.setIn(['records', data.catalogId, data.recordId, 'history'], history);
    } else {
      // История для каталога
      let history = this.getIn(['catalogsHistory', data.catalogId]);
      history = this._setFilterToObject(history, filter);
      this.setIn(['catalogsHistory', data.catalogId], history);
    }
    this.changed();
  },

  _setFilterToObject(object, filter) {
    if (!filter) {
      return object;
    }
    if (!object) {
      object = Immutable.Map();
    }

    object = object.set('filter', filter);
    object = object.set('loading', false);
    object = object.set('forceReload', true);
    object = object.set('allLoaded', false);

    return object;
  },

  getHistories(params, data) {
    if (data.recordId) {
      // История для одной записи
      let history = this.getIn(['records', data.catalogId, data.recordId, 'history']);
      if (history) {
        history = history.set('loading', true);
        history = history.set('loadError', null);
        this.setIn(['records', data.catalogId, data.recordId, 'history'], history);
      }
      this.changed('История для каталога');
    } else {
      // История для каталога
      let history = this.getIn(['catalogsHistory', data.catalogId]);
      if (history) {
        history = history.set('loading', true);
        history = history.set('loadError', null);
        this.setIn(['catalogsHistory', data.catalogId], history);
      }
      this.changed();
    }
  },

  getHistoriesCompleted(result, params, data, query) {
    result = result || [];
    if (query.recordId) {
      // История для рекорда
      let history = this.getIn(['records', query.catalogId, query.recordId, 'history']);
      if (!history) {
        return;
      }
      history = this._updateHistory(history, query.limit, result);
      this.setIn(['records', query.catalogId, query.recordId, 'history'], history);
    } else {
      // История для каталога
      let history = this.getIn(['catalogsHistory', query.catalogId]);
      if (!history) {
        return;
      }
      history = this._updateHistory(history, query.limit, result);
      this.setIn(['catalogsHistory', query.catalogId], history);
    }
    this.currentLoading = null;
    this.changed();
  },

  /**
   * @internal
   * @param object
   * @param limit
   * @param result
   * @returns {*}
   */
  _updateHistory(object, limit, result) {
    const forceReload = object.get('forceReload');
    let lastId = object.get('lastId', null);
    const newIds = {};
    let history;

    result.forEach((h) => {
      newIds[h.id] = true;
    });

    if (result.length) {
      lastId = _.last(result).id;
    }

    if (forceReload) {
      history = Immutable.fromJS(result);
    } else {
      history = object.get('items') || new Immutable.List();
      history = history.filter((h) => !newIds[h.get('id')]);
      history = Immutable.fromJS(result).concat(history);
    }
    history = history.sort((a, b) => b.get('id') - a.get('id'));

    // у нас на руках полная история
    let allLoaded = false;
    if (result.length < limit) {
      allLoaded = true;
    }

    object = object.set('items', history);
    object = object.set('loading', false);
    object = object.set('forceReload', false);
    object = object.set('allLoaded', allLoaded);

    if (lastId) {
      object = object.set('lastId', lastId);
    }
    object = object.set('loadError', null);

    return object;
  },

  getHistoriesFailed(e, params, data, query) {
    if (query.recordId) {
      let history = this.getIn(['records', query.catalogId, query.recordId, 'history']);
      if (history) {
        history = history.set('loading', false);
        history = history.set('loadError', true);
        this.setIn(['records', query.catalogId, query.recordId, 'history'], history);
        this.changed();
      }
    } else {
      let history = this.getIn(['catalogsHistory', query.catalogId]);
      if (history) {
        history = history.set('loading', false);
        history = history.set('loadError', true);
        this.setIn(['catalogsHistory', query.catalogId], history);
        this.changed();
      }
    }
  },

  forceReload({ catalogId, recordId }, force) {
    recordId
      ? this.setIn(['records', catalogId, recordId, 'history', 'forceReload'], force)
      : this.setIn(['catalogsHistory', catalogId, 'forceReload'], force);

    this.changed();
  },
};
