import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from '../StateProvider';

import Icon from './UI/Icon';

class HelpIcon extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <a
        href={props.docsUrl + props.helpPath}
        target={props.inBlank ? '_blank' : null}
        title={props.helpTitle ? props.helpTitle : props.t('buttons.help')}
        rel="noreferrer"
      >
        <Icon type="icon objects-66" />
      </a>
    );
  }
}

HelpIcon.defaultProps = {
  helpPath: '',
  icon: 'anticon-icon interface-56',
  inBlank: true,
  docsUrl: '',
};

HelpIcon.propTypes = {
  docsUrl: PropTypes.string,
  helpPath: PropTypes.string.isRequired,
  helpTitle: PropTypes.string,
  icon: PropTypes.string,
  inBlank: PropTypes.bool,
};

export default connect(withTranslation()(HelpIcon), { docsUrl: ['vendor', 'docsUrl'] });
