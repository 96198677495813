import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Select } from 'antd';

import Checkbox from '../../../common/UI/Checkbox';
import FIELD_TYPES from '../../../../configs/fieldTypes';
import valueActionTypes from '../../../../configs/valueActionTypes';
import batchRecordsActions from '../../../../actions/batchRecordsActions';

import styles from './ControlItemMassUpdate.less';

class ControlItemMassUpdate extends PureComponent {
  state = {
    checked: false,
  };

  onClickCheckbox = () => {
    const { sceneId, params, record, controlConfig, readOnly } = this.props;
    const { catalogId, recordId } = params;

    const fieldId = controlConfig.get('id');
    const forbiddenToSelect = controlConfig.getIn('apiOnly') && controlConfig.get('readOnly');

    if (readOnly || forbiddenToSelect) {
      return;
    }

    const newChecked = !this.state.checked;
    this.setState({ checked: newChecked });

    batchRecordsActions.changefieldsEditableStatus({ sceneId, fieldId }, newChecked);

    let values = record.get('values');

    if (!newChecked) {
      values = values.delete(fieldId);
    } else {
      const emptyValue = '';
      values = values.set(fieldId, emptyValue);
    }

    batchRecordsActions.updateBatchUpdateRecordValues(catalogId, recordId, values);

    batchRecordsActions.changeBatchStatus(sceneId);
  };

  onChangeAction = (value) => {
    const { sceneId, controlConfig } = this.props;
    const fieldId = controlConfig.get('id');

    batchRecordsActions.changeBatchUpdateValueActions({ sceneId, fieldId }, value);
  };

  getOptions = () => {
    const config = this.props.controlConfig.get('config');
    const options = [];

    options.push({
      value: valueActionTypes.UPDATE,
      label: this.props.t('batchUpdateRecords.valueActions.set'),
    });

    if (config.get('multiselect')) {
      options.push(
        {
          value: valueActionTypes.ADD,
          label: this.props.t('batchUpdateRecords.valueActions.add'),
        },
        {
          value: valueActionTypes.EXCLUDE,
          label: this.props.t('batchUpdateRecords.valueActions.exclude'),
        },
      );
    }

    return options;
  };

  render() {
    const { controlConfig, readOnly, t, children } = this.props;
    const { checked } = this.state;

    const disableMassEdit =
      controlConfig.get('type') !== FIELD_TYPES.FILE || controlConfig.get('type') !== FIELD_TYPES.BUTTON;

    return (
      <div className={styles.massUpdateWrapper}>
        <Checkbox
          checked={checked}
          className={styles.massUpdateCheckbox}
          disabled={!disableMassEdit}
          readOnly={readOnly}
          onChange={this.onClickCheckbox}
        />
        {checked ? (
          <Select
            options={this.getOptions()}
            defaultValue={valueActionTypes.UPDATE}
            className={styles.massUpdateSelect}
            onChange={this.onChangeAction}
          />
        ) : disableMassEdit ? (
          <div onClick={this.onClickCheckbox} className={styles.massUpdatePlaceHolder}>
            {t('batchUpdateRecords.form.fields.placeholder')}
          </div>
        ) : (
          <span className={styles.massUpdateNotChanged}>{t('batchUpdateRecords.form.fields.notChange')}</span>
        )}

        {checked && <div className={styles.massUpdateControl}>{children}</div>}
      </div>
    );
  }
}

export default withTranslation()(ControlItemMassUpdate);
