import React, { useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import CheckBox from '../../common/UI/Checkbox';

import styles from './selectors.less';

const { Option } = Select;

function CheckBoxesSelector(props) {
  const { t } = useTranslation();
  const filterOption = (inputValue, option) => option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  const onChange = (value) => props.onChange && props.onChange(value);

  const onEndEditing = () => {
    props.onEndEditing && props.onEndEditing();
  };

  const onDropdownVisibleChange = (visible) => {
    if (!visible) {
      onEndEditing();
    }
  };

  const additionalItems = props.config.get('items').map((item) => {
    const checked = props.value.includes(item.get('id'));

    return (
      <Option
        title={item.get('name')}
        className={cn({ [styles.selectorActiveOption]: checked }, styles.noCheckedMarkForMultiselect, styles.option)}
        key={item.get('id')}
      >
        <CheckBox checked={checked} title={item.get('name')} />
      </Option>
    );
  });

  const placeholder = t('record.values.count', { count: props.value && props.value.size });

  return (
    <Select
      mode="multiple"
      className={props.className}
      popupClassName={styles.selectorDropdown}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ maxWidth: '300px' }}
      style={{ width: '100%' }}
      placeholder={placeholder}
      value={props.value.toJS()}
      autoFocus={props.autoFocus}
      defaultOpen={props.autoFocus}
      onChange={onChange}
      filterOption={filterOption}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onInputKeyDown={props.onKeyPress}
      showArrow={false}
    >
      {additionalItems}
    </Select>
  );
}

CheckBoxesSelector.defaultProps = {
  value: Immutable.List(),
};

CheckBoxesSelector.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
  onKeyPress: PropTypes.func,
};

export default CheckBoxesSelector;
