import React from 'react';
import cn from 'classnames';

import Icon from '../Icon';
import ButtonTransparent from '../ButtonTransparent';

import styles from './buttonClose.less';

export default function ButtonClose(props) {
  const { onClick, title, className, shiftLeft, shiftRight, small, medium, large } = props;
  return (
    <ButtonTransparent
      onClick={onClick}
      title={title}
      className={cn(styles.buttonClose, className, {
        [styles.shiftLeft]: shiftLeft,
        [styles.shiftRight]: shiftRight,
        [styles.small]: small,
        [styles.medium]: medium,
        [styles.large]: large,
      })}
    >
      <Icon spin type="icon interface-74" />
    </ButtonTransparent>
  );
}
