import React, { Component } from 'react';
import Immutable from 'immutable';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { Dropdown, Row } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Icon from '../../../../../../common/UI/Icon';
import ButtonTransparent from '../../../../../../common/UI/ButtonTransparent';
import { alert, promptModal, promptConfirm } from '../../../../../../common/Modal';
import { checkAccessOnObject } from '../../../../../../../utils/rights';
import PRIVILEGE_CODES from '../../../../../../../configs/privilegeCodes';
import RESOURCE_TYPES from '../../../../../../../configs/resourceTypes';

import apiActions from '../../../../../../../actions/apiActions';
import modalsActions from '../../../../../../../actions/modalsActions';
import sceneActions from '../../../../../../../actions/sceneActions';
import { connect } from '../../../../../../StateProvider';

import userSettingsActions from '../../../../../../../actions/userSettingsActions';
import { validateApiKey } from '../../../../../../../utils/validateApiKey';

import styles from './catalogHeader.less';
import catalogActions from '../../../../../../../actions/catalogActions';

class CatalogActions extends Component {
  static propTypes = {
    catalog: PropTypes.object,
    sectionId: PropTypes.string,
    catalogId: PropTypes.string,
    history: PropTypes.object,
  };

  isSchemaLocked = () => {
    const { t } = this.props;
    const isSchemaLocked = this.props.license && this.props.license.getIn(['modules', 'schemalock']);
    if (isSchemaLocked) {
      alert({
        headerText: t('modals.schemaLock.headerText'),
        text: t('modals.schemaLock.text'),
        okText: t('modals.schemaLock.okText'),
      });
    }
    return isSchemaLocked;
  };

  favorite = (e) => {
    const { catalog } = this.props;
    const catalogId = catalog?.get('id');

    let { favoriteCatalogs } = this.props;
    if (_.isUndefined(favoriteCatalogs)) {
      favoriteCatalogs = Immutable.List();
    }

    const index = favoriteCatalogs ? favoriteCatalogs.indexOf(catalogId) : -1;

    /**
     * Depends on item existance in the favorite array
     * delete or push item using index
     */
    favoriteCatalogs = index !== -1 ? favoriteCatalogs.splice(index, 1) : favoriteCatalogs.push(catalogId);

    favoriteCatalogs = favoriteCatalogs.filter((i) => !!i);
    userSettingsActions.setKey(['ui', 'favoriteCatalogs'], favoriteCatalogs);
  };

  access = (e) => {
    const { catalog } = this.props;
    const sectionId = catalog?.get('sectionId');
    const catalogId = catalog?.get('id');

    if (catalogId) {
      const isAdmin = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.ADMIN);
      const readOnly = !checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.ACCESS);
      const object = { catalogId };
      const parents = [{ sectionId }];
      modalsActions.openAccessModal({ object, parents }, RESOURCE_TYPES.CATALOG, { readOnly, isAdmin });
    }
  };

  /* Удаление всех записей */
  truncate = () => {
    const { catalog, sceneId, match, t } = this.props;
    const catalogId = catalog?.get('id');

    const viewId = match && match.params.viewId;

    promptConfirm({
      headerText: t('modals.truncateConfirm.headerText'),
      text: (
        <span>
          {t('modals.truncateConfirm.firstText')}
          <br />
          {t('modals.truncateConfirm.secondText')}
        </span>
      ),
      okText: t('modals.truncateConfirm.okText'),
      cancelText: t('modals.truncateConfirm.cancelText'),
      value: catalog.get('name'),
      placeHolder: catalog.get('name'),
      onOk: () => {
        apiActions
          .deleteRecord(
            {
              catalogId,
              recordId: '$all',
            },
            { sceneId, viewId },
          )
          .then(() => {
            /* в случае удачного удаления, происходит перезапись текущих записей новыми (т.е. пустым объектом) можно просто удалить все записи из сцены */
            /*
            recordActions.requestForRecords(catalogId, sceneId, {
              viewId: this.props.match && this.props.match.params.viewId
            });
          */
            sceneActions.deleteRecordsFromScene(sceneId);
          });
      },
    });
  };

  editCatalog = () => {
    if (this.isSchemaLocked()) {
      return;
    }

    const { catalog } = this.props;
    const sectionId = catalog?.get('sectionId');
    const catalogId = catalog?.get('id');

    this.props.history.push({
      pathname: `/section/${sectionId}/catalog/${catalogId}/edit`,
      search: this.props.location.search,
    });
  };

  remove = () => {
    if (this.isSchemaLocked()) {
      return;
    }

    const { catalog, history, location, sceneId, t } = this.props;
    const sectionId = catalog?.get('sectionId');
    const catalogId = catalog?.get('id');

    promptConfirm({
      headerText: t('modals.removeConfirm.headerText'),
      text: (
        <span>
          {t('modals.removeConfirm.firstText')}
          <br />
          {t('modals.removeConfirm.secondText')}
        </span>
      ),
      okText: t('modals.removeConfirm.okText'),
      cancelText: t('modals.removeConfirm.cancelText'),
      value: catalog.get('name'),
      placeHolder: catalog.get('name'),
      onOk: () => {
        apiActions
          .deleteCatalog(
            {
              catalogId,
            },
            { sceneId },
          )
          .then(() => {
            history.push({
              pathname: `/section/${sectionId}`,
              search: location.search,
            });
          });
        /* Удаление каталога вызывает удаление сцены */
        sceneActions.deleteScene(sceneId);
      },
    });
  };

  openApiKeyModal = () => {
    const { t, catalogId, loadingForModal } = this.props;
    promptModal({
      headerText: t('modals.apiKeys.catalogApiKeyConfirm.headerText'),
      defaultValue: catalogId,
      onOk: this.onSetKey,
      okText: t('buttons.save'),
      cancelText: t('buttons.cancel'),
      promptHint: t('modals.apiKeys.promptHint'),
      loading: loadingForModal || false,
      validateFunc: validateApiKey,
      t,
    });
  };

  onSetKey = async (newKey) => {
    const { catalogId, catalog, location, history, sceneId, t } = this.props;
    const sectionId = catalog?.get('sectionId');

    if (catalogId && sectionId) {
      try {
        // Не задано? Явно указываем null
        // => удаляем запись из recordIds и получаем числовой dbId как раньше
        newKey = _.isEmpty(_.trim(newKey)) ? null : newKey;
        await apiActions.updateCatalog({ catalogId, sectionId }, { id: newKey }, { sceneId }).then((result) => {
          const realNewId = result.id || newKey;
          history.push({
            pathname: `/section/${sectionId}/catalog/${realNewId}`,
            search: _.get(location, ['search'], ''),
          });
        });
        catalogActions.truncateCatalogFromStore({ catalogId });
      } catch (e) {
        alert({
          headerText: t('message.saveError'),
          text: t('modals.setApiKeyErrors.onSaveError'),
          okText: t('modals.setApiKeyErrors.okText'),
        });
      }
    }
  };

  render() {
    const { catalog, className, favoriteCatalogs, t } = this.props;

    const isAccess = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.ACCESS);
    const isAdmin = checkAccessOnObject(RESOURCE_TYPES.CATALOG, catalog, PRIVILEGE_CODES.ADMIN);

    let menu = [];

    // access
    if (isAccess) {
      menu.push({
        key: 3,
        label: (
          <a onClick={this.access}>
            <Icon type="icon edition-55" className={cn(styles.settingIcon, styles.settingIconAccess)} />
            {t('buttons.accessCatalog')}
          </a>
        ),
      });
    }

    if (isAdmin) {
      // edit catalog
      let adminItemsMenu = [
        {
          key: 2,
          label: (
            <a onClick={this.editCatalog}>
              <Icon type="icon setting-10" className={styles.settingIcon} />
              {t('buttons.configureCatalog')}
            </a>
          ),
        },
        // set api key
        {
          key: 4,
          label: (
            <a onClick={this.openApiKeyModal}>
              <Icon type="icon edition-59" className={styles.settingIcon} />
              {t('buttons.setApiKey')}
            </a>
          ),
        },
        // delete all records
        {
          key: 5,
          label: (
            <a onClick={this.truncate} className={styles.settingRemove}>
              <Icon type="icon text-24" className={styles.settingIcon} />
              {t('buttons.truncateCatalog')}
            </a>
          ),
        },
        // delete catalog
        {
          key: 6,
          label: (
            <a onClick={this.remove} className={styles.settingRemove}>
              <Icon type="icon edition-41" className={styles.settingIcon} />
              {t('buttons.removeCatalog')}
            </a>
          ),
        },
      ];

      if (_.startsWith(catalog.get('id'), '$')) {
        adminItemsMenu = adminItemsMenu.filter((item) => item.key != 'setApiKey');
      }

      menu = [...menu, ...adminItemsMenu];
    }

    menu = _.sortBy(menu, 'key');

    if (!menu.length) {
      return null;
    }

    // favorite
    const isFavorite = favoriteCatalogs && favoriteCatalogs.indexOf(catalog.get('id')) > -1;
    const favoriteTitle = isFavorite ? t('buttons.removeFavorite') : t('buttons.addFavorite');

    return (
      <Row className={styles.actionsContainer}>
        <ButtonTransparent
          onClick={this.favorite}
          className={isFavorite ? styles.settingRemoveFavorite : styles.settingAddFavorite}
          title={favoriteTitle}
        >
          <Icon type="icon vote-38" className={styles.settingFavoriteIcon} />
        </ButtonTransparent>

        <Dropdown
          menu={{ items: menu }}
          trigger={['click']}
          placement="bottomRight"
          title={t('catalogData.actionsButton')}
        >
          <ButtonTransparent className={cn(styles.catalogSetting, className)}>
            <Icon type="icon setting-10" />
          </ButtonTransparent>
        </Dropdown>
      </Row>
    );
  }
}

export default connect(withRouter(withTranslation()(CatalogActions)), {
  favoriteCatalogs: ['userSettings', 'ui', 'favoriteCatalogs'],
});
