import FIELD_TYPES from '../../configs/fieldTypes';

import Group from './Group';
import Address from './Address';
import CheckBoxes from './CheckBoxes';
import Contact from './Contact';
import Date from './Date';
import Dropdown from './Dropdown';
import Switch from './Switch';
import File from './File';
import Object from './Object';
import Number from './Number';
import Progress from './Progress';
import RadioButton from './RadioButton';
import Stars from './Stars';
import Text from './Text';
import User from './User';
import Button from './Button';

const interfaces = {
  [FIELD_TYPES.GROUP]: Group,
  [FIELD_TYPES.ADDRESS]: Address,
  [FIELD_TYPES.CHECKBOXES]: CheckBoxes,
  [FIELD_TYPES.CONTACT]: Contact,
  [FIELD_TYPES.PAIR]: Contact, // ЗАЧЕМ ОН НУЖЕН НЕ ПОНЯТНО
  [FIELD_TYPES.DATE]: Date,
  [FIELD_TYPES.DROPDOWN]: Dropdown,
  [FIELD_TYPES.SWITCH]: Switch,
  [FIELD_TYPES.BUTTON]: Button,
  [FIELD_TYPES.FILE]: File,
  [FIELD_TYPES.OBJECT]: Object,
  [FIELD_TYPES.NUMBER]: Number,
  [FIELD_TYPES.PROGRESS]: Progress,
  [FIELD_TYPES.RADIOBUTTON]: RadioButton,
  [FIELD_TYPES.STARS]: Stars,
  [FIELD_TYPES.TEXT]: Text,
  [FIELD_TYPES.USER]: User,
};

const F = (field) => interfaces[field.get('type')] || {};

export default {
  isEmpty: (field, value) => value === undefined || F(field).isEmpty(value),
  createComponent: (field, value, componentType) => F(field).createComponent(field, value, componentType),
  receivePossibleItems: (field, records, filters) =>
    F(field).receivePossibleItems && F(field).receivePossibleItems(field, records, filters),
  calcId: (field, value) => F(field).calcId(value),
  valueIs: (field, value, type) => F(field).valueIs(value, type),
  convertIdToValue: (field, id, records, filters) => F(field).convertIdToValue(field, id, records, filters),
  setValue: (field, prevValues, newValues) => F(field).setValue(prevValues, newValues),
  removeItem: (field, values, id) => F(field).removeItem(values, id),
  getEmptyValue: (field) => F(field).getEmptyValue(),
  compare: (field, value1, value2) => F(field).compare(value1, value2),
  sortValues: (field, values, sortType) => F(field).sortValues && F(field).sortValues(field, values, sortType),
  hasItem: (field, values, item) => F(field).hasItem(values, item),
  getComponent: (field, type) => F(field).getComponent(type),
  parseValue: (field, value, catalogId, locale) => F(field).parseValue(field, value, catalogId, locale),
  validateValue: (field, value, outError) => F(field).validateValue(field, value, outError),
  validateField: (field, allFields) => F(field).validateField(field, allFields),
  convertFilterToRecordValue: (field, value, catalogs, user) =>
    F(field).convertFilterToRecordValue(field, value, catalogs, user),
  getCanComponentExpandWidthOrHeight: (field, singleFieldInSection, singleFieldInTab) =>
    F(field).getCanComponentExpandWidthOrHeight(field, singleFieldInSection, singleFieldInTab),
  getDefaultValue: (field) => F(field).getDefaultValue(field),
  validateRequired: (field, value) => F(field).validateRequired(value),
  boardWitchColor: (field) => F(field).boardWitchColor(),
  visibleRules: (field, value) => F(field, value).visibleRules(value),
  sortRecordsInCards: (field, records) => F(field, records).sortRecordsInCards(field, records),
  checkChangeYourself: (field, value) => F(field, value).checkChangeYourself(field, value),
};
