import Immutable from 'immutable';

export default {
  create(data) {
    let scene = {
      __name: 'Scene',
      type: undefined,
      shouldClose: false,
      container: undefined,
      loading: false,
      loadingError: null,
      views: {},
      shouldReload: false,
      parentSceneId: null,
      multiModalVisible: false,
    };

    scene = Immutable.fromJS(scene);
    scene = scene.merge(Immutable.fromJS(data));
    return scene;
  },
};
