import _ from 'lodash';
import React from 'react';

import { ItemGroup } from './ItemGroup';

import styles from '../styles/helper.less';

function HelperList({ switchToTutorial, tutorials }) {
  return (
    <div className={styles.helperBody}>
      {tutorials &&
        !_.isEmpty(tutorials) &&
        tutorials.map((block) => <ItemGroup switchToTutorial={switchToTutorial} key={block.title} {...block} />)}
    </div>
  );
}

export { HelperList };
