import FIELD_TYPES from './fieldTypes';

export const extendedFieldTypes = [
  {
    type: FIELD_TYPES.GROUP,
    config: { type: 'group' },
    icon: 'text-56',
    title: `fieldTypes.${FIELD_TYPES.GROUP}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.GROUP}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.TEXT,
    config: { type: 'text' },
    icon: 'text-27',
    title: `fieldTypes.${FIELD_TYPES.TEXT}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.TEXT}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.TEXT,
    config: { type: 'textEditor' },
    icon: 'text-16',
    title: 'fieldTypes.textEditor.name',
    placeholder: `fieldTypes.textEditor.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.NUMBER,
    config: { type: FIELD_TYPES.NUMBER },
    icon: 'social-threewords',
    title: `fieldTypes.${FIELD_TYPES.NUMBER}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.NUMBER}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.DATE,
    config: { type: FIELD_TYPES.DATE },
    icon: 'time-2',
    title: `fieldTypes.${FIELD_TYPES.DATE}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.DATE}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.CONTACT,
    config: { type: 'phone' },
    icon: 'communication-2',
    title: 'fieldTypes.phone.name',
    placeholder: `fieldTypes.phone.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.CONTACT,
    config: { type: 'email' },
    icon: 'communication-61',
    title: 'fieldTypes.email.name',
    placeholder: `fieldTypes.email.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.CONTACT,
    config: { type: 'site' },
    icon: 'interface-3',
    title: 'fieldTypes.site.name',
    placeholder: `fieldTypes.site.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.ADDRESS,
    config: { type: FIELD_TYPES.ADDRESS },
    icon: 'places-40',
    title: `fieldTypes.${FIELD_TYPES.ADDRESS}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.ADDRESS}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.DROPDOWN,
    config: { type: FIELD_TYPES.DROPDOWN },
    icon: 'business-20',
    title: `fieldTypes.${FIELD_TYPES.DROPDOWN}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.DROPDOWN}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.CHECKBOXES,
    config: { type: FIELD_TYPES.CHECKBOXES },
    icon: 'status-2',
    title: `fieldTypes.${FIELD_TYPES.CHECKBOXES}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.CHECKBOXES}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.RADIOBUTTON,
    config: { type: 'radiobuttonGroup' },
    icon: 'social-orkut',
    title: `fieldTypes.radiobuttonGroup.name`,
    placeholder: `fieldTypes.radiobuttonGroup.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.RADIOBUTTON,
    config: { type: 'radiobuttonSelect' },
    icon: 'arrows-chevron-medium-1-01',
    title: `fieldTypes.radiobuttonSelect.name`,
    placeholder: `fieldTypes.radiobuttonSelect.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.SWITCH,
    config: { type: FIELD_TYPES.SWITCH },
    icon: 'health-5',
    title: `fieldTypes.${FIELD_TYPES.SWITCH}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.SWITCH}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.PROGRESS,
    config: { type: FIELD_TYPES.PROGRESS },
    icon: 'business-24',
    title: `fieldTypes.${FIELD_TYPES.PROGRESS}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.PROGRESS}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.STARS,
    config: { type: FIELD_TYPES.STARS },
    icon: 'vote-38',
    title: `fieldTypes.${FIELD_TYPES.STARS}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.STARS}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.USER,
    config: { type: FIELD_TYPES.USER },
    icon: 'users-1',
    title: `fieldTypes.${FIELD_TYPES.USER}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.USER}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.OBJECT,
    config: { type: FIELD_TYPES.OBJECT },
    icon: 'edition-50',
    title: `fieldTypes.${FIELD_TYPES.OBJECT}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.OBJECT}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.FILE,
    config: { type: FIELD_TYPES.FILE },
    icon: 'files-13',
    title: `fieldTypes.${FIELD_TYPES.FILE}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.FILE}.namePlaceholder`,
  },
  {
    type: FIELD_TYPES.BUTTON,
    config: { type: FIELD_TYPES.BUTTON },
    icon: 'edition-66',
    title: `fieldTypes.${FIELD_TYPES.BUTTON}.name`,
    placeholder: `fieldTypes.${FIELD_TYPES.BUTTON}.namePlaceholder`,
  },
];
