import _ from 'lodash';

const delimiter = '.';
const preparingProcess = [
  ({ catalogId }) => (catalogId ? ['catalogs', catalogId] : []),
  ({ linkedCatalogId }) => (linkedCatalogId ? ['linkedCatalogs', linkedCatalogId] : []),
  ({ viewMode }) => (viewMode ? ['viewMode', viewMode] : []),
  ({ fieldId }) => (fieldId ? ['fields', fieldId] : []),
  ({ filterId }) => (filterId ? ['filters', filterId] : []),
  ({ recordId }) => (recordId ? ['records', recordId] : []),
  ({ option }) => (option ? ['options', option] : []),
];
export default {
  delimiter,

  makeObject(str, defVal) {
    const resObj = {};
    const length = str.split(delimiter).length - 1;
    str.split('.').reduce((a, b, i) => {
      if (length == i) {
        a[b] = defVal;
      } else a[b] = {};
      return a[b];
    }, resObj);
    return resObj;
  },

  makeKey(params, type) {
    const res = preparingProcess
      .map((fn) => fn(params).join(delimiter))
      .filter((arr) => arr.length)
      .join(delimiter);

    if (type) {
      return [res, type].join(delimiter);
    }
    return res;
  },

  makeKeyForSearch(params) {
    const res = preparingProcess
      .map((fn) => fn(params).join(delimiter))
      .filter((arr) => arr.length)
      .join(delimiter);
    return res + delimiter;
  },
};
