import React from 'react';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';

import LinkedItem from '../../../../LinkedItem';
import LoadingSpin from '../../../../../LoadingSpin';
import styles from '../../controls.less';
import Icon from '../../../../Icon';

// in kbytes
function toMb(value, t) {
  let resVal = (Number(value) / 1000 / 1000).toFixed(1);
  if (resVal === '0.0') resVal = '0.1';
  return `${resVal} ${t('record.fields.file.size')}`;
}

function toProgress(progress) {
  if (isNaN(progress) || progress === 'NaN') {
    return '';
  }
  return `${parseInt(progress, 10)}%`;
}

class FileRow extends React.Component {
  onRemove = () => {
    this.props.removeFn && this.props.removeFn(this.props.file);
  };

  render() {
    const { t } = this.props;
    const { file } = this.props;
    const item = {};
    if (file.loading && !file.error) {
      item.icon = <LoadingSpin />;
    } else {
      item.icon = `icon ${file.error ? 'interface-54' : 'files-13'}`;
    }
    item.text = file.title;
    item.subText =
      file.size && file.loading ? (
        toProgress(this.props.updateProgress.get(file.id))
      ) : (
        <Icon type="icon interface-40" title={toMb(file.size, t)} />
      );

    const fileUrl = !(file.loading || file.error) ? file.url : null;
    return (
      <LinkedItem
        key={item.key}
        type="link"
        item={item}
        linkProps={{
          href: fileUrl,
          target: '_blank',
        }}
        subLinkProps={{
          href: fileUrl,
          title: t('record.fields.file.download'),
          download: true,
          target: '_blank',
        }}
        title={file.title}
        className={this.props.itemWrapperClassName}
        classNameItem={cn(this.props.itemСlassName, { [styles.uploadError]: file.error })}
        removable={!this.props.readOnly}
        titleOnRemove={t('record.fields.file.remove')}
        onClickRemove={this.onRemove}
      />
    );
  }
}

export default withTranslation()(FileRow);
