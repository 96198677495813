export default (t) => ({
  element: 'bpmn:ServiceTask',
  service: 'sqlRequest',
  title: t('script.controlls.sqlRequest.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon programing-47',
  offset_x: 0,
  offset_y: -15,
  priority: 4,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.sqlRequest.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section',
      name: t('script.controlls.sqlRequest.connection'),
      type: 'group',
    },
    {
      id: 'dbType',
      name: t('script.controlls.sqlRequest.connectionType'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'pg',
            name: 'PostgreSQL',
            color: 'E7E9ED',
          },
          {
            id: 'mysql',
            name: 'MySQL',
            color: 'E7E9ED',
          },
          {
            id: 'oracle',
            name: 'Oracle',
            color: 'E7E9ED',
          },
          {
            id: 'mssql',
            name: 'MsSQL',
            color: 'E7E9ED',
          },
          {
            id: 'sqlite',
            name: 'Sqlite',
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['pg'],
        map: 'input',
      },
    },
    {
      id: 'connectionType',
      name: t('script.controlls.sqlRequest.connectionMethod'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'parameters',
            name: t('script.common.params'),
            color: 'E7E9ED',
          },
          {
            id: 'connection',
            name: t('script.common.connectionString'),
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['parameters'],
        map: 'input',
      },
    },
    {
      id: 'dbhost',
      name: t('script.controlls.sqlRequest.serverAddress'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'dbname',
      name: t('script.controlls.sqlRequest.database'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'dblogin',
      name: t('script.controlls.sqlRequest.login'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'dbpass',
      name: t('script.controlls.sqlRequest.password'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
      },
      visible: {
        connectionType: {
          $include: 'parameters',
        },
      },
    },
    {
      id: 'connectionString',
      name: t('script.common.connectionString'),
      type: 'text',
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        map: 'input',
        type: 'multiline',
        theme: 'script',
      },
      visible: {
        connectionType: {
          $include: 'connection',
        },
      },
    },
    {
      id: 'section1',
      name: t('script.controlls.sqlRequest.request'),
      type: 'group',
    },
    {
      id: 'query',
      name: t('script.controlls.sqlRequest.title'),
      type: 'text',
      hint: t('script.controlls.sqlRequest.queryHint'),
      readOnly: false,
      config: {
        placeholder: t('script.common.valueExpression'),
        type: 'multiline',
        theme: 'script',
        map: 'input',
      },
    },
    {
      id: 'queryParams',
      name: t('script.controlls.sqlRequest.requestParams'),
      type: 'pair',
      readOnly: false,
      config: {
        placeholderValue: t('script.controlls.sqlRequest.param'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        map: 'input',
      },
    },
    {
      id: 'section2',
      name: t('script.controlls.sqlRequest.response'),
      type: 'group',
    },
    {
      id: 'async',
      name: t('script.controlls.sqlRequest.responseWait'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'sync',
            name: t('script.common.yes'),
            color: 'E7E9ED',
          },
          {
            id: 'async',
            name: t('script.common.no'),
            color: 'E7E9ED',
          },
        ],
        defaultEmptyValue: ['sync'],
      },
    },
    {
      id: 'output',
      name: t('script.common.saveTo'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.sqlRequest.outputHint'),
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        async: {
          $include: 'sync',
        },
      },
    },
  ],
  exits: [],
});
