import React, { useState } from 'react';
import cn from 'classnames';
import { Cell } from 'fixed-data-table-2';

import LoadingSpin from '../../LoadingSpin';

import styles from './table.less';

function ValueCell(props) {
  /* // used before with dropdown edit button
  const [hover, setHover] = useState(false);
  const onButtonHover = () => setHover(true);
  const onButtonUnhover = () => setHover(false);
  
  const onActive = event => {
    event && event.stopPropagation();
    //setHover(false);
    props.onActive();
  };
  */

  const onDoubleClick = (event) => {
    props.onDoubleClick();
    props.editable && props.onActive && props.onActive();
  };

  return (
    <Cell
      className={cn(
        styles.cell,
        { [styles.cellEditable]: props.editable },
        props.className,
        // { [styles.cellHovered]: hover }
      )}
      columnKey={props.columnKey}
      height={props.heigth}
      width={props.width}
      onDoubleClick={onDoubleClick}
    >
      {props.children}
      {
        props.inProcess ? <LoadingSpin className={styles.cellSpin} /> : null
        /*
        props.canActive && (
          <ButtonTransparent
            onMouseEnter={onButtonHover}
            onMouseLeave={onButtonUnhover}
            className={styles.cellButton}
            onClick={onActive}
            onMouseDown={e => e.stopPropagation()}
          >
            <Icon type={`icon ${props.cellType !== CELL_TYPES.CONTROL ? "arrows-chevron-medium-1-01" : "edition-32";}`}/>
          </ButtonTransparent>
        )
        */
      }
    </Cell>
  );
}

export default ValueCell;
