import { InputMappers, OutputMappers } from '../helpers/mappers';
import i18n from '../../../../../configs/i18n';
import { RADIOBUTTONGROUP, RADIOBUTTONSELECT } from '../../../../../configs/radiobuttonFieldSubTypes';

const Properties = (field, t = i18n.t) => {
  const config = field.get('config');
  const trsPrefix = `catalogEditor.fieldProperties.configProperties.field.${field.get('type')}.`;

  return {
    properties: [
      {
        id: 'configPropertiesSection',
        name: t('catalogEditor.fieldProperties.configProperties.section'),
        type: 'group',
      },
      {
        id: 'config.defaultValue',
        name: t(`${trsPrefix}defaultValue.label`),
        type: 'checkboxes',
        readOnly: false,
        config: {
          items: [{ id: 'true', name: t(`${trsPrefix}defaultValue.value`) }],
        },
      },
      {
        id: 'config.type',
        name: t(`${trsPrefix}type.label`),
        type: 'radiobutton',
        readOnly: false,
        config: {
          mode: 'dropdown',
          items: [
            {
              id: RADIOBUTTONSELECT,
              name: t(`${trsPrefix}type.items.${RADIOBUTTONSELECT}`),
            },
            {
              id: RADIOBUTTONGROUP,
              name: t(`${trsPrefix}type.items.${RADIOBUTTONGROUP}`),
            },
          ],
        },
      },
    ],
    values: {
      'config.defaultValue': InputMappers.boolToCheckbox(config.get('defaultValue')),
      'config.type': config.get('type'),
    },

    getValues: (propery, value) => {
      switch (propery) {
        case 'config.defaultValue':
          value = OutputMappers.checkboxToBool(value);
          break;
        case 'config.type':
          if (value == 'radiobuttonGroup') {
            return { 'config.type': RADIOBUTTONGROUP };
          }
          return { 'config.type': RADIOBUTTONSELECT };
      }
      return { [propery]: value };
    },
  };
};

export default Properties;
