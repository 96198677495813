import i18n from '../../../../../configs/i18n';

import { EMAIL, PHONE, SITE } from '../../../../../configs/contactFieldSubTypes';

const Properties = (field, t = i18n.t) => {
  const config = field.get('config');
  const trsPrefix = `catalogEditor.fieldProperties.configProperties.field.${field.get('type')}.`;

  return {
    properties: [
      {
        id: 'configPropertiesSection',
        name: t('catalogEditor.fieldProperties.configProperties.section'),
        type: 'group',
      },
      {
        id: 'config.type',
        name: t(`${trsPrefix}type.label`),
        type: 'radiobutton',
        readOnly: false,
        config: {
          mode: 'dropdown',
          items: [
            {
              id: PHONE,
              name: t(`${trsPrefix}type.items.${PHONE}`),
            },
            {
              id: EMAIL,
              name: t(`${trsPrefix}type.items.${EMAIL}`),
            },
            {
              id: SITE,
              name: t(`${trsPrefix}type.items.${SITE}`),
            },
          ],
        },
      },
    ],

    values: {
      'config.type': config.get('type'),
    },

    getValues: (propery, value) => ({ [propery]: value }),
  };
};

export default Properties;
