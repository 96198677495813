import ReplaceOptions from 'bpmn-js/lib/features/replace/ReplaceOptions';

ReplaceOptions.SEQUENCE_FLOW = [
  {
    label: 'Условный поток',
    actionName: 'replace-with-sequence-flow',
    className: 'bpmn-icon-connection',
  },
  {
    label: 'Поток по умолчанию',
    actionName: 'replace-with-default-flow',
    className: 'bpmn-icon-default-flow',
  },
  {
    label: 'Conditional Flow',
    actionName: 'replace-with-conditional-flow',
    className: 'bpmn-icon-conditional-flow',
  },
];
