import React from 'react';
import { Route } from 'react-router';
import { withTranslation } from 'react-i18next';

import RecordsBatchUpdate from '../../../RecordsBatch/RecordsBatchUpdate';
import RecordsBatchDelete from '../../../RecordsBatch/RecordsBatchDelete';
import RecordController from '../../../Record/RecordController';
import View from '../../../App/AppBody/Section/SectionBody/Catalog/View';
import { connect } from '../../../StateProvider';
import getLink from '../../router/getLink';
import getConfirmation from '../getConfirmation';
import routes from '../../../../routes';
import HashMemoryRouter from '../../router/HashMemoryRouter';
import SCENE_TYPE from '../../../../configs/sceneTypes';

import uiActions from '../../../../actions/uiActions';

import styles from './multiModal.less';

class ModalSceneContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.prepareComponent();

    // save webForm State
    if (this.props.isWebForm) {
      uiActions.setIsWebForm();
    }
  }

  componentDidUpdate(prevProps) {
    const { catalogId: currentCatalogId, recordId: currentrecordId } = this.props;
    const { catalogId: prevCatalogId, recordId: prevRecordId } = prevProps;

    if (
      (currentCatalogId && currentCatalogId) !== prevCatalogId ||
      (currentrecordId && currentrecordId) !== prevRecordId
    ) {
      this.prepareComponent();
    }
  }

  prepareComponent() {
    const { type, sectionId, catalogId, recordId } = this.props;
    let { viewId } = this.props;
    let link;
    let route;
    let component;

    switch (type) {
      case SCENE_TYPE.CATALOG:
        route = routes.view;
        link = viewId
          ? getLink(null, route, { sectionId, catalogId, viewId })
          : getLink(null, route, { sectionId, catalogId });
        component = ({ match }) => {
          viewId = match.params && match.params.viewId ? match.params.viewId : viewId;

          return <View {...this.props} viewId={viewId} openRecordsInModal />;
        };

        break;

      case SCENE_TYPE.RECORD:
        if (recordId === '$new') {
          route = routes.newRecord;
        } else {
          route = routes.record;
        }
        link = route && getLink(null, route, { sectionId, catalogId, recordId });
        link = link.pathname;
        component = () => <RecordController {...this.props} />;

        break;

      case SCENE_TYPE.RECORDS_BATCH_UPDATE:
        route = routes.batchUpdate;
        link = viewId
          ? getLink(null, route, { sectionId, catalogId, viewId, recordId })
          : getLink(null, route, { sectionId, catalogId, recordId });

        link = link.pathname;
        component = ({ match }) => {
          viewId = match.params && match.params.viewId ? match.params.viewId : viewId;

          return <RecordsBatchUpdate {...this.props} viewId={viewId} />;
        };

        break;

      case SCENE_TYPE.RECORDS_BATCH_DELETE:
        route = routes.batchDelete;
        link = viewId
          ? getLink(null, route, { sectionId, catalogId, viewId })
          : getLink(null, route, { sectionId, catalogId });
        link = link.pathname;
        component = ({ match }) => {
          viewId = match.params && match.params.viewId ? match.params.viewId : viewId;

          return <RecordsBatchDelete {...this.props} viewId={viewId} />;
        };

        break;

      default:
        break;
    }

    this.setState({
      link,
      route,
      component,
    });
  }

  render() {
    const { isActive, type, t } = this.props;
    const { link, route, component } = this.state;

    if (!(type && link && route && component)) {
      return null;
    }

    return (
      <HashMemoryRouter
        initialEntries={[link]}
        initialIndex={0}
        getUserConfirmation={(message, callback) => getConfirmation(message, callback, null, undefined, t)}
      >
        <div style={{ display: isActive ? 'block' : 'none' }} className={styles.recordContainer}>
          <Route path={route.path} render={component} />
        </div>
      </HashMemoryRouter>
    );
  }
}

export default connect(
  withTranslation()(ModalSceneContainer),
  {
    scenes: ['scenes'],
    catalogs: ['catalogs'],
  },
  (props, { scenes, catalogs }) => {
    const { sceneId } = props;
    const scene = scenes.get(sceneId);
    const type = scene && scene.get('type');
    const catalogId = scene && scene.getIn(['params', 'catalogId']);
    const recordId = scene && scene.getIn(['params', 'recordId']);
    const viewId = scene && scene.getIn(['params', 'viewId']);
    const sectionIdFromScene = scene && scene.getIn(['params', 'sectionId']);
    const sectionIdFromCatalog = catalogs.getIn([catalogId, 'sectionId']);
    return {
      sectionId: sectionIdFromScene || sectionIdFromCatalog,
      catalogId,
      recordId,
      viewId,
      type,
      ...props,
    };
  },
);
