import React from 'react';
import cn from 'classnames';
import guid from 'guid';
import { withTranslation } from 'react-i18next';

import Icon from '../../UI/Icon';
import { connect } from '../../../StateProvider';
import ButtonTransparent from '../../UI/ButtonTransparent';
import routes from '../../../../routes';
import LoadingSpin from '../../LoadingSpin';
import SCENE_TYPES from '../../../../configs/sceneTypes';

import styles from './multiModal.less';

class ListItem extends React.Component {
  switchModal = (e) => {
    const { switchModal, sceneId } = this.props;
    switchModal && switchModal(sceneId);
  };

  removeScene = (e) => {
    const { sceneId, onClose } = this.props;

    e.stopPropagation();
    onClose && onClose(sceneId);
  };

  render() {
    const { isNew, title, isActive, sceneId, pointers, t } = this.props;

    // title
    const text = isNew ? t('record.newRecordCreating') : title;
    return (
      <li
        className={cn(styles.listItem, {
          [styles.listItemSelected]: isActive,
        })}
        onClick={this.switchModal}
        key={sceneId}
      >
        <div
          className={cn(styles.itemBody, {
            [styles.itemTextDanger]: this.props.hasBeenEdit || isNew,
          })}
        >
          {pointers}
          {!this.props.loading ? (
            <Icon
              title={this.props.catalogName}
              type={`anticon anticon-icon ${this.props.icon}`}
              className={styles.itemBodyIcon}
            />
          ) : (
            <LoadingSpin className={styles.itemBodyIcon} />
          )}
          <span className={styles.itemText}>{text}</span>
        </div>

        <ButtonTransparent className={styles.closeBtn} onClick={this.removeScene} title={t('multiModal.hideRecord')}>
          <Icon className={styles.itemRemoveIcon} type="anticon anticon-icon interface-74" />
        </ButtonTransparent>
      </li>
    );
  }
}

export default withTranslation()(
  connect(ListItem, ['records', 'catalogs', 'scenes'], (props, { records, catalogs, scenes }) => {
    const { sceneId, t } = props;

    const scene = scenes && scenes.get(sceneId);
    const sceneType = scene && scene.get('type');

    const recordsCount = (scene && scene.get('recordsCount')) || 0;

    const isBatchRecordsDelete = sceneType === SCENE_TYPES.RECORDS_BATCH_DELETE;
    const isBatchRecordsUpdate = sceneType === SCENE_TYPES.RECORDS_BATCH_UPDATE;
    const isBatchRecords = isBatchRecordsDelete || isBatchRecordsUpdate;

    const catalogId = scene && scene.getIn(['params', 'catalogId']);
    const recordId = scene && scene.getIn(['params', 'recordId']);
    const route = !recordId ? routes.view : routes.record;
    const shouldClose = scene && scene.get('shouldClose');
    const isNew = records.getIn([recordId, 'isNew']);
    const catalogName = catalogs.getIn([catalogId, 'name']);

    const titleForBatchRecords =
      isBatchRecords &&
      (isBatchRecordsDelete
        ? t('batchDeleteRecords.recordsListTitle', { count: recordsCount, catalogName })
        : t('batchUpdateRecords.recordsListTitle', { count: recordsCount, catalogName }));

    return {
      ...props,
      catalogId,
      isNew,
      icon: catalogs.getIn([catalogId, 'icon']),
      title: titleForBatchRecords || records.getIn([catalogId, recordId, 'title']) || catalogName,
      hasBeenEdit: records.getIn([catalogId, recordId, 'hasBeenEdit']),
      catalogName: catalogs.getIn([catalogId, 'name']),
      loading: records.getIn([catalogId, recordId, 'loading']),
      route,
      shouldClose,
    };
  }),
);
