import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Slider from 'rc-slider';

import styles from './controls.less';

const { Range } = Slider;

const MIN = 0;
const MAX = 100;

class ProgressRangeField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getPropsValue(),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: this.getPropsValue(nextProps) });
  }

  onChange = (value) => this.setState({ value });

  onSave = () => {
    const { onSave, fieldId } = this.props;

    const [min, max] = this.state.value;

    onSave(fieldId, {
      at: min,
      to: max,
    });
  };

  getPropsValue(props = this.props) {
    const v = Immutable.fromJS(props.value || {}).toJS();
    return [v.at || MIN, v.to || MAX];
  }

  render() {
    const { value } = this.state;
    const [min, max] = value;

    return (
      <div className={styles.progressBar}>
        <div className={styles.rangeNumber}>{min}</div>
        <Range
          className={styles.rangeSlider}
          defaultValue={value}
          step={1}
          value={value}
          disabled={this.props.readOnly}
          onChange={this.onChange}
          onAfterChange={this.onSave}
        />
        <div className={styles.rangeNumber}>{max}</div>
      </div>
    );
  }
}

ProgressRangeField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default ProgressRangeField;
