import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import _ from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import raf from 'raf';
import Label from './Helpers/SelectLabel';
import LinkedRecordSelector from '../../common/UI/ControlList/controls/RecordDropdown/LinkedRecordSelector';

import styles from './selectors.less';

function ObjectSelector(props) {
  const { t } = useTranslation();
  let { controlConfig } = props;
  const type = controlConfig.get('elementsRemoteGroup');

  const multiselect = props.config.get('multiselect');

  const enableSelect = _.isBoolean(controlConfig.getIn(['config', 'enableSelect']))
    ? controlConfig.getIn(['config', 'enableSelect'])
    : true;

  const enableCreate = _.isBoolean(controlConfig.getIn(['config', 'enableCreate']))
    ? controlConfig.getIn(['config', 'enableCreate'])
    : true;

  controlConfig = controlConfig.setIn(['config', 'enableSelect'], enableSelect);
  controlConfig = controlConfig.setIn(['config', 'enableCreate'], enableCreate);

  const [searchingText, setSearchingText] = useState('');
  const [selectedItems, setSelectedItems] = useState();

  /**
   * @function prepareValue
   * @description
   * if multiselect so map keys to allow show selected
   * items in dropdown, else we need to show lable in
   * Select component
   * @returns value for dropdown
   */

  /* оставить */
  const prepareValue = () => {
    if (multiselect) {
      return props.value.map((item) => item.key).toJS();
    }
    return props.value && props.value.size
      ? {
          key: props.value.get(0).key,
          label: <Label type={props.fieldType} config={props.config} value={props.originalValue} />,
        }
      : [];
  };

  /* 
    использование AdditionalItems в качестве места для хранения выбранных элементов
    сделанно это для того, чтобы в таблице отображались выбранные Опции
  */

  useEffect(() => {
    let value = props.value.toJS();
    if (searchingText) {
      value = _.sortBy(value, (val) => !val.text.includes(searchingText));
    }

    setSelectedItems(value);
  }, [searchingText]);

  const onAddItem = (value) => {
    // clear items if selected empty item
    if (value.find((i) => i.key === '$null')) {
      value = [];
    }

    props.onChange && props.onChange(value);

    if (!multiselect) {
      raf(() => {
        props.onEndEditing && props.onEndEditing(value);
      });
    }
  };

  const onVisibleChange = (visible) => {
    !visible && props.onEndEditing && props.onEndEditing(props.value);
  };

  const requestParams = controlConfig.get('requestParams');
  const additionalClickItems = controlConfig.get('additionalClickItems');

  const clearAvailableItems = controlConfig.get('clearAvailableItems');
  const loadAvailableItems = controlConfig.get('loadAvailableItems');

  const loadItems = (...args) => enableSelect && loadAvailableItems && loadAvailableItems(...args);
  const filterFn = (item) => !(props.value && props.value.find((val) => val.key === item.key));
  const onSearchText = (value) => setSearchingText(value);
  const placeholder = props.placeholder || t('record.fields.object.add');
  const additionalItems = _.concat(
    [
      {
        key: '$null', // to allow select none
        text: '',
        sort: -1,
        selectable: true,
        filterable: true,
      },
    ],
    additionalClickItems || [],
    selectedItems || [],
  );
  return (
    <LinkedRecordSelector
      config={controlConfig.get('config')}
      type={type}
      requestParams={requestParams}
      placeholder={placeholder}
      additionalItems={additionalItems}
      value={prepareValue()}
      className={cn(props.className, {
        [styles.linkedRecordHidden]: multiselect,
      })}
      dropdownClassName={cn(styles.selectorDropdown, props.dropdownClassName)}
      optionsClassName={styles.option}
      sortBy={false}
      multiselect={multiselect}
      open={!!props.autoFocus}
      autoFocus={props.autoFocus}
      filterFn={!multiselect && filterFn}
      onSearchText={onSearchText}
      loadAvailableItems={loadItems}
      clearAvailableItems={clearAvailableItems}
      onAddItem={onAddItem}
      onVisibleChange={onVisibleChange}
    />
  );
}

ObjectSelector.defaultProps = {
  value: Immutable.List(),
};

export default ObjectSelector;
