export default {
  setIsWebForm() {
    this.setIn(['ui', 'isWebForm'], true);
    this.changed();
  },

  sidebarMenuFixing(fixed) {
    this.setIn(['ui', 'sidebarMenu', 'fixed'], fixed);
    this.changed();
    this.saveToStorage('ui.sidebarMenu.fixed', fixed);
  },

  leftPanelVisible(visible) {
    this.setIn(['ui', 'leftPanel', 'visible'], visible);
    this.changed();
    this.saveToStorage('ui.leftPanel.visible', visible);
  },

  toggleHelper(isOpen) {
    this.setIn(['ui', 'helper', 'isOpen'], isOpen);
    this.changed();
  },

  setHelperCode(code) {
    this.setIn(['ui', 'helper', 'code'], code);
    this.changed();
  },

  showHelper(code, force = false) {
    this.setHelperCode(code);
  },

  unsetHelperCode() {
    this.setIn(['ui', 'helper', 'code'], null);
    this.changed();
  },

  setShowChatTabInModal(visible) {
    this.setIn(['userSettings', 'ui', 'showChatTabInModal'], visible);
    this.saveToStorage('userSettings.ui.showChatTabInModal', visible);
  },

  /* Sections Menu Hover */
  sectionMenuHover(sectionId) {
    this.setIn(['ui', 'hoveredSectionId'], sectionId);
    this.changed();
  },
};
