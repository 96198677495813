import i18n from '../../../../../configs/i18n';

const Properties = (field, t = i18n.t) => {
  const config = field.get('config');
  const trsPrefix = `catalogEditor.fieldProperties.configProperties.field.${field.get('type')}.`;

  return {
    properties: [
      {
        id: 'configPropertiesSection',
        name: t('catalogEditor.fieldProperties.configProperties.section'),
        type: 'group',
      },
      {
        id: 'config.token',
        name: t(`${trsPrefix}token.label`),
        hint: t(`${trsPrefix}token.hint`),
        type: 'text',
        readOnly: false,
        config: {},
      },
    ],

    values: {
      'config.token': config.get('token'),
    },

    getValues: (propery, value) => ({ [propery]: value }),
  };
};

export default Properties;
