import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Immutable from 'immutable';
import Activities from '../../../Record/Activities';
import { BATCH_RECORDS_STATUS } from '../../../../configs/recordsBatchStatus';
import { connect } from '../../../StateProvider';

function RecordsBatchDeleteActivities(props) {
  RecordsBatchDeleteActivities.propTypes = {
    catalog: ImmutablePropTypes.map.isRequired,
    sceneId: PropTypes.string.isRequired,
    scene: ImmutablePropTypes.map.isRequired,
    onClose: PropTypes.func,
    onStartDelete: PropTypes.func.isRequired,
  };

  const getMainButton = () => {
    const { scene, onPauseUpdate, onResumeUpdate, t } = props;
    const deletingStatus = scene && scene.getIn(['data', 'batchStatus']);
    let button;

    switch (deletingStatus) {
      case BATCH_RECORDS_STATUS.LOADING:
        button = {
          type: 'ghost',
          text: t('batchDeleteRecords.buttons.loading'),
          disabled: true,
        };
        break;
      case BATCH_RECORDS_STATUS.DELETING:
        button = {
          type: 'primary',
          text: t('batchDeleteRecords.buttons.pause'),
          onClick: onPauseUpdate,
        };
        break;

      case BATCH_RECORDS_STATUS.PAUSED:
        button = {
          type: 'primary',
          text: t('batchDeleteRecords.buttons.resume'),
          onClick: onResumeUpdate,
        };
        break;
      case BATCH_RECORDS_STATUS.COMPLETED:
      default:
        button = null;
        break;
    }
    return Immutable.fromJS(button);
  };

  const mainButton = getMainButton();
  const singleButton = mainButton && mainButton.get('new');
  return (
    <Activities
      singleButton={singleButton}
      mainButton={mainButton}
      // dropDownButtonItems={dropDownButtonItems}
    />
  );
}

export default withTranslation()(
  connect(
    RecordsBatchDeleteActivities,
    {
      scenes: ['scenes'],
    },
    (props, { scenes }) => {
      const scene = scenes.get(props.sceneId);
      return {
        ...props,
        scene,
      };
    },
  ),
);
