/* eslint-disable */
import _ from 'lodash';
import moment from 'moment';

const FiltersKeys = {
  // date ranges.
  TODAY: 'TODAY_DATE',
  TILL_TODAY: 'TILL_TODAY_DATE',
  YESTERDAY: 'YESTERDAY_DATE',
  THIS_WEEK: 'THIS_WEEK_DATE',
  LAST_WEEK: 'LAST_WEEK_DATE',
  CURRENT_MONTH: 'CURRENT_MONTH_DATE', // obsolet
  THIS_MONTH: 'THIS_MONTH_DATE',
  LAST_30_DAYS: 'LAST_30_DAYS_DATE',
  LAST_MONTH: 'LAST_MONTH_DATE',
  THIS_YEAR: 'THIS_YEAR_DATE',
  LAST_365_DAYS: 'LAST_365_DAYS_DATE',
  LAST_YEAR: 'LAST_YEAR_DATE',
  CURRENT_DAY: 'CURRENT_DAY_DATE',
  NULL: 'NULL_DATE',
};

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export default function fieldDateVisibleRules(filterParams) {
  const clientTime = function (...args) {
    const date = moment(...args);
    /* if (timeZone !== null) {
          date.utcOffset(timeZone);
        } */
    return date;
  };

  if (_.isString(filterParams) || filterParams === null) {
    let date = null;
    // if one from virtual filter keys..
    switch (filterParams) {
      case FiltersKeys.TODAY:
        filterParams = {
          at: clientTime().startOf('day').format(MOMENT_FORMAT),
          to: clientTime().endOf('day').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.TILL_TODAY:
        filterParams = {
          at: null,
          to: clientTime().endOf('day').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.YESTERDAY:
        const yesterdayDate = clientTime().add(-1, 'days');
        filterParams = {
          at: yesterdayDate.startOf('day').format(MOMENT_FORMAT),
          to: yesterdayDate.endOf('day').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.THIS_WEEK:
        const today = clientTime();
        filterParams = {
          at: today.startOf('isoWeek').format(MOMENT_FORMAT),
          to: today.endOf('isoWeek').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.LAST_WEEK:
        date = clientTime().add(-1, 'weeks');
        filterParams = {
          at: date.startOf('isoWeek').format(MOMENT_FORMAT),
          to: date.endOf('isoWeek').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.CURRENT_MONTH: // obsolet
      case FiltersKeys.THIS_MONTH:
        date = clientTime();
        filterParams = {
          at: date.startOf('month').format(MOMENT_FORMAT),
          to: date.endOf('month').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.LAST_30_DAYS:
        date = clientTime().add(-30, 'days');
        filterParams = {
          at: date.startOf('day').format(MOMENT_FORMAT),
          to: clientTime().endOf('day').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.LAST_MONTH:
        date = clientTime().add(-1, 'month');
        filterParams = {
          at: date.startOf('month').format(MOMENT_FORMAT),
          to: date.endOf('month').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.THIS_YEAR:
        date = clientTime();
        filterParams = {
          at: date.startOf('year').format(MOMENT_FORMAT),
          to: date.endOf('year').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.LAST_365_DAYS:
        date = clientTime().add(-365, 'days');
        filterParams = {
          at: date.startOf('day').format(MOMENT_FORMAT),
          to: clientTime().endOf('day').format(MOMENT_FORMAT),
        };
        break;
      case FiltersKeys.LAST_YEAR:
        date = clientTime().add(-1, 'year');
        filterParams = {
          at: date.startOf('year').format(MOMENT_FORMAT),
          to: date.endOf('year').format(MOMENT_FORMAT),
        };
        break;

      // Типы фильтра которые не описываются промежутками
      case FiltersKeys.CURRENT_DAY:
        // TO DO
        // UNSUPORTED
        break;
      case FiltersKeys.NULL:
      case null:
        return null; // { "$eq": null };
    }
    if (_.isPlainObject(filterParams)) {
      // Если filterParams был преобразован в объект промежутка дат, то обрабатываем его как промежуток
      return range(filterParams);
    }
  } else {
    let { at, to } = filterParams;
    let [atIsRelative, toIsRelative] = [false, false];

    // parse input data
    [at, to] = [at, to].map((str) => {
      str = String(str).trim();

      if (!str) {
        return null;
      }

      const isNumber = /^\-?\d+$/.test(str);

      if (isNumber) {
        return parseInt(str);
      }

      const date = new Date(str);

      if (date.getTime()) {
        return moment(date).format(MOMENT_FORMAT);
      }

      return null;
    });

    // convert number to date
    {
      if (_.isNumber(at)) {
        at = clientTime().add(at, 'days').toDate();
        atIsRelative = true;
      }

      if (_.isNumber(to)) {
        to = clientTime().add(to, 'days').toDate();
        toIsRelative = true;
      }
    }

    // normalize interval
    if (at != null && to != null && at > to) {
      [at, to] = [to, at];
      [atIsRelative, toIsRelative] = [toIsRelative, atIsRelative];
    }

    // add time to relative dates
    {
      if (atIsRelative) {
        at = clientTime(at).startOf('day').format(MOMENT_FORMAT);
      }

      if (toIsRelative) {
        to = clientTime(to).endOf('day').format(MOMENT_FORMAT);
      }
    }
    return range({ at, to });
  }
}

function range({ at, to }) {
  const v = {};

  if (at) {
    v.$gte = at;
  }
  if (to) {
    v.$lte = to;
  }

  return v;
}
