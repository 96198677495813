import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import DefaultRedirect from '../../../../../../common/router/DefaultRedirect';
import NavRoute from '../../../../../../common/router/Route';
import routes from '../../../../../../../routes';

import viewActions from '../../../../../../../actions/viewActions';
import sceneActions from '../../../../../../../actions/sceneActions';
import { connect } from '../../../../../../StateProvider';
import ViewHeader from './ViewHeader';
import ViewBody from './ViewBody';

import styles from './view.less';

class View extends React.Component {
  static propTypes = {
    sceneId: PropTypes.string,
    catalogId: PropTypes.string,
    viewId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      colsMode: 'small',
    };
  }

  loadView(viewId, catalogId, sceneId) {
    if (viewId && catalogId) {
      viewActions.preGetView({ viewId, catalogId, sceneId });
    }
  }

  getDefaultView() {
    let { defaultViewId } = this.props;
    defaultViewId = defaultViewId && defaultViewId.get('value');

    if (defaultViewId) {
      return Immutable.Map({ id: defaultViewId });
    }

    return Immutable.Map({ id: '0' });
  }

  getFieldsToRender = () => {
    const { userSettingsFields, catalog } = this.props;
    let fields = catalog && catalog.get('fields');
    if (!fields) {
      fields = Immutable.List();
    }
    if (!userSettingsFields) {
      return fields;
    }
    const res = fields.filter((field) => {
      const visible = userSettingsFields && userSettingsFields.getIn([field.get('id'), 'visible', 'visible']);

      return visible === undefined ? true : visible;
    });
    return res;
  };

  UNSAFE_componentWillMount() {
    const { viewId, catalogId, sceneId } = this.props;
    this.loadView(viewId, catalogId, sceneId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevCatalogId = this.props.catalogId;
    const { catalogId } = nextProps;
    const prevViewId = this.props.viewId;
    const { viewId } = nextProps;

    const { sceneId } = nextProps;

    if ((catalogId && catalogId !== prevCatalogId) || (viewId && viewId !== prevViewId)) {
      this.loadView(viewId, catalogId, sceneId);
    }
  }

  componentDidUpdate(prevProps) {
    const { shouldClose, isActive, sceneId } = this.props;

    if (shouldClose && isActive) {
      sceneActions.deleteScene(sceneId);
    }
  }

  toggleColsWidthMode = (mode) => {
    this.setState({ colsMode: mode });
  };

  render() {
    const { catalogId, sceneId, viewId, catalog, onClose, openRecordsInModal, isWebForm, leftPanel, defaultViewMode } =
      this.props;

    /* редирект на вид по умолчанию */

    if (!viewId) {
      return <DefaultRedirect route={routes.view} params="viewId" object={this.getDefaultView()} />;
    }

    return (
      <NavRoute
        route={routes.view}
        render={(routeProps) => {
          const viewId = routeProps.match.params && routeProps.match.params.viewId;
          return (
            <div className={styles.container}>
              <ViewHeader
                catalogId={catalogId}
                sceneId={sceneId}
                viewId={viewId}
                catalog={catalog}
                isWebForm={isWebForm}
                leftPanel={leftPanel}
                openRecordsInModal={openRecordsInModal}
                onClose={onClose}
                /* MOVE inside HEADER */
                getFieldsToRender={this.getFieldsToRender}
                defaultViewMode={defaultViewMode}
                toggleColsWidthMode={this.toggleColsWidthMode}
                colsMode={this.state.colsMode}
              />

              <ViewBody
                sceneId={sceneId}
                catalogId={catalogId}
                viewId={viewId}
                isWebForm={isWebForm}
                leftPanel={leftPanel}
                /* если нужно рендерить с крестиком, то это значит что нужно открывать все данные в модальном окне */
                openRecordsInModal={openRecordsInModal}
                getFieldsToRender={this.getFieldsToRender}
                colsMode={this.state.colsMode}
              />
            </div>
          );
        }}
      />
    );
  }
}

export default connect(
  View,
  {
    userSettings: ['userSettings', 'catalogs'],
    scenes: ['scenes'],
    catalogs: ['catalogs'],
  },
  (props, { userSettings, scenes, catalogs }) => {
    const { sceneId, catalogId } = props;
    const catalog = catalogs.get(catalogId);
    const shouldClose = scenes.getIn([sceneId, 'shouldClose']);
    const userSettingsFields = catalogId && userSettings.getIn([catalogId, 'viewMode', 'catalogHistory', 'fields']);

    const defaultViewId = userSettings.getIn([catalogId, 'options', 'defaultViewId']);

    const defaultViewMode = props.defaultViewMode || userSettings.getIn([catalogId, 'options', 'defaultViewMode']);

    return {
      ...props,
      catalogId,
      catalog,
      userSettingsFields,
      defaultViewId,
      defaultViewMode,
      shouldClose,
      scenes,
    };
  },
);
