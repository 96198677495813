export default (t) => ({
  hidden: false,
  element: 'bpmn:ServiceTask',
  service: 'convertDoc',
  title: t('script.controlls.convertDoc.title'),
  helplink: '',
  class: 'other',
  color: '#666B70',
  border: 2,
  icon: 'anticon-icon files-70',
  offset_x: 0,
  offset_y: -15,
  toolbar_offset_y: -10,
  priority: 4,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        defaultEmptyValue: t('script.controlls.convertDoc.title'),
        type: 'text',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section',
      name: t('script.controlls.convertDoc.dataSource'),
      type: 'group',
    },
    {
      id: 'method',
      name: t('script.controlls.convertDoc.methodName'),
      type: 'radiobutton',
      readOnly: false,
      hint: t('script.controlls.convertDoc.methodHint'),
      config: {
        items: [
          {
            id: 'html-pdf',
            name: 'HTML → PDF',
            color: 'E7E9ED',
          },
          {
            id: 'excel-json',
            name: 'Excel → JSON',
            color: 'E7E9ED',
          },
          {
            id: 'xml-json',
            name: 'XML → JSON',
            color: 'E7E9ED',
          },
        ],
        type: 'select',
        map: 'input',
      },
      configOld: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: '',
      },
    },
    {
      id: 'source_format',
      name: t('script.controlls.convertDoc.source'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'catalog',
            name: t('script.controlls.convertDoc.sourceCatalog'),
            color: 'E7E9ED',
          },
          {
            id: 'url',
            name: t('script.controlls.convertDoc.sourceUrl'),
            color: 'E7E9ED',
          },
          {
            id: 'content',
            name: t('script.controlls.convertDoc.sourceContent'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['catalog'],
        map: 'input',
      },
    },
    {
      id: 'source_catalogId',
      name: t('script.common.catalog'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'catalogs',
        map: 'input',
      },
      visible: {
        source_format: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'source_recordId',
      name: t('script.common.recordId'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
      visible: {
        source_format: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'source_fieldId',
      name: t('script.controlls.convertDoc.field'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'fields',
        subtypes: ['file'],
        parent: 'source_catalogId',
        map: 'input',
      },
      visible: {
        source_format: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'source_content',
      name: t('script.controlls.convertDoc.content'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        placeholder: t('script.common.valueExpression'),
        map: 'input',
        theme: 'script',
      },
      visible: {
        source_format: {
          $include: 'content',
        },
      },
    },
    {
      id: 'source_url',
      name: t('script.controlls.convertDoc.url'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.convertDoc.urlHint'),
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
      visible: {
        source_format: {
          $include: 'url',
        },
      },
    },
    {
      id: 'section_data',
      name: t('script.common.params'),
      type: 'group',
    },
    {
      id: 'data_format',
      name: t('script.common.valueFormat'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'keyvalue',
            name: t('script.common.keyValue'),
            color: 'E7E9ED',
          },
          {
            id: 'json',
            name: t('script.common.json'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['keyvalue'],
        map: 'input',
      },
    },
    {
      id: 'data_values',
      name: t('script.common.data'),
      type: 'pair',
      readOnly: false,
      config: {
        placeholderValue: t('script.controlls.convertDoc.constant'),
        placeholderSubValue: t('script.common.valueExpression'),
        type: 'script',
        theme: 'script',
        map: 'input',
      },
      visible: {
        data_format: {
          $include: 'keyvalue',
        },
      },
    },
    {
      id: 'data_json',
      name: t('script.common.data'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.convertDoc.jsonHint'),
      config: {
        type: 'multiline',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
      visible: {
        data_format: {
          $include: 'json',
        },
      },
    },
    {
      id: 'section_output',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'output_format',
      name: t('script.common.saveAs'),
      type: 'dropdown',
      readOnly: false,
      config: {
        items: [
          {
            id: 'catalog',
            name: t('script.controlls.convertDoc.saveCatalog'),
            color: 'E7E9ED',
          },
          {
            id: 'url',
            name: t('script.controlls.convertDoc.saveUrl'),
            color: 'E7E9ED',
          },
          {
            id: 'content',
            name: t('script.controlls.convertDoc.saveContent'),
            color: 'E7E9ED',
          },
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ['catalog'],
        map: 'input',
      },
    },
    {
      id: 'output_catalogId',
      name: t('script.common.catalog'),
      type: 'radiobutton',
      readOnly: false,
      config: {
        items: [],
        type: 'select',
        data: 'catalogs',
        map: 'input',
      },
      visible: {
        output_format: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'output_recordId',
      name: t('script.common.recordId'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
      visible: {
        output_format: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'output_fieldId',
      name: t('script.controlls.convertDoc.field'),
      type: 'radiobutton',
      readOnly: false,
      hint: t('script.controlls.convertDoc.fieldHint'),
      config: {
        items: [],
        type: 'select',
        data: 'fields',
        subtypes: ['file'],
        parent: 'output_catalogId',
        map: 'input',
      },
      configOld: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.numberExpression'),
      },
      visible: {
        output_format: {
          $include: 'catalog',
        },
      },
    },
    {
      id: 'output_filename',
      name: t('script.controlls.convertDoc.fileName'),
      type: 'text',
      readOnly: false,
      hint: t('script.controlls.convertDoc.fileHint'),
      config: {
        type: 'text',
        theme: 'script',
        map: 'input',
        placeholder: t('script.common.valueExpression'),
      },
      visible: {
        output_format: {
          $in: ['catalog', 'url'],
        },
      },
    },
    {
      id: 'output_url',
      name: t('script.common.saveUrl'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        output_format: {
          $in: ['catalog', 'url'],
        },
      },
    },
    {
      id: 'output_content',
      name: t('script.common.saveContent'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
      visible: {
        output_format: {
          $include: 'content',
        },
      },
    },
  ],
  exits: [],
});
