import _ from 'lodash';
import { InputMappers, OutputMappers } from './helpers/mappers';
import i18n from '../../../../configs/i18n';

const Properties = (field, t = i18n.t) => {
  const isSystemField = _.startsWith(field && field.get('id'), '$');
  const id = _.isNull(field.get('newId')) ? '' : (field && field.get('newId')) || (field && field.get('id')) || '';

  const prevId = field.get('prevId');
  const visiblePrevId = id !== prevId && field && field.get('duplicateResultWithPrevId') == true;

  return {
    properties: [
      {
        id: 'technicalPropertiesSection',
        name: t('catalogEditor.fieldProperties.technicalProperties.section'),
        type: 'group',
      },
      {
        id: 'id',
        name: t('catalogEditor.fieldProperties.technicalProperties.id.label'),
        type: 'text',
        readOnly: isSystemField,
        hint: t('catalogEditor.fieldProperties.technicalProperties.id.hint'),
        config: {
          type: 'text',
        },
      },
      ...(id !== prevId
        ? [
            {
              id: 'duplicateResultWithPrevId',
              name: t('catalogEditor.fieldProperties.technicalProperties.duplicateResultWithPrevId.label'),
              type: 'checkboxes',
              readOnly: false,
              config: {
                items: [
                  {
                    id: 'true',
                    name: t('catalogEditor.fieldProperties.technicalProperties.duplicateResultWithPrevId.value'),
                  },
                ],
              },
            },
          ]
        : []),
      ...(visiblePrevId
        ? [
            {
              id: 'prevId',
              name: t('catalogEditor.fieldProperties.technicalProperties.prevId.label'),
              hint: t('catalogEditor.fieldProperties.technicalProperties.prevId.hint'),
              type: 'text',
              readOnly: false,
              config: {
                type: 'text',
              },
            },
          ]
        : []),
      {
        id: 'comment',
        name: t('catalogEditor.fieldProperties.technicalProperties.comment'),
        type: 'text',
        readOnly: false,
        config: {
          type: 'multiline',
        },
      },
    ],

    values: {
      id,
      prevId,
      history: InputMappers.boolToCheckbox(field.get('history')),
      filterable: InputMappers.boolToCheckbox(field.get('filterable')),
      apiOnly: InputMappers.boolToCheckbox(field.get('apiOnly')),
      duplicateResultWithPrevId: InputMappers.boolToCheckbox(field.get('duplicateResultWithPrevId')),
      comment: field.get('comment'),
    },

    getValues: (property, value) => {
      switch (property) {
        case 'history':
        case 'filterable':
        case 'apiOnly':
        case 'duplicateResultWithPrevId':
          value = OutputMappers.checkboxToBool(value);
          break;
      }
      return { [property]: value };
    },
  };
};

export default Properties;
