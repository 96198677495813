import Reflux from 'reflux';

export default function createAsyncAction(fn, options = {}) {
  const action = Reflux.createAction({
    ...options,
    asyncResult: true,
    sync: true,
  });
  action.trigger = (function (originalFn) {
    return function (...args) {
      originalFn.apply(this, args);
      return fn.apply(this, args);
    };
  })(action.trigger);
  return action;
}
