import React from 'react';
import { useTranslation } from 'react-i18next';
import calculateRemainsRecords from '../../../utils/calculateRemainsRecords';

import Icon from '../../common/UI/Icon';
import { connect } from '../../StateProvider';

import styles from './appHeader.less';

function RecordsCountIndicator({ ...props }) {
  const { t, i18n } = useTranslation();
  const { company, license } = props;
  const recordsLimit = license.get('records') || 0;
  const recordsCount = company.get('recordsCount') || 0;

  if (!recordsLimit) {
    return null;
  }

  const { recordsRemainingPercentage, recordsRemained } = calculateRemainsRecords(recordsLimit, recordsCount);

  let icon = '';
  let color = '';
  const text =
    `${t('license.records.leftText') + new Intl.NumberFormat(i18n.language).format(recordsRemained)} (${
      recordsRemainingPercentage
    }%)` +
    `\n${t('license.records.countText')}${new Intl.NumberFormat(i18n.language).format(recordsCount)}${t(
      'license.records.limitText',
    )}${new Intl.NumberFormat(i18n.language).format(recordsLimit)}\n${t('license.records.updateText')}`;

  if (recordsRemainingPercentage < 1) {
    icon = 'hardware-51';
    color = '#ff8888';
  } else if (recordsRemainingPercentage < 25) {
    icon = 'hardware-51';
    color = '#ffee99';
  } else if (recordsRemainingPercentage < 75) {
    icon = 'hardware-52';
  } else if (recordsRemainingPercentage < 100) {
    icon = 'hardware-53';
  }
  return (
    <div className={styles.recordsCountIndicatorContainer} style={color ? { color } : {}} title={text}>
      <Icon type={`icon ${icon}`} className={styles.recordsCountIndicatorIcon} />
      <span className={styles.recordsCountIndicatorCharge}>
        {recordsRemainingPercentage}
        <span className={styles.recordsCountIndicatorPercent}>%</span>
      </span>
    </div>
  );
}

export default connect(RecordsCountIndicator, ['company', 'license']);
