import React from 'react';

import Icon from '../../../../../common/UI/Icon';

import styles from '../companyInfo.less';

function LicenseEmpty(props) {
  return (
    <div className={styles.containerLicenseEmpty}>
      <Icon className={styles.iconLicensceEmpty} type="icon programing-61" />
      <span>{props.t('userMenu.license.licenseEmpty')}</span>
    </div>
  );
}

export default LicenseEmpty;
