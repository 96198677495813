import _ from 'lodash';
import Immutable from 'immutable';

import FIELD_TYPES from '../configs/fieldTypes';
import AppState from '../appState';

export default {
  /*
   * Приводит конфиг path в формат ['{some}', '{1}', '{some}']
   *
   * @path {catalogId, fieldId}
   *
   * @returns ['catalogs', '{catalogId}', 'filters', 'fields', '{fieldId}']
   */
  getViewFilterPath({ sceneId, viewId, fieldId }) {
    if (Number(viewId) === 0) {
      viewId = '$new';
    }
    return ['scenes', sceneId, 'views', viewId, 'filters', fieldId];
  },

  getViewFiltersPath({ sceneId, viewId }) {
    return ['scenes', sceneId, 'views', viewId, 'filters'];
  },

  getFiltersPath({ sceneId }) {
    return ['scenes', sceneId, 'data', 'filters'];
  },

  // getCatalogFieldPath(path, catalogId) {
  //   return ['catalogs', catalogId, 'views', 'filters', 'fields', path.fieldId];
  // },

  // getCatalogFields(catalogId) {
  //   // return ['currentCatalog', 'filters', 'fields'];
  //   return ['catalogs', catalogId, 'views', 'filters', 'fields'];
  // },

  /**
   * @param type field
   * @param value
   */
  convertFilterValueForRequestByFieldType(value, type, field) {
    switch (type) {
      case FIELD_TYPES.USER:
        return _.map(value, (item) => item.id);
      case FIELD_TYPES.OBJECT:
        return _.map(value, (item) => {
          value = _.pick(item, ['catalogId', 'recordId', 'filters']);
          if (value.filters) {
            // extended fields
            let extendedCatalogFields = field.getIn(['config', 'fields', item.catalogId]) || Immutable.List();
            // additional extended fields
            let extendedCatalogAllFields = AppState.getIn(['catalogs', item.catalogId, 'fields']) || Immutable.List();
            // remove duplicates
            extendedCatalogAllFields = extendedCatalogAllFields.filter(
              (f1) => !extendedCatalogFields.some((f2) => f2.get('id') == f1.get('id')),
            );
            // merge extended fields with additional extended fields
            extendedCatalogFields = extendedCatalogFields.concat(extendedCatalogAllFields);
            // filter by extended fields
            value.filters = this.getFiltersForRequest(value.filters, extendedCatalogFields);
          }
          return value;
        });

      default:
        return value;
    }
  },

  mergeFilters(object1, object2) {
    if (!object1) {
      return object2;
    }

    if (!object2) {
      return object1;
    }

    const immutableCase = Immutable.Map.isMap(object1) && Immutable.Map.isMap(object2);

    if (immutableCase) {
      const merger = (oldVal, newVal) => {
        if (Immutable.List.isList(oldVal) && Immutable.List.isList(newVal)) {
          return oldVal.concat(newVal);
        }
        return oldVal.merge(newVal);
      };

      return object1.mergeWith(merger, object2);
    }
    object1 = object1 && object1.toJS ? object1.toJS() : object1;
    object2 = object2 && object2.toJS ? object2.toJS() : object2;

    const merger = (oldVal, newVal) => {
      if (_.isArray(oldVal) && _.isArray(newVal)) {
        return oldVal.concat(newVal);
      }
      return oldVal.merge(newVal);
    };

    return _.mergeWith(object1, object2, merger);
  },
  getFiltersForRequest(filters, fields) {
    // code moved from stores/filtersMixin.js
    const result = [];
    for (const fieldId in filters) {
      if (filters.hasOwnProperty(fieldId)) {
        const fieldFromCatalog = fields.find((f) => f.get('id') === fieldId);
        if (!fieldFromCatalog) {
          console.warn('not found field "%s" for filter', fieldId);
        } else {
          const type = fieldFromCatalog.get('type');
          let filter = filters[fieldId];
          if (!_.isArray(filter)) {
            // do not know why this happens
            filter = [filter];
          }
          for (const { value } of filter) {
            if (!_.isEmpty(value) || value === 0) {
              result.push({
                fieldId,
                value: this.convertFilterValueForRequestByFieldType(value, type, fieldFromCatalog),
              });
            }
          }
        }
      }
    }

    return result;
  },

  // filters for field visibility
  convertFiltersToNewFormat(filters) {
    // extended filters came from api as [{fieldId, value}, ]
    // but on client format should be {fieldId: value}
    if (_.isArray(filters)) {
      const filtersObj = {};
      _.forEach(filters, (f) => {
        const { fieldId, value } = f;
        filtersObj[fieldId] = value;
      });
      filters = filtersObj;
    }

    // filters came from api as {fieldId: value, }
    // but on client format should be {fieldId: [{value: {}}, ] }
    return _.reduce(
      filters,
      (hash, filter, fieldId) => {
        if ((_.isArray(filter) || _.isObject(filter)) && _.isEmpty(filter)) {
          return hash;
        }

        // extended fileds
        if (_.isArray(filter)) {
          filter = filter.map((item) => {
            if (item.filters) {
              const extFilters = this.convertFiltersToNewFormat(item.filters);
              item.filters = extFilters;
            }
            return item;
          });
        }

        const filterInNewFormat = _.isArray(filter) && _.get(filter, ['0', 'value']);

        if (!filterInNewFormat) {
          filter = [{ value: filter }];
        }

        hash[fieldId] = filter;

        return hash;
      },
      {},
    );
  },

  // filters for field visibility
  convertFiltersToPrevFormat(filters) {
    filters = !Immutable.Map.isMap(filters) ? Immutable.fromJS(filters) : filters;
    filters = filters
      // has values
      .filter((filtersByFieldId) => filtersByFieldId && filtersByFieldId.size > 0)
      .map((filtersByFieldId) => {
        let value = filtersByFieldId.getIn(['0', 'value']);
        // extended filters
        // только для полей сотрудник и связанный каталог
        if (Immutable.List.isList(value)) {
          value = value.map((item) => {
            if (item.get && item.get('filters')) {
              const extFilters = this.convertFiltersToPrevFormat(item.get('filters'));
              item = item.set('filters', extFilters);
            }
            return item;
          });
        }

        return value;
      });

    return filters;
  },

  prepareWidgetFilters(widget) {
    const { recordsFilter, ...otherWidhetProps } = widget;

    let filters = recordsFilter && recordsFilter.filters;

    if (!filters) {
      return widget;
    }

    filters = this.convertFiltersToNewFormat(filters);

    recordsFilter.filters = filters;
    return {
      ...otherWidhetProps,
      recordsFilter,
    };
  },

  prepareWidgetsFilters(widgets) {
    return widgets.map((widget) => this.prepareWidgetFilters(widget));
  },

  prepareWidgetFiltersForRequest(widget) {
    const filtersPath = ['recordsFilter', 'filters'];

    let filters = widget.getIn(filtersPath);

    if (!filters) {
      return widget;
    }

    // в графиках фильтры отправляются в запросах в прежней структуре (fieldId: value), отличной от структуры фильтров в записях ({ fieldId, value }), поэтмоу берем первый фильтр, тк можно отправить только один фильтр
    filters = this.convertFiltersToPrevFormat(filters);

    widget = widget.setIn(filtersPath, filters);

    return widget;
  },
};
