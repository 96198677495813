export default (t) => ({
  hidden: true,
  element: 'bpmn:ServiceTask',
  service: 'bpiumCalculate',
  title: t('script.controlls.bpiumCalculate.title'),
  helplink: '',
  class: 'bpium',
  color: '#7cab9e',
  border: 2,
  icon: 'anticon-icon files-51',
  offset_x: -5,
  offset_y: -15,
  priority: 1,
  config: [
    {
      id: 'commonSection',
      name: t('script.common.general'),
      type: 'group',
    },
    {
      id: 'name',
      name: t('script.common.title'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        valueType: 'const',
        map: 'input',
        defaultEmptyValue: t('script.controlls.bpiumCalculate.title'),
      },
    },
    {
      id: 'description',
      name: t('script.common.description'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'multiline',
        valueType: 'const',
        map: 'input',
      },
    },
    {
      id: 'section1',
      name: t('script.common.result'),
      type: 'group',
    },
    {
      id: 'output',
      name: t('script.common.saveTo'),
      type: 'text',
      readOnly: false,
      config: {
        type: 'text',
        map: 'output',
        placeholder: t('script.common.variableName'),
      },
    },
  ],
  exits: [],
});
