import * as React from 'react';
import classNames from 'classnames';
import LocaleReceiver from 'antd/es/locale-provider/LocaleReceiver';

const svgBaseProps = {
  width: '1em',
  height: '1em',
  fill: 'currentColor',
  'aria-hidden': 'true',
  focusable: 'false',
};

function Icon(props) {
  const {
    // affect outter <i>...</i>
    className,
    // affect inner <svg>...</svg>
    type,
    component: Component,
    viewBox,
    spin,
    rotate,
    tabIndex,
    onClick,
    // children
    children,
    // other
    theme, // default to outlined
    twoToneColor,
    ...restProps
  } = props;

  const classString = classNames(
    {
      [`anticon`]: true,
      [`anticon-${type}`]: Boolean(type),
    },
    className,
  );
  const svgClassString = classNames({
    [`anticon-spin`]: !!spin || type === 'loading',
  });
  let innerNode;
  const svgStyle = rotate
    ? {
        msTransform: `rotate(${rotate}deg)`,
        transform: `rotate(${rotate}deg)`,
      }
    : undefined;
  const innerSvgProps = {
    ...svgBaseProps,
    className: svgClassString,
    style: svgStyle,
    viewBox,
  };
  if (!viewBox) {
    delete innerSvgProps.viewBox;
  }
  // component > children > type
  if (Component) {
    innerNode = <Component {...innerSvgProps}>{children}</Component>;
  }
  if (children) {
    innerNode = (
      <svg {...innerSvgProps} viewBox={viewBox}>
        {children}
      </svg>
    );
  }
  if (typeof type === 'string') {
    const computedType = type;
    innerNode = <i className={svgClassString} type={computedType} primaryColor={twoToneColor} style={svgStyle} />;
  }
  let iconTabIndex = tabIndex;
  if (iconTabIndex === undefined && onClick) {
    iconTabIndex = -1;
  }
  return (
    <LocaleReceiver componentName="Icon">
      {(locale) => (
        <i
          aria-label={type && `${locale.icon}: ${type}`}
          {...restProps}
          tabIndex={iconTabIndex}
          onClick={onClick}
          className={classString}
        >
          {/* {innerNode} */}
        </i>
      )}
    </LocaleReceiver>
  );
}

export default Icon;
