import React from 'react';

import ButtonTransparent from '../../../../../../common/UI/ButtonTransparent';

import styles from './feedBack.less';

function FeedbackError({ setFeedbackError, t }) {
  return (
    <div className={styles.containerLicenseAbout}>
      <div className={styles.feedbackSuccessContainerItems}>
        <h4 style={{ margin: 0 }} className={styles.feedbackTitle}>
          {t('userMenu.feedback.feedbackError')}
        </h4>
        <ButtonTransparent className={styles.setFeedbackErrorBtn} onClick={() => setFeedbackError(false)}>
          {t('userMenu.feedback.buttons.repeat')}
        </ButtonTransparent>
      </div>
    </div>
  );
}

export default FeedbackError;
