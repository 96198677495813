import React from 'react';
import PropTypes from 'prop-types';

import { getParams } from '../utils';

import ListItem from './ListItem';

function LinkedRecordsInListMode(mainProps) {
  const { RecordSelect, sceneId, ...props } = mainProps;

  return (
    <>
      {props.items &&
        props.items.map((item) => {
          if (!item) {
            return;
          }

          const params = getParams(item, props.controlConfig, props.remoteGroup);
          return (
            <ListItem
              sceneId={sceneId}
              key={item.get('key')}
              item={item}
              params={params}
              apiOnly={props.apiOnly}
              editable={!props.readOnly}
              onRemoveItem={() => props.onRemoveItem && props.onRemoveItem(item)}
              onRestoreItem={() => props.onRestoreItem && props.onRestoreItem(item)}
            />
          );
        })}
      {RecordSelect ? <RecordSelect.type additionalItems={props.additionalItems} {...RecordSelect.props} /> : null}
    </>
  );
}

LinkedRecordsInListMode.propTypes = {
  items: PropTypes.object,
  readOnly: PropTypes.bool,
  apiOnly: PropTypes.bool,
  params: PropTypes.object,
  onClickItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default LinkedRecordsInListMode;
