import { InputMappers, OutputMappers } from '../helpers/mappers';
import i18n from '../../../../../configs/i18n';

const Properties = (field, t = i18n.t) => {
  const config = field.get('config');
  const trsPrefix = `catalogEditor.fieldProperties.configProperties.field.${field.get('type')}.`;

  return {
    properties: [],

    values: {},
  };
};

export default Properties;
