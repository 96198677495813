import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Switch from '../UI/ControlList/controls/Switch';

import styles from './dataTypes.less';

function SwitchField(props) {
  return <Switch {...props} wrapperClassName={cn(props.containerClassName, styles.switchField)} editable={false} />;
}

SwitchField.propTypes = {
  value: PropTypes.number,
  config: PropTypes.object,
};

export default SwitchField;
