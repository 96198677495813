// @flow
import _ from 'lodash';
import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
// $FlowIssue: Immutable **has** default export
import { withTranslation } from 'react-i18next';
// $FlowIssue: This in ignore list
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import debug from 'debug';
import Editor from './editor';
import components from './components';
import availableLinkedRecordActions from '../../actions/availableLinkedRecordActions';
import LinkedRecordCreate from '../Record/LinkedRecordCreate';
import makeApiRequest from '../../utils/makeApiRequest';
import findComponent from './helpers/findComponent';
import { getInput, getOutput } from './helpers/InputOutput';
import { parseInput, parseOutput } from './helpers/parameters';
import { parse as parseValue } from './helpers/parameters/types/value';

import ScriptSideBar from './sideBar';
import emptyScript from './emptyScript';
import { getSystemIds } from './helpers/getSystemIds';

const log = debug('BPM:Script');

function getDefaultQuery() {
  return {
    query: { skipPrevId: false },
  };
}

function Container(props) {
  return <div className="bpmn-body">{props.children}</div>;
}

// type TField = {
//   id: string,
//   name: string,
//   type: string,
//   config: {
//     type: ?string,
//     items: ?Array<any>,
//     defaultEmptyValue: ?any,
//   },
// };

// type TComponent = {
//   element: string,
//   service: string,
//   title: string,
//   helplink: string,
//   class: string,
//   color: string,
//   border: number,
//   icon: string,
//   offset_x: number,
//   offset_y: number,
//   priority: number,
//   config: Array<TField>,
// };

// type Props = {
//   file: string,
//   onCancel: Function,
//   saveFn: Function,
// };

// type State = {
//   config: Array<TField>,
//   component: ?Object,
//   values: ?Object,
//   element: ?Object,
//   sideBarOpen: boolean,
// };

function isNoProps(element) {
  if (!element) {
    return true;
  }

  if (element.type === 'label') {
    return true;
  }

  // https://github.com/bpmn-io/bpmn-js-properties-panel/blob/2568265384484c2fd18fad70a9c17e5c44f15941/lib/provider/camunda/parts/SequenceFlowProps.js#L169
  if (element.type === 'bpmn:SequenceFlow') {
    const CONDITIONAL_SOURCES = [
      'bpmn:Activity',
      'bpmn:ExclusiveGateway',
      'bpmn:InclusiveGateway',
      'bpmn:ComplexGateway',
    ];
    return !CONDITIONAL_SOURCES.includes(_.get(element.source, 'type'));
  }
}

class ScriptBody extends Component {
  // state: State;
  // onChange: Function;
  // onSelectElement: Function;
  // onSave: Function;
  // onClose: Function;
  // closeSideBar: Function;
  // editor: ?Editor;

  constructor(props) {
    super(props);
    this.state = {
      config: [],
      component: null,
      values: null,
      element: null,
      sideBarOpen: false,
      xml: emptyScript(this.props.t),
    };
    this.onChange = this.onChange.bind(this);
    this.onSelectElement = this.onSelectElement.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.closeSideBar = this.closeSideBar.bind(this);
    this.editor = null;

    this.getViews = _.memoize((catalogId) => makeApiRequest(`/catalogs/${catalogId}/views`).then((r) => r.body));

    this.getCatalog = _.memoize((id) => makeApiRequest(`/catalogs/${id}`, getDefaultQuery()).then((r) => r.body));

    this.getRecord = _.memoize(
      (catalogId, recordId) =>
        makeApiRequest(`/catalogs/${catalogId}/records/${recordId}`, getDefaultQuery()).then((r) => r.body),
      (...args) => JSON.stringify(args.map(String)),
    );

    this.getLinkedRecord = _.memoize(
      (catalogId, recordId) =>
        this.getCatalog(catalogId).then((c) =>
          this.getRecord(c.id, recordId).then((r) => {
            const text = r.title;
            const { icon } = c;
            const item = {
              catalogId: c.id,
              sectionId: c.sectionId,
              recordId,
              recordTitle: r.title,
              catalogIcon: c.icon,
            };
            return [{ key: recordId, text, icon, item }];
          }),
        ),
      (...args) => JSON.stringify(args),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      const xml = emptyScript(this.props.t);
      this.setState({ xml });
    }
  }

  onSelectElement(element) {
    if (isNoProps(element)) {
      this.setState({
        component: null,
        values: null,
        element: null,
        sideBarOpen: false,
      });
      return;
    }

    const businessObject = getBusinessObject(element);
    const inputParameters = getInput(businessObject);
    const outputParameters = getOutput(businessObject);

    log('element', element);

    const values = {
      name: businessObject.name,
      description: _.get(businessObject, 'documentation.0.text'),
      ...parseInput(inputParameters),
      ...parseOutput(outputParameters),
    };

    const component = findComponent(businessObject, this.props.t);
    if (!component) {
      this.setState({ sideBarOpen: false });
      return;
    }

    let { config } = component;
    config.forEach((item) => {
      if (!item) {
        return;
      }

      const { config = {} } = item;

      switch (config.map) {
        case 'output':
          const value = values[item.id];
          // helper parseInput/parseOutput deosn't know about field type
          if (item.type === 'pair') {
            if (value) {
              values[item.id] = _.isArray(value) ? value : [value];
            }
          } else {
            values[item.id] = _.isPlainObject(value) ? value.key : value;
          }
          break;
        case 'expression':
          values[item.id] = parseValue(_.get(businessObject, 'conditionExpression.body'));
          break;
        case 'timer':
          values[item.id] = _.get(businessObject, 'eventDefinitions[0].timeDuration.body');
          break;
        case 'outputErrorCode':
          values[item.id] = _.get(businessObject, 'eventDefinitions.0.errorCodeVariable');
          break;
        case 'outputErrorMessage':
          values[item.id] = _.get(businessObject, 'eventDefinitions.0.errorMessageVariable');
          break;
      }

      switch (item.type) {
        case 'pair':
          values[item.id] = (values[item.id] || []).map((item) => ({
            value: item.key,
            subValue: item.value,
          }));
          break;
        case 'radiobutton':
          // eslint-disable-next-line no-multi-assign
          let value = (values[item.id] = values[item.id] || config.defaultEmptyValue || '');

          value = getSystemIds(value);

          // if field is a select of catalogs
          // so need to get the list of available catalogs
          // and update depended fields
          if (config.data === 'catalogs') {
            const catalogs = [];
            window.appState.get('catalogs').forEach((catalog) => {
              catalogs.push({
                name: catalog.get('name'),
                id: getSystemIds(catalog.get('id')), // TODO : may be not need anymore
              });
            });
            config.items = catalogs;
            value && requestAnimationFrame(() => this.updateConfig(item.id, value));
          }
          break;
        case 'object':
          const recordId = values[item.id];
          if (recordId) {
            values[item.id] = [{ key: recordId, text: null, item: null }];
            this.getLinkedRecord(config.catalogId, recordId).then((value) => {
              const values = {
                ...this.state.values,
                [item.id]: value,
              };
              this.setState({
                values,
              });
            });
          }

          if (config.catalogId) {
            item.requestParams = { catalogId: config.catalogId };
            item.elementsRemoteGroup = 'linkedRecords';
            item.loadAvailableItems = availableLinkedRecordActions.loadAvailableItems;
            item.clearAvailableItems = availableLinkedRecordActions.clearAvailableItems;

            if (config.enableCreate) {
              item.additionalClickItems = [
                {
                  id: 'add',
                  title: 'Добавить',
                  text: `Добавить ${item.name}`,
                  sort: -1,
                  selectable: false,
                  filterable: false,
                  children: ({ linkProps }) => (
                    <LinkedRecordCreate
                      catalogId={config.catalogId}
                      linkProps={linkProps}
                      onCreate={({ id }) => {
                        this.getLinkedRecord(config.catalogId, id).then((value) => this.onChange(item.id, value));
                      }}
                    />
                  ),
                },
              ];
            } else {
              item.additionalClickItems = [];
            }
          }
          break;
        case 'dropdown':
          values[item.id] = values[item.id] || config.defaultEmptyValue || [];
          break;
        case 'text':
          values[item.id] = values[item.id] || config.defaultEmptyValue || '';
          break;
        case 'number':
          values[item.id] = values[item.id] || config.defaultEmptyValue || 0;
          break;
        default:
          break;
      }
    });

    // map config to "ControlList"
    // be able to calc visible fields
    config = config.map((item) => ({
      ...item,
      visibleRules: item.visible,
    }));

    this.setState({
      config,
      component,
      element,
      sideBarOpen: true,
      values,
    });
  }

  updateConfig(fieldId, value, clearChildValues) {
    let { config } = this.state;
    const field = config.find((testField) => testField.id === fieldId);
    if (!field) {
      return;
    }

    // if Field is a select of Catalogs, so need to prepare other depended fields
    // like fields with list of Fields or list of Views
    if (field.type === 'radiobutton' && field.config && field.config.data === 'catalogs') {
      // set loading state
      config = config.map((testField) => {
        if (testField.config && testField.config.parent === fieldId) {
          if (['views', 'fields'].indexOf(testField.config.data) !== -1) {
            return { ...testField, showLoading: true };
          }
        }
        return testField;
      });
      this.setState({ config });

      // get data
      this.getCatalog(value)
        .then(async (catalog) => {
          const views = await this.getViews(value);
          return { catalog, views };
        })
        .then(({ catalog, views }) => {
          config = this.state.config; // need reget because it can be changed by another field
          config = config.map((testField) => {
            // if field is depended from this one
            if (testField.config && testField.config.parent === fieldId) {
              if (!catalog) {
                return testField;
              }

              // preapare list
              switch (testField.config.data) {
                case 'views':
                  clearChildValues && this.onChange(testField.id, '0');
                  testField.config.items = [].concat([{ id: '0', name: 'Все записи' }], views);
                  break;

                case 'fields':
                  let items = catalog.fields || [];

                  // add ID as field (for sorting)
                  if (testField.type === 'radiobutton') {
                    // filter available fields by subtype
                    if (testField.config.subtypes && testField.config.subtypes.length) {
                      items = _.filter(items, (f) => testField.config.subtypes.indexOf(f.type) !== -1);
                    } else {
                      items = _.concat(
                        {
                          id: 'id',
                          name: 'ID записи',
                          type: 'number',
                        },
                        items,
                      );

                      // set default value
                      clearChildValues && this.onChange(testField.id, 'id');
                    }

                    // clean field not needed data
                    items = _.map(items, (f) => _.pick(f, ['id', 'name', 'type']));

                    testField.config.items = items;
                  } else if (testField.type === 'pair') {
                    const options = [];
                    let lastSection;
                    _.forEach(items, (i) => {
                      if (i.type == 'group') {
                        const o = {
                          label: i.name,
                          options: [],
                        };
                        lastSection = o;
                        options.push(o);
                      } else {
                        const o = {
                          label: i.name,
                          subLabel: i.id,
                          value: i.id,
                        };
                        if (!lastSection) {
                          // no sections in catalog
                          options.push(o);
                        } else {
                          // put to section
                          lastSection.options.push(o);
                        }
                      }
                    });

                    testField.config.options = options;
                  }

                  break;
                default:
                  break;
              }
            }
            testField.showLoading = false;
            return testField;
          });
          this.setState({ config });
        });
    }
  }

  async onChange(fieldId, value) {
    const clearChildValues = this.state.values[fieldId] != value;
    this.updateConfig(fieldId, value, clearChildValues);

    const { config } = this.state;
    const field = config.find((testField) => testField.id === fieldId);

    if (!field) {
      return;
    }

    if (field.type === 'radiobutton' && value && value.toString) {
      value = value.toString();
    }

    if (field.type === 'object') {
      value = value.map((item) => {
        const recordId = _.get(item, ['item', 'id']);
        return _.set(item, ['item', 'recordId'], recordId);
      });
    }

    const values = { ...this.state.values, [fieldId]: value };
    this.setState({
      values,
    });

    this.editor && this.editor.setValues(this.state.element, values, config);
  }

  onSave(xml) {
    this.editor && this.editor.setValues(this.state.element, this.state.values, this.state.config);
    this.props.saveFn(xml);
  }

  onClose() {
    this.props.onCancel();
  }

  closeSideBar() {
    this.setState({
      sideBarOpen: false,
    });
  }

  render() {
    const { component, config, values, xml } = this.state;
    const { params } = this.props;
    const _components = components.map((c) => c(this.props.t));

    return (
      <div className="bpmn-wrapper">
        <CSSTransitionGroup
          component={Container}
          transitionName={{
            enter: 'bpmn-rightEnter',
            enterActive: 'bpmn-rightEnterActive',
            leave: 'bpmn-rightLeave',
            leaveActive: 'bpmn-rightLeaveActive',
          }}
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
          transitionLeave
        >
          <div key="editor" className="bpmn-editor">
            <Editor
              file={this.props.file}
              saveFn={this.onSave}
              ref={(editor) => {
                this.editor = editor;
              }}
              bpmn={xml}
              onSelectElement={this.onSelectElement}
              toolbar={_components}
              onCancel={this.onClose}
            />
          </div>
          {this.state.sideBarOpen && (
            <ScriptSideBar
              params={params}
              component={component}
              config={config}
              values={values}
              onChange={this.onChange}
              onClose={this.closeSideBar}
              t={this.props.t}
            />
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(ScriptBody);
