import { Row } from 'antd';
import cn from 'classnames';
import React from 'react';

import Icon from '../../Icon';

import styles from './controls.less';

function Group({ id, title, subTitle, countText, closed, onClick, className }) {
  let content = title;

  if (subTitle) {
    content = (
      <>
        {title}: &nbsp;
        <span className={styles.groupSubTitle}>{subTitle}</span>
      </>
    );
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className={cn(styles.groupHeader, className)}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      onClick={() => onClick && onClick(id)}
    >
      <span className={styles.groupText}>
        {closed ? <Icon className={styles.groupExpandIcon} type="icon interface-86" /> : null}
        {content}
      </span>
      {closed && countText ? <span className={styles.groupCount}>{countText}</span> : null}
    </Row>
  );
}

export default Group;
