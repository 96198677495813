export const BATCH_RECORDS_STATUS = {
  LOADING: 'loading',
  READY: 'ready',
  UPDATING: 'updating',
  DELETING: 'deleting',
  PAUSED: 'paused',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const STATUSES_TO_NOT_SHOW_RESULT = [BATCH_RECORDS_STATUS.LOADING, BATCH_RECORDS_STATUS.READY];

export const EPOCH_SIZE = 1000;
