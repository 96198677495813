import React, { Component } from 'react';
import cn from 'classnames';

import style from './splitter.less';

class HandleBar extends Component {
  render() {
    const { transparent, handleMouseDown } = this.props;
    return (
      <div
        className={cn(style.handleBar, {
          [style.handleBarTransparent]: transparent,
        })}
        onMouseDown={(e) => handleMouseDown(e)}
        onTouchStart={(e) => handleMouseDown(e)}
      >
        <span className={style.handleBar_drag} />
      </div>
    );
  }
}

export default HandleBar;
