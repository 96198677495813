import _ from 'lodash';
import { Base64 } from 'js-base64';
import qs from 'qs';

const encodeBase64 = (value) => {
  value = JSON.stringify(value);
  return Base64.encode(value, true);
};

const decodeBase64 = (value) => {
  try {
    value = Base64.decode(value, true);
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
};

const parseQS = (search) => {
  const cleanSearch = search && search[0] === '?' ? search.slice(1) : search;
  return cleanSearch ? qs.parse(cleanSearch) : {};
};

const stringifyQS = (searchObject) => {
  let search = qs.stringify(searchObject);
  search = search ? `?${search}` : search;
  return search;
};

export const decode = (search, name, base64) => {
  const searchObject = parseQS(search);
  if (base64) {
    searchObject[name] = decodeBase64(searchObject[name]);
  }
  return searchObject;
};

export const encode = (search, name, value, base64) => {
  const searchObject = parseQS(search);
  if (value === '' || _.isUndefined(value) || _.isNull(value)) {
    delete searchObject[name];
  } else {
    const isEqual = _.isObject(value)
      ? _.isEqual(searchObject[name], value)
      : String(searchObject[name]) === String(value);

    if (!isEqual) {
      searchObject[name] = base64 ? encodeBase64(value) : value;
    }
  }

  return stringifyQS(searchObject, name);
};
