import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './dataTypes.less';

function ProgressField(props) {
  const className = cn(props.containerClassName, styles.simpleField);

  if (props.value == null) {
    return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
  }
  return <span className={className}>{`${+props.value.toFixed(2)}%`}</span>;
}

ProgressField.propTypes = {
  value: PropTypes.number,
  config: PropTypes.object,
};

export default ProgressField;
