import _ from 'lodash';
import Immutable from 'immutable';

import FIELD_TYPES from '../../../../configs/fieldTypes';
import GeneralProperties from './GeneralProperties';
import TechnicalProperties from './TechnicalProperties';

import i18n from '../../../../configs/i18n';
import { prepareProperties } from './helpers/prepareProperties';

const Fields = {
  [FIELD_TYPES.GROUP]: require('./fields/Group').default,
  [FIELD_TYPES.TEXT]: require('./fields/Text').default,
  [FIELD_TYPES.CONTACT]: require('./fields/Contact').default,
  [FIELD_TYPES.ADDRESS]: require('./fields/Address').default,
  [FIELD_TYPES.NUMBER]: require('./fields/Number').default,
  [FIELD_TYPES.DATE]: require('./fields/Date').default,
  [FIELD_TYPES.DROPDOWN]: require('./fields/Dropdown').default,
  [FIELD_TYPES.SWITCH]: require('./fields/Switch').default,
  [FIELD_TYPES.BUTTON]: require('./fields/Button').default,
  [FIELD_TYPES.CHECKBOXES]: require('./fields/Checkboxes').default,
  [FIELD_TYPES.RADIOBUTTON]: require('./fields/Radiobutton').default,
  [FIELD_TYPES.PROGRESS]: require('./fields/Progress').default,
  [FIELD_TYPES.STARS]: require('./fields/Stars').default,
  [FIELD_TYPES.USER]: require('./fields/User').default,
  [FIELD_TYPES.OBJECT]: require('./fields/Object').default,
  [FIELD_TYPES.FILE]: require('./fields/File').default,
};

const FieldProperties = (field, catalogFields, t = i18n.t) => {
  const FieldPropertiesByType = Fields[field.get('type')];
  const fieldProperties = [GeneralProperties(field, t), FieldPropertiesByType(field, t), TechnicalProperties(field, t)];

  // join properties & values
  let properties = [];
  let values = {};
  fieldProperties.forEach((f) => {
    if (f.properties) {
      properties = _.concat(properties, f.properties);
    }
    if (f.values) {
      values = _.assign(values, f.values);
    }
  });

  prepareProperties(field, properties, values);

  return {
    properties: Immutable.fromJS(properties),
    values: Immutable.fromJS(values),
    getValues: (property, value) => {
      let allValues = {};
      fieldProperties.forEach((f) => {
        if (f.getValues) {
          const values = f.getValues(property, value);
          value = values[property];
          allValues = _.assign(allValues, values);
        }
      });
      return allValues;
    },
  };
};

export default FieldProperties;
