import React, { Component } from 'react';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from '../../../StateProvider';
import actions from '../../../../actions/apiActions';

import Icon from '../../../common/UI/Icon';

import styles from './AppUpdateIndicator.less';

class AppUpdateIndicator extends Component {
  refreshTime = 60 * 60 * 1000;

  state = {
    isVersionDepreciated: false,
  };

  checkUpdate() {
    actions.getCompanyInfo();
  }

  refresh = () => {
    window.location = `${window.location.origin}?${this.props.appVersion}${window.location.hash}`;

    this.setState({ isVersionDepreciated: false });
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.checkUpdate(), this.refreshTime);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appVersion !== this.props.appVersion && prevProps.appVersion) {
      this.setState({ isVersionDepreciated: true });
    }
  }

  render() {
    const { isVersionDepreciated } = this.state;
    if (isVersionDepreciated) {
      return (
        <div className={styles.AppUpdateIndicator}>
          <div className={styles.text}>
            <Icon className={styles.icon} type="icon objects-2" />
            {this.props.t('refreshVersion.newVersion')}
          </div>
          <Button
            className={styles.warning}
            /* type: warning */
            onClick={this.refresh}
          >
            {this.props.t('refreshVersion.refresh')}
          </Button>
        </div>
      );
    }
    return null;
  }
}

export default withTranslation()(
  connect(AppUpdateIndicator, {
    appVersion: ['company', 'appVersion'],
  }),
);
