import React from 'react';
import NavRoute from './Route';

export default function mapRouteParams(
  Component,
  route,
  mapper = (params, props, { history, location }) => ({
    ...params,
    ...props,
    history,
    location,
  }),
) {
  return function MapProps(props) {
    return (
      <NavRoute
        route={route}
        children={(router) => {
          const { match } = router;
          const params = (match && match.params) || {};
          return <Component {...mapper(params, props, router)} />;
        }}
      />
    );
  };
}
